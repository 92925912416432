import React from 'react';
import { getTotalAmount } from '../components/common/selected-plans/selectedPlansUtils';
import { getSelectedPlans } from '../pages/public/dental-plans/dentalPlansUtils';
import { useGlobalStore } from '../store/globalStore';
import { useUserStore } from '../store/userStore';
import { setAdobeAnalytics } from './utils/globalUtils';

const GlobalAnalytics: React.FC = () => {
  const { loggedIn, webAccountGUID, role, userEmailAddress, userDateOfBirth } = useUserStore((state) => state);
  const { zipcode, county, brand, state, coverageDate, selectedPlan, selectedVisionPlan, selectedDentalPlan, subsidyresponse, subsidyValue } = useGlobalStore(
    (state) => state
  );
  const totalPremium = () => {
    const plans = getSelectedPlans(selectedPlan, selectedDentalPlan, selectedVisionPlan);
    return getTotalAmount(plans);
  };

  setAdobeAnalytics({
    customer: {
      authStatus: loggedIn ? 'yes' : 'no',
      webGuid: webAccountGUID,
      userType: role,
      email: userEmailAddress,
      birthDate: userDateOfBirth,
      zip: zipcode,
      county: county,
      state: state,
      brand: brand,
      coverageStartDate: coverageDate,
      medicalPlanId: selectedPlan?.plan?.plan[0].planID ? selectedPlan.plan.plan[0].planID : null,
      medicalPlanPrice: selectedPlan?.plan?.rateData?.rate?.totSubsidizedPrem ?? null,
      medicalPlanName: selectedPlan?.plan?.planMarketingName ? selectedPlan.plan.planMarketingName : null,
      dentalPlanId: selectedDentalPlan?.plan?.plan[0].planID ? selectedDentalPlan.plan.plan[0].planID : null,
      dentalPlanPrice: selectedDentalPlan?.plan?.rateData?.rate?.totSubsidizedPrem ?? null,
      dentalPlanName: selectedDentalPlan?.plan?.planMarketingName ? selectedDentalPlan.plan.planMarketingName : null,
      visionPlanId: selectedVisionPlan?.plan?.plan[0].planID ? selectedVisionPlan.plan.plan[0].planID : null,
      visionPlanPrice: selectedVisionPlan?.plan?.rateData?.rate?.totSubsidizedPrem ?? null,
      visionPlanName: selectedVisionPlan?.plan?.planMarketingName ? selectedVisionPlan.plan.planMarketingName : null,
      cartPremium: totalPremium(),
      subsidyAmount: subsidyresponse?.subsidyCalc?.[0]?.subsidyAmount ? subsidyresponse.subsidyCalc[0].subsidyAmount : null,
      subsidyEligible: subsidyresponse?.subsidyCalc?.[0]?.householdMemberElig?.[0]?.eligibility
        ? subsidyresponse.subsidyCalc[0].householdMemberElig[0].eligibility
        : null
    }
  });
  return null;
};
export default GlobalAnalytics;
