import { Button, Modal } from '@sydney-broker-ui/ios';

import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Controller, FormProvider, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCMSStaticContent, getCMSdynamicContent } from '../../../../api/cms/cmsUtil';
import { getZipcodeValidation } from '../../../../api/services/utilService';
import CustomRadioGroup from '../../../../components/common/custom-radio/CustomRadio';
import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import { saveDemographicInfo } from '../../../../components/common/public-nav-bar/SaveAndResumeLater.utils';
import ZipCode from '../../../../components/common/zip-code/ZipCode';
import Questioncards from '../../../../components/editable/QuestionCards/QuestionCards';
import { AGENT_SELECTION, CALIFORNIA_CODE, DATE_FORMATS, DENTAL_PLAN_TYPES, LANGUAGE_CODES, LQE_NY_STATE, LQE_STATES, MEDICAL_PLAN_TYPES, NAV_PATHS, PAGE_BLOCKS, PAGE_IDS, PLAN_TYPES, PLAN_TYPES_CONTENT, PLAN_TYPES_NAMES, PROFILETYPES, SCREEN_NAMES, USER_DOMAINS, USER_ROLES } from '../../../../shared/globalConstants';
import { arraysEqual, checkDateRangesInEnrollmentPeriods, compareDemographicsData, compareTwoObjects, convertApiDatatoFormDataFormat, filterDependentsOnParentRelationType, getNotMedicaidEligibleDependents, getPlanYear, isEmpty, isNullOrUndefined, isObjectEmpty } from '../../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../../store/globalStore';
import { useUserStore } from '../../../../store/userStore';
import { getNavigationInfo } from '../../login/login-modal/loginUtils';
import { getFormattedApplicantDetails } from '../../medical-plans/medicalPlansUtils';
import ApplicantInfo from '../applicant-info/ApplicantInfo';
import './BasicsLanding.scss';
import { deleteShopperAgentConnect, saveShopperAgentConnect } from './BasicsLandingService';
import { getShopperAgentConnectPayload } from './BasicsLandingUtils';
import { default as Content } from './messages.json';

function BasicsLanding() {
  const [openModal, setModalOpen] = useState(false);
  const [openAlertModal, setAlertModalOpen] = useState(false);
  const [showConfirmChangesModal, setShowConfirmChangesModal] = useState(false);
  const [removeDependents, setRemoveDependents] = useState(false);
  const [coverageTypeOptions, setCoverageTypeOptions] = useState([]);
  const [macCoverageTypeOptions, setMacCoverageTypeOptions] = useState<any[]>([]);
  const [macFlowShowPopup, setMacFlowShowPopup] = useState(false);
  const [macConflictPopup, setMacConflictPopup] = useState(false);
  const [dependentsDisable, setAddDependentsDisable] = useState(false);
  const Data = Content.data.basicspage;
  const [pageContent, setPageContent] = useState<any>(Data);
  const [questionCardContent, setQuestionCardContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [coverageTypeContent, setCoverageTypeContent] = useState<any>('');
  const [macTitle, setMacTitle] = useState(pageContent?.customRadiosQuestioner2.label);
  const [switchToShopper, setSwitchToShopper] = useState(false);
  const [updatedConflict, setUpdatedConflict] = useState(false);
  const [disableContinue, setDisableContinue] = useState(false);
  /*--- NAVIGATE ----*/
  let navigate = useNavigate();
  const location = useLocation();
  /*--- NAVIGATE ----*/

  /*----- Quote Store ------*/
  const { zipcode, state, brand, county, countyCode, year, planTypes, coverageDates, coverageDate, updateCoverageType, editZipCodeFormDetails, applicantFormDetails, updateApplicantFormDetails, editCoverageType, selectedPlan, updateCountyList, updateSelectedCounty, updateZipCodeResponse, updateZipCodeFormDetails, editZipCodeRes, updatePlanTypes, zipCodeRes, updateEditCoverageType, coverageType, resetEditZipCodeFormDetails, resetEditZipCodeRes, selectedDentalPlan, selectedVisionPlan, updateEditZipCodeRes, updateDataChosen, updateEditZipCodeFormDetails, updateEditPlanTypes, saveandResumeLaterFlag, editplanTypes, resetMedicalPlanStates, resetDentalPlanStates, resetVisionPlanStates, updateSelectedDentalPlan, updateSelectedVisionPlan, resetEyeDoctorStates, resetDentistsStates, resetDrugStoreStates, resetProviderStates, resetHouseHoldMemberValues, resetEstimateSavings, memberInfo, dataChosen, updateMemberPlanTypes, memberPlanTypes, updateShowRecommendationModal, updateChangeDemographicFlag, updateIsBasicsPageUpdated, agentInfo, updateMacConflictSenario } = useGlobalStore(
    (store) => store
  );
  /*----- Quote Store ------*/

  const stores = {
    globalStore: useGlobalStore(),
    userStore: useUserStore(),
    location: location.pathname
  };
  //const { role } = useUserStore((state) => state);

  /*-----getting AEM Static Content JSON-----*/
  document.body.classList.remove("no-scroll");
  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.BASICS_LANDING);
      let cmsResponse = response.data;
      content = cmsResponse.data.iospagecontentList.items[0].pageContent?.basicspage;
    } catch (error) {
      content = Content.data.basicspage;
      return false;
    } finally {
      setPageContent(content);
      setContentLoaded(true);
      if (stores.userStore.role === USER_ROLES.MEMBER) {
        setQuestionCardContent(content.questioncardContent);
      }
    }
    return true;
  }, [zipCodeRes]);

  const initiate = async () => {
    await getContent();
    // if (stores.userStore.role === USER_ROLES.MEMBER) {
    //   const questionCardData = await getCMSModelJsonContent(stores.userStore.isWellpoint ? QUESTION_CARDS_URL_WELLPOINT : QUESTION_CARDS_URL_ANTHEM);
    //   setQuestionCardContent(questionCardData.data);
    // }
  };

  useEffect(() => {
    initiate();
    if (stores.userStore.role === USER_ROLES.MEMBER && stores.userStore.demographicInfo && memberInfo.demographicInfo && dataChosen === '') {
      setMacConflictPopup(!compareDemographicsData(stores.userStore.demographicInfo, memberInfo.demographicInfo));
    }
  }, []);

  useEffect(() => {
    if (planTypes.length > 0 && stores.userStore.role === USER_ROLES.MEMBER) {
      if (coverageType === PLAN_TYPES.MEDICAL) {
        planTypes.includes(PLAN_TYPES_CONTENT[coverageType]) ? setDisableContinue(false) : setDisableContinue(true);
      } else {
        planTypes.includes(PLAN_TYPES_CONTENT.D) ? setDisableContinue(false) : setDisableContinue(true);
      }
    }
  }, [planTypes]);

  useEffect(() => {
    if (macConflictPopup) {
      stores.globalStore.updateLoginType('');
    }
  }, [macConflictPopup])

  useEffect(() => {
    if (stores.userStore.role === USER_ROLES.SHOPPER && switchToShopper) {
      updateCoverageTypeOptions(stores.userStore.isWellpoint ? pageContent?.customRadiosQuestionerWellpoint.options : pageContent?.customRadiosQuestioner1.options, planTypes, coverageType);
    }
  }, [stores.userStore.role]);

  const isFirstRender = useRef(true);
  useEffect(() => {
    if (isFirstRender.current && pageContent) {
      isFirstRender.current = false;
      if (stores.userStore.role !== USER_ROLES.MEMBER) {
        if (isEmpty(zipCodeRes) && !isEmpty(editZipCodeRes) && editZipCodeFormDetails && editCoverageType && editplanTypes && !isEmpty(editZipCodeFormDetails)) {
          updateZipCodeResponse(editZipCodeRes);
          updateZipCodeFormDetails(editZipCodeFormDetails.zipcode, editZipCodeFormDetails.county, editZipCodeFormDetails.year);
          updateCoverageType(editCoverageType);
          updatePlanTypes(editZipCodeFormDetails.county, editZipCodeFormDetails.year);
          updateCoverageTypeOptions(stores.userStore.isWellpoint ? pageContent?.customRadiosQuestionerWellpoint.options : pageContent?.customRadiosQuestioner1.options, editplanTypes, editCoverageType);
        } else {
          updateCoverageTypeOptions(stores.userStore.isWellpoint ? pageContent?.customRadiosQuestionerWellpoint.options : pageContent?.customRadiosQuestioner1.options, planTypes, coverageType);
        }
      } else {
        updateMemberPlanTypes(memberInfo.currentCoverages);
        if (memberPlanTypes.length > 0) {
          updateMacCoverageTypes(pageContent?.customRadiosQuestioner2.options, pageContent?.memeberCoveragesOptions, memberPlanTypes, pageContent?.memeberCoveragesOptionsHeader);
          updateCoverageType(PLAN_TYPES[memberPlanTypes[0]]);
        }
        updateApplicantFormDetails(applicantFormDetails);
      }
      return;
    }
  }, [pageContent]);

  /*----- PLAN TYPES Store ------*/
  const updateCoverageTypeOptions = (options, newPlanTypes?, resetCoverageType?) => {
    const updateOptions = options?.filter((type) => {
      return newPlanTypes ? newPlanTypes.indexOf(type.planType) !== -1 : planTypes.indexOf(type.planType) !== -1;
    });
    setCoverageTypeOptions(updateOptions);
    if (newPlanTypes.includes('MEDICAL') && resetCoverageType && MEDICAL_PLAN_TYPES.includes(resetCoverageType)) {
      methods.setValue('coverageType', resetCoverageType);
      updateCoverageType(resetCoverageType);
    } else if (newPlanTypes.includes('DENTAL') && resetCoverageType && DENTAL_PLAN_TYPES.includes(resetCoverageType)) {
      methods.setValue('coverageType', resetCoverageType);
      updateCoverageType(resetCoverageType);
    } else {
      methods.setValue('coverageType', updateOptions[0]?.value);
      updateCoverageType(updateOptions[0]?.value);
    }
  };
  /*----- PLAN TYPES Store ------*/

  /*---- mac Coverage types Method ----*/
  const updateMacCoverageTypes = (options, coveragesData, memplanTypes, headerOptions) => {
    if (coveragesData) {
      const selectedPlanOptions = coveragesData[memplanTypes.join("&").toLowerCase()];
      const index = options.findIndex((val) => val.id === (PLAN_TYPES_NAMES.MEDICAL).toLowerCase());
      if (!memplanTypes.includes(PLAN_TYPES_NAMES.MEDICAL) && index !== -1) {
        options.splice(index, 1);
      }
      options.forEach((option: any) => {
        option.label = selectedPlanOptions[(option.id).toLowerCase()];
      })
      setMacCoverageTypeOptions(options);
      setMacTitle(headerOptions[memplanTypes.join("&").toLowerCase()])
    }
  }
  /*---- mac Coverage types Method ----*/

  /*----- zipcode modal ----*/
  const openZipCodePopup = () => {
    setModalOpen(true);
  };

  const closeZipCodePopup = () => {
    setModalOpen(false);
    document.body.classList.remove("no-scroll");
  };

  const handleUpdateZipCode = (isEdit) => {
    showAlertModal(null);
    closeZipCodePopup();
  };
  /*----- zipcode modal ----*/

  const handleUpdateDependents = (value) => {
    setRemoveDependents(value);
  }


  /*-------- subscribe with slectors -----------*/
  let planTypesdestroyed = false;

  const unsub = useGlobalStore.subscribe(
    (currState) => currState.planTypes,
    (planTypes, prevState) => {
      if (planTypes.length > 0 && !arraysEqual(prevState, planTypes) && stores.userStore.role !== USER_ROLES.MEMBER) {
        setCoverageTypeOptions([]);
        updateCoverageTypeOptions(stores.userStore.isWellpoint ? pageContent?.customRadiosQuestionerWellpoint.options : pageContent?.customRadiosQuestioner1.options, planTypes);
        planTypesdestroyed = true;
      }
    },
    { fireImmediately: true }
  );

  if (planTypesdestroyed) {
    unsub();
  }

  let memberPlanTypesdestroyed = false;

  const memeberunsub = useGlobalStore.subscribe(
    (currState) => currState.memberPlanTypes,
    (memberPlanTypes, prevState) => {
      if (memberPlanTypes.length > 0 && !arraysEqual(prevState, memberPlanTypes) && stores.userStore.role === USER_ROLES.MEMBER) {
        updateMacCoverageTypes(pageContent?.customRadiosQuestioner2.options, pageContent?.memeberCoveragesOptions, memberPlanTypes, pageContent?.memeberCoveragesOptionsHeader);
        updateCoverageType(PLAN_TYPES[memberPlanTypes[0]]);
        memberPlanTypesdestroyed = true;
      }
    },
    { fireImmediately: true }
  );

  if (memberPlanTypesdestroyed) {
    memeberunsub();
  }

  let planyearChangedDestroyed = false;

  const planyearChangedsub = useGlobalStore.subscribe(
    (currState) => currState.year,
    (year, prevState) => {
      if (year !== prevState) {
        updateCoverageType(methods.getValues('coverageType'))
      }
    },
    { fireImmediately: true }
  );

  if (planyearChangedDestroyed) {
    planyearChangedsub();
  }

  /*-------- subscribe with slectors -----------*/

  /*--------- get Dynamic Content ------------*/
  const getdynamicContent = async (coverageType: string) => {
    let selectedCoverageType = PLAN_TYPES_CONTENT[coverageType]?.toLocaleLowerCase();
    let queryParam = ';state=' + zipCodeRes.stateCode + ';brand=' + brand + ';coveragetype=' + selectedCoverageType + ';role=' + stores.userStore.role?.toLowerCase() + ';year=' + year;
    if (stores.userStore.role === USER_ROLES.MEMBER) {
      queryParam = queryParam + ';existingplantype=' + memberPlanTypes.join('').toLowerCase();
    }
    await getCMSdynamicContent(PAGE_IDS.BASIC_LANDING, PAGE_BLOCKS.COVERAGE_TYPE, queryParam)
      .then((response) => {
        setCoverageTypeContent(response.data?.data.iosdynamiccontentList?.items[0]?.textsummary);
      })
      .catch((error) => {
        setCoverageTypeContent('');
      });
  };

  useEffect(() => {
    if (!isNullOrUndefined(coverageType)) {
      getdynamicContent(coverageType);
    }
  }, [coverageType, zipcode, year])

  /*--------- get Dynamic Content ------------*/

  /*--- check if current changes are changes are same as in store ----*/
  const checkIfBasicDetailsmatch = (applicantInfo, role?) => {
    const keysToExclude = ['firstName', 'email', 'disabled', 'address', 'isPolicyApplicant', 'gender', 'phoneNumber', 'phoneType', 'smsPermission']
    if (zipcode === editZipCodeFormDetails?.zipcode && state === editZipCodeFormDetails.state && brand === editZipCodeFormDetails.brand && county === editZipCodeFormDetails.county && countyCode === editZipCodeFormDetails.countyCode && year === editZipCodeFormDetails.year && (role !== USER_ROLES.MEMBER ? coverageType === editCoverageType : true) && compareTwoObjects(applicantFormDetails, applicantInfo, keysToExclude)) {
      return true;
    } else {
      return false;
    }
  }
  /*--- check if current changes are changes are same as in store ----*/
  /*------ show LQE based on Date config ----------*/
  const showLQESection = () => {
    if (stores.globalStore.year === stores.globalStore.enrollmentYear) {
      return checkDateRangesInEnrollmentPeriods(stores.globalStore.zipCodeRes.currentDateTimeInZone.toString(), ['WS', 'OE'], stores.globalStore.globalEnrollmentPeriods, stores.globalStore.state)
    }
    else {
      return false;
    }
  }

  /*------ show LQE based on Date config ----------*/

  let storeFormData = convertApiDatatoFormDataFormat(applicantFormDetails, state, stores.userStore.role);

  const methods = useForm<BasicsPageForm>({
    mode: 'onChange',
    defaultValues: {
      applicant: {
        applicantType: 'PRIMARY',
        firstName: '',
        dateOfBirth: '',
        email: '',
        phoneNumber: '',
        phoneType: '',
        smsPermission: false,
        isTabaccoUser: '',
        applicantId: "0",
        gender: undefined,
        status: "A"
      },
      coverageType: '',
      coverage: 'applicant-only',
      spouse: [],
      dependents: []
    },
    values: {
      applicant: { ...storeFormData.applicant },
      coverageType: coverageType ? coverageType : '',
      coverage: (storeFormData.spouse.length > 0 || storeFormData.dependents.length > 0) ? 'applicant-and-others' : 'applicant-only',
      spouse: storeFormData.spouse,
      dependents: storeFormData.dependents
    },
    resetOptions: {
      keepDirtyValues: true, // user-interacted input will be retained
      keepErrors: true, // input errors will be retained with value update
    },
  });

  const { errors } = methods.formState;

  const basicsPageFormSubmit = async (data: formDataInterface) => {
    methods.trigger();
    if (isEmpty(methods.formState.errors)) {
      let formData: Applicant[] = [{ ...data.applicant }, ...data.spouse, ...data.dependents];
      if (stores.userStore.role === USER_ROLES.MEMBER && data.coverageType !== editCoverageType) {
        resetMedicalPlanStates();
        resetDentalPlanStates();
        resetVisionPlanStates();
        resetProviderStates();
        resetDentistsStates();
        resetEyeDoctorStates();
        resetHouseHoldMemberValues();
        resetEstimateSavings();
      }
      //(selectedPlan.plan !== null || selectedPlan.plan !== undefined) &&
      if (!isObjectEmpty(editZipCodeFormDetails) && stores.userStore.role !== USER_ROLES.MEMBER) {
        if (!checkIfBasicDetailsmatch(formData)) {
          setShowConfirmChangesModal(true);
        } else {
          updateApplicantFormDetails(formData);
          checkForMacFlowPopUps();
        }
      } else {

        updateEditZipCodeRes(zipCodeRes);
        updateEditZipCodeFormDetails(zipcode, county, year);
        updateEditCoverageType(coverageType);
        updateEditPlanTypes(county, year);
        updateApplicantFormDetails(getFormattedApplicantDetails(formData));
        checkForMacFlowPopUps();
        if (!checkIfBasicDetailsmatch(formData, stores.userStore.role)) {

          if (stores.userStore.loggedIn) {
            const globalStore = {
              globalStore: useGlobalStore.getState(),
              userStore: useUserStore.getState(),
              location: location.pathname
            };
            await saveDemographicInfo(globalStore);
          }
          if (!isObjectEmpty(editZipCodeFormDetails)) {
            resetMedicalPlanStates();
            resetDentalPlanStates();
            resetVisionPlanStates();
            resetProviderStates();
            resetDentistsStates();
            resetEyeDoctorStates();
            updateSelectedDentalPlan({
              plan: null,
              applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(useGlobalStore.getState().applicantFormDetails)),
              matchedProviders: []
            });

            updateSelectedVisionPlan({
              plan: null,
              applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(useGlobalStore.getState().applicantFormDetails)),
              matchedProviders: []
            });
          }
        }
      }
    }
  }

  /*------- check if role memeber and coveragetype matches memberinfo Current coverages -----*/
  const checkForMacFlowPopUps = () => {
    if (stores.userStore.role === USER_ROLES.MEMBER && PLAN_TYPES[memberPlanTypes[0]] === coverageType) {
      setMacFlowShowPopup(true);
    } else {
      basicsNavigation();
    }
  }

  /*--------- Navigation -----------*/
  const basicsNavigation = () => {
    document.body.classList.remove("no-scroll");
    const data = methods.getValues();
    const formData = [{ ...data.applicant }, ...data.spouse, ...data.dependents];
    switch (data.coverageType) {
      case PLAN_TYPES.DENTAL:
        updateSelectedDentalPlan({
          ...useGlobalStore.getState().selectedDentalPlan,
          applicants: getFormattedApplicantDetails(formData),
        });
        navigate(NAV_PATHS.DENTAL_LISTING);
        break;
      case PLAN_TYPES.VISION:
        updateSelectedVisionPlan({
          ...useGlobalStore.getState().selectedVisionPlan,
          applicants: getFormattedApplicantDetails(formData),
        });
        updateShowRecommendationModal(true);
        break;
      default:
        navigate(NAV_PATHS.ESTIMATE_COST_SAVINGS);
    }
  }


  // const updateZipwitheditZip = () => {
  //   updateZipCodeResponse(editZipCodeRes);
  // }

  const handelChange = (event) => {
    showAlertModal(event);
    if (!MEDICAL_PLAN_TYPES.includes(event.target.value)) {
      stores.globalStore.resetEstimateSavings();
    }
    updateCoverageType(event.target.value);
    const valueToBeSet = (memberPlanTypes.length === 1 && memberPlanTypes[0] === PLAN_TYPES_CONTENT.V) ? false : (stores.userStore.role === USER_ROLES.MEMBER && event.target.value !== PLAN_TYPES[memberPlanTypes[0]]);
    if (valueToBeSet || ((memberPlanTypes.length === 1 && memberPlanTypes[0] === PLAN_TYPES_CONTENT.V) && (event.target.value === PLAN_TYPES.DENTAL || event.target.value === PLAN_TYPES.VISION))) {
      let storeFormData = convertApiDatatoFormDataFormat(applicantFormDetails, state);
      methods.reset(storeFormData);
      updateZipCodeResponse(editZipCodeRes);
      if (editZipCodeFormDetails) {
        updateZipCodeFormDetails(editZipCodeFormDetails?.zipcode, editZipCodeFormDetails?.county, editZipCodeFormDetails?.year);
        updatePlanTypes(editZipCodeFormDetails?.county, editZipCodeFormDetails?.year);
        updateCountyList();
        updateSelectedCounty(editZipCodeFormDetails?.county);
      }
    }
    setAddDependentsDisable(valueToBeSet);
  };

  const showAlertModal = (event) => {
    if (event && event.target.value && state === CALIFORNIA_CODE && DENTAL_PLAN_TYPES.includes(event && event.target.value) && filterDependentsOnParentRelationType(methods.getValues().dependents).length > 0) {
      setAlertModalOpen(true);
      setRemoveDependents(true);
    } else if (state !== CALIFORNIA_CODE && filterDependentsOnParentRelationType(methods.getValues().dependents).length > 0) {
      setAlertModalOpen(true);
      setRemoveDependents(true);
    }
  }

  /*----- Dependents alert Alert modal modal ----*/
  const closeAlertPopup = () => {
    setAlertModalOpen(false);
  }
  /*----- Dependents alert Alert modal modal ----*/

  /*----- revert form data confirmation modal ----*/
  const handleshowConfirmChangesModalClose = () => {
    setShowConfirmChangesModal(false);
    document.body.classList.remove("no-scroll");
  }

  const undoBasicFormChanges = () => {
    updateZipCodeResponse(editZipCodeRes);
    if (editZipCodeFormDetails) {
      updateZipCodeFormDetails(editZipCodeFormDetails?.zipcode, editZipCodeFormDetails?.county, editZipCodeFormDetails?.year);
      updatePlanTypes(editZipCodeFormDetails?.county, editZipCodeFormDetails?.year);
      updateCountyList();
      updateSelectedCounty(editZipCodeFormDetails?.county);
      if (editCoverageType) {
        updateCoverageType(editCoverageType);
      }
    }
    let storeFormData = convertApiDatatoFormDataFormat(applicantFormDetails, state, year);
    methods.reset({
      applicant: storeFormData.applicant,
      spouse: storeFormData.spouse,
      dependents: storeFormData.dependents,
      coverageType: coverageType || 'MDV',
      coverage: (storeFormData.spouse.length > 0 || storeFormData.dependents.length > 0) ? 'applicant-and-others' : 'applicant-only',
    });
    setShowConfirmChangesModal(false);
    document.body.classList.remove("no-scroll");
  }

  const updateBasicFormChanges = () => {
    updateEditZipCodeRes(zipCodeRes);
    updateEditZipCodeFormDetails(zipcode, county, year);
    updateEditCoverageType(coverageType);
    updateEditPlanTypes(county, year);
    let data = methods.getValues();
    let formData = [{ ...data.applicant }, ...data.spouse, ...data.dependents];
    updateApplicantFormDetails(formData);
    setShowConfirmChangesModal(false);
    resetMedicalPlanStates();
    resetDentalPlanStates();
    resetVisionPlanStates();
    resetEyeDoctorStates();
    resetDentistsStates();
    resetProviderStates();
    resetHouseHoldMemberValues();
    if (!saveandResumeLaterFlag) {
      checkForMacFlowPopUps();
    }
    else {
      updateIsBasicsPageUpdated(true);
    }
    document.body.classList.remove("no-scroll");
  }

  /*----- revert form data confirmation modal ----*/

  /*------ mac flow popups -----------*/
  const macFlowPopupClose = () => {
    setMacFlowShowPopup(false);
    document.body.classList.remove("no-scroll");
  }

  const macFlowPopupContinue = () => {
    document.body.classList.remove("no-scroll");
    basicsNavigation();
  }
  /*------ mac flow popups -----------*/

  /*------ mac coflict popup --------*/
  const conflictPopup = useForm<ConflictPopUp>({
    mode: 'onChange',
    defaultValues: {
      selectedOption: 'profile'
    }
  });

  const macConflictPopupClose = () => {
    document.body.classList.remove("no-scroll");
    setMacConflictPopup(false);
  }

  const agentInfoSaveDelete = (userSelection: string) => {
    if (userSelection === PROFILETYPES.POLICY) {
      // save AOR and delete AgentConnect if it's available other keep agent connect

      if (memberInfo && memberInfo?.agentOfRecord) {
        //saveAgentOfRecordData();
        const agentPayload = getShopperAgentConnectPayload(memberInfo?.agentOfRecord);
        saveShopperAgentConnect(agentPayload, stores.userStore.webAccountGUID);
        updateMacConflictSenario(true, userSelection, AGENT_SELECTION.AGENT_OF_RECORD);
        if (agentInfo) {
          //deleteAgentConnectData;
          deleteShopperAgentConnect(agentInfo?.shopperId as string);
        }
      } else if (agentInfo) {
        //saveAgentOfRecordData();
        updateMacConflictSenario(true, userSelection, AGENT_SELECTION.AGENT_CONNECT);
      }
      setUpdatedConflict(true);
    } else {
      if (agentInfo) {
        //take agentconnect 
        updateMacConflictSenario(true, userSelection, AGENT_SELECTION.AGENT_CONNECT);

      } else {
        //saveagentrecord 
        const agentPayload = getShopperAgentConnectPayload(memberInfo?.agentOfRecord);
        saveShopperAgentConnect(agentPayload, stores.userStore.webAccountGUID);
        updateMacConflictSenario(true, userSelection, AGENT_SELECTION.AGENT_OF_RECORD);
      }
      setUpdatedConflict(true);
    }
  }

  const conflictPopupSubmit = async (data: ConflictPopUp) => {
    if (data.selectedOption === PROFILETYPES.PROFILE) {
      updateDataChosen(PROFILETYPES.PROFILE);
      setSwitchToShopper(true);
      setMacConflictPopup(false);
      stores.userStore.updateRole(USER_ROLES.SHOPPER);
      updateEditZipCodeRes(zipCodeRes);
      updateEditZipCodeFormDetails(zipcode, county, year);
      updateEditCoverageType(coverageType);
      updateEditPlanTypes(county, year);
      const getURL = await getNavigationInfo(stores.userStore?.demographicInfo?.demographicId, USER_ROLES.SHOPPER);
      navigate(getURL);
      stores.globalStore.updateLoginType('');
    } else if (data.selectedOption === PROFILETYPES.POLICY) {
      updateDataChosen(PROFILETYPES.POLICY);
      resetMedicalPlanStates();
      resetProviderStates();
      resetDrugStoreStates();
      resetDentalPlanStates();
      resetDentalPlanStates();
      resetVisionPlanStates();
      resetEyeDoctorStates();
      updateChangeDemographicFlag(true);
      const response = await getZipcodeValidation({ zipCode: memberInfo.demographicInfo?.zipCode || '', marketSegment: USER_DOMAINS.MARKET_SEGMENT });
      const planYear = getPlanYear(response.data.zipCodeResponse?.zipCode?.coverageEffectiveDate?.coverageDt, PLAN_TYPES[memberPlanTypes[0]] || '');
      updateZipCodeResponse(response.data.zipCodeResponse.zipCode);
      updateZipCodeFormDetails(
        memberInfo.demographicInfo.zipCode || '',
        response.data.zipCodeResponse?.zipCode.countyList.county[0].countyName || '',
        planYear
      );
      updatePlanTypes(memberInfo.demographicInfo?.county || '', planYear);
      updateCoverageType(PLAN_TYPES[memberPlanTypes[0]] || '');
      updateApplicantFormDetails(memberInfo.demographicInfo?.applicant || []);
      updateEditZipCodeRes(response.data.zipCodeResponse.zipCode);
      updateEditZipCodeFormDetails(memberInfo.demographicInfo.zipCode || '',
        response.data.zipCodeResponse?.zipCode.countyList.county[0].countyName || '',
        planYear);
      updateEditCoverageType(coverageType);
      updateEditPlanTypes(memberInfo.demographicInfo?.county || '', planYear);
      setMacConflictPopup(false);
      stores.globalStore.updateLoginType('');
      agentInfoSaveDelete(data.selectedOption);
    }
  }
  /*------ mac coflict popup --------*/

  useEffect(() => {
    if (saveandResumeLaterFlag) {
      updateBasicFormChanges();
    }
  }, [saveandResumeLaterFlag]);

  return (!contentLoaded ? <FullPageLoader /> :
    <div className={'basics-landing-container'}>
      {stores.userStore.role === USER_ROLES.MEMBER && <div className="member-banner">
        <div className="fwc-row">
          <div className="fwc-col-12">
            <p><span className="sae-icon sae-icon-high-importance"></span>
              {memberPlanTypes.includes(PLAN_TYPES_NAMES.MEDICAL) ? pageContent?.memberBanner.medical : memberPlanTypes.includes(PLAN_TYPES_NAMES.DENTAL) ? pageContent?.memberBanner.dental : pageContent?.memberBanner.vision}</p>
          </div>
        </div>
      </div>}
      <div className="fwc-row">
        <div className="fwc-col-12">
          {dependentsDisable && <p className="custom-alert">
            <span className="sae-icon sae-icon-error"></span>
            {pageContent?.alert.zipcode}
          </p>}
          <h2 className="title">Basics</h2>
        </div>
      </div>
      <div className="fwc-row">
        <div className="fwc-col-12">
          <div className="update-zipcode-block">
            <div className="autopopulatefiedls">
              <p id="year">
                <span className="sae-icon sae-icon-event-accepted"></span>
                {pageContent?.zipcode.labels.coverageYear} <span>{year}</span>
              </p>
              <p id="zipcode">
                <span className="sae-icon sae-icon-marker"></span>
                {pageContent?.zipcode.labels.zipCode}{' '}
                <span>
                  {zipcode} ({county})
                </span>
              </p>
              {coverageDate && (
                <p id="coverage-date">
                  <span className="sae-icon sae-icon-checked"></span>
                  {pageContent?.zipcode.labels.begins} <span>{dayjs(coverageDate).format(DATE_FORMATS.MMDDYYYY)}</span>
                </p>
              )}
            </div>
            {(stores.userStore.role !== USER_ROLES.MEMBER ? true : !dependentsDisable) && <div>
              <a data-analytics="editYearDateBasicsIos" id="edit" aria-label={'Edit Zip Code'} href="javascript:void(0)" onClick={openZipCodePopup}>
                <span className="sae-icon sae-icon-create-new"></span>
              </a>
            </div>}
          </div>
        </div>
      </div>
      <FormProvider {...methods}>
        <form className="basics-page-fom" onSubmit={methods.handleSubmit(basicsPageFormSubmit)} noValidate>
          {coverageTypeOptions.length > 0 && stores.userStore.role !== 'MEMBER' && (
            <>
              <Controller
                name="coverageType"
                control={methods.control} // Set your desired default value
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomRadioGroup
                    field={field}
                    label={pageContent?.customRadiosQuestioner1.label}
                    name={pageContent?.customRadiosQuestioner1.name}
                    options={coverageTypeOptions}
                    onCustomRadioSelect={handelChange} // Set your desired default value
                    role={stores.userStore.role}
                  />
                )}
              />
              {coverageTypeContent && <div className="fwc-row content-coverage-selected-type">
                <div className="fwc-col-12">
                  <div dangerouslySetInnerHTML={{ __html: coverageTypeContent }}></div>
                </div>
              </div>}
              <div className="fwc-row">
                <div className="fwc-col-12">
                  {errors.coverageType?.message && (
                    <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                      {errors.coverageType?.message}
                    </span>
                  )}
                </div>
              </div>
            </>
          )}
          {macCoverageTypeOptions.length > 0 && stores.userStore.role === 'MEMBER' && (
            <>
              <Controller
                name="coverageType"
                control={methods.control} // Set your desired default value
                rules={{ required: true }}
                render={({ field }) => (
                  <CustomRadioGroup
                    field={field}
                    label={macTitle}
                    name={pageContent?.customRadiosQuestioner2.name}
                    options={macCoverageTypeOptions}
                    onCustomRadioSelect={handelChange} // Set your desired default value
                    role={stores.userStore.role}
                  />
                )}
              />
              {coverageTypeContent && <div className="fwc-row content-coverage-selected-type">
                <div className="fwc-col-12">
                  <div dangerouslySetInnerHTML={{ __html: coverageTypeContent }}></div>
                </div>
              </div>}
              <div className="fwc-row">
                <div className="fwc-col-12">
                  {errors.coverageType?.message && (
                    <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                      {errors.coverageType?.message}
                    </span>
                  )}
                </div>
              </div>
              {!showLQESection() && ((LQE_STATES.includes(state) && coverageType === PLAN_TYPES.MEDICAL) || (LQE_NY_STATE === state && memberPlanTypes.some((element) => coverageType === PLAN_TYPES[element]))) && <><div className="fwc-row">
                <div className="fwc-col-12 lqe-section">
                  {errors && errors.lqe && errors.lqe.type === "validate" && (
                    <div className="member-banner">
                      <p role="alert" className="lqe"><span className="sae-icon sae-icon-high-importance"></span>
                        {pageContent.VALIDATE_LQE}
                      </p>
                    </div>
                  )}
                  <p className="content">{pageContent.LQEText}</p>
                  {pageContent && pageContent.LQE && pageContent.LQE[state] && pageContent.LQE[state].map((radio) => {
                    return (
                      <div className="fwc-radio">
                        <input
                          {...methods.register(`lqe`, {
                            required: (stores.userStore.role === USER_ROLES.MEMBER),
                            validate: (value) => value === 'CLQE',
                          })}
                          type="radio"
                          value={radio.key}
                          id={state + radio.key}
                        />
                        <label htmlFor={state + radio.key} dangerouslySetInnerHTML={{ __html: radio.value }}></label>
                      </div>
                    )
                  })}
                </div>
              </div>
                <div className="fwc-row">
                  <div className="fwc-col-12">
                    {errors && errors.lqe && errors.lqe.type === "required" && (
                      <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {pageContent.REQUIRED_FIELD}
                      </span>
                    )}
                  </div>
                </div>
              </>}
            </>
          )}
          <ApplicantInfo role={stores.userStore.role} errors={methods.formState.errors} plan={coverageType} coverageDate={coverageDate} stateCode={state} removeDependents={removeDependents} disableFields={dependentsDisable} loggedIn={stores.userStore.loggedIn} onUpdateDependents={handleUpdateDependents} updatedConflict={updatedConflict} />
          <div className="form-actions-wrapper">
            <div className="fwc-row">
              <div className="fwc-col-12">
                <Button data-analytics="continueBtnBasicsIos" id="btContinue" type="submit" disabled={disableContinue}>
                  {pageContent?.continue}
                </Button>
              </div>
            </div>
            {/* <div className="fwc-row">
              <div className="fwc-col-12">
                <a className="left-xxs right-xxs" id={'create-profile'} onClick={() => navigate('/individuals/shop/basics/planselectionsummary')}>
                  <span className="sae-icon sae-icon-contacts right-xxs"></span> <span className={'global-nav-link'}>{'Go To Plan Selection Summary'}</span>
                </a>
              </div>
            </div> */}
          </div>
          {/* <div className="form-actions-wrapper">
            <div className="fwc-row">
              <div className="fwc-col-12">
                <button id="btContinue" type="submit">
                  {Data.CONTINUE}
                </button>
              </div>
            </div>
          </div> */}
        </form>
        {/* <DevTool control={methods.control} /> */}
      </FormProvider>
      {questionCardContent && stores.userStore.role === USER_ROLES.MEMBER && <Questioncards {...questionCardContent} />}
      {/*<div className="flex-align-right global-nav-links">*/}
      {/*  <div className="fwc-flex j-end">*/}
      {/*    <a className="left-xxs right-xxs" id={'create-profile'} onClick={() => setShowFindYourDoctor(true)}>*/}
      {/*      <span className="sae-icon sae-icon-contacts right-xxs"></span> <span className={'global-nav-link'}>{'Find your doctor'}</span>*/}
      {/*    </a>*/}
      {/*    <FindYourDoctor showFindYourDoctor={showFindYourDoctor} closeFindYourDoctor={() => setShowFindYourDoctor(false)} />*/}
      {/*    <a id={'medicationsList'} onClick={() => setShowMyMedications(true)}>*/}
      {/*      <span className="sae-icon sae-icon-pill right-xxs"></span> <span className={'global-nav-link'}>{'Show me my medications'}</span>*/}
      {/*    </a>*/}
      {/*    <ShowMyMedications showSlider={showMyMedications} onSubmit={handleMedicationsListSubmit} onChange={() => setShowMyMedications(false)} />*/}

      {/*    <a className="left-xxs right-xxs" id={'create-profile'} onClick={() => setShowFindYourDentist(true)}>*/}
      {/*      <span className="sae-icon sae-icon-contacts right-xxs"></span> <span className={'global-nav-link'}>{'show me My dentist'}</span>*/}
      {/*    </a>*/}
      {/*    <FindYourDentist showFindYourDentist={showFindYourDentist} closeFindYourDentist={() => setShowFindYourDentist(false)} />*/}
      {/*    <a className="left-xxs right-xxs" id={'create-profile'} onClick={() => navigate('/individuals/shop/basics/planselectionsummary')}>*/}
      {/*      <span className="sae-icon sae-icon-contacts right-xxs"></span> <span className={'global-nav-link'}>{'Go To Plan Selection Summary'}</span>*/}
      {/*    </a>*/}
      {/*    <a className="left-xxs right-xxs" id={'medicationsList'} onClick={() => setShowMedicalPlanDetails(true)}>*/}
      {/*      <span className="sae-icon sae-icon-contacts right-xxs"></span> <span className={'global-nav-link'}>{'Medical Plan Details'}</span>*/}
      {/*    </a>*/}
      {/*    <MedicalPlanDetails showMedicalPlanDetails={showMedicalPlanDetails} closeMedicalPlanDetails={() => setShowMedicalPlanDetails(false)} />*/}
      {/*  </div>*/}
      {/*</div>*/}
      {openModal && <Modal
        content="This content will be overridden with html body content if exist any!"
        id="modal2"
        onClose={closeZipCodePopup}
        title="Edit Basics"
        open={openModal}
      >
        <Modal.Body>
          <ZipCode onUpdateZipCode={handleUpdateZipCode} isEdit="true"></ZipCode>
        </Modal.Body>
      </Modal>}
      <div className='alertModals'>
        {openAlertModal && <Modal
          id="alertModal"
          onClose={closeAlertPopup}
          className={'alertModal'}
          title={pageContent.modals.alertModal.title}
          open={openAlertModal}
        >
          <Modal.Body>
            <p>{pageContent.modals.alertModal.description}</p>
          </Modal.Body>
        </Modal>}
        {showConfirmChangesModal && <Modal open={showConfirmChangesModal}
          id="confirmationModal" className={'alertModal'} onClose={handleshowConfirmChangesModalClose} title={pageContent.modals.confirmationModal.title}>
          <Modal.Body>
            <p>{pageContent.modals.confirmationModal.description}</p>
          </Modal.Body>
          <Modal.Footer>
            <Button
              data-analytics="closeBtnChangeDemoModalIos"
              btnType="secondary"
              id="modal-action-cancel"
              onClick={handleshowConfirmChangesModalClose}
            >
              {pageContent.modals.confirmationModal.buttons.close}
            </Button>
            <Button
              data-analytics="undoBtnChangeDemoModalIos"
              id="modal-action-undo"
              onClick={undoBasicFormChanges}
            >
              {pageContent.modals.confirmationModal.buttons.undo}
            </Button>
            <Button
              data-analytics="contBtnChangeDemoModalIos"
              id="modal-action-continue"
              onClick={async () => {
                updateBasicFormChanges();
                updateChangeDemographicFlag(true);
                resetProviderStates();
                resetDentistsStates();
                resetEyeDoctorStates();
                resetEstimateSavings();
                if (stores.userStore.loggedIn) {
                  const globalStore = {
                    globalStore: useGlobalStore.getState(),
                    userStore: useUserStore.getState(),
                    location: location.pathname
                  };
                  await saveDemographicInfo(globalStore);
                }
              }}
            >
              {pageContent.modals.confirmationModal.buttons.continue}
            </Button>
          </Modal.Footer>
        </Modal>}
        {macFlowShowPopup && <Modal open={macFlowShowPopup} onClose={() => macFlowPopupClose()} title={pageContent.modals.macFlowModals[memberPlanTypes[0]].TITLE}>
          <Modal.Body className="popup-body">
            <div id="popup-model">
              <div className="dental-plan-popup-container" id="popup-container">
                <p id="popup-content">{pageContent.modals.macFlowModals[memberPlanTypes[0]].YOUR_CURRENT_COVERAGE}</p>
                <p id="if-you-need">{pageContent.modals.macFlowModals[memberPlanTypes[0]].IF_YOU_NEED}</p>
              </div>
              <Modal.Footer>
                <Button btnType="secondary" id="close" onClick={() => { macFlowPopupClose(); }}>
                  {pageContent.modals.macFlowModals.BUTTONS.CLOSE_BUTTON}
                </Button>
                <Button btnType="primary" id="continue" onClick={macFlowPopupContinue}>
                  {pageContent.modals.macFlowModals.BUTTONS.CONTINUE_BUTTON}
                </Button>
              </Modal.Footer>
            </div>
          </Modal.Body>
        </Modal>}
        {macConflictPopup && <Modal open={macConflictPopup} onClose={() => macConflictPopupClose()} title={pageContent.modals.macConflictModal.title}>
          <Modal.Body className="popup-body">
            <div id="popup-model">
              <div className="conflict-popup-container" id="conflict-modal-container">
                <form className="mac-conflict-fom" onSubmit={conflictPopup.handleSubmit(conflictPopupSubmit)} noValidate>
                  <div className='popup-wrapper'>
                    {pageContent.modals.macConflictModal.options.map((option) => (
                      <div className="fwc-radio">
                        <input
                          {...conflictPopup.register(`selectedOption`, {
                            required: true,
                          })}
                          type="radio"
                          value={option.value}
                          id={'conflict' + option.value}
                        />
                        <label htmlFor={'conflict' + option.value} dangerouslySetInnerHTML={{ __html: option.label }}></label>
                      </div>
                    ))}
                  </div>
                  <Button btnType="primary" id="continue" type="submit">
                    {pageContent.modals.macFlowModals.BUTTONS.CONTINUE_BUTTON}
                  </Button>
                </form>
              </div>
            </div>
          </Modal.Body>
        </Modal>}
      </div>
    </div>
  );
}

export default BasicsLanding;