import { Popover, PreLoader } from "@sydney-broker-ui/ios";
import React, { useCallback, useEffect, useState } from "react";
import { getCMSStaticContent } from "../../../api/cms/cmsUtil";
import { GRADE_LEVELS } from "../../../pages/public/medical-plans/constants";
import { LANGUAGE_CODES, SCREEN_NAMES } from "../../../shared/globalConstants";
import { useGlobalStore } from "../../../store/globalStore";
import CompareSlider from "../compare-slider/CompareSlider";
import { getApplicableGrade, getGradeLabel, isMappedContract } from "../plan-card/planCardUtils";
import './CompareTray.scss';
import { default as Content } from "./compareTray.json";
import { COMPARE_PLAN_TYPES, PLAN_MARKET_TYPES } from "./constant";

interface CompareTrayProps {
    onClose?: () => void;
    plans?: any;
    labels?: any;
    planType: string;
    matchedProvidersFlag?: boolean;
    matchedMedicationsFlag?: boolean;
    handleSelectPlan: (contract: any) => void;
    onFavouriteClick: (favouritePlanContractCode: string) => void;
}

function CompareTray(props: CompareTrayProps) {
    const [pageContent, setContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [collapsed, setCollapsed] = useState(false);
    const [showSlider, setShowSlider] = useState(false);
    const [comparedPlans, setComparedPlans] = useState([] as any);
    const [anchor, setAnchor] = useState<React.SetStateAction<null> | (EventTarget & HTMLSpanElement)>(null);
    const [transitionPopoverOpen, setTransitionPopoverOpen] = useState(false);
    const globalStore = useGlobalStore((store) => store);
    const {
        comparedMedicalPlans,
        comparedDentalPlans,
        comparedVisionPlans,
        selectedPlan,
        selectedVisionPlan,
        selectedDentalPlan,
        zipCodeRes,
        brand,
        favouriteMedicalPlans,
        favouriteDentalPlans,
        favouriteVisionPlans,
        currentCoverageMedicalPlan,
        currentCoverageDentalPlan,
        currentCoverageVisionPlan,
        updateComparedMedicalPlans,
        updateComparedDentalPlans,
        updateComparedVisionPlans,
    } = globalStore;

    const favouritePlans = props.planType === COMPARE_PLAN_TYPES.MEDICAL ? favouriteMedicalPlans : props.planType === COMPARE_PLAN_TYPES.DENTAL ? favouriteDentalPlans : favouriteVisionPlans;

    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.COMPARE_TRAY);
            let cmsResponse = response.data;

            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.COMPARETRAY);
        } catch (error) {
            setContent(Content.data.iospagecontentList.items[0].pageContent.COMPARETRAY);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, []);

    const initiate = async () => {
        await getContent();
    };

    useEffect(() => {
        initiate();
        if (props.planType === 'medical') {
            setComparedPlans(comparedMedicalPlans);
        } else if (props.planType === 'dental') {
            setComparedPlans(comparedDentalPlans);
        } else if (props.planType === 'vision') {
            setComparedPlans(comparedVisionPlans);
        }
    }, [comparedMedicalPlans, comparedDentalPlans, comparedVisionPlans]);
    const compareClick = (plan: ComparedContract) => {
        if (props.planType === 'medical') {
            if (comparedMedicalPlans.length < 3) {
                if (comparedMedicalPlans.find((comparedPlan) => comparedPlan.contract.contractCode === plan.contract.contractCode)) {
                    const newArray = comparedMedicalPlans.filter((comparedPlan) => comparedPlan.contract.contractCode !== plan.contract.contractCode);
                    updateComparedMedicalPlans(newArray);
                } else {
                    updateComparedMedicalPlans([...comparedMedicalPlans, plan]);
                }
            } else {
                if (comparedMedicalPlans.find((comparedPlan) => comparedPlan.contract.contractCode === plan.contract.contractCode)) {
                    const newArray = comparedMedicalPlans.filter((comparedPlan) => comparedPlan.contract.contractCode !== plan.contract.contractCode);
                    updateComparedMedicalPlans(newArray);
                }
            }
        } else if (props.planType === 'dental') {
            if (comparedDentalPlans.length < 3) {
                if (comparedDentalPlans.find((comparedPlan) => comparedPlan.contract.contractCode === plan.contract.contractCode)) {
                    const newArray = comparedDentalPlans.filter((comparedPlan) => comparedPlan.contract.contractCode !== plan.contract.contractCode);
                    updateComparedDentalPlans([...newArray]);
                } else {
                    updateComparedDentalPlans([...comparedDentalPlans, plan]);
                }
            } else {
                if (comparedDentalPlans.find((comparedPlan) => comparedPlan.contract.contractCode === plan.contract.contractCode)) {
                    const newArray = comparedDentalPlans.filter((comparedPlan) => comparedPlan.contract.contractCode !== plan.contract.contractCode);
                    updateComparedDentalPlans([...newArray]);
                }
            }
        } else if (props.planType === 'vision') {
            if (comparedVisionPlans.length < 3) {
                if (comparedVisionPlans.find((comparedPlan) => comparedPlan.contract.contractCode === plan.contract.contractCode)) {
                    const newArray = comparedVisionPlans.filter((comparedPlan) => comparedPlan.contract.contractCode !== plan.contract.contractCode);
                    updateComparedVisionPlans([...newArray]);
                } else {
                    updateComparedVisionPlans([...comparedVisionPlans, plan]);
                }
            } else {
                if (comparedVisionPlans.find((comparedPlan) => comparedPlan.contract.contractCode === plan.contract.contractCode)) {
                    const newArray = comparedVisionPlans.filter((comparedPlan) => comparedPlan.contract.contractCode !== plan.contract.contractCode);
                    updateComparedVisionPlans([...newArray]);
                }
            }
        }
    }

    const handleTransitionHelpClick = (currentTarget: React.SetStateAction<null> | (EventTarget & HTMLSpanElement)) => {
        setAnchor(currentTarget);
        setTransitionPopoverOpen(!transitionPopoverOpen);
    };

    const renderGradetag = (gradeLevels: GradeLevel[], isCurrentCoveragePlan: boolean = false, contract: Contract) => {
        if (isCurrentCoveragePlan) {
            return (
                <div className={'grade-wrapper current-coverage-grade-container'}>
                    <span className="grade-label">{pageContent?.PLAN_HEADERS?.CURRENT_COVERAGE}</span>
                </div>
            );
        } else if (isMappedContract(contract)) {
            return (
                <div className="transition-coverage-info-wrapper">
                    <div className={'grade-wrapper transition-coverage-grade-container'}>
                        <span className="grade-label">{props.labels?.transitionCoverage}</span>
                    </div>
                    <div>
                        {transitionPopoverOpen && anchor && (
                            <Popover id="transition-popover" placement={'top'} anchorEl={anchor} onClickOutside={() => setTransitionPopoverOpen(!transitionPopoverOpen)}>
                                <span className="transition-popover-content">{props.labels?.transitionCoverageTooltip}</span>
                            </Popover>
                        )}

                        <span id="plan-card-transition-tooltip" className="sae-icon sae-icon-help help-icon" onClick={(e) => handleTransitionHelpClick(e.currentTarget)} />
                    </div>
                </div>
            );

        } else {
            const applicableGrade = getApplicableGrade(gradeLevels);

            switch (applicableGrade) {
                case GRADE_LEVELS.RECOMMENDED:
                case GRADE_LEVELS.POPULAR:
                    return (
                        <div className={applicableGrade === GRADE_LEVELS.RECOMMENDED ? 'grade-wrapper recommended-grade-container' : 'grade-wrapper popular-grade-level'}>
                            <span className="grade-label">{getGradeLabel(applicableGrade, props.labels)}</span>
                        </div>
                    );
                default:
                    return null;
            }
        }
    };
    const marketTagClass = (poPlan: boolean, market: boolean) => {
        if (poPlan) {
            return PLAN_MARKET_TYPES.COLARADO_CONNECT
        } else if (market) {
            return PLAN_MARKET_TYPES.MARKET_PLACE;
        } else {
            return PLAN_MARKET_TYPES.OFF_MARKET;
        }
    }
    const isPlanSelected = (plan: any) => {
        if (props.planType === 'medical') {
            return selectedPlan?.plan?.contractCode === plan.contract.contractCode;
        } else if (props.planType === 'dental') {
            return selectedDentalPlan?.plan?.contractCode === plan.contract.contractCode;
        } else if (props.planType === 'vision') {
            return selectedVisionPlan?.plan?.contractCode === plan.contract.contractCode;
        }
    }

    const getAnalyticsFavoriteTag = () => {
        if (props.planType === COMPARE_PLAN_TYPES.MEDICAL) {
            return 'addFavoritesMedicalPlansIos';
        } else if (props.planType === COMPARE_PLAN_TYPES.DENTAL) {
            return 'addFavoritesDentalPlansIos';
        } else if (props.planType === COMPARE_PLAN_TYPES.VISION) {
            return 'addFavoritesVisionPlansIos';
        }
    }

    const isFavourite = (plan: any) => {
        return favouritePlans.includes(plan?.contract?.contractCode)
    }

    const getiIsCurrentCoveragePlan = (contractCode: string) => {
        if (props.planType === COMPARE_PLAN_TYPES.MEDICAL) {
            return currentCoverageMedicalPlan?.contractCode === contractCode;
        } else if (props.planType === COMPARE_PLAN_TYPES.DENTAL) {
            return currentCoverageDentalPlan?.contractCode === contractCode;
        } else if (props.planType === COMPARE_PLAN_TYPES.VISION) {
            return currentCoverageVisionPlan?.contractCode === contractCode;
        }
    }

    const planHeaders = () => {
        return (
            <div className={'compare-tray-content'}>
                {comparedPlans?.map((plan: any) => (
                    <div key={plan.contractCode} className="compare-tray-plan fwc-col-4 fwc-card-generic">
                        <div className="compare-tray-plan-header">
                            <div id="plan-card-grade" className="grade-container">
                                {renderGradetag(plan?.contract.gradeLevels, getiIsCurrentCoveragePlan(plan?.contract?.contractCode), plan?.contract)}
                            </div>
                            {showSlider && <div className={'market-tag ' + marketTagClass(plan?.contract?.poPlan, plan.contract.onExchange)}>{marketTagClass(plan?.contract?.poPlan, plan.contract.onExchange)}</div>}
                            <div onClick={() => compareClick(plan)} className={'fwc-icon fwc-icon-delete flex-align-right'} />
                        </div>
                        <div className={"compare-tray-plan-title " + (showSlider ? 'slider' : '')}>
                            {plan?.contract.planMarketingName}
                        </div>
                        {showSlider && <div className="right-buttons-container"> <a data-analytics={getAnalyticsFavoriteTag()} href={'javascript:void(0);'} id="plan-card-fav-button" className="right-button" onClick={() => props?.onFavouriteClick(plan?.contract?.contractCode)}>
                            <span id="plan-card-fav" className={`sae-icon ${isFavourite(plan) ? 'sae-icon-filled-star' : 'sae-icon-star'} fav-icon`} />
                            <span className="right-button-label">{isFavourite(plan) ? pageContent?.PLAN_HEADERS.REMOVE_FROM_FAVOURITES : pageContent?.PLAN_HEADERS.FAVORITES}</span>
                        </a></div>
                        }
                        {(showSlider && !isPlanSelected(plan)) && <button onClick={() => selectPlanClick(plan.contract)} className={'fwc-btn fwc-btn-primary fwc-col-12'}>{pageContent?.PLAN_HEADERS.SELECT}</button>}
                        {(showSlider && isPlanSelected(plan)) && <button onClick={() => selectPlanClick(plan.contract)} className={'fwc-btn fwc-btn-secondary fwc-col-12'}>{pageContent?.PLAN_HEADERS.DESELECT}</button>}
                    </div>
                ))}
                {(comparedPlans.length === 1 || comparedPlans.length === 2) &&
                    <div className="compare-tray-plan placeholder-plan fwc-col-4 fwc-card-generic"></div>}
                {comparedPlans.length === 1 &&
                    <div className="compare-tray-plan placeholder-plan fwc-col-4 fwc-card-generic"></div>}
            </div>
        )
    }
    const handleContinue = () => {
        setShowSlider(true);
    }
    const selectPlanClick = (plan: any) => {
        props.handleSelectPlan(plan);
        resetComparedPlans();
        setShowSlider(false);
        setCollapsed(true);
    }
    const resetComparedPlans = () => {
        if (props.planType === 'medical') {
            updateComparedMedicalPlans([]);
        } else if (props.planType === 'dental') {
            updateComparedDentalPlans([]);
        } else if (props.planType === 'vision') {
            updateComparedVisionPlans([]);
        }
        setComparedPlans([]);
    }

    return (
        <div>
            {!contentLoaded ? (<PreLoader id={'compare-tray-loader'} />) : (
                <div className="compare-tray-container">
                    <div className={'fwc-row fwc-flex-column'}>
                        <div className="compare-tray-header">
                            <div className="toggle-container">
                                <div
                                    id="plan-coverage-toggle"
                                    onClick={() => setCollapsed(!collapsed)}
                                    className={collapsed ? 'sae-icon sae-icon-angle-down toggle-icon' : 'sae-icon sae-icon-angle-up toggle-icon'}
                                />
                            </div>
                            <div className="compare-tray-title">
                                {pageContent?.SELECTED} ({comparedPlans?.length}) {pageContent?.PLANS_TEXT}
                            </div>
                            <a className={'flex-align-right right-xs'}
                                onClick={resetComparedPlans}>{pageContent?.CLEAR}</a>
                            <button className="fwc-btn fwc-btn-primary" onClick={handleContinue}>{pageContent?.CONTINUE}</button>
                        </div>
                        {!collapsed && planHeaders()}
                    </div>
                    {comparedPlans.length > 0 &&
                        <CompareSlider planHeaders={planHeaders()}
                            plans={comparedPlans}
                            showSlider={showSlider}
                            labels={props.labels}
                            planType={props.planType}
                            matchedProvidersFlag={props.matchedProvidersFlag}
                            matchedMedicationsFlag={props.matchedMedicationsFlag}
                            onClose={(e) => setShowSlider(e)} />
                    }
                </div>
            )}
        </div>
    );
}
export default CompareTray;
