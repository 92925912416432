import { USER_DOMAINS } from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD, getAgeByDob, isDateBefore1850, isFutureDate } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { default as Content } from './accountRecovery.json';
import { INVALID_DATES, REGEXS, REQUIRED_FIELD } from './constants';

const Strings = Content.data.iospagecontentList.items[0].pageContent.AccountRecovery;

const validateFirstName = (value: string, labels: any = Strings) => {
  const errorConfig = {
    isValidFirstName: true,
    firstNameErrorMessage: ''
  };

  if (value.length === 0) {
    errorConfig.isValidFirstName = false;
    errorConfig.firstNameErrorMessage = labels.REQUIRED_FIELD;
  } else if (!REGEXS.NAME.test(value)) {
    errorConfig.isValidFirstName = false;
    errorConfig.firstNameErrorMessage = labels.INVALID_FIRST_NAME;
  }

  return errorConfig;
};

const validateLastName = (value: string) => {
  const errorConfig = {
    isValidLastName: true,
    lastNameErrorMessage: ''
  };

  if (value.length === 0) {
    errorConfig.isValidLastName = false;
    errorConfig.lastNameErrorMessage = Strings.REQUIRED_FIELD;
  } else if (!REGEXS.NAME.test(value)) {
    errorConfig.isValidLastName = false;
    errorConfig.lastNameErrorMessage = Strings.INVALID_LAST_NAME;
  }

  return errorConfig;
};

const validateEmail = (value: string) => {
  const errorConfig = {
    isValidEmail: true,
    emailErrorObj: {
      title: '',
      label1: '',
      label2: '',
      label3: ''
    }
  };

  if (value.length === 0) {
    errorConfig.isValidEmail = false;
    errorConfig.emailErrorObj.title = Strings.REQUIRED_FIELD;
  } else if (!REGEXS.EMAIL.test(value)) {
    errorConfig.isValidEmail = false;
    errorConfig.emailErrorObj.title = Strings.INVALID_EMAIL;
    errorConfig.emailErrorObj.label1 = Strings.INVALIDE_EMAIL_DESC_1;
    errorConfig.emailErrorObj.label2 = Strings.INVALIDE_EMAIL_DESC_2;
    errorConfig.emailErrorObj.label3 = Strings.INVALIDE_EMAIL_DESC_3;
  }

  return errorConfig;
};

const validateDob = (value: string, isNextClicked: boolean) => {
  const errorConfig = {
    isValidDob: true,
    dobErrorMessage: ''
  };

  if (value.length === 0 || value === REQUIRED_FIELD || (isNextClicked && INVALID_DATES.includes(value))) {
    errorConfig.isValidDob = false;
    errorConfig.dobErrorMessage = Strings.REQUIRED_FIELD;
  } else if (INVALID_DATES.includes(value)) {
    errorConfig.isValidDob = false;
    errorConfig.dobErrorMessage = Strings.INVALID_DATE_1;
  } else if (isFutureDate(value)) {
    errorConfig.isValidDob = false;
    errorConfig.dobErrorMessage = Strings.INVALID_DATE_2;
  } else if (getAgeByDob(value) < 18) {
    errorConfig.isValidDob = false;
    errorConfig.dobErrorMessage = Strings.INVALID_DATE_3;
  } else if (isDateBefore1850(value)) {
    errorConfig.isValidDob = false;
    errorConfig.dobErrorMessage = Strings.INVALID_DATE_4;
  }

  return errorConfig;
};

const getForgotUserNamePayload = (firstName: string, lastName: string, emailAddress: string, dateOfBirth: string): ForgotUserNamePayload => {
  return {
    userNameRequest: {
      firstName,
      lastName,
      dateOfBirth: formatDateToYYYYMMDD(dateOfBirth),
      emailAddress
    },
    userDomain: {
      app: USER_DOMAINS.APP,
      brand: useGlobalStore.getState().brand,
      domainName: null,
      marketSegment: USER_DOMAINS.MARKET_SEGMENT
    }
  };
};

const getForgotPasswordPayload = (
  firstName: string,
  lastName: string,
  emailAddress: string,
  dateOfBirth: string,
  queryParams?: string
): ForgotPasswordPayload => {
  return {
    passwordRequest: {
      firstName,
      lastName,
      dateOfBirth: formatDateToYYYYMMDD(dateOfBirth),
      emailAddress
    },
    userDomain: {
      app: USER_DOMAINS.APP,
      brand: useGlobalStore.getState().brand,
      domainName: null,
      marketSegment: USER_DOMAINS.MARKET_SEGMENT
    },
    queryParams: ''
  };
};

const getValidateResetPwdIdSuccessPayload = (
  validateResetIdData: ValidateResetPwdIdResponse,
  resetId: string,
  brand: string
): ValidateResetIDSuccessPayload => {
  return {
    resetId: resetId,
    brand: brand,
    contacts: validateResetIdData.contacts,
    secretQuestionAnswers: validateResetIdData.passwordResponse.secretQuestionAnswers,
    pingRiskId: validateResetIdData.pingRiskId
  };
};

const getForgotUsernameSuccessPayload = (
  forgotUsernameData: ForgotUsernameResponse,
  firstName: string,
  lastName: string,
  email: string,
  dateOfBirth: string
): ForgotUsernameSuccessPayload => {
  return {
    userFirstName: firstName,
    userLastName: lastName,
    userEmailAddress: email,
    userDateOfBirth: dateOfBirth,
    contacts: forgotUsernameData.contacts,
    secretQuestionAnswers: forgotUsernameData.userAccount.secretQuestionAnswers,
    pingRiskId: forgotUsernameData.pingRiskId
  };
};

export {
  getForgotPasswordPayload,
  getForgotUserNamePayload,
  getForgotUsernameSuccessPayload,
  getValidateResetPwdIdSuccessPayload,
  validateDob,
  validateEmail,
  validateFirstName,
  validateLastName
};
