import React from 'react';
import { useNavigate } from 'react-router-dom';
import colaradoPlan from '../../../../../src/images/colaradoPlan.svg';
import { PLAN_MARKET_TYPES } from '../../../../components/common/compare-tray/constant';
// import { getFormattedApplicantDetails } from '../../medical-plans/medicalPlansUtils';
import './AtkPlanSummaryCard.scss';

const AtkPlanSummaryCard: React.FC<AtkPlanSummaryCardProps> = ({ labels, selectedSummaryPlan, planTypeIcon, planType, applicants, selectedPlanMarketType }) => {
    const navigate = useNavigate();

    const setAnalyticsString = () => {
        let str = '';
        switch (planType) {
            case 'Medical':
                str = 'MedPlan';
                break;
            case 'Dental':
                str = 'DentalPlan';
                break;
            case 'Vision':
                str = 'VisionPlan';
                break;
        }
        return str;
    }

    return (
        <div className="plan-summary-card">
            <div className="plan-type-container">
                <div className="plan-type-info-wrapper">
                    <span className={'sae-icon ' + planTypeIcon}></span>
                    <div className="plan-type-name">{planType}</div>
                </div>
            </div>
            <div className="plan-details-container">
                {selectedSummaryPlan && (
                    <div className="plan-info-container">
                        <div>
                            <h3>{selectedSummaryPlan.planMarketingName}</h3>
                        </div>
                        {
                            (selectedSummaryPlan.planMarketingName && selectedPlanMarketType === PLAN_MARKET_TYPES.COLARADO_CONNECT) && <div className='fwc-row fwc-row-bm'>
                                <div className='fwc-col-4 sm-3 md-3'>
                                    <img id="co-logo" alt="" src={colaradoPlan} />
                                </div>
                            </div>
                        }
                        <div>
                            <h4>Who's Covered </h4>
                            <div className="user-details-container">
                                {applicants?.map((dependent, index) => {
                                    return (
                                        <p key={index}>
                                            <i className="sae-icon sae-icon-user-checked"></i>
                                            {dependent.firstName} <span>{`(${dependent.applicantType})`}</span>
                                        </p>
                                    );
                                })}
                            </div>
                        </div>
                    </div>
                )}

                <div className="plan-change-container">
                    {selectedSummaryPlan && (
                        <div className="plan-price-container">
                            <p>
                                {labels.MONTHLY_PREMIUM}<span className="plan-price">${selectedSummaryPlan?.rateData?.rate?.totSubsidizedPrem}</span>
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </div>
    );
};

export default AtkPlanSummaryCard;
