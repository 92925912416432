import { MEDICAL_PLANS_TABS, PLAN_TIERS, SWITCH_FILTERS_KEYS } from '../../public/medical-plans/constants';

const PRODUCT_SELECTION_TABS: ShopperTab[] = [
  {
    value: MEDICAL_PLANS_TABS.OFF_MARKET_PLANS,
    label: ''
  },
  {
    value: MEDICAL_PLANS_TABS.MARKET_PLANS,
    label: ''
  }
];

const COLARADO_CONNECT_TAB: ShopperTab = {
  value: MEDICAL_PLANS_TABS.COLARADO_CONNECT_PLANS,
  label: ''
};

const PRODUCT_FILTER_TYPES = {
  PRODUCT_TYPES: 'PRODUCT_TYPES',
  MORE: 'MORE',
  RESET: 'RESET'
};

const PRODUCT_TYPES = {
  PPO: 'PPO',
  HMO: 'HMO',
  POS: 'POS'
};

const PRODUCT_FILTER_OPTIONS: InitialProductFilters = {
  PRODUCT_TYPES: [
    {
      key: PRODUCT_TYPES.PPO,
      value: PRODUCT_TYPES.PPO,
      label: '',
      isChecked: false,
      disabled: true,
      analytics: 'ppoFilterProductsIos'
    },
    {
      key: PRODUCT_TYPES.HMO,
      value: PRODUCT_TYPES.HMO,
      label: '',
      isChecked: false,
      disabled: true,
      analytics: 'hmoFilterMedicalPlansIos'
    },
    {
      key: PRODUCT_TYPES.POS,
      value: PRODUCT_TYPES.POS,
      label: '',
      isChecked: false,
      disabled: true,
      analytics: 'posFilterMedicalPlansIos'
    }
  ],
  MORE_FILTERS: {
    SWITCH_FILTERS: [{ key: SWITCH_FILTERS_KEYS.SHOW_MY_DOCTOR, label: '', tooltipText: '', enabled: false, disabled: true }],
    BENEFITS_TIER: [
      {
        key: 'CATASTROPHIC',
        value: PLAN_TIERS.CATASTROPHIC,
        label: '',
        isChecked: false,
        disabled: true,
        analytics: 'catastrophicFilterProductIos'
      },
      {
        key: 'BRONZE',
        value: PLAN_TIERS.BRONZE,
        label: '',
        isChecked: false,
        disabled: true,
        analytics: 'bronzeFilterProductIos'
      },
      {
        key: 'SILVER',
        value: PLAN_TIERS.SILVER,
        label: '',
        isChecked: false,
        disabled: true,
        analytics: 'silverFilterProductIos'
      },
      {
        key: 'GOLD',
        value: PLAN_TIERS.GOLD,
        label: '',
        isChecked: false,
        disabled: true,
        analytics: 'goldFilterProductIos'
      },
      {
        key: 'PLATINUM',
        value: PLAN_TIERS.PLATINUM,
        label: '',
        isChecked: false,
        disabled: true,
        analytics: 'platinumFilterProductIos'
      }
    ]
  }
};

export { COLARADO_CONNECT_TAB, PRODUCT_FILTER_OPTIONS, PRODUCT_FILTER_TYPES, PRODUCT_SELECTION_TABS, PRODUCT_TYPES };
