import axios from 'axios';
import dayjs from 'dayjs';
import { getBrandForAEMContent, getStateCodeForAEMContent } from '../../shared/utils/globalUtils';
import { useGlobalStore } from '../../store/globalStore';
const {
  REACT_APP_PUBLIC_URI,
  REACT_APP_STATIC,
  REACT_APP_AEM_URL,
  REACT_APP_DYNAMIC,
  REACT_APP_ENV,
  REACT_APP_PUBLIC_URI_ANTHEM,
  REACT_APP_PUBLIC_URI_WELLPOINT
} = process.env;
const STATIC_URL = REACT_APP_ENV === 'local' ? REACT_APP_PUBLIC_URI + REACT_APP_AEM_URL + REACT_APP_STATIC : REACT_APP_AEM_URL + REACT_APP_STATIC;
const DYNAMIC_URL = REACT_APP_ENV === 'local' ? REACT_APP_PUBLIC_URI + REACT_APP_AEM_URL + REACT_APP_DYNAMIC : REACT_APP_AEM_URL + REACT_APP_DYNAMIC;
const MODEL_JSON_URL = REACT_APP_ENV === 'local' ? REACT_APP_PUBLIC_URI : '';

export const getCMSStaticContent = (locale, pageId, brand, state) => {
  let finalBrand;
  let finalState;

  if (useGlobalStore.getState().agentSSOInfo?.isAtk) {
    //atkflow
    finalBrand = useGlobalStore.getState().agentBrand ? useGlobalStore.getState().agentBrand : getBrandForAEMContent();
  } else {
    finalBrand = brand ? brand : getBrandForAEMContent();
  }

  if (useGlobalStore.getState().agentSSOInfo?.isAtk) {
    //atkflow
    finalState = useGlobalStore.getState().agentState ? useGlobalStore.getState().agentState : getStateCodeForAEMContent();
  } else {
    finalState = state ? state : getStateCodeForAEMContent();
  }

  const url = ';brand=' + finalBrand + ';state=' + finalState + ';locale=' + locale + ';pageId=' + pageId;
  const encodedURL = STATIC_URL + encodeURIComponent(url);
  return axios.get(encodedURL);
};

export const getCMSdynamicContent = (pageId, pageBlock, query, currDate = '') => {
  const { zipCodeRes, agentSSOInfo, agentZipCodeRes } = useGlobalStore.getState();
  let currentdate = dayjs().toISOString();
  try {
    if (currDate === '') {
      currentdate = agentSSOInfo.isAtk ? dayjs(agentZipCodeRes.currentDateTimeInZone).toISOString() : dayjs(zipCodeRes.currentDateTimeInZone).toISOString();
    } else {
      currentdate = dayjs(currDate).toISOString();
    }
  } catch {
    currentdate = dayjs().toISOString();
  }

  const url = ';pageid=' + pageId + ';pageblock=' + pageBlock + ';locale=' + 'en_US' + query + ';currentdate=' + currentdate;
  const encodedURL = DYNAMIC_URL + encodeURIComponent(url);
  return axios.get(encodedURL);
};

export const getCMSModelJsonContent = (url) => {
  return axios.get(MODEL_JSON_URL + url);
};
