
const getPayloadForContactUs = (
    firstName = '',
    lastName = '',
    phone = '',
    email = '',
    preferredDay = '',
    preferredTime = '',
    brand = '',
    state = '',
    zip = '') => {
    const payload = {
        "callMeInfo": {
            "callDay": "preferredDay:"+ preferredDay +" , brand:" + brand + " , state:" + state + " , zip:" + zip + " , langPref:en",
            "callNow": "false",
            "callTime": preferredTime,
            "emailAddress": email,
            "emailOptIn": "false",
            "firstName": firstName,
            "lastName": lastName,
            "phoneNumber": phone+"-OTHER",
            "shopperId": ""
        }
    }
    return payload;
};
const formatPhoneNumber = (phoneNumber: string) => {
    let cleaned = ('' + phoneNumber).replace(/\D/g, '');

    let match = cleaned.match(/^(\d{3})(\d{3})(\d{4})$/);
    if (match) {
        return match[1] + '-' + match[2] + '-' + match[3];
    }

    return phoneNumber;
};

export {
    getPayloadForContactUs,
    formatPhoneNumber
    }
