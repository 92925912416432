import React from 'react'

import './ProductReviewTray.scss'

const ProductReviewTray: React.FC<ProductReviewTrayProps> = ({ labels, handleReviewClick }) => {
    return (
        <div className='product-review-tray-container'>
            <div className='fwc-row inner-container'>
                <div className='review-tray-title'>{labels?.MAX_PLANS}</div>
                <button
                    id="product-selection-review"
                    className="footer-button fwc-btn fwc-btn-primary"
                    onClick={() => {
                        handleReviewClick()
                    }}
                >
                    {labels?.REVIEW_SELECTED_PLANS}
                </button>
            </div>
        </div>
    )
}

export default ProductReviewTray