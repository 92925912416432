import dayjs from "dayjs";
import React, { useEffect, useState } from 'react';
import './DateInput.scss';


interface DateInputProps {
    className?: string;
    id: string;
    name: string;
    label?: string;
    onDateChange?: (date: string) => void;
    onBlur?: (date: any) => void;
    minDate?: string;
    maxDate?: string;
    placeholder?: string;
    defaultDate?: string;
    reset?: boolean;
    disabled?: boolean;
    updateValue?: string;
}
export const DateInput: React.FC<DateInputProps> = (props) => {
    const [value, setValue] = useState<any>('');
    const [error, setError] = useState('');
    const [minDate, setMinDate] = useState('1900-01-01');
    const [maxDate, setMaxDate] = useState('9999-12-31');
    useEffect(() => {
        if (value === '' && props.defaultDate) {
            setValue(dayjs(props.defaultDate).format('YYYY-MM-DD'));
        }
        if (props.minDate) {
            const minDate = dayjs(props.minDate).format('YYYY-MM-DD');
            setMinDate(minDate);
        }
        if (props.maxDate) {
            const maxDate = dayjs(props.maxDate).format('YYYY-MM-DD');
            setMaxDate(maxDate);
        }
    }, [props.defaultDate, props.maxDate, props.minDate, value]);
    useEffect(() => {
        if (props.updateValue != '' && props.updateValue != null) {
            setValue(dayjs(props.updateValue).format('YYYY-MM-DD'));
        }
    }, [props.updateValue]);
    useEffect(() => {
        if (value !== null && value !== undefined && value !== '') {
            props.onDateChange?.(value);
        }
    }, [value]);
    useEffect(() => {
        if (props.reset) {
            setValue('');
            props.onDateChange?.('');
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [props.reset]);

    const dateAccepted = (date: any) => {
        validateDate(date);
    }
    const validateDate = (date) => {
        props.onBlur?.(date);
        if (date.target.value !== null && date.target.value !== undefined && date.target.value !== '' && date.target.value !== 'Invalid Date') {
            const dateArray = (date.target.value).split('-');
            const year = parseInt(dateArray[0]);
            const month = parseInt(dateArray[1]);
            const day = parseInt(dateArray[2]);
            if (!props.minDate && year < 1900) {
                setError('Please enter a date after 01/01/1900');
                return false;
            }
            if (props.minDate && date.target.value < dayjs(props.minDate).format('YYYY-MM-DD')) {
                setError('Please enter a date after ' + props.minDate);
                return false;
            }
            setError('');
            return true;
        } else {
            return false;
        }
    }
    return (
        <div className={'fwc-input ' + props.className}>
            <label className={'fwc-label'} htmlFor={props.id}>{props.label}</label>
            <input
                type="date"
                min={minDate}
                max={maxDate}
                id={props.id}
                name={props.name}
                value={value}
                data-testid="custom-date-input"
                placeholder={props.placeholder}
                aria-invalid={error.length > 0 ? 'true' : 'false'}
                disabled={props.disabled}
                onBlur={(e) => dateAccepted(e)}
                onChange={(e) => setValue(e.target.value)}
            />
        </div>
    );
}
export default DateInput;
