import { Modal } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import React, { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../../api/cms/cmsUtil';
import { HTTP_STATUS_CODES } from '../../../../api/constants';
import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import SuccessModalBody from '../../../../components/common/success-modal-body/SuccessModalBody';
import { useShopperContext } from '../../../../context/shopper-context/ShopperContext';
import { LANGUAGE_CODES, SCREEN_NAMES } from '../../../../shared/globalConstants';
import { formatDateToYYYYMMDD } from '../../../../shared/utils/globalUtils';
import { useUserStore } from '../../../../store/userStore';
import './UpdateSecurityQA.scss';
import { checkAnswerHasError, checkDuplicateQuestion, checkQuestionHasError, getUpdateSecurityQAPayload, getUpdatedAnswerErrors, getUpdatedQuestions } from './UpdateSecurityQAUtils';
import { ANSWERFIELDS, ANSWER_POSSIBLE_ERROR, QUESTIONFIELDS, SECURITY_QUESTIONS, UPDATE_SHOPPER_QUESTION_ANSWER_RESPONSE_CODES } from './constants';
import { updateShopperSecurityQA } from './updateSecurityQAServices';
import { default as Content } from './updatesecurityQA.json';


const UpdateSecurityQA = () => {
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.UPDATE_SECURITY_QA);
      content = response.data.data.iospagecontentList.items[0].pageContent?.SECURITY_QA;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent.SECURITY_QA;
      return false;
    } finally {
      setPageContent(content);
      setContentLoaded(true);
      setQuestion(SECURITY_QUESTIONS(content.QUESTIONS));
    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    initiate();
  }, []);
  const {
    userName,
    userFirstName,
    userLastName,
    userDateOfBirth,
    userEmailAddress,
    userPhoneNumber,
    userPhoneNumberType,
    userSMSConsent,
    webAccountGUID,
    shopperId,
    role,
    secretQuestionAnswers
  } = useUserStore((state) => state);
  const { userPassword } = useShopperContext();

  QUESTIONFIELDS.question1 = secretQuestionAnswers[0]?.securityQuestion;
  QUESTIONFIELDS.question2 = secretQuestionAnswers[1]?.securityQuestion;
  QUESTIONFIELDS.question3 = secretQuestionAnswers[2]?.securityQuestion;
  secretQuestionAnswers[0]?.securityAnswer != null ? (ANSWERFIELDS.answer1 = secretQuestionAnswers[0]?.securityAnswer) : (ANSWERFIELDS.answer1 = '');
  secretQuestionAnswers[1]?.securityAnswer != null ? (ANSWERFIELDS.answer2 = secretQuestionAnswers[1]?.securityAnswer) : (ANSWERFIELDS.answer2 = '');
  secretQuestionAnswers[2]?.securityAnswer != null ? (ANSWERFIELDS.answer3 = secretQuestionAnswers[2]?.securityAnswer) : (ANSWERFIELDS.answer3 = '');

  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [questions, setQuestion] = useState<any>();
  const [selectedquestions, setSelectedQuestions] = useState(QUESTIONFIELDS);
  const [showQuestionError, setQuestionError] = useState(QUESTIONFIELDS);
  const [isRequiredField, setIsRequiredField] = useState(false);
  const [isDuplicateQuestion, setIsDuplicateQuestion] = useState(false);
  const [answers, setAnswers] = useState(ANSWERFIELDS);
  const [showAnswerError, setAnswerError] = useState(ANSWER_POSSIBLE_ERROR);
  const { updateSecretQuestionAnswers } = useUserStore((store) => store);
  const [updateShopperQAInfoError, setUpdateShopperQAInfoError] = useState({
    hasError: false,
    errorStatus: '',
    message: ''
  });


  const getSecurityQuestion = (e: ChangeEvent<HTMLSelectElement>) => {
    if (pageContent) {
      const updatedQuestions = getUpdatedQuestions(questions, e);
      setIsDuplicateQuestion(false);
      setQuestion(updatedQuestions);
      setQuestionError({ ...showQuestionError, [e.target.name]: 'selected' });
      setSelectedQuestions({
        ...selectedquestions,
        [e.target.name]: e.target.value
      });
    }

  };

  const displayQuestionErrorMessage = (id: string) => {
    if (showQuestionError[id] === 'notselected' || (isRequiredField && selectedquestions[id] === '')) {
      return (<span role="alert" className="fwc-inline-icon fwc-icon-delete" id="question_error">
        {pageContent?.QUESTIONFIELDERROR.SELECT_ONE}
      </span>)
    } else if (isDuplicateQuestion) {
      return (<span role="alert" className="fwc-inline-icon fwc-icon-delete" id="question_error">
        {pageContent?.QUESTIONFIELDERROR.SAME_QUESTION}
      </span>)

    }
  };

  const isQuestionSelected = (id: string) => {
    selectedquestions[id] === 'Select' || selectedquestions[id] === ''
      ? setQuestionError({ ...showQuestionError, [id]: 'notselected' })
      : setQuestionError({ ...showQuestionError, [id]: 'selected' });
  };

  const getAnswers = (e: { target: { name: string | number; value: string | number } }) => {
    setAnswers({ ...answers, [e.target.name]: e.target.value });
  };

  const validateAnswer = (id: string) => {
    if (pageContent) {
      const answerErrors = getUpdatedAnswerErrors(id, SECURITY_QUESTIONS(pageContent.QUESTIONS), answers, showAnswerError, userName, userPassword);
      setAnswerError(answerErrors);
    }

  };

  const displayAnswerErrorMessage = (id: string) => {
    if (showAnswerError[id].isempty || (isRequiredField && answers[id] === '')) {
      return (
        <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="question_error">
          {pageContent?.ANSWERFIELDERRORS.REQUIRED}
        </span>
      );
    } else if (
      showAnswerError[id].specialCharacters ||
      showAnswerError[id].isSpaceAdded ||
      showAnswerError[id].isQuestionAdded ||
      showAnswerError[id].isUsernameAdded ||
      showAnswerError[id].isPasswordAdded
    ) {
      return (
        <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="question_error">
          {pageContent?.ANSWERFIELDERRORS.SPECIAL_CHARACTER}
        </span>
      );
    } else if (showAnswerError[id].minValueValidation) {
      return (
        <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="question_error">
          {pageContent?.ANSWERFIELDERRORS.MORE_THAN_4}
        </span>
      );
    }
  };

  const validateQuestionAndAnswers = () => {
    if (checkQuestionHasError(selectedquestions, answers) || checkAnswerHasError(showAnswerError)) {
      setIsRequiredField(true);
    } else if (checkDuplicateQuestion(selectedquestions)) {
      setIsDuplicateQuestion(true);
    }
    else {
      updateSecretQuestionAnswers({
        secretQuestionAnswers: [
          { index: 0, securityQuestion: selectedquestions.question1, securityAnswer: answers.answer1 },
          { index: 1, securityQuestion: selectedquestions.question2, securityAnswer: answers.answer2 },
          { index: 2, securityQuestion: selectedquestions.question3, securityAnswer: answers.answer3 }
        ]
      });

      const payload: UpdateSecurityQAPayload = getUpdateSecurityQAPayload(
        userName,
        webAccountGUID,
        shopperId,
        role,
        true,
        0,
        selectedquestions.question1,
        answers.answer1,
        1,
        selectedquestions.question2,
        answers.answer2,
        2,
        selectedquestions.question3,
        answers.answer3,
        userFirstName,
        userLastName,
        formatDateToYYYYMMDD(userDateOfBirth),
        userEmailAddress,
        userSMSConsent,
        userPhoneNumber,
        userPhoneNumberType
      );

      updateShopperSecurityQA(payload)
        .then((response) => {
          const data: UpdateShopperQAResponse = response.data;

          if (
            response.status === HTTP_STATUS_CODES.SUCCESS &&
            data.responseMessage.responseCode === UPDATE_SHOPPER_QUESTION_ANSWER_RESPONSE_CODES.RESPONSE_CODE
          ) {
            setShowSuccessModel(true);
          } else {
            setUpdateShopperQAInfoError({
              hasError: true,
              errorStatus: data.status,
              message: data.responseMessage.message
            });
          }
        })
        .catch((error: AxiosError<RegisterShopperErrorResponse>) => {
          console.warn('Register Shopper Error response :', error);
          if (isAxiosError(error)) {
            const errorObj = error.response?.data?.error;

            setUpdateShopperQAInfoError({
              hasError: true,
              errorStatus: errorObj?.errorKey ? errorObj?.errorKey : '',
              message: 'Profile Information Save Failed'
            });
          } else {
            setUpdateShopperQAInfoError({
              hasError: true,
              errorStatus: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
              message: 'Sorry, Something went wrong!'
            });
          }
          setLoading(false);
        });
    }
  };

  const onClickOfCancelOption = (e) => {
    e.preventDefault();
    setSelectedQuestions(QUESTIONFIELDS);
    setAnswers(ANSWERFIELDS);
    Array.from(document.querySelectorAll("input")).forEach(
      input => (input.value = "")
    );


  };

  const onClickSaveOption = (e) => {
    e.preventDefault();
    validateQuestionAndAnswers();
  };

  function closeModal() {

    setShowSuccessModel(false);

  }

  return (!contentLoaded ? <FullPageLoader /> :
    <div className={'update-security-container'}>
      <div className={'tab-title-container'}>
        <h3 id="update-tab-title">
          <span className="sae-icon sae-icon-keyhole-shield right-xxs"></span>
          {pageContent?.HEADING}
        </h3>
      </div>
      <hr />

      <div className={'security-content'}>
        <div>
          <p>{pageContent?.DESCRIPTION}</p>
          <ul>
            <li>{pageContent?.PASSWORDRULES.PASSWORDRULE_1}</li>
            <li>{pageContent?.PASSWORDRULES.PASSWORDRULE_2}</li>
            <li>{pageContent?.PASSWORDRULES.PASSWORDRULE_3}</li>
          </ul>
        </div>

        <div className="security-questions-container">
          <div className="fwc-select-list">
            <label className="fwc-label" htmlFor="update-question1-select">
              <b>{pageContent?.QUESTIONFIELDLABEL.QUESTION_1}</b>
            </label>
            <select
              name="question1"
              id="update-question1-select"
              onChange={getSecurityQuestion}
              onBlur={() => isQuestionSelected('question1')}
              value={selectedquestions['question1'] || ''}
            >
              {(selectedquestions['question1'] !== 'Select' && selectedquestions['question1'] !== '') && <option value={selectedquestions['question1']}>{selectedquestions['question1']}</option>}
              {pageContent && questions.slice(0).reverse().map((item, idx) => {
                const isSelected = Object.values(selectedquestions).includes(item.value);
                return (!isSelected && !item.isSelected) && (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
            {displayQuestionErrorMessage('question1')}
          </div>
          <div className="fwc-input">
            <label className="fwc-label">{pageContent?.ANSWERFIELDLABEL.ANSWER_1}</label>
            <input type="text" id="update-answer-one" name="answer1" onChange={getAnswers} onBlur={() => validateAnswer('answer1')} maxLength={20} />
            {displayAnswerErrorMessage('answer1')}
          </div>
          <div className="fwc-select-list">
            <label className="fwc-label" htmlFor="update_question2-select">
              <b>{pageContent?.QUESTIONFIELDLABEL.QUESTION_2}</b>
            </label>
            <select
              name="question2"
              id="update-question2-select"
              onChange={getSecurityQuestion}
              onBlur={() => isQuestionSelected('question2')}
              value={selectedquestions['question2'] || ''}
            >
              {(selectedquestions['question2'] !== 'Select' && selectedquestions['question2'] !== '') && <option value={selectedquestions['question2']}>{selectedquestions['question2']}</option>}
              {questions.slice(0).reverse().map((item, idx) => {
                const isSelected = Object.values(selectedquestions).includes(item.value);
                return (!isSelected && !item.isSelected) && (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
            {displayQuestionErrorMessage('question2')}
          </div>
          <div className="fwc-input">
            <label className="fwc-label">{pageContent?.ANSWERFIELDLABEL.ANSWER_2}</label>
            <input type="text" id="update-answer-two" name="answer2" onChange={getAnswers} onBlur={() => validateAnswer('answer2')} maxLength={20} />
            {displayAnswerErrorMessage('answer2')}
          </div>
          <div className="fwc-select-list">
            <label className="fwc-label" htmlFor="update-question3-select">
              <b>{pageContent?.QUESTIONFIELDLABEL.QUESTION_3}</b>
            </label>
            <select
              name="question3"
              id="update-question3-select"
              onChange={getSecurityQuestion}
              onBlur={() => isQuestionSelected('question3')}
              value={selectedquestions['question3'] || ''}
            >
              {(selectedquestions['question3'] !== 'Select' && selectedquestions['question3'] !== '') && <option value={selectedquestions['question3']}>{selectedquestions['question3']}</option>}
              {questions.slice(0).reverse().map((item, idx) => {
                const isSelected = Object.values(selectedquestions).includes(item.value);
                return (!isSelected && !item.isSelected) && (
                  <option key={idx} value={item.value}>
                    {item.label}
                  </option>
                );
              })}
            </select>
            {displayQuestionErrorMessage('question3')}
          </div>
          <div className="fwc-input">
            <label className="fwc-label">{pageContent?.ANSWERFIELDLABEL.ANSWER_3}</label>
            <input type="text" id="update-answer-three" name="answer3" onChange={getAnswers} onBlur={() => validateAnswer('answer3')} maxLength={20} />
            {displayAnswerErrorMessage('answer3')}
          </div>
          <div className={'fwc-flex j-center btm-md top-sm'}>
            <button className={'fwc-btn fwc-btn-secondary'} id="update-qa-cancel" onClick={(e) => onClickOfCancelOption(e)}>
              {pageContent?.BUTTONS.CANCEL}
            </button>
            <button className={'fwc-btn fwc-btn-primary'} id="update-qa-save" onClick={(e) => onClickSaveOption(e)}>
              {pageContent?.BUTTONS.SAVE}
            </button>
          </div>
        </div>
      </div>
      <Modal open={showSuccessModel}>
        <Modal.Body>
          {showSuccessModel && <SuccessModalBody
            heading={pageContent?.QA_SUCCESS_MODAL.HEADING}
            description={pageContent?.QA_SUCCESS_MODAL.DESC}
            closeButtonText={pageContent?.QA_SUCCESS_MODAL.CB_TEXT}
            closeModal={() => {
              closeModal();
            }}
          />
          }
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdateSecurityQA;
