import { Button, Modal, ModalSlide, Tooltip } from '@sydney-broker-ui/ios';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { getCMSStaticContent, getCMSdynamicContent } from '../../../api/cms/cmsUtil';
import { STATUSES } from '../../../api/constants';
import EmailPrint from '../../../components/common/email-print/EmailPrint';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import colaradoPlan from '../../../images/colaradoPlan.svg';
import { AGENT_ROLES, FINDCARE_URL_ANTHEM, FINDCARE_URL_WELLPOINT, LANGUAGE_CODES, OVERVIEW_RATING_URL, PAGE_BLOCKS, PAGE_IDS, PLAN_TYPES, SBC_URL_ANTHEM, SBC_URL_WELLPOINT, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { PLAN_BENEFIT_CODES } from '../medical-plans/constants';
import { fetchAlphaPrefix } from '../medical-plans/medicalPlansServices';
import { getCoverageTypeForDynamicAEM } from '../plan-recommendations/planRecommendationsUtils';
import './MedicalPlanDetails.scss';
import { default as Content } from './medicalPlanDetails.json';
import { displayStars, getApplicationTypeForAEM } from './medicalPlanDetailsUtils';

const Strings = Content.data.iospagecontentList.items[0].pageContent?.MedicalPlanDetails;

const MedicalPlanDetailsSlider: React.FC<MedicalPlanDetailsProps> = ({ isCurrentCoveragePlan, contract, closeMedicalPlanDetails, divRef, showCloseButton }) => {
  const [openModal, setModalOpen] = useState(false);
  const [openOverviewRatingModal, setOpenOverviewRatingModal] = useState(false);
  const [openViewPlanTypeModal, setOpenViewPlanTypeModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const impRef = useRef<any>(null)
  const [pageContent, setContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [alphaPrefixCode, setAlphaPrefixCode] = useState<string>('');
  const [pdfBrochureUrl, setPdfBrochureUrl] = useState<string>('');

  const { isWellpoint, role } = useUserStore((store) => store);
  const FINDCARE_URL = isWellpoint ? FINDCARE_URL_WELLPOINT : FINDCARE_URL_ANTHEM;
  const SBC_URL = isWellpoint ? SBC_URL_WELLPOINT : SBC_URL_ANTHEM;

  let FINDCARE_URL_WITH_CODE = FINDCARE_URL;
  useEffect(() => {
    //apend alphaPrefixCode to the findcare url
    if (alphaPrefixCode) {
      FINDCARE_URL_WITH_CODE = `${FINDCARE_URL}?alphaprefix=${alphaPrefixCode}`;
    }
  }, [alphaPrefixCode])

  const globalStore = useGlobalStore((store) => store);
  const { zipCodeRes, state, brand, year, agentBrand, agentState, agentZipCodeRes, agentYear, agentSSOInfo } = globalStore;
  const { isAtk } = agentSSOInfo;

  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.MEDICAL_PLAN_DETAILS);
      let cmsResponse = response.data;

      setContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.MedicalPlanDetails);
      // setPdfBrochureUrl(cmsResponse.data.iospagecontentList.items[0].pageContent?.MedicalPlanDetails?.PRODUCT_BROUCHER_LINK);
    } catch (error) {
      setContent(Content.data.iospagecontentList.items[0].pageContent?.MedicalPlanDetails);

      return false;
    } finally {
      setContentLoaded(true);
    }
    return true;
  }, []);

  const getPdfBroucher = async () => {
    let queryParam = '';
    if (isAtk) {
      queryParam = ';state=' + agentState + ';brand=' + agentBrand + ';role=' + AGENT_ROLES.BROKER.toLowerCase() + ';plantype=' + (getApplicationTypeForAEM(contract.onExchange, contract.poPlan)) + ';coveragetype=' + getCoverageTypeForDynamicAEM(SCREEN_NAMES.MEDICAL_PLAN_DETAILS) + ';year=' + agentYear;
    } else {
      queryParam = ';state=' + globalStore.zipCodeRes.stateCode + ';brand=' + brand + ';role=' + role.toLowerCase() + ';plantype=' + (getApplicationTypeForAEM(contract.onExchange, contract.poPlan)) + ';coveragetype=' + getCoverageTypeForDynamicAEM(SCREEN_NAMES.MEDICAL_PLAN_DETAILS) + ';year=' + year;
    }
    await getCMSdynamicContent(PAGE_IDS.PRODUCT_BROUCHER_LINK_ALL, PAGE_BLOCKS.PRODUCT_BROUCHER_LINK, queryParam)
      .then((response) => {
        setPdfBrochureUrl(response?.data.data?.iosdynamiccontentList?.items[0]?.textsummary);
      })
      .catch((error) => {
        console.warn('MedicalPlanDetails.tsx getPdfBroucher error :', error);
      });
  };

  const initiate = async () => {
    await getPdfBroucher();
    await getContent();
    const aplhaPrefixPayload: AlpaPrefixReq = {
      contractCode: contract.contractCode
    }
    getAlphaPrefix(aplhaPrefixPayload)
  };

  const getAlphaPrefix = (payload: AlpaPrefixReq) => {
    fetchAlphaPrefix(payload).then((response) => {
      if (response?.data?.message === STATUSES.SUCCESS) {
        setAlphaPrefixCode(response?.data?.alphaPrefixCode)
      } else {
        setAlphaPrefixCode('')
      }
    })
      .catch((error) => {
        setAlphaPrefixCode('')
      });
  }

  useEffect(() => {
    initiate();
  }, []);

  const handleSliderClose = useCallback(() => {
    closeMedicalPlanDetails();

  }, [closeMedicalPlanDetails]);

  const openRatingDetailPopup = () => {
    setModalOpen(!openModal);
  };

  const openOverViewRatingDetailPopup = () => {
    setOpenOverviewRatingModal(!openOverviewRatingModal);
  };

  const openViewPlanTypePopup = () => {
    setOpenViewPlanTypeModal(!openViewPlanTypeModal);
  };

  const openViewSummaryLink = () => {
    window.open(`${SBC_URL}${contract.contractCode}`, "_blank");
  };

  return (
    <>
      {(loading || !contentLoaded) && <FullPageLoader />}
      <ModalSlide
        direction={'right'}
        open={contract ? true : false}
        onClose={() => handleSliderClose()}
      >
        <ModalSlide.Body>
          <div className={'slider-container'}>
            <div className='fwc-row fwc-row-wrap fwc-row-bm'>
              <div className='fwc-col-12 sm-12 md-12 medical-plan-details-container'>
                <h2 className='btm-xxs title' id='medical-plan-detail-title'>
                  <span
                    id='medical-plan-detail-heading'
                    className=' heart-health-icon sae-icon sae-icon-heart-health  right-xxs'
                  ></span>
                  {pageContent?.MEDICAL_PLAN_DETAILS}
                </h2>
                {!loading && <>
                  <div className='fwc-row info-div-align fwc-row-bm'>
                    <span className='sae-icon sae-icon-info info-icon-size right-xxs'
                    ></span>
                    <p id='see'>{pageContent?.SEE} <span id={'important-link'} className='important-link' onClick={() => impRef.current.scrollIntoView({ behavior: 'smooth' })}> {pageContent?.IMPORTANT} </span>{pageContent?.NOTICE_ABOUT}</p>
                  </div>

                  <div className='fwc-row hmo-row fwc-row-bm'>
                    {!isCurrentCoveragePlan && <div className='fwc-col-5 sm-6 md-6 hom-icon-div'>
                      <p id='product-type'>{pageContent?.PLAN_TYPES[contract?.plan[0]?.productType]?.label}  <Tooltip icon message={pageContent?.PLAN_TYPES[contract?.plan[0]?.productType]?.desc} /></p>
                    </div>
                    }
                    <div className='fwc-col-5 sm-4 md-4'>
                      <span id={'paln-type-link'} className='important-link' onClick={openViewPlanTypePopup}> {pageContent?.VIEW_ALL_PLAN_TYPES} </span>
                    </div>
                    {!isAtk && <div>
                      <EmailPrint isCurrentCoveragePlan={isCurrentCoveragePlan} pdfBrochureUrl={pdfBrochureUrl ? pdfBrochureUrl : ''} type={PLAN_TYPES.MEDICAL} alphaPrefix={alphaPrefixCode} emailPrintContract={contract} />
                    </div>
                    }
                  </div>

                  <div className='plan-div fwc-row-bm'>
                    <div className='fwc-row fwc-row-bm'>
                      <div className='fwc-col-8 sm-12 md-12'>
                        <p className='plan-name' id='plan-name'> {contract?.planMarketingName} - ({contract?.contractCode})</p>
                      </div>
                      <div className='fwc-col-4 sm-12 md-12'>
                        {
                          contract?.qualityRating && <>
                            <div className='fwc-row star-col'  >
                              <div dangerouslySetInnerHTML={{ __html: displayStars(Number(contract?.qualityRating?.G)) }}>
                              </div>
                              <h6>{contract?.qualityRating?.G}</h6>
                              <span id="info-div" className='sae-icon sae-icon-help icon-help' onClick={(e) => openOverViewRatingDetailPopup()}>
                              </span>
                            </div>
                            <div className='fwc-row start-details-row'>
                              <p className='highlight-text' id='rating-detail' onClick={openRatingDetailPopup}>Rating Details</p>
                            </div>
                          </>
                        }
                      </div>
                    </div>
                    {
                      contract?.poPlan && <div className='fwc-row fwc-row-bm'>
                        <div className='fwc-col-4 sm-3 md-3'>
                          <img id="co-logo" alt="" src={colaradoPlan} />
                        </div>
                      </div>
                    }
                    {!isCurrentCoveragePlan && <div className='fwc-row'>
                      <div className='fwc-col-3 sm-12 md-3 plan-div-last-row'>
                        <span className='sae-icon sae-icon-pdf  right-xxs icon-style' onClick={() => window.open(pdfBrochureUrl, "_blank")}
                        ></span>
                        <p className='highlight-text' id='pdf-brocher' onClick={() => window.open(pdfBrochureUrl, "_blank")}>{pageContent?.PDF_BROCHURE}</p>
                      </div>
                      <div className='fwc-col-6 sm-12 md-6 plan-div-last-row'>
                        <span className='sae-icon sae-icon-external-link  right-xxs icon-style'
                        ></span>
                        <p className='highlight-text' id='view-summary' onClick={openViewSummaryLink}>{pageContent?.VIEW_SUMMARY_OF_BENEFITS_COVERAGE}</p>
                      </div>
                      <div className='fwc-col-3 sm-12 md-3 plan-div-last-row'>
                        <span className='sae-icon sae-icon-search-1  right-xxs icon-style' id='find-care-span'
                          onClick={() => window.open(FINDCARE_URL_WITH_CODE, "_blank")}></span>
                        <p className='highlight-text' id='find-care' onClick={() => window.open(FINDCARE_URL_WITH_CODE, "_blank")}>{pageContent?.FIND_CARE}</p>
                      </div>
                    </div>
                    }
                  </div>
                  <div>
                    <table>
                      <thead>
                        <tr>
                          <th>{pageContent?.BENEFIT} </th>
                          <th>{pageContent?.IN_NETWORK_BENEFIT_DETAILS}</th>
                        </tr>
                      </thead>
                      <tbody>

                        {(contract?.rateData?.rate && Object.keys(contract?.rateData?.rate).length) ?
                          <tr>
                            <td> <h6>{pageContent?.MONTHLY_COST}</h6>  </td>
                            <td>
                              <div className='fwc-row'>
                                <div className='fwc-col-3 sm-12 md-4'>
                                  <h5 className="plan-label-text" >${contract?.rateData.rate.totSubsidizedPrem}</h5>
                                  <p className="plan-label-text">{pageContent?.MONTHLY_PREMIUM}</p>
                                </div>
                                <div className='fwc-col-9 sm-12 md-8'>
                                  <h6 className="plan-label-text">{pageContent?.MONTHLY_COST_BREAK}</h6>
                                  <div>
                                    <div className='fwc-row gap-breakdown'>
                                      <div className='fwc-col-6 sm-6 md-6 plan-label-text'>
                                        {pageContent?.TOTAL_PLAN_COST}
                                      </div>
                                      <div className='fwc-col-6 sm-6 md-6 plan-label-text-result'>
                                        ${contract?.rateData.rate.totalPremium}
                                      </div>
                                    </div>
                                    <div className='fwc-row gap-breakdown'>
                                      <div className='fwc-col-6 sm-6 md-6 plan-label-text'>
                                        {pageContent?.SUBSIDY_APPLIED}
                                      </div>
                                      <div className='fwc-col-6 sm-6 md-6 plan-label-text-result'>
                                        ${contract?.rateData.rate.subsidyAmtApplied?.toFixed(2)}
                                      </div>
                                    </div>
                                    <div className='fwc-row gap-total-mem'>
                                      <div className='fwc-col-6 sm-6 md-6 plan-label-text'>
                                        <h6 className='total-mem-wrap'>{pageContent?.TOTAL_MEMBER_RESP}</h6>
                                      </div>
                                      <div className='fwc-col-6 sm-6 md-6 plan-label-text-result plan-result'>
                                        <h5>${contract?.rateData.rate.totSubsidizedPrem}</h5>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </td>
                          </tr> : null}

                        {
                          contract?.plan[0]?.planBenefits?.map((item, index) => {
                            if (!isWellpoint || item.planBenefitCode !== PLAN_BENEFIT_CODES.BLUE_CARD) {
                              return (
                                <tr key={index}>
                                  <td><h6>{pageContent?.PLAN_BENEFIT_NAMES[item.planBenefitCode]}  <Tooltip icon message={pageContent?.PLAN_BENEFIT_NAMES_TOOLTIP[item.planBenefitCode]} labelAriaTipTerm={'Help for Deductibles'} /></h6> </td>
                                  <td><span dangerouslySetInnerHTML={{ __html: item.planBenefitValue }}></span></td>
                                </tr>
                              )
                            }
                          })
                        }
                      </tbody>
                    </table>
                  </div>
                </>
                }

              </div>
            </div>
            {(!loading && pageContent) && <div id="important-link-div" ref={impRef} className='fwc-row-bm'>
              <h6 id='important'>{pageContent?.IMPORTANT}</h6>
              <div id='important-1' className='fwc-row fwc-row-bm'>
                {pageContent?.IMPORTANT_1}
              </div>
              <div id='important-2' className='fwc-row fwc-row-bm'>
                {pageContent?.IMPORTANT_2}
              </div>
              <div id='important-3' className='fwc-row fwc-row-bm'>
                {pageContent?.IMPORTANT_3}
              </div>
              <div id='important-4' className='fwc-row fwc-row-bm'>
                {pageContent?.IMPORTANT_4}
              </div>
            </div>}
          </div>
        </ModalSlide.Body>
        <ModalSlide.Footer>
          <Button
            btnType='secondary'
            id='modal-slide-cancel'
            className='right-xs'
            onClick={handleSliderClose}
          >
            {showCloseButton ? pageContent?.CLOSE : pageContent?.VIEW_ALL_MEDICAL_PLANS}
          </Button>
        </ModalSlide.Footer>
        {(pageContent && openModal) && <Modal
          content='This content will be overridden with html body content if exist any!'
          id='rating-modal'
          onClose={() => setModalOpen(false)}
          title={pageContent?.START_RATINGS}
          open={openModal}
        >
          <Modal.Body>
            <div className='ratings-container'>
              {
                contract?.qualityRating && Object.keys(contract?.qualityRating).map((item, index) => {
                  return (
                    <div className='fwc-row fwc-row-bm'>
                      <div className='fwc-col-10'>
                        <h6>{pageContent?.STAR_RATINGS[item].label}</h6>
                        <p>{pageContent?.STAR_RATINGS[item].desc}</p>
                      </div>
                      <div className='fwc-col-2'>
                        {
                          (contract?.qualityRating && contract?.qualityRating[item]) ?
                            <div dangerouslySetInnerHTML={{ __html: displayStars(Number(contract.qualityRating[item])) }}>
                            </div> : <h6>{pageContent?.NOT_RATED}</h6>
                        }
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button id='modal-slide-select' onClick={() => setModalOpen(false)}>
              {pageContent?.CLOSE}
            </Button>
          </Modal.Footer>
        </Modal>}

        {(pageContent && openOverviewRatingModal) && <Modal
          content='This content will be overridden with html body content if exist any!'
          id='overview-rating-modal'
          onClose={openOverViewRatingDetailPopup}
          title={pageContent?.OVERALL_RATING}
          open={openOverviewRatingModal}
          class="more-popup-header"
        >
          <Modal.Body className="more-popup-body">
            <p>{pageContent?.SUMARRY}</p>
            <p id='more-type-link' className='more-detail-link' onClick={() => window.open(OVERVIEW_RATING_URL, "_blank")}>
              {pageContent?.MORE_DETAILS}
            </p>
          </Modal.Body>
        </Modal>}

        {(pageContent && openViewPlanTypeModal) && <Modal
          content='This content will be overridden with html body content if exist any!'
          id='view-plan-type-modal'
          onClose={() => setOpenViewPlanTypeModal(false)}
          title='Different types of health plans'
          open={openViewPlanTypeModal}
        >
          <Modal.Body>
            <div className='plan-types-container'>
              <p className='fwc-row fwc-row-bm'>{pageContent?.PLAN_TYPES_HEADER}</p>
              {
                Object.keys(pageContent?.PLAN_TYPES).map((item, index) => {
                  return (
                    <div className='fwc-row fwc-row-bm'>
                      <div className='fwc-col-12'>
                        <h6>{pageContent?.PLAN_TYPES[item].label}</h6>
                        <p>{pageContent?.PLAN_TYPES[item].desc}</p>
                      </div>
                    </div>
                  )
                })
              }
            </div>
          </Modal.Body>
          <Modal.Footer>
            <Button id='modal-slide-select' onClick={() => setOpenViewPlanTypeModal(false)}>
              {pageContent?.CLOSE}
            </Button>
          </Modal.Footer>
        </Modal>}
      </ModalSlide>
    </>
  );
}


const MedicalPlanDetails = memo(MedicalPlanDetailsSlider);

export default MedicalPlanDetails;
