import { Button, Modal, Tooltip } from '@sydney-broker-ui/ios';
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useFieldArray, useFormContext } from 'react-hook-form';
import { getCMSStaticContent } from '../../../../api/cms/cmsUtil';
import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import PhoneNumberInput from '../../../../components/common/phone-number-mask/PhoneNumberMask';
import { ALPHA_REGX_WITH_SPACE_HYPEN, CALIFORNIA_CODE, DEFAULT_YEAR, EMAIL_REGX, GEORGIA_CODE, LANGUAGE_CODES, MEDICAL_PLAN_TYPES, PARENT_VALUES, RELATIONSHIP, SCREEN_NAMES, SET_APPLICATION_CONSTANTS, TOBACCO_STATE_CODES, USER_ROLES } from '../../../../shared/globalConstants';
import { isApplicantDateInRange, isApplicantSpouseInRange, isDateinFuture, isDependentChildDateInRange, isInValidDate, isNullOrUndefined } from '../../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../../store/globalStore';
import { useUserStore } from '../../../../store/userStore';
import './../../../../shared/types/index.d.ts';
import './ApplicantInfo.scss';
import { default as Content } from './applicantInfo.json';

function ApplicantInfo(props) {
  const { plan, errors, removeDependents, role, disableFields, loggedIn, onUpdateDependents, updatedConflict } = props;
  const { register, control, setValue, getValues, trigger, resetField, watch, setError, clearErrors, reset, unregister } = useFormContext();
  const [showConfirmChangesModal, setShowConfirmChangesModal] = useState(false);
  const [showParentTextBlock, setShowParentTextBlock] = useState(false);
  const [smsPermission, setSmsPermission] = useState(false);
  const { applicantFormDetails, updateApplicantFormDetails, zipcode, state, brand, county, countyCode, year, coverageType, editZipCodeFormDetails, coverageDate, zipCodeRes } = useGlobalStore((store) => store);
  const { isPHILogin } = useUserStore((state) => state);
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);

  const {
    fields: dependentFields,
    append: dependentAppend,
    remove: dependentRemove,
  } = useFieldArray({ control, name: "dependents" });
  const {
    fields: spouseFields,
    append: spouseAppend,
    remove: spouseRemove,
  } = useFieldArray({ control, name: "spouse" });

  /* ---- Add Spouse and Dependents Method -----*/
  const addDepSpouseFormFields = (e, type: string) => {
    let length = spouseFields.length + dependentFields.length;
    e.preventDefault();
    if (type === "dependent") {
      dependentAppend({
        applicantType: (state === CALIFORNIA_CODE && MEDICAL_PLAN_TYPES.includes(coverageType)) ? "" : RELATIONSHIP.CHILD,
        firstName: "",
        dateOfBirth: "",
        isTabaccoUser: (state && state === SET_APPLICATION_CONSTANTS.KY && year !== DEFAULT_YEAR) ? SET_APPLICATION_CONSTANTS.NO : !((state && TOBACCO_STATE_CODES.includes(state))) ? SET_APPLICATION_CONSTANTS.NO : '',
        applicantId: (length === 0) ? (1).toString() : (length + 1).toString(),
        status: "A",
        gender: undefined,
        disabled: false
      });
    } else {
      spouseAppend({
        applicantType: "SPOUSE",
        firstName: "",
        dateOfBirth: "",
        isTabaccoUser: (state && state === SET_APPLICATION_CONSTANTS.KY && year !== DEFAULT_YEAR) ? SET_APPLICATION_CONSTANTS.NO : !((state && TOBACCO_STATE_CODES.includes(state))) ? SET_APPLICATION_CONSTANTS.NO : '',
        status: "A",
        applicantId: (length === 0) ? (1).toString() : (length + 1).toString(),
        disabled: false
      });
    }
  };
  /* ---- Add Spouse and Dependents Method -----*/

  /* --- watch ----*/
  const phoneNumberWatch = watch('applicant.phoneNumber');
  const phoneTypeWatch = watch('applicant.phoneType');
  const coverageWatch = watch('coverage');
  /* --- watch ----*/

  interface dependentInterface {
    applicantType: string;
    firstName: string;
    dateOfBirth: string;
    isTabaccoUser: string;
    applicantId: string;
    status: string;
    gender: string | undefined
  }

  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.APPLICANT_INFO);
      let cmsResponse = response.data;
      setPageContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.ApplicantInfo);
    } catch (error) {
      setPageContent(Content?.data.iospagecontentList.items[0].pageContent?.ApplicantInfo);

      return false;
    }
    finally {
      setContentLoaded(true);
    }
    return true;
  }, []);

  useEffect(() => {
    initiate();
  }, [zipCodeRes.stateCode]);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    if (window._satellite) {
      window._satellite.track('toggleSmsNotifOnBasicsIos');
    } else {
      console.warn('window._satellite is not defined');
    }
  }, [smsPermission])

  useEffect(() => {
    if (!isNullOrUndefined(getValues('applicant.dateOfBirth')) && getValues('applicant.dateOfBirth') !== '') {
      trigger('applicant.dateOfBirth');
    }
    if (spouseFields.length !== 0) {
      revalidateFieldArray();
    }
  }, [spouseFields])

  useEffect(() => {
    if (role === USER_ROLES.MEMBER && updatedConflict) {
      resetFieldArray();
    }
  }, [role, updatedConflict])

  useEffect(() => {
    if (removeDependents) {
      let values: number[] = []
      getValues().dependents.forEach((element, index) => {
        if (element.applicantType && PARENT_VALUES.includes(element.applicantType)) {
          values.push(index);
        }
      });
      dependentRemove(values);
      let formData = [{ ...getValues().applicant }, ...getValues().spouse, ...getValues().dependents];
      reset(formData);
      updateApplicantFormDetails(formData);
      onUpdateDependents(false);
    }
  }, [removeDependents])

  useEffect(() => {
    if (coverageWatch === 'applicant-only') {
      dependentRemove();
      spouseRemove();
    }
  }, [coverageWatch])


  /*---- Validations ---*/
  const validateFirstName = (value) => {
    return ALPHA_REGX_WITH_SPACE_HYPEN.test(value);
  }

  const validateEmailAddress = (value) => {
    return EMAIL_REGX.test(value);
  }

  const validatePhoneNumber = (value) => {
    const phoneType = getValues('applicant.phoneType');
    if ((value == null || (typeof value === "string" && value.trim().length === 0))) {
      if (phoneType == null || (typeof phoneType === "string" && phoneType.trim().length === 0)) {
        return true;
      } else if (phoneType != null || phoneType != '') {
        return false
      }
    }
  }

  const validatePhoneType = (value) => {
    const phoneNumber = getValues('applicant.phoneNumber');
    if ((value == null || (typeof value === "string" && value.trim().length === 0))) {
      if (phoneNumber == null || (typeof phoneNumber === "string" && phoneNumber.trim().length === 0)) {
        return true;
      } else if (phoneNumber != null && phoneNumber != '') {
        return false
      }
    }
  }

  const validateRealationShipType = (id, value) => {
    checkforParentValue();
    if (getValues(`${id}.dateOfBirth`) !== '') {
      trigger(`${id}.dateOfBirth`);
    }
    if (value === '') {
      return pageContent?.REQUIRED_FIELD;
    } else if (value === RELATIONSHIP.PARENTINLAW && spouseFields.length === 0) {
      return pageContent?.PARENT_IN_LAW_ERROR;
    }
    return value !== '';
  }

  const revalidateFieldArray = async () => {
    // Trigger validation for all fields in the array
    await Promise.all(
      dependentFields.map(async (field, index) => {
        if (getValues(`dependents[${index}].applicantType`)) {
          await trigger(`dependents[${index}].applicantType`)
        }
        // Add more fields if needed
      })
    );
  };

  const resetFieldArray = async () => {
    dependentFields.map(async (field, index) => {
      setValue(`dependents[${index}].isTabaccoUser`, '');
      // Add more fields if needed
    });
    spouseFields.map(async (field, index) => {
      setValue(`spouse[${index}].isTabaccoUser`, '');
      // Add more fields if needed
    });
    setValue(`applicant.isTabaccoUser`, '');
  }

  const validateTobacco = (value) => {
    if (value) {
      return value === SET_APPLICATION_CONSTANTS.YES || value === SET_APPLICATION_CONSTANTS.NO;
    } else {
      return pageContent?.REQUIRED_FIELD;
    }
  }

  const validateAge = (type, value, id?, relationType?) => {
    let spouseLength = spouseFields.length;
    let invalidDate = isInValidDate(value);
    if (value === '' && invalidDate) {
      return pageContent?.REQUIRED_FIELD;
    } else if (isInValidDate(value)) {
      return pageContent?.INVALID_DATE;
    } else if (isDateinFuture(zipCodeRes.currentDateTimeInZone, value)) {
      return pageContent?.FUTURE_DATE;
    } else if (type === 'applicant' && value) {
      if (spouseLength > 0) {
        let val1 = isApplicantDateInRange(coverageDate, value);
        if (val1) {
          let val = isApplicantSpouseInRange(coverageDate, value);
          if (!val) {
            return pageContent?.APPLICANT_SPOUSE_DOB_ERROR;
          }
        } else {
          return coverageType ? (MEDICAL_PLAN_TYPES.includes(coverageType) ? pageContent?.APPLICANT_ONLY_DOB_MEDICAL_ERROR : pageContent?.APPLICANT_ONLY_DOB_DENTAL_VISION_ERROR) : '';
        }
        return val1;
      } else {
        let val = isApplicantDateInRange(coverageDate, value);
        if (!val) {
          return coverageType ? (MEDICAL_PLAN_TYPES.includes(coverageType) ? pageContent?.APPLICANT_ONLY_DOB_MEDICAL_ERROR : pageContent?.APPLICANT_ONLY_DOB_DENTAL_VISION_ERROR) : '';
        } else {
          clearErrors(`${id}.dateOfBirth`)
        }
        return val;
      }
    } else if (type === 'spouse' && value) {
      let val1 = isApplicantDateInRange(coverageDate, value);
      let val = isApplicantSpouseInRange(coverageDate, value);
      if (val1) {
        if (getValues('applicant.dateOfBirth') !== '') {
          trigger('applicant.dateOfBirth');
        }
        if (!val) {
          return pageContent?.APPLICANT_SPOUSE_DOB_ERROR;
        }
      } else {
        return coverageType ? (MEDICAL_PLAN_TYPES.includes(coverageType) ? pageContent?.APPLICANT_ONLY_DOB_MEDICAL_ERROR : pageContent?.APPLICANT_ONLY_DOB_DENTAL_VISION_ERROR) : '';
      }
      return val;
    } else if (type === 'dependent' && value) {
      //isDependentChildDateInRange
      if (state === CALIFORNIA_CODE && MEDICAL_PLAN_TYPES.includes(coverageType)) {
        if ((relationType === RELATIONSHIP.CHILD || relationType === RELATIONSHIP.OTHER) || (getValues(`${id}.applicantType`) === RELATIONSHIP.CHILD || getValues(`${id}.applicantType`) === RELATIONSHIP.OTHER)) {
          let val = isDependentChildDateInRange(coverageDate, value);
          if (!val) {
            clearErrors(`${id}.applicantType`);
            return pageContent?.DEPENDENT_DOB_ERROR;
          }
          return val;
        } else {
          let val = isApplicantDateInRange(coverageDate, value);
          if (!val) {
            return pageContent?.APPLICANT_ONLY_DOB_MEDICAL_ERROR;
          } else {
            clearErrors([`${id}.dateOfBirth`, `${id}.applicantType`]);
          }
          return val;
        }
      } else {
        let val = isDependentChildDateInRange(coverageDate, value);
        if (!val) {
          clearErrors(`${id}.applicantType`);
          return pageContent?.DEPENDENT_DOB_ERROR;
        }
        return val;
      }
    }
  }
  /*---- Validations ---*/

  const checkforParentValue = () => {
    if ((MEDICAL_PLAN_TYPES.includes(coverageType) && state === CALIFORNIA_CODE)) {
      let dependentValues = getValues().dependents.length > 0 ? getValues().dependents : applicantFormDetails.filter((data) => data.applicantType === "DEPENDENT");
      if (dependentValues?.length && dependentValues.some(e => PARENT_VALUES.includes(e.applicantType))) {
        setShowParentTextBlock(true);
      } else {
        setShowParentTextBlock(false);
      }
    } else {
      setShowParentTextBlock(false);
    }
  }

  const handleshowConfirmChangesModalClose = () => {
    setShowConfirmChangesModal(false);
  }

  const undoBasicFormChanges = () => {
    updateApplicantFormDetails(applicantFormDetails);
    setShowConfirmChangesModal(false);
  }

  const updateBasicFormChanges = () => {

  }

  return (!contentLoaded ? <FullPageLoader /> :
    <>
      <div className={"application-info " + (role === USER_ROLES.MEMBER ? "member " : "shopper")}>
        <div className="fwc-row">
          <div className="fwc-col-12 sm-12">
            <h3 className="title">{pageContent?.APPLICATION_INFO}</h3>
            <div className={"fwc-row " + (getValues('applicant.isPolicyApplicant') === SET_APPLICATION_CONSTANTS.YES ? "whiteBg " : "")}>
              <div className="fwc-col-5 md-6 sm-12 ">
                <div className='form-group'>
                  <div className="fwc-input">
                    <label className="fwc-label" htmlFor="applicant.firstName">
                      {pageContent?.FIRST_NAME}
                    </label>
                    <input
                      id="applicant.firstName"
                      maxLength={30}
                      placeholder={pageContent?.ENTER_YOUR_FIRST_NAME}
                      type="text"
                      disabled={getValues('applicant.isPolicyApplicant') === SET_APPLICATION_CONSTANTS.YES}
                      {...register("applicant.firstName", {
                        required: {
                          value: true,
                          message: pageContent?.REQUIRED_FIELD
                        },
                        validate: validateFirstName,
                      })}
                    />
                    {errors && errors.applicant && errors.applicant.firstName && errors.applicant.firstName.type === "required" && (
                      <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {pageContent?.REQUIRED_FIELD}
                      </span>
                    )}
                    {errors && errors.applicant && errors.applicant.firstName && errors.applicant.firstName.type === "validate" && (
                      <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {pageContent?.INVALID_CHARACTER}
                      </span>
                    )}
                  </div>
                  <Controller
                    control={control}
                    defaultValue={'PRIMARY'}
                    render={({ field }) => (
                      <input type="hidden" {...field} />
                    )}
                    name={`applicant.applicantType`}
                  />
                  <Controller
                    control={control}
                    defaultValue={'0'}
                    render={({ field }) => (
                      <input type="hidden" {...field} />
                    )}
                    name={`applicant.applicantId`}
                  />
                </div>
              </div>
              <div className="fwc-col-3 md-6 sm-12 ">
                <div className='form-group'>
                  <div className="fwc-input dob">
                    <label className="fwc-label" htmlFor="applicant.dateOfBirth">
                      {pageContent?.DATE_OF_BIRTH}{' '}
                    </label><span><Tooltip icon message={pageContent?.HELP_DATE_OF_BIRTH} /></span>
                    <input type="date" min={dayjs('1900-01-01').format('YYYY-MM-DD')} max={dayjs().format('YYYY-MM-DD')} placeholder="mm-dd-yyyy" disabled={getValues('applicant.isPolicyApplicant') === SET_APPLICATION_CONSTANTS.YES} {...register(`applicant.dateOfBirth`, {
                      validate: (value) => validateAge('applicant', value, 'applicant'),
                    })} />
                    {/* <Controller
                      name="applicant.dateOfBirth"
                      rules={{ required: { value: true, message: pageContent?.REQUIRED_FIELD } }}
                      render={({ field: { value, onChange, onBlur, name } }) => (
                        <DateInput
                          id="applicant.dateOfBirth"
                          name={name}
                          defaultDate={value}
                          disabled={role === USER_ROLES.MEMBER}
                          onBlur={(value) => value === '' || validateAge('applicant', value.target.value, 'applicant')}
                          onDateChange={onChange}
                        />
                      )}
                    /> */}
                    {errors && errors.applicant && errors.applicant.dateOfBirth && errors.applicant.dateOfBirth.type === "required" && (
                      <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {pageContent?.REQUIRED_FIELD}
                      </span>
                    )}
                    {errors && errors.applicant && errors.applicant.dateOfBirth && errors.applicant.dateOfBirth.type === "validate" && errors.applicant.dateOfBirth.message && (
                      <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {errors.applicant.dateOfBirth.message}
                      </span>
                    )}

                  </div>
                </div>
              </div>
              <div className='fwc-col-4'></div>
            </div>
            {getValues('applicant.isMedicAidEligible') && state !== GEORGIA_CODE && <div className='fwc-row'>
              <div className='fwc-col-8 alert-info'>
                <p><i className="sae-icon sae-icon-high-importance"></i><span className="name">{getValues('applicant.firstName')}</span> {pageContent?.medicaidEligible}</p>
              </div>
            </div>}
            {getValues('applicant.isMedicAidEligible') && state === GEORGIA_CODE && <div className='fwc-row'>
              <div className='fwc-col-8 alert-info'>
                <p><i className="sae-icon sae-icon-high-importance"></i><span className="name">{getValues('applicant.firstName')}</span> {pageContent?.gaStatemedicaidEligible}</p>
              </div>
            </div>}
            {(isPHILogin ? (!loggedIn && !isPHILogin) : !loggedIn) && <div className="fwc-row">
              <div className="fwc-col-5 md-6 sm-12 ">
                <div className='form-group'>
                  <div className="fwc-input">
                    <label className="fwc-label" htmlFor="applicant.email">
                      {pageContent?.EMAIL_ADDRESS}
                    </label>
                    <Controller
                      control={control}
                      defaultValue={''}
                      rules={{
                        required: false,
                        validate: (value) => value === '' || validateEmailAddress(value)
                      }}
                      render={({ field }) => (
                        <input
                          placeholder={pageContent?.ENTER_YOUR_EMAIL_ADDRESS}
                          id="applicant.email"
                          maxLength={254}
                          type="text" {...field} />
                      )}
                      name={`applicant.email`}
                    />
                    {/* <input
                      placeholder={pageContent?.ENTER_YOUR_EMAIL_ADDRESS}
                      id="applicant.email"
                      type="text"
                      {...register('applicant.email', {
                        validate: (value) => value === '' || validateEmailAddress(value),
                      })}
                    /> */}
                    {errors && errors.applicant && errors.applicant.email && errors.applicant.email.type === "required" && (
                      <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {pageContent?.REQUIRED_FIELD}
                      </span>
                    )}
                    {errors && errors.applicant && errors.applicant.email && errors.applicant.email.type === "validate" && (
                      <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {pageContent?.INVALID_EMAIL}
                      </span>
                    )}
                  </div>
                </div>
              </div>
              <div className="fwc-col-5 md-6 sm-12 ">
                <div className='form-group phonenumber'>
                  <div className="fwc-input">
                    <div className={'fwc-input-select-combo'}>
                      <div className={'fwc-input'}>
                        <label className="fwc-label" htmlFor="applicant.phoneNumber">
                          {pageContent?.PHONE_NUMBER_OPT}
                        </label>
                        <Controller
                          name="applicant.phoneNumber"
                          defaultValue={''}
                          rules={{
                            required: false,
                            minLength: 10,
                            maxLength: 10,
                            validate: (value) => validatePhoneNumber(value),
                            deps: ['applicant.phoneType']
                          }}
                          render={({ field: { value, onChange } }) => (
                            <PhoneNumberInput
                              id="applicant.phoneNumber" value={value} onChange={onChange} />
                          )}
                        />
                      </div>
                      <div className={'fwc-select-list'}>
                        <label id="phoneType" className="fwc-label phone-type-label" htmlFor="applicant.phoneType"></label>
                        <Controller
                          name="applicant.phoneType"
                          control={control}
                          defaultValue={phoneTypeWatch}
                          rules={{
                            required: false, validate: (value) => validatePhoneType(value), deps: ['applicant.phoneNumber']
                          }}
                          render={({ field }) => (
                            <select
                              className="text-trim"
                              id="applicant.phoneType"
                              {...field}
                            >
                              <option value="">{pageContent?.SELECT_PHONE_TYPE}</option>
                              <option value="mobile">{pageContent?.MOBILE}</option>
                              <option value="phone">{pageContent?.PHONE}</option>
                              <option value="office">{pageContent?.OFFICE}</option>
                              <option value="other">{pageContent?.OTHER}</option>
                            </select>
                          )}
                        />
                      </div>
                    </div>
                    <div className="fwc-row">
                      <div className="fwc-col-6 input-padding-right">
                        {errors && errors.applicant && errors.applicant.phoneNumber && errors.applicant.phoneNumber.type === "validate" && (
                          <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                            {pageContent?.REQUIRED_FIELD}
                          </span>
                        )}
                        {errors && errors.applicant && errors.applicant.phoneNumber && errors.applicant.phoneNumber.type === "minLength" && (
                          <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                            {pageContent?.PHONE_NO_INVALID}
                          </span>
                        )}
                        {errors && errors.applicant && errors.applicant.phoneNumber && errors.applicant.phoneNumber.type === "maxLength" && (
                          <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                            {pageContent?.PHONE_NO_INVALID}
                          </span>
                        )}
                      </div>
                      <div className="fwc-col-6 input-padding-left">
                        {errors && errors.applicant && errors.applicant.phoneType && errors.applicant.phoneType.type === "validate" && (
                          <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                            {pageContent?.REQUIRED_FIELD}
                          </span>
                        )}
                      </div>
                    </div>
                  </div>
                  {(phoneTypeWatch === 'mobile') && (
                    <div className="fwc-row a-center sms-margin">
                      <div className="fwc-col-6 sm-6 phone-type-padding">
                        <label className="align-flex" htmlFor="applicant-sms">
                          {pageContent?.PERMISSION_SMS}
                        </label>
                      </div>
                      <div className="fwc-col-6 sm-6 phone-type-padding">
                        <div className="fwc-toggle ">
                          <input
                            type="checkbox"
                            {...register('applicant.smsPermission', {
                              required: false
                            })}
                            id="applicant-sms"
                            onChange={e => setSmsPermission(e.target.checked)}
                          />
                          <label className="fwc-label" htmlFor="applicant-sms"></label>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>

              <div className='fwc-col-2'></div>
            </div>}
            {((state && state === SET_APPLICATION_CONSTANTS.KY ? (TOBACCO_STATE_CODES.includes(state) && year === DEFAULT_YEAR) : TOBACCO_STATE_CODES.includes(state))) && (
              <div className='form-group'>
                <div className="fwc-row">
                  <div className="fwc-col-5 sm-12">
                    <fieldset className='tobacco-fieldset'>
                      <legend className="fwc-label tobacco">
                        {pageContent?.HAVE_YOU_USED_TOBACCO}<span><Tooltip icon message={pageContent?.HELP_TOBACCO} /></span>
                      </legend>
                      <div className="fwc-radio">
                        <input
                          {...register('applicant.isTabaccoUser', {
                            validate: (value) => validateTobacco(value)
                          })}
                          type="radio"
                          value="YES"
                          id="applicant-tobacco-yes"
                        />
                        <label htmlFor="applicant-tobacco-yes">Yes</label>
                      </div>
                      <div className="fwc-radio">
                        <input
                          {...register('applicant.isTabaccoUser', {
                            validate: (value) => validateTobacco(value)
                          })}
                          type="radio"
                          value="NO"
                          id="applicant-tobacco-no"
                        />
                        <label htmlFor="applicant-tobacco-no">No</label>
                      </div>
                    </fieldset>
                    {errors && errors.applicant && errors.applicant.isTabaccoUser && errors.applicant.isTabaccoUser.type === "validate" && (
                      <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                        {pageContent?.REQUIRED_FIELD}
                      </span>
                    )}
                  </div>
                  {/* <div className="fwc-col-5 sm-12">

                    <div className="fwc-toggle ">
                      <input
                        type="checkbox"
                        id="applicant-tobacco"
                        {...register('applicant.tobacco')}
                      />
                      <label className="fwc-label" htmlFor="tobacco"></label>
                    </div>
                  </div> */}
                </div>
              </div>
            )}
            {!((state && TOBACCO_STATE_CODES.includes(state))) && (
              <Controller
                control={control}
                defaultValue={'NO'}
                render={({ field }) => (
                  <input type="hidden" {...field} />
                )}
                name={`applicant.isTabaccoUser`}
              />
            )}
            <div className='fwc-row'>
              <div className='fwc-col-12'>
                <h3 className="tag-title">{pageContent?.WHO_WILL_NEED_COVERAGE}</h3>
              </div>
            </div>
            <div className='form-group'>
              <div className="fwc-row">
                <div className="fwc-col-5 md-12 sm-12 radio-wrapper">
                  <div className="fwc-radio">
                    <input
                      {...register('coverage')}
                      type="radio"
                      value="applicant-only"
                      id="app-only"
                      data-analytics={'applicantOnlyBasicsIos'}
                    />
                    <label htmlFor="app-only">{pageContent?.APPLICANT_ONLY}</label>
                  </div>
                  <div className="fwc-radio">
                    <input
                      {...register('coverage')}
                      type="radio"
                      value="applicant-and-others"
                      id="app-others"
                      data-analytics="applicantOthersBasicsIos"
                    />
                    <label htmlFor="app-others">{pageContent?.APLLICANT_OTHERS}</label>
                  </div>
                </div>
              </div>
            </div>

            {spouseFields.map((element, index) => {
              return (
                <>
                  <div className={"fwc-row " + ((getValues(`spouse[${index}].isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES) ? "whiteBg " : "")} key={element.id}>
                    <div className="fwc-col-5 md-6 sm-12">
                      <div className='form-group'>
                        <div className="fwc-input">
                          <label
                            className="fwc-label"
                            htmlFor={`spouse[${index}].firstName`}
                          >
                            {pageContent?.SPOUSE_DOMESTIC_PARTNER}
                          </label>

                          <input
                            {...register(`spouse[${index}].firstName`, {
                              required: {
                                value: true,
                                message: pageContent?.REQUIRED_FIELD,
                              },
                              validate: validateFirstName,
                            })}
                            disabled={getValues(`spouse[${index}].isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES}
                            id={`spouse[${index}].firstName`}
                            maxLength={30}
                            placeholder={pageContent?.ENTER_YOUR_FIRST_NAME}
                            type="text"
                          />
                          {errors && errors.spouse && errors.spouse[index].firstName && errors.spouse[index].firstName?.type === "required" && (
                            <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                              {pageContent?.REQUIRED_FIELD}
                            </span>
                          )}
                          {errors && errors.spouse && errors.spouse[index].firstName && errors.spouse[index].firstName?.type === "validate" && (
                            <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                              {pageContent?.INVALID_CHARACTER}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="fwc-col-3  md-6 sm-12">
                      <div className='form-group'>
                        <div className="fwc-input dob">
                          <label
                            className="fwc-label"
                            htmlFor={`spouse[${index}].dateOfBirth`}
                          >
                            {pageContent?.DATE_OF_BIRTH}{" "}
                          </label><span>
                            <Tooltip icon message={pageContent?.HELP_DATE_OF_BIRTH} /></span>
                          <input type="date" min={dayjs('1900-01-01').format('YYYY-MM-DD')} max={dayjs().format('YYYY-MM-DD')} disabled={getValues(`spouse[${index}].isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES} {...register(`spouse[${index}].dateOfBirth`, {
                            validate: (value) => validateAge('spouse', value, `spouse[${index}]`),
                          })} />
                          {/* <Controller
                            name={`spouse[${index}].dateOfBirth`}
                            rules={{ required: { value: true, message: pageContent?.REQUIRED_FIELD } }}
                            render={({ field: { value, onChange, onBlur, name } }) => (
                              <DateInput
                                id={`spouse[${index}].dateOfBirth`}
                                defaultDate={value}
                                name={name}
                                disabled={getValues(`spouse[${index}].disabled`)}
                                onBlur={(value) => value === '' || validateAge('spouse', value.target.value, `spouse[${index}]`)}
                                onDateChange={onChange}
                              />
                            )}
                          /> */}
                          {errors.spouse && errors.spouse[index]?.dateOfBirth?.type === "required" && (
                            <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                              {pageContent?.REQUIRED_FIELD}
                            </span>
                          )}
                          {errors.spouse && errors.spouse[index]?.dateOfBirth?.type === "validate" && errors.spouse[index]?.dateOfBirth?.message && (
                            <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                              {errors.spouse[index]?.dateOfBirth?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="fwc-col-4 md-6 sm-12 desktop">
                      <i
                        className="sae-icon sae-icon-trash trash-icon"
                        id={`remove-spouse${index}`}
                        onClick={() => { spouseRemove(index); }}
                      ></i>
                    </div>
                  </div>
                  <div className='fwc-row'>
                    {((state && state === SET_APPLICATION_CONSTANTS.KY ? (TOBACCO_STATE_CODES.includes(state) && year === DEFAULT_YEAR) : TOBACCO_STATE_CODES.includes(state))) && (

                      <div className='fwc-col-12 md-6 sm-12'>
                        <div className='form-group'>
                          <fieldset className='tobacco-fieldset mt20'>
                            <legend className="fwc-label tobacco">
                              {pageContent?.HAVE_YOU_USED_TOBACCO}<span><Tooltip icon message={pageContent?.HELP_TOBACCO} /></span>
                            </legend>
                            <div className="fwc-radio">
                              <input
                                {...register(`spouse[${index}].isTabaccoUser`, {
                                  validate: (value) => validateTobacco(value)
                                })}
                                type="radio"
                                value="YES"
                                id={`spouse[${index}]-tobacco-yes`}
                              />
                              <label htmlFor={`spouse[${index}]-tobacco-yes`}>Yes</label>
                            </div>
                            <div className="fwc-radio">
                              <input
                                {...register(`spouse[${index}].isTabaccoUser`, {
                                  validate: (value) => validateTobacco(value)
                                })}
                                type="radio"
                                value="NO"
                                id={`spouse[${index}]-tobacco-no`}
                              />
                              <label htmlFor={`spouse[${index}]-tobacco-no`}>No</label>
                            </div>
                          </fieldset>
                          {errors && errors.spouse && errors.spouse[index].isTabaccoUser && errors.spouse[index].isTabaccoUser?.type === "validate" && (
                            <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                              {pageContent?.REQUIRED_FIELD}
                            </span>
                          )}
                        </div>
                      </div>

                    )}
                    <div className="fwc-col-4 md-6 sm-12 mobile">
                      <Button
                        btnType="secondary"
                        id={`remove-spouse${index}`}
                        onClick={() => { spouseRemove(index); }}
                      >Remove Spouse</Button>
                    </div>
                  </div>
                  {getValues(`spouse[${index}].isMedicAidEligible`) && state !== GEORGIA_CODE && <div className='fwc-row'>
                    <div className='fwc-col-8 alert-info'>
                      <p><i className="sae-icon sae-icon-high-importance"></i><span className="name">{getValues(`spouse[${index}].firstName`)}</span> {pageContent?.medicaidEligible}</p>
                    </div>
                  </div>}
                  {getValues(`spouse[${index}].isMedicAidEligible`) && state === GEORGIA_CODE && <div className='fwc-row'>
                    <div className='fwc-col-8 alert-info'>
                      <p><i className="sae-icon sae-icon-high-importance"></i><span className="name">{getValues(`spouse[${index}].firstName`)}</span> {pageContent?.gaStatemedicaidEligible}</p>
                    </div>
                  </div>}
                  <div className='fwc-row divider'>
                    <div className='fwc-col-12 '>
                      <hr />
                    </div>
                  </div>
                </>
              );
            })}

            {dependentFields.map((element, index) => {
              return (
                <>
                  <div className={"fwc-row " + ((getValues(`dependents[${index}].isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES) ? "whiteBg " : "")} key={element.id}>
                    <div className="fwc-col-5 md-6 sm-12">
                      <div className='form-group'>
                        <div className="fwc-input">
                          <label className="fwc-label" htmlFor={`dependents[${index}].firstName`}>
                            {pageContent?.DEPENDENT_FIRST_NAME}
                          </label>
                          <input
                            {...register(`dependents[${index}].firstName`, {
                              required: {
                                value: true,
                                message: pageContent?.REQUIRED_FIELD,
                              },
                              validate: validateFirstName,
                            })}
                            id={`dependents[${index}].firstName`}
                            maxLength={30}
                            disabled={getValues(`dependents[${index}].isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES}
                            placeholder={pageContent?.ENTER_YOUR_FIRST_NAME}
                            type="text"
                          />
                          {errors.dependents && errors.dependents[index]?.firstName?.type === "required" && (
                            <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                              {pageContent?.REQUIRED_FIELD}
                            </span>
                          )}
                          {errors.dependents && errors.dependents[index]?.firstName?.type === "validate" && (
                            <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                              {pageContent?.INVALID_CHARACTER}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    <div className="fwc-col-3 md-6 sm-12">
                      <div className='form-group'>
                        <div className="fwc-input dob">
                          <label
                            className="fwc-label"
                            htmlFor={`dependents[${index}].dateOfBirth`}
                          >
                            {pageContent?.DATE_OF_BIRTH}{" "}
                          </label> <span>
                            <Tooltip icon message={pageContent?.HELP_DATE_OF_BIRTH} /></span>
                          <input type="date" min={dayjs('1900-01-01').format('YYYY-MM-DD')} max={dayjs().format('YYYY-MM-DD')} disabled={getValues(`dependents[${index}].isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES} {...register(`dependents[${index}].dateOfBirth`, {
                            validate: (value) => validateAge('dependent', value, `dependents[${index}]`, getValues(`dependents[${index}].applicantType`)),
                          })} />
                          {/* <Controller
                            name={`dependents[${index}].dateOfBirth`}
                            rules={{
                              required: { value: true, message: pageContent?.REQUIRED_FIELD }
                            }}
                            render={({ field: { value, onChange, onBlur, name } }) => (
                              <DateInput
                                id={`dependents[${index}].dateOfBirth`}
                                defaultDate={value}
                                name={name}
                                disabled={getValues(`dependents[${index}].disabled`)}
                                onBlur={(value) => value === '' || validateAge('dependent', value.target.value, `dependents[${index}]`, getValues(`dependents[${index}].applicantType`))}
                                onDateChange={onChange}
                              />
                            )}
                          /> */}
                          {errors.dependents && errors.dependents[index]?.dateOfBirth?.type === "required" && (
                            <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                              {pageContent?.REQUIRED_FIELD}
                            </span>
                          )}
                          {errors.dependents && errors.dependents[index]?.dateOfBirth?.type === "validate" && errors.dependents[index]?.dateOfBirth?.message && (
                            <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                              {errors.dependents[index]?.dateOfBirth?.message}
                            </span>
                          )}
                        </div>
                      </div>
                    </div>
                    {(state === CALIFORNIA_CODE) && (coverageType && MEDICAL_PLAN_TYPES.includes(coverageType)) && (
                      <div className="fwc-col-3  md-6 sm-12">
                        <div className='form-group'>
                          <div className="fwc-select-list">
                            <label className="fwc-label" htmlFor={`dependents[${index}].applicantType`}>
                              {pageContent?.RELATIONSHIP_APPLICANT}
                            </label>
                            {getValues(`dependents[${index}].isPolicyApplicant`) === SET_APPLICATION_CONSTANTS.YES ? <p>{getValues(`dependents[${index}].applicantType`)}</p> : <select
                              className="text-trim"
                              id={`dependents[${index}].applicantType`}
                              {...register(`dependents[${index}].applicantType`, {
                                validate: (value) => validateRealationShipType(`dependents[${index}]`, value)
                              })}
                            >
                              <option value="">{pageContent?.SELECT}</option>
                              <option value={RELATIONSHIP.CHILD}>{pageContent?.CHILD}</option>
                              <option value={RELATIONSHIP.PARENT}>{pageContent?.PARENT}</option>
                              <option value={RELATIONSHIP.STEPPARENT}>{pageContent?.STEPPARENT}</option>
                              <option value={RELATIONSHIP.PARENTINLAW}>{pageContent?.PARENT_IN_LAW}</option>
                              <option value={RELATIONSHIP.OTHER}>{pageContent?.OTHER}</option>
                            </select>}
                            {errors.dependents && errors.dependents[index]?.applicantType?.type === "validate" && (
                              <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                {errors.dependents[index]?.applicantType?.message}
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    )}
                    <div className="fwc-col-1 md-12 sm-12 desktop">
                      <i
                        className="sae-icon sae-icon-trash trash-icon"
                        id={`remove-dependent${index}`}
                        onClick={() => { dependentRemove(index) }}
                      ></i>
                    </div>
                  </div>
                  <div className='fwc-row'>
                    {((state && state === SET_APPLICATION_CONSTANTS.KY ? (TOBACCO_STATE_CODES.includes(state) && year === DEFAULT_YEAR) : TOBACCO_STATE_CODES.includes(state))) && (
                      <div className='fwc-col-5 md-6 sm-12'>
                        <div className='form-group'>
                          <fieldset className='tobacco-fieldset mt20'>
                            <legend className="fwc-label tobacco">
                              {pageContent?.HAVE_YOU_USED_TOBACCO}<span><Tooltip icon message={pageContent?.HELP_TOBACCO} /></span>
                            </legend>
                            <div className="fwc-radio">
                              <input
                                {...register(`dependents[${index}].isTabaccoUser`, {
                                  validate: (value) => validateTobacco(value)
                                })}
                                type="radio"
                                value="YES"
                                id={`dependents[${index}]-tobacco-yes`}
                              />
                              <label htmlFor={`dependents[${index}]-tobacco-yes`}>Yes</label>
                            </div>
                            <div className="fwc-radio">
                              <input
                                {...register(`dependents[${index}].isTabaccoUser`, {
                                  validate: (value) => validateTobacco(value)
                                })}
                                type="radio"
                                value="NO"
                                id={`dependents[${index}]-tobacco-no`}
                              />
                              <label htmlFor={`dependents[${index}]-tobacco-no`}>No</label>
                            </div>
                          </fieldset>
                          {errors.dependents && errors.dependents[index]?.isTabaccoUser?.type === "validate" && (
                            <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                              {pageContent?.REQUIRED_FIELD}
                            </span>
                          )}
                        </div>
                      </div>
                    )}<div className="fwc-col-4 md-6 sm-12 mobile">
                      <Button
                        btnType="secondary"
                        className=""
                        id={`remove-dependent${index}`}
                        onClick={() => { dependentRemove(index) }}
                      >Remove Dependent</Button>
                    </div>
                  </div>
                  {getValues(`dependents[${index}].isMedicAidEligible`) && state !== GEORGIA_CODE && <div className='fwc-row'>
                    <div className='fwc-col-8 alert-info'>
                      <p><i className="sae-icon sae-icon-high-importance"></i><span className="name">{getValues(`dependents[${index}].firstName`)}</span> {pageContent?.medicaidEligible}</p>
                    </div>
                  </div>}
                  {getValues(`dependents[${index}].isMedicAidEligible`) && state === GEORGIA_CODE && <div className='fwc-row'>
                    <div className='fwc-col-8 alert-info'>
                      <p><i className="sae-icon sae-icon-high-importance"></i><span className="name">{getValues(`dependents[${index}].firstName`)}</span> {pageContent?.gaStatemedicaidEligible}</p>
                    </div>
                  </div>}
                  <div className='fwc-row divider'>
                    <div className='fwc-col-12 '>
                      <hr />
                    </div>
                  </div>
                </>
              );
            })}

            {coverageWatch !== 'applicant-only' &&
              <div className='fwc-row'>
                <div className='fwc-col-12 addApplicantandOthers'>
                  <Button
                    btnType="secondary"
                    name="add-dependent"
                    id="add-dependent"
                    data-analytics="addDepBtnBasicsIos"
                    disabled={(spouseFields.length === 1 ? dependentFields.length === 18 : dependentFields.length === 19) || disableFields}
                    onClick={(e) => { addDepSpouseFormFields(e, 'dependent'); }}
                  >
                    + {pageContent?.ADD_DEPENDENT}
                  </Button>
                  <Button data-analytics="addSpouseBtnBasicsIos" btnType="secondary" name="add-spouse" id="add-spouse" disabled={(spouseFields.length === 1 || dependentFields.length === 19) || disableFields}
                    onClick={(e) => addDepSpouseFormFields(e, 'spouse')}>
                    + {pageContent?.ADD_SPOUSE}
                  </Button>
                </div>
              </div>
            }
            {disableFields && <p className="custom-alert">
              <span className="sae-icon sae-icon-error"></span>
              {pageContent?.alert.dependents}
            </p>}
          </div>
        </div>
        <div className='fwc-row'>
          <div className='fwc-col-12 ca-related-content'>
            {(MEDICAL_PLAN_TYPES.includes(coverageType) && state === CALIFORNIA_CODE) && (
              <div id="ca-rel-1">
                {pageContent?.CA_RELATIONSHIP}
              </div>
            )}
            {showParentTextBlock && (
              <div id="ca-rel-2" dangerouslySetInnerHTML={{ __html: pageContent?.CA_PARENT_IN_LAW }}>
              </div>
            )}
          </div>
        </div>

      </div >
      <Modal open={showConfirmChangesModal} onClose={handleshowConfirmChangesModalClose} title={'Change Demographics'}>
        <Modal.Body>
          <p>The changes you have made on this page require us to recaluclate the prices on the plans. Items that have been selected or marked as favorite will be reset</p>
        </Modal.Body>
        <Modal.Footer>
          <Button
            btnType="secondary"
            id="modal-action-cancel"
            onClick={handleshowConfirmChangesModalClose}
          >
            close
          </Button>
          <Button
            id="modal-action-undo"
            onClick={undoBasicFormChanges}
          >
            Undo
          </Button>
          <Button
            id="modal-action-continue"
            onClick={updateBasicFormChanges}
          >
            Continue
          </Button>
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ApplicantInfo;
