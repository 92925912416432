import { Modal } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent, getCMSdynamicContent } from '../../../api/cms/cmsUtil';
import { STATUSES } from '../../../api/constants';
import LoginModal from '../../../pages/public/login/login-modal/LoginModal';
import { getApplicationTypeForAEM } from '../../../pages/public/medical-plan-details/medicalPlanDetailsUtils';
import { fetchAlphaPrefix } from '../../../pages/public/medical-plans/medicalPlansServices';
import { getCoverageTypeForDynamicAEM } from '../../../pages/public/plan-recommendations/planRecommendationsUtils';
import TwoFAModalBody from '../../../pages/public/two-fa-modal/TwoFAModalBody';
import { TWO_FA_USE_CASES } from '../../../pages/public/two-fa-modal/constants';
import { default as Content } from '../../../pages/public/two-fa-modal/twoFAModal.json';
import { AGENT_ROLES, BRAND_NAMES, COMPARE_PLANS, EMAIL_REGX, EMAIL_REGX_FOR_ATK, FINDCARE_URL_ALPHAPREFIX_ANTHEM, FINDCARE_URL_ALPHAPREFIX_WELLPOINT, FINDCARE_URL_ANTHEM, FINDCARE_URL_WELLPOINT, ISSUEDBY_STATECODES, LANGUAGE_CODES, MEDICAL_PLAN_LIST, NAV_PATHS, NO_REPLY, NO_REPLY_WELLPOINT, PAGE_BLOCKS, PAGE_IDS, PLAN_LIST, PLAN_SUMMARY_STR, PLAN_TYPES, PLAN_TYPES_NAMES, QUOTE_SUMMARY, REQUESTED_FROM, SCREEN_NAMES, SEND_PROPOSAL_RESPONSE_CODE, STATE_CODES, USER_ROLES, WELLPOINT_FOR_EMAIL } from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import FullPageLoader from '../full-page-loader/FullPageLoader';
import { default as GlobalHeaderContent } from '../global-header/globalHeaderContent.json';
import './EmailPrint.scss';
import { default as ModalContent } from './emailPrint.json';
import { atkSendProposal, fetchAtkPrint, fetchEmailPlan, fetchPrintPlan } from './emailPrintServices';
import { calculateTotalCost, checkTransitionExist, converBase64ToPdf, getAgentDetailsByState, getAtkPrintPayload, getAtkSendProposalPayload, getEmailAdress, getFormattedEmailApplicantDetails, selectNowCheck } from './emailPrintUtils';
const twoFAHeading = Content.data.iospagecontentList.items[0].pageContent.TWO_FA.HEADING;
//const { REACT_APP_PUBLIC_URI } = process.env;

//const emailPageContent = ModalContent.data.iospagecontentList.items[0].pageContent;

const headerContent = GlobalHeaderContent.data.iospagecontentList.items[0].pageContent.GlobalHeader;

export default function EmailPrint({
    pdfBrochureUrl,
    type,
    alphaPrefix,
    providerNetworkType,
    emailPrintContract,
    isCurrentCoveragePlan,
    emailPrintContracts,
    hideSendPrintButtons = false,
    openEmailModal = false,
    printQuote = false,
    closeEmailModal,
    onPrintQuoteSuccess
}: {
    pdfBrochureUrl?: string;
    type?: string;
    alphaPrefix?: string;
    providerNetworkType?: string;
    emailPrintContract?: Contract;
    isCurrentCoveragePlan?: boolean;
    emailPrintContracts?: Contracts;
    hideSendPrintButtons?: boolean;
    openEmailModal?: boolean;
    printQuote?: boolean;
    closeEmailModal?: () => void;
    onPrintQuoteSuccess?: () => void;
}
) {
    const [showLoginModal, setShowLoginModal] = useState(false);
    const [showEmailModal, setShowEmailModal] = useState(false);
    const [showTwoFAModal, setShowTwoFAModal] = useState(false);
    const [loading, setLoading] = useState(false);
    const [emailSent, setEmailSent] = useState(false);
    const [emailSentError, setEmailSentError] = useState(false);
    const [printSentError, setPrintSentError] = useState(false);
    const [medicalPdfUrl, setMedicalPdfUrl] = useState('');
    const [dentalPdfUrl, setDentalPdfUrl] = useState('');
    const [visionPdfUrl, setVisionPdfUrl] = useState('');
    const [emailSentObj, setEmailSentObj] = useState<EmailProps>({} as EmailProps);
    const [emailReqObj, setEmailReqObj] = useState<EmailPlanReq>({} as EmailPlanReq);
    const [modalHeading, setModalHeading] = useState('');
    const [contentLoaded, setContentLoaded] = useState(false);
    const [pageContent, setContent] = useState<any>(null);
    const [pageLogoContent, setLogoContent] = useState<any>(null);
    const [pdfPhysicianURLArr, setPdfPhysicianURLArr] = useState<PdfPhysicianURL[]>([]);
    const { zipCodeRes, brand, selectedPlan, selectedDentalPlan, selectedVisionPlan,
        applicantFormDetails, coverageDate, county, favouriteMedicalPlans, favouriteVisionPlans,
        favouriteDentalPlans, selectedProduct, currentCoverageDentalPlan, currentCoverageMedicalPlan, currentCoverageVisionPlan, state, agentSSOInfo, year, agentBrand, agentState, agentYear, comparedProducts, aTKsaveProposalResponse, agentApplicantFormDetails, updateATKsaveProposalResponse } = useGlobalStore((store) => store);
    const { loggedIn, demographicInfo, role, isWellpoint } = useUserStore((state) => state);

    const { isAtk } = agentSSOInfo;
    const FINDCARE_URL = isWellpoint ? FINDCARE_URL_WELLPOINT : FINDCARE_URL_ANTHEM;
    const FINDCARE_ALPHAPREFIX = isWellpoint ? FINDCARE_URL_ALPHAPREFIX_WELLPOINT : FINDCARE_URL_ALPHAPREFIX_ANTHEM;

    const {
        register,
        handleSubmit,
        formState,
        getValues,
        reset
    } = useForm<EmailProps>({
        mode: "onChange"
    });

    const navigate = useNavigate();

    const { errors, isValid } = formState;

    useEffect(() => {
        document.body.classList.remove("no-scroll");
    }, [showEmailModal, emailSent, emailSentError, printSentError]);

    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.EMAIL_PRINT);
            let cmsResponse = response.data;

            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.EmailPrint);
        } catch (error) {
            setContent(ModalContent.data.iospagecontentList.items[0].pageContent?.EmailPrint);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, [zipCodeRes.stateCode]);


    const getLogoContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.GLOBAL_HEADER);
            let cmsResponse = response.data;

            setLogoContent(cmsResponse.data.iospagecontentList.items[0].pageContent.GlobalHeader);
        } catch (error) {
            setLogoContent(headerContent);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, [zipCodeRes.stateCode]);


    const getPdfURL = useCallback(async (screenName: string, planTyeForAEM: string): Promise<boolean> => {
        setMedicalPdfUrl('');
        setDentalPdfUrl('');
        setVisionPdfUrl('');
        if (screenName !== '') {
            try {
                let queryParam = '';
                if (isAtk) {
                    queryParam = ';state=' + agentState + ';brand=' + agentBrand + ';role=' + AGENT_ROLES.BROKER.toLowerCase() + ';coveragetype=' + ';plantype=' + planTyeForAEM + ';coveragetype=' + getCoverageTypeForDynamicAEM(screenName) + ';year=' + agentYear;
                } else {
                    queryParam = ';state=' + state + ';brand=' + brand + ';role=' + role.toLowerCase() + ';coveragetype=' + ';plantype=' + planTyeForAEM + ';coveragetype=' + getCoverageTypeForDynamicAEM(screenName) + ';year=' + year;
                }
                const response = await getCMSdynamicContent(PAGE_IDS.PRODUCT_BROUCHER_LINK_ALL, PAGE_BLOCKS.PRODUCT_BROUCHER_LINK, queryParam)
                const cmsResponse = response?.data;

                switch (screenName) {
                    case SCREEN_NAMES.MEDICAL_PLAN_DETAILS:
                        setMedicalPdfUrl(cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary);
                        break;
                    case SCREEN_NAMES.DENTAL_DETAILS_SCREEN_NAME:
                        setDentalPdfUrl(cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary);
                        break;
                    case SCREEN_NAMES.VISION_PLAN_DETAILS:
                        setVisionPdfUrl(cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary);
                        break;
                    default:
                        setMedicalPdfUrl('');
                        setDentalPdfUrl('');
                        setVisionPdfUrl('');
                }

            } catch (error) {
                setMedicalPdfUrl('');
                setDentalPdfUrl('');
                setVisionPdfUrl('');
                return false;
            }
        }
        return true;

    }, [zipCodeRes.stateCode]);



    const getAlphaPrefix = (payload: AlpaPrefixReq, planType: string, contractCode: string) => {
        fetchAlphaPrefix(payload).then((response) => {
            if (response?.data?.message === STATUSES.SUCCESS) {
                const index = pdfPhysicianURLArr.findIndex(el => el.contractCode === contractCode);
                if (index !== -1) {
                    if (planType === PLAN_TYPES_NAMES.DENTAL) {
                        pdfPhysicianURLArr[index].physicianFinderUrl = response?.data?.providerNetworkType ? `${FINDCARE_URL}?planstate=${state}&plantype=${PLAN_TYPES_NAMES.DENTAL}&planname=${response?.data?.providerNetworkType}` : '';
                    } else {
                        pdfPhysicianURLArr[index].physicianFinderUrl = response?.data?.alphaPrefixCode ? `${FINDCARE_ALPHAPREFIX}${response?.data?.alphaPrefixCode}` : '';
                    }
                    setPdfPhysicianURLArr(pdfPhysicianURLArr);
                }
            }
        })
            .catch((error) => {
                const index = pdfPhysicianURLArr.findIndex(el => el.contractCode === contractCode);
                if (index !== -1) {
                    pdfPhysicianURLArr[index].physicianFinderUrl = '';
                    setPdfPhysicianURLArr(pdfPhysicianURLArr);
                }
            });
    }

    const initiate = async () => {
        await getContent();
        await getLogoContent();
    };

    useEffect(() => {
        initiate();
    }, []);


    useEffect(() => {
        if (emailReqObj && Object.keys(emailReqObj)?.length) {
            sendEmail();
        }
    }, [emailReqObj])


    useEffect(() => {
        if (emailPrintContracts && emailPrintContracts?.length) {
            let isMedicalContract = emailPrintContracts?.some(el => el.plan[0].planType === PLAN_TYPES_NAMES.MEDICAL);
            let isDentalContract = emailPrintContracts?.some(el => el.plan[0].planType === PLAN_TYPES_NAMES.DENTAL);
            let isVisionContract = emailPrintContracts?.some(el => el.plan[0].planType === PLAN_TYPES_NAMES.VISION);
            if (isMedicalContract) {
                const medicalContract: Contract = emailPrintContracts?.find(el => el.plan[0].planType === PLAN_TYPES_NAMES.MEDICAL) ?? {} as Contract;
                getPdfURL(SCREEN_NAMES.MEDICAL_PLAN_DETAILS, getApplicationTypeForAEM(medicalContract?.onExchange, medicalContract?.poPlan));
            }
            if (isDentalContract) {
                const dentalContract: Contract = emailPrintContracts?.find(el => el.plan[0].planType === PLAN_TYPES_NAMES.DENTAL) ?? {} as Contract;
                getPdfURL(SCREEN_NAMES.DENTAL_DETAILS_SCREEN_NAME, getApplicationTypeForAEM(dentalContract?.onExchange, dentalContract?.poPlan));
            }
            if (isVisionContract) {
                const visionContract: Contract = emailPrintContracts?.find(el => el.plan[0].planType === PLAN_TYPES_NAMES.VISION) ?? {} as Contract;
                getPdfURL(SCREEN_NAMES.VISION_PLAN_DETAILS, getApplicationTypeForAEM(visionContract?.onExchange, visionContract?.poPlan));
            }
            let arr: PdfPhysicianURLArr = emailPrintContracts?.map(el => ({ contractCode: el.contractCode, planType: el.plan[0].planType, physicianFinderUrl: '' }));
            setPdfPhysicianURLArr(arr);
        }
    }, [emailPrintContracts])


    useEffect(() => {
        if (pdfPhysicianURLArr && pdfPhysicianURLArr?.length) {
            emailPrintContracts?.forEach(el => {
                getAlphaPrefix({ contractCode: el.contractCode }, el.plan[0].planType, el.contractCode);
            });
        }
    }, [pdfPhysicianURLArr])

    const handleModalClose = (e) => {
        isAtk && closeEmailModal && closeEmailModal();
        setShowEmailModal(false);
        setShowLoginModal(false);
    };

    const handleTwoFAModalClose = () => {
        setShowTwoFAModal(false);
    };

    const validateReEnterEmail = (value) => {
        return value === getValues().email;
    };

    const submitEmailForm = handleSubmit(async (data) => {
        if (isValid) {
            setEmailSentObj(data);
            if (isAtk) {
                const atkSendProposalPayload: AtkSendProposalPayload = getAtkSendProposalPayload(data, pageContent?.ATK_SEND_PROPOSAL, pdfBrochureUrl ?? '');
                setLoading(true);
                setEmailSentError(false);
                setShowEmailModal(false);
                closeEmailModal && closeEmailModal();
                atkSendProposal(atkSendProposalPayload).then((response) => {
                    setLoading(false);
                    if (response.data.responseMessage.responseCode === SEND_PROPOSAL_RESPONSE_CODE.SUCCESS) {
                        setEmailSent(true);

                    } else {
                        setEmailSent(false);
                        setEmailSentError(true);
                    }
                })
                    .catch((error) => {
                        setLoading(false);
                        setEmailSent(false);
                        setEmailSentError(true);
                    });
            } else {
                if ((type === PLAN_SUMMARY_STR || type === COMPARE_PLANS) && emailPrintContracts && emailPrintContracts.length) {
                    setEmailReqObj(formatEmailPlanRequestObj(emailPrintContracts));
                } else if (emailPrintContract?.plan && Object.keys(emailPrintContract).length) {
                    setEmailReqObj(formatEmailPlanRequestObj([emailPrintContract]));
                }
            }
        }
    });

    const setLogoUrl = () => {
        let url: string = '';
        const logoBrand = isAtk ? agentBrand : brand;
        switch (logoBrand) {
            case BRAND_NAMES.ABCBS:
                url = pageLogoContent?.ABCBS_LOGO_PNG;
                break;
            case BRAND_NAMES.ABC:
                url = pageLogoContent?.ABC_LOGO_PNG;
                break;
            case BRAND_NAMES.WELLPOINT_BRAND:
                url = pageLogoContent?.WELLPOINT_LOGO_PNG;
                break;
            default:
                url = '';
        }
        return `${window.location.origin}` + url;
    }
    const setAnalyticsString = () => {
        let str = '';
        switch (type) {
            case 'M':
                str = 'Medical';
                break;
            case 'D':
                str = 'Dental';
                break;
            case 'V':
                str = 'Vision';
                break;
            case 'comparePlans':
                str = 'ComparePlans';
                break;
            case 'planSummary':
                str = 'Summary';
                break;
        }
        return str;
    }

    const setColoradoUrl = () => {
        let url = '';
        if (type === PLAN_SUMMARY_STR || type === COMPARE_PLANS) {
            let isColaradoPlan = emailPrintContracts?.some(el => el.poPlan)
            if (isColaradoPlan) {
                url = `${window.location.origin}` + pageLogoContent?.ABCBS_CO_LOGO;
            }
        } else {
            if (emailPrintContract && emailPrintContract?.poPlan) {
                url = `${window.location.origin}` + pageLogoContent?.ABCBS_CO_LOGO
            }
        }
        return url;
    }

    const setPhysicianFinderUrl = (contractCode: string, planType: string) => {
        let url = '';
        if ((type === PLAN_SUMMARY_STR || type === COMPARE_PLANS) && pdfPhysicianURLArr?.length) {
            const index = pdfPhysicianURLArr.findIndex(el => el.contractCode === contractCode);
            if (index !== -1) {
                url = pdfPhysicianURLArr[index].physicianFinderUrl;
            }
        } else {
            if (planType === PLAN_TYPES_NAMES.DENTAL) {
                url = providerNetworkType ? `${FINDCARE_URL}?planstate=${state}&plantype=${PLAN_TYPES_NAMES.DENTAL}&planname=${providerNetworkType}` : '';
            } else {
                url = alphaPrefix ? `${FINDCARE_ALPHAPREFIX}${alphaPrefix}` : '';
            }
        }
        return url;
    }

    const setPdfBrochureUrl = (planType: string) => {
        let url = '';

        if (type === PLAN_SUMMARY_STR || type === COMPARE_PLANS) {
            switch (planType) {
                case PLAN_TYPES_NAMES.MEDICAL:
                    url = medicalPdfUrl ? medicalPdfUrl : '';
                    break;
                case PLAN_TYPES_NAMES.DENTAL:
                    url = dentalPdfUrl ? dentalPdfUrl : '';
                    break;
                case PLAN_TYPES_NAMES.VISION:
                    url = visionPdfUrl ? visionPdfUrl : '';
                    break;
                default:
                    url = '';
            }
        } else {
            url = pdfBrochureUrl ? pdfBrochureUrl : '';
        }
        return url;
    }

    const checkCurrentCoveragePlan = (contractCode: string, planType: string) => {
        let current = false;
        if (role !== USER_ROLES.MEMBER) {
            return current;
        }
        if (type === COMPARE_PLANS || type === PLAN_SUMMARY_STR) {
            switch (planType) {
                case PLAN_TYPES_NAMES.MEDICAL:
                    return current = contractCode === currentCoverageMedicalPlan?.contractCode;

                case PLAN_TYPES_NAMES.DENTAL:
                    return current = contractCode === currentCoverageDentalPlan?.contractCode;

                case PLAN_TYPES_NAMES.VISION:
                    return current = contractCode === currentCoverageVisionPlan?.contractCode;
                default:
                    return current;
            }
        } else {
            return current = isCurrentCoveragePlan ? isCurrentCoveragePlan : false;
        }
    }


    const disclaimerForWellpointStates = () => {
        switch (state) {
            case STATE_CODES.FL:
                return pageContent?.FOOTER?.FL;
            case STATE_CODES.MD:
                return pageContent?.FOOTER?.MD;
            case STATE_CODES.TX:
                return pageContent?.FOOTER?.TX;
            default:
                return pageContent?.FOOTER_CONTENT;
        }
    }

    const getAgentDetails = () => {
        const agent = getAgentDetailsByState(agentSSOInfo, agentState);
        if (["agency", "general_agent"].includes(agentSSOInfo?.agentType.toLowerCase())) {
            if (agentSSOInfo?.agentInfo?.agent[0]?.agencyName) {
                return agentSSOInfo.agentInfo.agent[0].agencyName;
            } else {
                return agent?.agencyInfo?.agency?.agencyName ?? agentSSOInfo?.agentInfo?.agent[0]?.agentFName + ' ' + agentSSOInfo?.agentInfo?.agent[0]?.agentLName;
            }
        } else {
            return agentSSOInfo?.agentInfo?.agent[0]?.agentFName + ' ' + agentSSOInfo?.agentInfo?.agent[0]?.agentLName;
        }
    }

    const selectSubject = () => {
        if (isAtk) {
            return isWellpoint ? pageContent?.ATK_SEND_PROPOSAL?.WELLPOINT?.EMAIL_SUBJECT + ' ' + getAgentDetails() : pageContent?.ATK_SEND_PROPOSAL?.ANTHEM?.EMAIL_SUBJECT + ' ' + getAgentDetails();
        } else {
            return isWellpoint ? (pageContent?.REQUESTD_INFO + ' ' + WELLPOINT_FOR_EMAIL) : (pageContent?.REQUESTD_INFO + ' ' + brand);
        }
    }

    const formatAdditionalParams = () => {
        const additionalParams: AdditionalParams = {
            category: MEDICAL_PLAN_LIST,
            coloradoLogoUrl: setColoradoUrl(),
            dentalPlanSelected: !!(selectedDentalPlan.plan && Object.keys(selectedDentalPlan?.plan).length),
            disclaimer: pageContent?.FOOTER_CONTENT,
            issuedByCheck: ISSUEDBY_STATECODES.includes(zipCodeRes.stateCode) && type === PLAN_TYPES.MEDICAL,
            logoUrl: setLogoUrl(),
            medicalPlanSelected: !!(selectedPlan.plan && Object.keys(selectedPlan?.plan).length),
            message: getValues().message,
            selectedDentalCost: selectedDentalPlan.plan && Object.keys(selectedDentalPlan?.plan).length ? selectedDentalPlan.plan?.rateData?.rate.totalPremium.toString() ?? '' : '',
            selectedDentalPlanName: selectedDentalPlan.plan && Object.keys(selectedDentalPlan?.plan).length ? selectedDentalPlan.plan.planMarketingName : '',
            selectedMedicalCost: selectedPlan.plan && Object.keys(selectedPlan?.plan).length ? selectedPlan.plan?.rateData?.rate.totSubsidizedPrem.toString() ?? '' : '',
            selectedMedicalPlanName: selectedPlan.plan && Object.keys(selectedPlan?.plan).length ? selectedPlan.plan.planMarketingName : '',
            selectedVisionCost: selectedVisionPlan.plan && Object.keys(selectedVisionPlan?.plan).length ? selectedVisionPlan.plan?.rateData?.rate.totalPremium.toString() ?? '' : '',
            selectedVisionPlanName: selectedVisionPlan.plan && Object.keys(selectedVisionPlan?.plan).length ? selectedVisionPlan.plan.planMarketingName : '',
            selectNowCheck: !(selectNowCheck()),
            subject: `${REQUESTED_FROM} ${isWellpoint ? WELLPOINT_FOR_EMAIL : brand}`,
            templateName: type === PLAN_SUMMARY_STR ? QUOTE_SUMMARY : PLAN_LIST,
            to: getValues().email,
            totalCost: calculateTotalCost(selectedPlan?.plan?.rateData?.rate?.totSubsidizedPrem, selectedDentalPlan?.plan?.rateData?.rate?.totalPremium, selectedVisionPlan?.plan?.rateData?.rate?.totalPremium).toString(),
            visionPlanSelected: !!(selectedVisionPlan.plan && Object.keys(selectedVisionPlan?.plan).length),
        };

        return additionalParams;
    }

    const formatEmailDemographics = () => {
        if (demographicInfo && Object.keys(demographicInfo).length) {
            const emailDemographicsObj: EmailDemographics = {
                agent: {
                    agentTin: ''
                },
                applicants: getFormattedEmailApplicantDetails(demographicInfo?.applicant ?? []),
                brand: demographicInfo?.brand ?? '',
                county: demographicInfo?.county ?? '',
                coverageDate: formatDateToYYYYMMDD(demographicInfo?.coverageEffectiveDate ?? ''),
                id: Number(demographicInfo.demographicId),
                stateCode: demographicInfo?.state ?? '',
                zipCode: demographicInfo.zipCode ?? ''
            }
            return emailDemographicsObj;
        } else {
            const emailDemographicsObj: EmailDemographics = {
                agent: {
                    agentTin: ''
                },
                applicants: getFormattedEmailApplicantDetails(applicantFormDetails),
                brand: brand,
                county: county,
                coverageDate: formatDateToYYYYMMDD(coverageDate),
                id: '',
                stateCode: zipCodeRes.stateCode,
                zipCode: zipCodeRes.zipCode
            }
            return emailDemographicsObj;
        }
    }

    const formatEmailPlanBenefit = (benfits: PlanBenefit[]) => {
        const arr: EmailPlanBenefit[] = benfits?.map(el => ({
            name: el.planBenefitDisplayName,
            sortOrder: el.planBenefitSortOrder,
            value: el.planBenefitValue
        }))
        return arr;
    }
    const formatEmailPlan = (plans: Plan[]) => {
        const arr: EmailPlan[] = plans?.map(el => ({ displayName: el.planDisplayName, planName: el.planName, type: el.planType, benefits: formatEmailPlanBenefit(el.planBenefits) }));
        return arr
    }


    const getIssuedBy = (contractObj: Contract) => {
        if (ISSUEDBY_STATECODES.includes(zipCodeRes.stateCode) && type === PLAN_TYPES.MEDICAL) {
            if (role === USER_ROLES.MEMBER) {
                return pageContent?.REFER_TO_SBC;
            } else {
                return contractObj?.companyDesc
            }
        } else {
            return '';
        }
    }

    const formatEmailContract = (contractObj: Contract, contracts: Contract[]) => {
        const emailContractObj: EmailContract = {
            coloradoOptions: contractObj.poPlan,
            contractCode: contractObj.contractCode,
            issuedBy: getIssuedBy(contractObj),
            pdfBrochureUrl: setPdfBrochureUrl(contractObj.plan[0].planType),
            physicianFinderUrl: setPhysicianFinderUrl(contractObj.contractCode, contractObj.plan[0].planType),
            plan: formatEmailPlan(contractObj.plan),
            planMarketingName: contractObj.planMarketingName,
            portfolio: contractObj.onExchange,
            selectNowUrl: getSelectNowUrl(contractObj, contracts),
            tier: contractObj?.tiersType ? contractObj.tiersType : ''
        }
        return emailContractObj;
    }

    const getSelectNowUrl = (contractObj: Contract, contracts: Contract[]) => {
        let selectNowUrl = '';
        if (!(selectNowCheck())) {
            selectNowUrl = `${window.location.origin}${NAV_PATHS.STANDALONE_LOGIN}?language=en&demographicsId=${demographicInfo.demographicId}&${getPlanContractCodes(contractObj, contracts)}`;
        }
        return selectNowUrl;
    }

    const getPlanContractCodes = (contractObj: Contract, contracts: Contract[]) => {
        if (contractObj.plan[0].planType === PLAN_TYPES_NAMES.MEDICAL) {
            return `medicalContractCode=${contractObj.contractCode}`;
        } else if (contractObj.plan[0].planType === PLAN_TYPES_NAMES.DENTAL) {
            let returnUrl = `dentalContractCode=${contractObj.contractCode}`;
            const medicalContract = contracts.find(el => el.plan[0].planType === PLAN_TYPES_NAMES.MEDICAL);
            if (contracts.length > 1 && medicalContract) {
                returnUrl += `&medicalContractCode=${medicalContract?.contractCode}`;
            }
            return returnUrl;
        } else {
            let returnUrl = `visionContractCode=${contractObj.contractCode}`;
            if (contracts.length > 1) {
                const medicalContract = contracts.find(el => el.plan[0].planType === PLAN_TYPES_NAMES.MEDICAL);
                if (medicalContract) {
                    returnUrl += `&medicalContractCode=${medicalContract?.contractCode}`;
                }
                const dentalContract = contracts.find(el => el.plan[0].planType === PLAN_TYPES_NAMES.DENTAL);
                if (dentalContract) {
                    returnUrl += `&dentalContractCode=${dentalContract?.contractCode}`;
                }
                return returnUrl;
            }
            return returnUrl;
        }
    }


    const checkFavoritePlan = (contractCode: string, planType: string) => {
        let checkFav = false;
        switch (planType) {
            case PLAN_TYPES_NAMES.MEDICAL:
                checkFav = favouriteMedicalPlans?.includes(contractCode);
                break;
            case PLAN_TYPES_NAMES.DENTAL:
                checkFav = favouriteDentalPlans?.includes(contractCode);
                break;
            case PLAN_TYPES_NAMES.VISION:
                checkFav = favouriteVisionPlans?.includes(contractCode);
                break;
            default:
                checkFav = false;
        }
        return checkFav;
    }
    const formatEmailContractPayload = (contractPlans: Contract[]) => {
        const arr: EmailContractPayload[] = contractPlans?.map(el => ({
            contract: formatEmailContract(el, contractPlans),
            current: checkCurrentCoveragePlan(el.contractCode, el.plan[0].planType),
            favorite: checkFavoritePlan(el.contractCode, el.plan[0].planType),
            rate: {
                subsidizedPremium: el?.rateData?.rate?.totSubsidizedPrem.toString() ?? '',
                totalMonthlyPremium: el?.rateData?.rate?.totalPremium.toString() ?? ''
            },
            transitionExist: checkTransitionExist()
        }));
        return arr;
    }

    const formatEmailPlanRequestObj = (plans: Contracts) => {
        const emailPlan: EmailPlanReq = {
            additionalParams: formatAdditionalParams(),
            contractList: formatEmailContractPayload(plans),
            demographics: formatEmailDemographics()
        }
        return emailPlan;
    }


    const sendEmail = () => {
        setLoading(true);
        setEmailSentError(false);
        setShowEmailModal(false);
        closeEmailModal && closeEmailModal();
        fetchEmailPlan(emailReqObj).then((response) => {
            setLoading(false);
            if (response.data.status === STATUSES.SUCCESS) {
                setEmailSent(true);
            } else {
                setEmailSent(false);
                setEmailSentError(true);
            }
        })
            .catch((error) => {
                setLoading(false);
                setEmailSent(false);
                setEmailSentError(true);
            });
    }


    const sendPrint = () => {
        setLoading(true);
        setPrintSentError(false);
        let printReqObj: EmailPlanReq;
        if (type === PLAN_SUMMARY_STR || type === COMPARE_PLANS) {
            printReqObj = emailPrintContracts && emailPrintContracts.length ? formatEmailPlanRequestObj(emailPrintContracts) : {} as EmailPlanReq;
        } else {
            printReqObj = emailPrintContract?.plan && Object.keys(emailPrintContract).length ? formatEmailPlanRequestObj([emailPrintContract]) : {} as EmailPlanReq;
        }
        fetchPrintPlan(printReqObj).then((response) => {
            setLoading(false);
            if (response.data.status === STATUSES.SUCCESS) {
                converBase64ToPdf(response.data.pdfString)
            } else {
                setPrintSentError(true);
            }
        })
            .catch((error) => {
                setLoading(false);
                setPrintSentError(true);
            });
    }

    const atkPrint = async () => {
        setLoading(true);
        setPrintSentError(false);
        const payload = getAtkPrintPayload(pageContent?.ATK_PRINT_CONTENT?.DISCLAIMER, setLogoUrl());
        fetchAtkPrint(payload).then((response) => {
            setLoading(false);
            if (response.data.status === STATUSES.SUCCESS) {
                converBase64ToPdf(response.data.pdfString);
                onPrintQuoteSuccess && onPrintQuoteSuccess();
            } else {
                setPrintSentError(true);
                onPrintQuoteSuccess && onPrintQuoteSuccess();
            }
        })
            .catch((error) => {
                setLoading(false);
                setPrintSentError(true);
                onPrintQuoteSuccess && onPrintQuoteSuccess();
            });
    }

    useEffect(() => {
        if (openEmailModal) {
            reset();
            setShowEmailModal(true);
        }

    }, [openEmailModal])

    useEffect(() => {
        if (printQuote) {
            atkPrint();
        }
    }, [printQuote])

    const showEmail = () => {
        if (loggedIn || isAtk) {
            reset();
            setShowEmailModal(true);
        } else {
            setShowLoginModal(true)
        }
    }

    const showPrint = () => {
        if (loggedIn || isAtk) {
            isAtk ? atkPrint() : sendPrint();
        } else {
            setShowLoginModal(true);
        }
    }

    const closeEmailSuccessModal = () => {
        setEmailSent(false);
        if (isAtk) {
            try {
                updateATKsaveProposalResponse({} as ATKsaveProposalResponse);
                navigate(NAV_PATHS.ATK_SESSION_END);
            }
            catch (err) {
                console.error("Atk send proposal error", err);
            }
        }
    }

    return (
        <>
            {!hideSendPrintButtons &&
                <div className='fwc-col-2 sm-2 md-2 email-print'>
                    <button className="fwc-btn fwc-btn-primary" data-analytics={'sendQuoteButtonPlan' + setAnalyticsString() + 'Ios'} onClick={showEmail}>{pageContent?.SEND_QUOTE}</button>
                    <button className="fwc-btn fwc-btn-primary left-xs" data-analytics={'printQuoteButtonPlan' + setAnalyticsString() + 'Ios'} onClick={showPrint}>{pageContent?.PRINT_QUOTE}</button>
                </div>
            }

            {(!contentLoaded || loading) && <FullPageLoader />}
            {
                (contentLoaded && showLoginModal && !loggedIn) &&
                <Modal open={showLoginModal} onClose={handleModalClose} title={pageContent.TITLE}>
                    <Modal.Body>
                        <LoginModal
                            showModal={showLoginModal}
                            closeLoginModal={() => {
                                setShowLoginModal(false);
                            }}
                            initiateTwoFa={() => {
                                setShowLoginModal(false);
                                setModalHeading(twoFAHeading);
                                setShowTwoFAModal(true);
                            }}
                            loginDesc={pageContent?.PRINT_MODAL_LOGIN_DESC}
                        />
                    </Modal.Body>
                </Modal>
            }
            {
                (contentLoaded && showTwoFAModal) &&
                <Modal open={showTwoFAModal} onClose={handleTwoFAModalClose} title={modalHeading}>
                    <Modal.Body>
                        <TwoFAModalBody
                            useCase={TWO_FA_USE_CASES.LOGIN}
                            showModal={showTwoFAModal}
                            showFooter={true}
                            closeModal={handleTwoFAModalClose}
                            hideModalHeading={() => setModalHeading('')}
                            updateModalHeading={(heading) => setModalHeading(heading)}
                        />
                    </Modal.Body>
                </Modal>
            }
            {
                (contentLoaded && showEmailModal && (loggedIn || isAtk)) &&
                <Modal open={showEmailModal} onClose={handleModalClose} title={pageContent?.EMAIL_PLANS}>
                    <Modal.Body>
                        <div className='email-form'>
                            <form
                                className="email-wrapper"
                                onSubmit={submitEmailForm}
                                noValidate
                            >
                                {!isAtk &&
                                    <div className="fwc-row fwc-row-bm">
                                        {pageContent?.FROM}:<b className='no-reply'>{isWellpoint ? NO_REPLY_WELLPOINT : NO_REPLY}</b>
                                    </div>
                                }
                                <div className="fwc-row fwc-row-bm">
                                    <div className="fwc-col-12 md-12 sm-12 ">
                                        <div className="fwc-input">
                                            <label className="fwc-label" htmlFor="email">
                                                {isAtk ? `${pageContent?.EMAIL} (${pageContent?.ATK_SEND_PROPOSAL?.USE_COMMAS_TO_SEPARATE_EMAILS})` : pageContent?.EMAIL}
                                            </label>
                                            <input
                                                placeholder={''}
                                                id="email"
                                                type="text"
                                                {...register("email", {
                                                    required: {
                                                        value: true,
                                                        message: pageContent?.REQUIRED_FIELD,
                                                    },
                                                    pattern: {
                                                        value: isAtk ? EMAIL_REGX_FOR_ATK : EMAIL_REGX,
                                                        message: pageContent?.INVALID_EMAIL,
                                                    },
                                                })}
                                                defaultValue={isAtk ? getEmailAdress() : ''}
                                            />

                                            {errors && errors.email?.message && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {errors.email?.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                {!isAtk &&
                                    <div className="fwc-row fwc-row-bm">
                                        <div className="fwc-col-12 md-12 sm-12 ">
                                            <div className="fwc-input">
                                                <label className="fwc-label" htmlFor="reEnterEmail">
                                                    {pageContent?.REENTER_EMAIL}
                                                </label>
                                                <input
                                                    placeholder={''}
                                                    id="reEnterEmail"
                                                    type="text"
                                                    {...register("reEnterEmail", {
                                                        required: {
                                                            value: true,
                                                            message: pageContent?.REQUIRED_FIELD,
                                                        },
                                                        pattern: {
                                                            value: EMAIL_REGX,
                                                            message: pageContent?.INVALID_EMAIL,
                                                        },
                                                        validate: validateReEnterEmail,
                                                    })}
                                                />
                                                {errors && errors.reEnterEmail?.message && (
                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                        {errors.reEnterEmail?.message}
                                                    </span>
                                                )}
                                                {errors && errors.reEnterEmail && errors.reEnterEmail.type === "validate" && (
                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                        {pageContent?.EMAIL_MISMATCH}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                }
                                <div className="fwc-row fwc-row-bm">
                                    <div className="fwc-col-12 md-12 sm-12 ">
                                        <div className="fwc-input">
                                            <label className="fwc-label" htmlFor="subject">
                                                {'Subject'}
                                            </label>
                                            <input
                                                placeholder={''}
                                                id="subject"
                                                type="text"
                                                {...register("subject")}
                                                defaultValue={selectSubject()}
                                                disabled={!isAtk}
                                            />

                                        </div>
                                    </div>
                                </div>
                                <div className="fwc-row fwc-row-bm">
                                    <div className="fwc-col-12 md-12 sm-12 ">
                                        <div className="fwc-textarea">
                                            <label className="fwc-label" htmlFor="reEnterEmail">
                                                {pageContent?.MESSAGE}
                                            </label>
                                            <textarea
                                                placeholder={''}
                                                id="message"
                                                {...register("message", {
                                                    required: {
                                                        value: true,
                                                        message: pageContent?.REQUIRED_FIELD,
                                                    },
                                                    maxLength: {
                                                        value: 255,
                                                        message: pageContent?.MESSAGE_RESTRICT
                                                    }
                                                })}
                                                defaultValue={isAtk ? pageContent?.ATK_SEND_PROPOSAL?.ANTHEM?.DEFAULT_MESAGE : ''}
                                            />
                                            {errors && errors.message?.message && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {errors.message?.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className="btn-wrapper">
                                    <button className={'fwc-btn fwc-btn-secondary'} id='cancel' type="reset" value="Reset" onClick={handleModalClose}>{pageContent?.CANCEL}</button>
                                    <button className={'fwc-btn fwc-btn-primary'} id='send-email' type="submit" value="Submit" >{pageContent?.SEND_EMAIL}</button>
                                </div>
                            </form>
                        </div>
                    </Modal.Body>
                </Modal>
            }
            {
                emailSent &&
                <Modal open={emailSent} onClose={() => closeEmailSuccessModal()} title={pageContent?.EMAIL_SENT}>
                    <Modal.Body>
                        <div className='email-form'>
                            {!isAtk &&
                                <div className="fwc-row-bm">
                                    <p>{pageContent?.FROM}:</p>
                                    <b>{isWellpoint ? NO_REPLY_WELLPOINT : NO_REPLY}</b>
                                </div>
                            }
                            <div className="fwc-row-bm">
                                <p>{pageContent?.TO_EMAIL}:</p>
                                <b>{emailSentObj.email}</b>
                            </div>
                            <div className="fwc-row-bm">
                                <p>{pageContent?.SUBJECT}:</p>
                                <b>{isAtk ? emailSentObj.subject : selectSubject()}</b>
                            </div>
                            <div className="fwc-row-bm">
                                <p>{pageContent?.MESSAGE}:</p>
                                <b>{emailSentObj.message}</b>
                            </div>
                        </div>
                        <div className="btn-wrapper btn-ok-padd fwc-row-bm">
                            <button className={'fwc-btn fwc-btn-primary ok-btn'} id='ok' onClick={() => closeEmailSuccessModal()}>{pageContent?.OK}</button>
                        </div>
                    </Modal.Body>
                </Modal>

            }

            {
                emailSentError &&
                <Modal open={emailSentError} onClose={() => setEmailSentError(false)} title={pageContent?.EMAIL_PLANS}>
                    <Modal.Body>
                        <div className='email-form'>
                            <div className='fwc-row fwc-row-bm'>
                                <h2>{pageContent?.EMAIL_NOT_SENT}</h2>
                            </div>
                        </div>
                        <div className="btn-wrapper btn-ok-padd fwc-row-bm">
                            <button className={'fwc-btn fwc-btn-primary ok-btn'} id='ok' onClick={() => setEmailSentError(false)}>{pageContent?.CLOSE}</button>
                        </div>
                    </Modal.Body>
                </Modal>

            }

            {
                printSentError &&
                <Modal open={printSentError} onClose={() => setPrintSentError(false)} title={pageContent?.PRINT_PLANS}>
                    <Modal.Body>
                        <div className='email-form'>
                            <div className='fwc-row fwc-row-bm'>
                                <h2>{pageContent?.PRINT_EXCP}</h2>
                            </div>
                        </div>
                        <div className="btn-wrapper btn-ok-padd fwc-row-bm">
                            <button className={'fwc-btn fwc-btn-primary ok-btn'} id='ok' onClick={() => setPrintSentError(false)}>{pageContent?.CLOSE}</button>
                        </div>
                    </Modal.Body>
                </Modal>

            }
        </>
    )
}
