import { Paragraph, Popover } from '@sydney-broker-ui/ios';
import React, { useState } from 'react';

import AddProduct from '../add-product/AddProduct';
import MatchingListCard from '../matching-list-card/MatchingListCard';
import ProductBenefits from '../product-benefits/ProductBenefits';
import ProductCardOptional from '../product-card-optional/ProductCardOptional';

import { displayStars } from '../../../pages/public/medical-plan-details/medicalPlanDetailsUtils';
import { getMatchedDoctorsForPlan } from '../../../pages/public/medical-plans/medicalPlansUtils';
import { getOptionalProducts } from '../../../pages/secure/product-selection/productSelectionUtils';
import { getFormattedCurrency } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { getMedicationNames } from '../plan-card/planCardUtils';
import { getDentalProduct, getMatchedProvidersShowMoreOrLessLabel, getMatchedProvidersTitle, getVisionProduct, isReviewedProduct } from './productCardUtils';

import pdf from '../../../images/pdf.svg';
import qualified_dental from '../../../images/qualified_dental.svg';
import qualified_vision from '../../../images/qualified_vision.svg';
import { EMEDDED_HEALTH_BENEFITS } from '../../../pages/public/medical-plans/constants';
import { PLAN_TYPES, PLAN_TYPES_NAMES } from '../../../shared/globalConstants';
import './ProductCard.scss';

const ProductCard: React.FC<ProductCardProps> = ({ labels,
    primaryProductType,
    contract,
    drugList,
    matchedProviders,
    matchedMedications = [],
    matchedMedicationsFlag,
    matchedProvidersFlag,
    reviewedProducts,
    matchedDentists,
    matchedDentistsFlag,
    matchedEyeDoctors,
    matchedEyeDoctorsFlag,
    onProductNameClick,
    onApplyClick,
    scrolltoImprtantNotice,
    onReiviewClick,
    setRatingDetailsModalContent,
    setShowOverallRatingModal,
    onAddProductClick,
    onDeleteClick,
    addProviders
}) => {
    const providerFlag = primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT ? matchedProvidersFlag : primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? matchedDentistsFlag : matchedEyeDoctorsFlag;

    const [anchor, setAnchor] = useState<React.SetStateAction<null> | (EventTarget & HTMLSpanElement)>(null);
    const [popoverOpen, setPopoverOpen] = useState(false);
    const [showMore, setShowMore] = useState(false);

    const { isWellpoint } = useUserStore()
    const {
        dentalProducts,
        visionProducts,
        savedDentists,
        matchDentistsToPlans,
        savedEyeDoctors,
        matchEyeDoctorsToPlans,
        agentCoverageType
    } = useGlobalStore(state => state)

    const applicableDentalProducts = getOptionalProducts(contract, dentalProducts);
    const applicableVisionProducts = getOptionalProducts(contract, visionProducts);

    const renderMonthlyPremium = (amount: number) => {
        if (amount) {
            return getFormattedCurrency(amount);
        } else {
            return labels.RATE_NOT_AVAILABLE;
        }
    };

    const handleHelpClick = (currentTarget: React.SetStateAction<null> | (EventTarget & HTMLSpanElement)) => {
        setAnchor(currentTarget);
        setPopoverOpen(!popoverOpen);
    };

    const renderPopoverContent = (rateObj: { totalPremium: number; totSubsidizedPrem: number; subsidyAmtApplied: number }) => {
        return (
            <Paragraph>
                <div className="popover-container">
                    <span className="label-amount-container-margin-btm">
                        <b>{labels.MONTHLY_COST_BREAKDOWN}</b>
                    </span>
                    <div className="amount-label-container">
                        <p>{labels.TOTAL_PLAN_COST}</p>
                        <span>
                            <b>{getFormattedCurrency(rateObj.totalPremium)}</b>
                        </span>
                    </div>
                    <div className="amount-label-container label-amount-container-margin-btm">
                        <p>{labels.SUBSIDY_APPLIED}</p>
                        <span>
                            <b>{getFormattedCurrency(rateObj.subsidyAmtApplied)}</b>
                        </span>
                    </div>
                    <div className="amount-label-container">
                        <span className="label-margin-right">
                            <b>{labels.TOTAL_MEMBER_RESPONSIBILITY}</b>
                        </span>
                        <span className="total-subsidised-amount">
                            <b>{getFormattedCurrency(rateObj.totSubsidizedPrem)}</b>
                        </span>
                    </div>
                </div>
            </Paragraph>
        );
    };

    const renderSubsidyApplied = () => {
        if (parseFloat(contract?.rateData?.rate?.subsidyAmtApplied?.toString() ?? '0') && primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT) {
            return <span className="subsidy-applied"><span className="sae-icon sae-icon-checked checked-icon" />{labels.SUBSIDY_APPLIED}</span>
        }
    }

    const renderQualifiedHelath = () => {
        return (
            <div className="qualified-health-plan">
                <span>{labels.QUALIFIED_HEALTH_PLAN}:</span>
                <img src={qualified_dental} className="qualified-icon" alt="" id="qualified-dental-icon" />
                <img src={qualified_vision} className="qualified-icon" alt="" id="qualified-vision-icon" />
            </div>
        )
    }

    const getPlanIncudes = () => {
        if (contract?.ehbIndTypes && contract?.ehbIndTypes.includes(EMEDDED_HEALTH_BENEFITS.DEN_EHB)) {
            return (
                <div className='embedded-bnenfits'>
                    <span
                        id={'product-card-benefit-plan-type-row-one-value-' + contract.contractCode}
                        className="benefit-label"
                    >{labels.EMBEDDED + '\n'}</span>
                    <span
                        id={'product-card-benefit-plan-type-row-two-value-' + contract.contractCode}
                        className="benefit-label"
                    >{labels.PEDIATRICS_BENEFITS}</span>
                </div>
            )
        }
    }

    const getRatings = () => {
        if (primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT && contract?.qualityRating) {
            return (
                <div className="Rating-details-wrapper">
                    <div className="rating-stars">
                        {
                            contract?.qualityRating && <div dangerouslySetInnerHTML={{ __html: displayStars(Number(contract?.qualityRating?.G)) }} />
                        }
                    </div>
                    <div className="rating-label">
                        <h6>{contract?.qualityRating?.G}</h6>
                        <span id="product-rating-tooltip" className="sae-icon sae-icon-help help-icon" onClick={() => setShowOverallRatingModal()} />
                        <span className="rating-text" onClick={() => contract.qualityRating && setRatingDetailsModalContent(contract.qualityRating)}>{labels.RATING_DETAILS}</span>
                    </div>
                </div>
            )
        }
    }

    return (
        <div id="product-card" key={contract.contractCode} className="product-card-container">
            <div className="plane-name-premium-container">
                <h4
                    id="product-card-plan-name"
                    className="plan-name"
                    data-testid="product-name"
                    onClick={() => {
                        onProductNameClick(contract);
                    }}
                >
                    {contract.planMarketingName + ' ' + contract.contractCode}
                </h4>

                <div className="monthly-premium-container">
                    <div className="monthly-premium">
                        <p id="product-card-plan-premium" className="label-margin label-margin ">
                            {labels.MONTHLY_PREMIUM}
                        </p>
                        <h4 className="label-margin">{renderMonthlyPremium(contract?.rateData?.rate?.totSubsidizedPrem ?? 0)}</h4>
                        {popoverOpen && anchor && contract?.rateData?.rate && (
                            <Popover id="product-card-plan-amount-popover" placement={'top'} anchorEl={anchor} onClickOutside={() => setPopoverOpen(!popoverOpen)}>
                                {renderPopoverContent(contract.rateData.rate)}
                            </Popover>
                        )}
                        <span id="product-card-amount-tooltip" data-testid="product-amount-help-click" className="sae-icon sae-icon-help help-icon" onClick={(e) => handleHelpClick(e.currentTarget)} />
                    </div>
                    {renderSubsidyApplied()}
                </div>
            </div>
            {getRatings()}
            <div className="drug-qualified-health-wrapper">
                {primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT && <div className="covered-drugs">
                    <a data-analytics="drugListProductSelectionIos" href={drugList} id="product-card-drug-list" className="drug-list-hyper-link" target="_blank">
                        <img src={pdf} className="pdf-icon" alt="" id="drugs-pdf-icon" />
                        <span className="covered-drugs-text">{labels.COVERED_DRUGS}</span>
                    </a>
                </div>
                }
                {/* {renderQualifiedHelath()} */}
            </div>

            <div className="matching-list-outer-wrapper">
                {providerFlag && (
                    <MatchingListCard
                        icon={'sae-icon-doctors-bag'}
                        label={getMatchedProvidersTitle(primaryProductType, labels)}
                        showMoreText={`${labels.SHOW_MORE} ${getMatchedProvidersShowMoreOrLessLabel(primaryProductType, labels)}`}
                        showLessText={`${labels.SHOW_LESS} ${labels.DOCTORS}`}
                        providers={primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT ? matchedProviders : primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? matchedDentists : matchedEyeDoctors}
                        tooltipMessage={labels.MATCHED_DOCTORS_TOOLTIP_TEXT}
                    />
                )}
                {matchedMedicationsFlag && (
                    <MatchingListCard
                        icon={'sae-icon-prescription-pill-bottle'}
                        label={labels.MEDICATIONS_LIST_LABEL}
                        showMoreText={`${labels.SHOW_MORE} ${labels.MEDICATIONS}`}
                        showLessText={`${labels.SHOW_LESS} ${labels.MEDICATIONS}`}
                        data={getMedicationNames(matchedMedications)}
                        impNoticeText={labels.IMPORTANT_DRUGS_NOTICE}
                        scrolltoImprtantNotice={scrolltoImprtantNotice}
                    />
                )}
            </div>

            <ProductBenefits labels={labels} productType={primaryProductType} contract={contract} showMore={showMore} isWellpoint={isWellpoint} />

            <div className="showmore-apply-button-container">
                {true && <span id="product-card-show-more-toggle" className="show-more-text" onClick={() => setShowMore(!showMore)}>
                    <span>{showMore ? labels.SHOW_LESS : labels.SHOW_MORE}</span>
                    <span id="plan-card-show-more" className={showMore ? 'sae-icon sae-icon-angle-up toggle-icon' : 'sae-icon sae-icon-angle-down toggle-icon'} />
                </span>}
                {/* Hiding apply now button for now */}
                <div>
                    <button
                        className={`select-button fwc-btn fwc-btn-primary`}
                        data-analytics={{}}
                        onClick={() => {
                            const selectedProduct: SelectedProduct = {
                                medicalProduct: primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT ? contract : undefined,
                                medicalApplicants: useGlobalStore.getState().selectedProduct.medicalApplicants,
                                matchedDoctors: matchedProviders,
                                matchedMedications: matchedMedications,
                                dentalProduct: primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? contract : getDentalProduct(contract)?.dentalProduct,
                                dentalApplicants: useGlobalStore.getState().selectedProduct.dentalApplicants,
                                matchedDentists: primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? matchedDentists : getMatchedDoctorsForPlan(matchDentistsToPlans, savedDentists, getDentalProduct(contract)?.dentalProduct?.contractCode ?? ''),
                                visionProduct: primaryProductType === PLAN_TYPES_NAMES.VISION_PRODUCT ? contract : getVisionProduct(contract)?.visionProduct,
                                visionApplicants: useGlobalStore.getState().selectedProduct.visionApplicants,
                                matchedEyeDoctors: primaryProductType === PLAN_TYPES_NAMES.VISION_PRODUCT ? matchedEyeDoctors : getMatchedDoctorsForPlan(matchEyeDoctorsToPlans, savedEyeDoctors, getVisionProduct(contract)?.visionProduct?.contractCode ?? '')
                            }
                            onApplyClick(selectedProduct);
                        }}
                    >
                        {labels.APPLY_NOW}
                    </button>
                </div>

            </div>
            <div className="bottom-container">
                <div className="bottom-left-container">
                    {!isWellpoint && <>
                        {agentCoverageType === PLAN_TYPES.MDV &&
                            <div className="add-dental-product">
                                {getDentalProduct(contract)?.primaryProductContractCode !== contract.contractCode ?
                                    applicableDentalProducts.length > 0 ?
                                        <AddProduct labels={labels} onAddProductClick={(currentTarget) => onAddProductClick(currentTarget, PLAN_TYPES_NAMES.DENTAL_PRODUCT, contract)} useCase={PLAN_TYPES_NAMES.DENTAL_PRODUCT} /> :
                                        <><span className="sae-icon sae-icon-info no-plans-icon"></span><span className="no-plans-available">{labels?.NO_DENTAL_PLANS_AVAILABLE}</span></>
                                    : <ProductCardOptional
                                        labels={labels}
                                        contract={getDentalProduct(contract)?.dentalProduct ?? {} as Contract}
                                        matchedDentists={getMatchedDoctorsForPlan(matchDentistsToPlans, savedDentists, getDentalProduct(contract)?.dentalProduct?.contractCode ?? '')}
                                        matchedDentistsFlag={matchDentistsToPlans}
                                        useCase={PLAN_TYPES_NAMES.DENTAL_PRODUCT}
                                        onProductNameClick={(clickedProduct: Contract) => { onProductNameClick(clickedProduct) }}
                                        onChangeProductClick={(currentTarget) => { onAddProductClick(currentTarget, PLAN_TYPES_NAMES.DENTAL_PRODUCT, contract) }}
                                        onDeleteClick={(selectedProduct) => onDeleteClick(selectedProduct, PLAN_TYPES_NAMES.DENTAL_PRODUCT, contract.contractCode ?? '')}
                                        addProviders={(useCase) => { addProviders(useCase) }}
                                    />
                                }
                            </div>
                        }
                        {agentCoverageType !== PLAN_TYPES.VISION &&
                            <div className="add-vision-product">
                                {getVisionProduct(contract)?.primaryProductContractCode !== contract.contractCode ?
                                    applicableVisionProducts.length > 0 ?
                                        <AddProduct labels={labels} onAddProductClick={(currentTarget) => onAddProductClick(currentTarget, PLAN_TYPES_NAMES.VISION_PRODUCT, contract)} useCase={PLAN_TYPES_NAMES.VISION_PRODUCT} /> :
                                        <><span className="sae-icon sae-icon-info no-plans-icon"></span><span className="no-plans-available">{labels?.NO_VISION_PLANS_AVAILABLE}</span></>
                                    : <ProductCardOptional
                                        labels={labels}
                                        contract={getVisionProduct(contract)?.visionProduct ?? {} as Contract}
                                        matchedEyeDoctors={getMatchedDoctorsForPlan(matchEyeDoctorsToPlans, savedEyeDoctors, getVisionProduct(contract)?.visionProduct?.contractCode ?? '')}
                                        matchedEyeDoctorsFlag={matchEyeDoctorsToPlans}
                                        useCase={PLAN_TYPES_NAMES.VISION_PRODUCT}
                                        onProductNameClick={(clickedProduct: Contract) => { onProductNameClick(clickedProduct) }}
                                        onChangeProductClick={(currentTarget) => { onAddProductClick(currentTarget, PLAN_TYPES_NAMES.VISION_PRODUCT, contract) }}
                                        onDeleteClick={(selectedProduct) => onDeleteClick(selectedProduct, PLAN_TYPES_NAMES.VISION_PRODUCT, contract.contractCode ?? '')}
                                        addProviders={(useCase) => { addProviders(useCase) }}
                                    />
                                }
                            </div>
                        }
                    </>
                    }
                </div>
                <div className="bottom-right-container">
                    <div className="fwc-checkbox">
                        <input
                            id={"product-card-add-to-review-" + contract.contractCode}
                            type="checkbox"
                            checked={isReviewedProduct(primaryProductType, reviewedProducts, contract)}
                            disabled={!isReviewedProduct(primaryProductType, reviewedProducts, contract) && reviewedProducts.length === 3}
                            onClick={() => {
                                const selectedProduct: SelectedProduct = {
                                    medicalProduct: primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT ? contract : undefined,
                                    medicalApplicants: useGlobalStore.getState().selectedProduct.medicalApplicants,
                                    matchedDoctors: matchedProviders,
                                    matchedMedications: matchedMedications,
                                    dentalProduct: primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? contract : getDentalProduct(contract)?.dentalProduct,
                                    dentalApplicants: useGlobalStore.getState().selectedProduct.dentalApplicants,
                                    matchedDentists: primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT ? matchedDentists : getMatchedDoctorsForPlan(matchDentistsToPlans, savedDentists, getDentalProduct(contract)?.dentalProduct?.contractCode ?? ''),
                                    visionProduct: primaryProductType === PLAN_TYPES_NAMES.VISION_PRODUCT ? contract : getVisionProduct(contract)?.visionProduct,
                                    visionApplicants: useGlobalStore.getState().selectedProduct.visionApplicants,
                                    matchedEyeDoctors: primaryProductType === PLAN_TYPES_NAMES.VISION_PRODUCT ? matchedEyeDoctors : getMatchedDoctorsForPlan(matchEyeDoctorsToPlans, savedEyeDoctors, getVisionProduct(contract)?.visionProduct?.contractCode ?? '')
                                }

                                onReiviewClick(selectedProduct)
                            }}
                            onChange={() => { }} />
                        <label className="right-button-label" htmlFor={"product-card-add-to-review-" + contract.contractCode}>{labels.ADD_TO_REVIEW}</label>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default ProductCard;