import React from 'react';
import ZipCode from '../zip-code/ZipCode';
import './Hero.scss';

function Hero(props) {
  return (
    <div>
      <div className={'fwc-row'}>
        <p className="fwc-col-8 md-12 sm-12 page-heading-paragraph btm-xs">{props.description}</p>
      </div>
      <div className={'fwc-row'}>
        <div className="fwc-col-5 md-12 sm-12 fwc-card-generic hero-card">
          <h4 className="btm-xs title">{props.findPlanText}</h4>
          <ZipCode
            zipCodeText={props.zipCodeText}
            getQuoteText={props.getQuoteText}
            planYearText={props.planYearText}
            invalidCountryText={props.invalidCountryText}
            invalidCountryLink={props.invalidCountryLink}
            zipCodeToolTip={props.zipCodeToolTip}
            planYearToolTip={props.planYearToolTip}
            invalidZipCodeText={props.invalidZipCodeText}
            disablePlanYear={props.disablePlanYear}
            jsonArea={props.jsonArea}
          ></ZipCode>
        </div>
      </div>
    </div>
  );
}
export default Hero;
