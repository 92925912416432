import { PLAN_MARKET_TYPES } from '../../../components/common/compare-tray/constant';
import { getAgentDetailsByState } from '../../../components/common/email-print/emailPrintUtils';
import {
  AGENT_PARENTTIN_CONSTANTS,
  AGENT_ROLES,
  APPLICANT_TYPES,
  APPLICATION_TYPE,
  BRAND_NAMES,
  DEFAULT_CAMPAIGNIDS,
  PLAN_TYPES,
  PLAN_TYPES_NAMES,
  SET_APPLICATION_CONSTANTS,
  SET_BRAND_NAMES,
  USER_DOMAINS
} from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD, getRelationshipType } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';

const getDemographic = (applicantDemoGraphic: DemographicData) => {
  const demographicData: Demographic = {
    lastName: applicantDemoGraphic.applicantDemographicInfo.lastName ? applicantDemoGraphic.applicantDemographicInfo.lastName : null,
    firstName: applicantDemoGraphic.applicantDemographicInfo.firstName,
    dateOfBirth: applicantDemoGraphic.applicantDemographicInfo.dateOfBirth,
    gender: applicantDemoGraphic.applicantDemographicInfo?.gender ? applicantDemoGraphic.applicantDemographicInfo.gender : null,
    id: 0,
    emailAddress: applicantDemoGraphic.emailAddress,
    isUseTobacco: applicantDemoGraphic.applicantDemographicInfo.isTabaccoUser ? applicantDemoGraphic.applicantDemographicInfo.isTabaccoUser : null,
    relationshipType: getRelationshipType(applicantDemoGraphic.applicantDemographicInfo.applicantType),
    action: SET_APPLICATION_CONSTANTS.UPDATE,
    yrResideInUS: 0,
    monthResideInUS: 0
  };
  return demographicData;
};

const getAddress = (address: AtkSetAppAddress[]) => {
  const applicantAddress: UserAddress[] = [];
  address?.map((item) => {
    applicantAddress.push({
      id: 0,
      addressLine1: item?.address1 ?? null,
      addressLine2: item?.address2 ?? null,
      city: item?.city ?? null,
      county: item?.county ?? null,
      state: item.state ?? null,
      postalCode: item.zip ?? null,
      addressType: item?.addressType ?? SET_APPLICATION_CONSTANTS.HOME,
      careOf: null,
      action: SET_APPLICATION_CONSTANTS.UPDATE,
      personalMailBoxNo: null
    });
  });
  return applicantAddress;
};

const agentAddress = (agentInfo: AgentSSOStateInfo) => {
  const AtkAgentAddress = agentInfo?.agentAddressInfo?.agentAddress;
  const agentAddress: UserAddress = {
    id: 0,
    addressLine1: AtkAgentAddress?.address1 ? AtkAgentAddress?.address1 : null,
    addressLine2: AtkAgentAddress?.address2 && AtkAgentAddress?.address2 === '' ? AtkAgentAddress?.address2 : null,
    city: AtkAgentAddress?.city ? AtkAgentAddress?.city : null,
    county: null,
    state: AtkAgentAddress?.state ? AtkAgentAddress?.state : null,
    postalCode: AtkAgentAddress?.zip ? AtkAgentAddress?.zip : null,
    addressType: null,
    careOf: null,
    action: SET_APPLICATION_CONSTANTS.UPDATE,
    personalMailBoxNo: null,
    countyCode: null
  };
  return agentAddress;
};

const getPlan = (plan: Contract, applicantType: string) => {
  const Plan: PlanDetails = {
    action: SET_APPLICATION_CONSTANTS.UPDATE,
    companyDescr: plan.companyDesc ? plan?.companyDesc : '',
    contractCode: plan.contractCode,
    eBHFlag: plan.ehbIndTypes.length > 0 ? SET_APPLICATION_CONSTANTS.YES : SET_APPLICATION_CONSTANTS.NO,
    ereAppliedAPTC: 0.0,
    id: 0,
    lifeCoverageAmt: 0.0,
    planId: plan.plan[0]?.planID,
    planName: plan.planMarketingName,
    planType: plan.plan[0]?.productType,
    premiumAmt: applicantType === SET_APPLICATION_CONSTANTS.PRIMARY ? (plan?.rateData?.rate?.totSubsidizedPrem ?? 0.0) : 0.0,
    productType: plan.plan[0]?.planType,
    qHPId: plan.qHPId,
    qHPVariation: plan.qHPVariation,
    renewalPremiumAmt: 0.0
  };
  return Plan;
};

const getPlanSelection = (planDetails: AtkSetAppApplicantPayload, applicantType: string) => {
  const planTotalInfo: PlanDetails[] = planDetails.plan.map((item) => getPlan(item, applicantType));
  const planSelectionData: PlanSelection = {
    action: SET_APPLICATION_CONSTANTS.UPDATE,
    id: 0,
    optRider: [],
    plan: planTotalInfo
  };
  return planSelectionData;
};

const getApplicantTotalInfo = (applicantDetails: AtkSetAppApplicantPayload, hcid: string | null) => {
  const applicantInfo: ApplicantAllInfo = {
    id: 0,
    memberCode: 0,
    demographic: getDemographic(applicantDetails.demographicInfo),
    address: getAddress(applicantDetails?.address),
    planSelection: getPlanSelection(applicantDetails, applicantDetails.demographicInfo.applicantDemographicInfo.applicantType),
    priorCoverage: [],
    age: 0,
    action: SET_APPLICATION_CONSTANTS.UPDATE,
    existingPolicyId: 0,
    sequenceNo: 0,
    hcid: hcid
  };
  return applicantInfo;
};

const getPaidAgent = (agentInfo: AgentSSOStateInfo) => {
  const PaidAgent: PaidAgent = {
    id: 0,
    agentTIN: agentInfo?.agencyInfo?.agency?.paidTIN ?? '',
    issuedPolicies: [],
    action: SET_APPLICATION_CONSTANTS.UPDATE
  };
  return PaidAgent;
};

const getParentAgent = (agentInfo: AgentSSOStateInfo) => {
  const parentAgent: ParentAgent = {
    id: 0,
    agentTIN: agentInfo?.agencyInfo?.agency?.parentTIN ?? '',
    agencyName: agentInfo?.agencyInfo?.agency?.agencyName ?? '',
    emailAddress: null,
    issuedPolicies: [],
    action: SET_APPLICATION_CONSTANTS.UPDATE
  };
  return parentAgent;
};

const agentType = (agentInfo: AgentSSOStateInfo): string => {
  if (
    agentInfo?.agencyInfo?.agency &&
    agentInfo?.agencyInfo?.agency?.parentTIN &&
    (agentInfo?.agencyInfo?.agency?.parentTIN === AGENT_PARENTTIN_CONSTANTS.PARENT_TIN_1E ||
      agentInfo?.agencyInfo?.agency?.parentTIN === AGENT_PARENTTIN_CONSTANTS.ENCRYPTEDTIN_CAPTIVEAGENT)
  ) {
    return AGENT_ROLES.CAPTIVEAGENT;
  } else if (
    agentInfo?.agencyInfo?.agency &&
    agentInfo?.agencyInfo?.agency?.parentTIN &&
    (agentInfo?.agencyInfo?.agency?.parentTIN === AGENT_PARENTTIN_CONSTANTS.PARENT_TIN_5E ||
      agentInfo?.agencyInfo?.agency?.parentTIN === AGENT_PARENTTIN_CONSTANTS.ENCRYPTEDTIN_DIRECTSALES)
  ) {
    return AGENT_ROLES.DIRECTSALES;
  } else {
    return AGENT_ROLES.BROKER;
  }
};

const getAgentInfo = (agentSsoInfo: AgentSSOInfo, agentState: string) => {
  const agentInfo = agentSsoInfo?.agentInfo?.agent[0];
  const agentDetails = getAgentDetailsByState(agentSsoInfo, agentState);
  const agent: Agent = {
    id: 0,
    agentTIN: agentInfo?.writingTIN,
    firstName: agentInfo?.agentFName,
    lastName: agentInfo?.agentLName,
    agencyName: agentInfo?.agencyName ? agentInfo?.agencyName : (agentDetails?.agencyInfo?.agency?.agencyName ?? ''),
    phoneNo: agentDetails?.phone ?? '',
    faxNo: agentDetails?.fax ?? null,
    emailAddress: agentDetails?.email ?? '',
    parentAgent: getParentAgent(agentDetails as AgentSSOStateInfo),
    address: agentAddress(agentDetails as AgentSSOStateInfo),
    agentType: agentType(agentDetails as AgentSSOStateInfo),
    issuedPolicies: [],
    action: SET_APPLICATION_CONSTANTS.UPDATE,
    npn: agentInfo.NPN,
    licenseNumber: agentDetails?.licenseNumber ?? null,
    certificateNumber: '',
    fFEUserId: '',
    phoneExt: agentDetails?.phoneExt ?? null,
    agentCode: agentDetails?.agencyInfo?.agency?.agentCode ?? '',
    paidAgent: getPaidAgent(agentDetails as AgentSSOStateInfo)
  };
  return agent;
};

const getAtkSetApplicationPayload = (applicantDetails: AtkSetAppPayloadDetails) => {
  let applicantTotalInfo: ApplicantAllInfo[] = applicantDetails.applicantPlanInfo.map((item) =>
    getApplicantTotalInfo(item, applicantDetails.applicationinfo.hcid)
  );

  const payload: SetApplicationPayload = {
    partnerId: SET_APPLICATION_CONSTANTS.OLS,
    user: {
      userId: applicantDetails.applicationinfo.userId,
      shopperRole: applicantDetails.applicationinfo.shopperRole
    },
    application: {
      state: applicantDetails.applicationinfo.state,
      isAgentAssisted: SET_APPLICATION_CONSTANTS.YES,
      premiumAmt: applicantDetails.applicationinfo.premiumAmt,
      campaignId: applicantDetails.applicationinfo.campaignId,
      brandName: applicantDetails.applicationinfo.brandName,
      applicationType: applicantDetails.applicationinfo.applicationType,
      reqEffDate: applicantDetails.applicationinfo.reqEffDate,
      accessControlList: [],
      agent: applicantDetails.agent ? getAgentInfo(applicantDetails.agent, applicantDetails.applicationinfo.state) : null,
      applicant: applicantTotalInfo,
      adeCustomerId: applicantDetails.applicationinfo.adeCustomerId,
      quotedPlans: [],
      pin: 0,
      action: SET_APPLICATION_CONSTANTS.UPDATE,
      additionalInfo: applicantDetails.applicationinfo.additionalInfo,
      isAddSpouse: applicantDetails.applicationinfo.isAddSpouse,
      isAddDependents: applicantDetails.applicationinfo.isAddDependents,
      initialPremium: 0.0,
      exchTotalAmtOwed: 0.0,
      exchAPTCAmt: 0.0,
      applicationSeqId: 0,
      exchTotPremiumAmt: 0.0,
      applicationLanguage: SET_APPLICATION_CONSTANTS.ENGLISH,
      aptcAttestationPersons: [],
      electedAPTCPercentage: 0,
      appliedAPTCAmount: 0.0,
      rateAreaId: 0,
      exchTransLogId: 0,
      exchAPTCPct: 0.0,
      renewalPremiumAmt: 0.0,
      ipAddress: applicantDetails.applicationinfo.ipAddress,
      hcid: applicantDetails.applicationinfo.hcid,
      appSource: 'APE-EB'
    },
    isSubmit: null
  };
  return payload;
};

const getEncryptacnPayload = (responceAcn: string) => {
  let acnPayload = {
    acn: responceAcn
  };
  return acnPayload;
};

const isApplicantHavingPlan = (applicants: Applicant[], applicantName: string, applicantId: string | undefined) => {
  let filterByName = applicants?.filter((applicant) =>
    applicantId && applicant.applicantId ? applicant.applicantId === applicantId : applicant.firstName === applicantName
  );
  return filterByName && filterByName?.length > 0;
};

const isAgentInfoConsider = () => {
  const { agentSSOInfo, agentState } = useGlobalStore.getState();
  const agentDetails = getAgentDetailsByState(agentSSOInfo, agentState);
  return agentSSOInfo &&
    agentSSOInfo?.agentInfo?.agent[0]?.writingTIN &&
    agentSSOInfo?.agentInfo?.agent[0]?.writingTIN !== '' &&
    ((agentSSOInfo?.agentInfo?.agent[0]?.agencyName && agentSSOInfo?.agentInfo?.agent[0]?.agencyName !== '') ||
      (agentDetails?.agencyInfo?.agency?.agencyName && agentDetails?.agencyInfo?.agency?.agencyName !== '') ||
      (agentSSOInfo?.agentInfo?.agent[0]?.agentLName && agentSSOInfo?.agentInfo?.agent[0]?.agentLName !== '') ||
      (agentSSOInfo?.agentInfo?.agent[0]?.agentFName && agentSSOInfo?.agentInfo?.agent[0]?.agentFName !== ''))
    ? agentSSOInfo
    : null;
};

const getShoperRole = (agentState: string, agentSsoInfo: AgentSSOInfo) => {
  const agentInfo = agentSsoInfo?.agentInfo?.agent[0];
  const agentDetails = getAgentDetailsByState(agentSsoInfo, agentState);
  let shoperRole = '';
  if (agentInfo && agentInfo?.agentType && (agentInfo.agentType === AGENT_ROLES.DELEGATE || agentInfo.agentType === AGENT_ROLES.TELEASSISTANT)) {
    shoperRole = AGENT_ROLES.ASSISTANT;
  } else if (
    agentDetails &&
    agentDetails?.agencyInfo?.agency?.parentTIN &&
    (agentDetails?.agencyInfo?.agency?.parentTIN === AGENT_PARENTTIN_CONSTANTS.PARENT_TIN_1E ||
      agentDetails?.agencyInfo?.agency?.parentTIN === AGENT_PARENTTIN_CONSTANTS.PARENT_TIN_5E ||
      agentDetails?.agencyInfo?.agency?.parentTIN === AGENT_PARENTTIN_CONSTANTS.ENCRYPTEDTIN_CAPTIVEAGENT ||
      agentDetails?.agencyInfo?.agency?.parentTIN === AGENT_PARENTTIN_CONSTANTS.ENCRYPTEDTIN_DIRECTSALES)
  ) {
    shoperRole = AGENT_ROLES.AGENT;
  } else {
    shoperRole = AGENT_ROLES.BROKER;
  }
  return shoperRole;
};

const getApplicantAddress = () => {
  const { agentCounty, agentState, agentZipcode, agentCountyCode } = useGlobalStore.getState();
  let applicantAddress: AtkSetAppAddress[] = [];
  applicantAddress = [
    {
      address1: '',
      address2: '',
      city: '',
      county: agentCounty,
      state: agentState,
      addressType: SET_APPLICATION_CONSTANTS.HOME,
      zip: agentZipcode
    }
  ];
  return applicantAddress;
};

const getApplicationType = (onExchange: boolean) => {
  if (onExchange) {
    return APPLICATION_TYPE.ON_EXCHANGE;
  }

  return APPLICATION_TYPE.OFF_EXCHANGE;
};

const isSpouseDependentAddedPlan = (plans: Applicant[], spouse: boolean) => {
  if (spouse) {
    return plans?.find((item) => item.applicantType === APPLICANT_TYPES.SPOUSE || item.applicantType === APPLICANT_TYPES.DOMESTICPARTNER);
  } else {
    const isDependentAdded = plans?.filter(
      (item) => ![APPLICANT_TYPES.PRIMARY, APPLICANT_TYPES.SPOUSE, APPLICANT_TYPES.DOMESTICPARTNER].includes(item.applicantType) && item
    );
    return isDependentAdded.length > 0;
  }
};

const getIsSpouseDependentAdd = (selectedProduct: SelectedProduct, spouse: boolean) => {
  if (selectedProduct?.medicalProduct && isSpouseDependentAddedPlan(selectedProduct?.medicalApplicants, spouse)) {
    return SET_APPLICATION_CONSTANTS.YES;
  }
  if (selectedProduct?.dentalProduct && isSpouseDependentAddedPlan(selectedProduct?.dentalApplicants, spouse)) {
    return SET_APPLICATION_CONSTANTS.YES;
  }
  if (selectedProduct?.visionProduct && isSpouseDependentAddedPlan(selectedProduct?.visionApplicants, spouse)) {
    return SET_APPLICATION_CONSTANTS.YES;
  }
  return SET_APPLICATION_CONSTANTS.NO;
};

const getCurrentPlanIndicator = (currentCoverages: CurrentCoverages) => {
  const uniqueTypes: string[] = [...new Set(currentCoverages.map((item) => item.planType))];
  if (uniqueTypes.includes(PLAN_TYPES_NAMES.LIFE)) {
    const index = uniqueTypes.indexOf(PLAN_TYPES_NAMES.LIFE);
    uniqueTypes.splice(index, 1);
  }
  let currentPlanIndicator: string = '';
  if (uniqueTypes.includes(PLAN_TYPES_NAMES.MEDICAL)) {
    currentPlanIndicator += PLAN_TYPES.MEDICAL;
  }
  if (uniqueTypes.includes(PLAN_TYPES_NAMES.DENTAL)) {
    currentPlanIndicator += (currentPlanIndicator === '' ? '' : '|') + PLAN_TYPES.DENTAL;
  }
  if (uniqueTypes.includes(PLAN_TYPES_NAMES.VISION)) {
    currentPlanIndicator += (currentPlanIndicator === '' ? '' : '|') + PLAN_TYPES.VISION;
  }
  return currentPlanIndicator === '' ? null : currentPlanIndicator;
};

const getAdditionalInfo = (SelectedProduct: SelectedProduct) => {
  let additionalInfo: string = '';
  if (SelectedProduct?.medicalProduct) {
    additionalInfo += SelectedProduct?.medicalProduct?.tiersType;
  }
  // if (selectedDentalPlan?.plan) {
  //   additionalInfo += (additionalInfo === '' ? '' : ';') + selectedDentalPlan?.plan?.tiersType;
  // }
  // if (selectedVisionPlan?.plan) {
  //   additionalInfo += (additionalInfo === '' ? '' : ';') + selectedVisionPlan?.plan?.tiersType;
  // }
  return additionalInfo === '' ? null : additionalInfo;
};

const getSetApplicationData = (onExchange: boolean) => {
  const { selectedProduct, agentCoverageDate, agentBrand, agentState, agentApplicantFormDetails, agentSSOInfo, aTKsaveProposalResponse } =
    useGlobalStore.getState();
  const { isWellpoint } = useUserStore.getState();

  const getTotalPremium = () => {
    let totalPremium: number = 0;
    if (selectedProduct?.medicalProduct) {
      totalPremium += selectedProduct?.medicalProduct?.rateData?.rate?.totSubsidizedPrem ?? 0;
    }
    if (selectedProduct?.dentalProduct) {
      totalPremium += selectedProduct?.dentalProduct?.rateData?.rate?.totalPremium ?? 0;
    }
    if (selectedProduct?.visionProduct) {
      totalPremium += selectedProduct?.visionProduct?.rateData?.rate?.totalPremium ?? 0;
    }
    return parseFloat(totalPremium.toFixed(2).toString());
  };

  let applicantDetails: AtkSetAppApplicantPayload[] = [];

  const applicantInfo = agentApplicantFormDetails;

  const agentEmail = agentApplicantFormDetails.filter((item) => item.applicantType === APPLICANT_TYPES.PRIMARY);

  if (applicantInfo) {
    applicantInfo.map((item) => {
      let plans: Contract[] = [];
      if (selectedProduct?.medicalProduct && isApplicantHavingPlan(selectedProduct?.medicalApplicants, item.firstName, item.applicantId)) {
        plans.push(selectedProduct?.medicalProduct);
      }
      if (selectedProduct?.dentalProduct && isApplicantHavingPlan(selectedProduct?.dentalApplicants, item.firstName, item.applicantId)) {
        plans.push(selectedProduct?.dentalProduct);
      }
      if (selectedProduct?.visionProduct && isApplicantHavingPlan(selectedProduct?.visionApplicants, item.firstName, item.applicantId)) {
        plans.push(selectedProduct?.visionProduct);
      }

      applicantDetails.push({
        demographicInfo: {
          applicantDemographicInfo: item,
          emailAddress: agentSSOInfo?.applicantInfo?.primaryEmailAddress ? agentSSOInfo?.applicantInfo?.primaryEmailAddress : agentEmail[0]?.email
        },
        address: agentSSOInfo?.applicantInfo?.applicantAddressInfo?.address
          ? agentSSOInfo?.applicantInfo?.applicantAddressInfo?.address
          : getApplicantAddress(),
        plan: plans
      });
    });
  }

  const applicationinfo: ApplicationInfo = {
    userId: agentSSOInfo?.eventHeader?.brokerUserID,
    shopperRole: getShoperRole(agentState, agentSSOInfo),
    state: agentState,
    premiumAmt: getTotalPremium(),
    campaignId: DEFAULT_CAMPAIGNIDS[agentState] ?? '',
    brandName: isWellpoint ? BRAND_NAMES.WELLPOINT_BRAND : agentBrand === BRAND_NAMES.ABC ? SET_BRAND_NAMES.BCC : SET_BRAND_NAMES.ANTHEM,
    applicationType: getApplicationType(onExchange),
    reqEffDate: formatDateToYYYYMMDD(agentCoverageDate) ?? '',
    hcid: null,
    // eid: memberInfo.demographicInfo.eid && memberInfo.demographicInfo.eid !== '' ? memberInfo.demographicInfo.eid : null,
    ipAddress: null,
    isAddSpouse: getIsSpouseDependentAdd(selectedProduct, true),
    isAddDependents: getIsSpouseDependentAdd(selectedProduct, false),
    // currentAnchorPlan: getcurrentAnchorPlan(memberInfo.currentCoverages),
    // currentPlanIndicator: getCurrentPlanIndicator(memberInfo.currentCoverages),
    additionalInfo: getAdditionalInfo(selectedProduct),
    adeCustomerId: aTKsaveProposalResponse?.user?.shopperId ?? ''
  };
  return getAtkSetApplicationPayload({ applicantPlanInfo: applicantDetails, applicationinfo: applicationinfo, agent: isAgentInfoConsider() });
};

const getAppplicationListingPayload = () => {
  const { agentSSOInfo, aTKsaveProposalResponse } = useGlobalStore.getState();
  return {
    userId: agentSSOInfo?.eventHeader?.brokerUserID,
    adeCustomerId: aTKsaveProposalResponse?.user?.shopperId as string,
    isInProgress: SET_APPLICATION_CONSTANTS.YES,
    pageNumber: '1',
    numberOfApplicationPerPage: '10',
    partnerId: SET_APPLICATION_CONSTANTS.OLS,
    consumerRequestHeader: {
      applicationId: USER_DOMAINS.APP
    },
    hcid: null
  };
};

const getUpdateQuoteStatusPayload = (acn: string, quoteId: string) => {
  const payload: UpdateQuoteStatusPayload = {
    acn: acn,
    quoteId: quoteId
  };
  return payload;
};

const getMarketType = (poPlan: boolean, onExchange: boolean) => {
  if (poPlan) {
    return PLAN_MARKET_TYPES.COLARADO_CONNECT;
  } else if (onExchange) {
    return PLAN_MARKET_TYPES.MARKET_PLACE;
  } else {
    return PLAN_MARKET_TYPES.OFF_MARKET;
  }
};

const getApplicableMarketType = (medicalPlan: Contract | undefined, dentalPlan: Contract | undefined, visionPlan: Contract | undefined): string => {
  const primaryPlan = medicalPlan ? medicalPlan : dentalPlan ? dentalPlan : visionPlan;

  return primaryPlan ? getMarketType(primaryPlan?.poPlan, primaryPlan?.onExchange) : PLAN_MARKET_TYPES.MARKET_PLACE;
};

export {
  getApplicableMarketType,
  getApplicationType,
  getAppplicationListingPayload,
  getAtkSetApplicationPayload,
  getEncryptacnPayload,
  getIsSpouseDependentAdd,
  getSetApplicationData,
  getShoperRole,
  getUpdateQuoteStatusPayload,
  isApplicantHavingPlan
};
