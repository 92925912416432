import React from 'react';
import { useUserStore } from '../../../store/userStore';
import Hero from '../../common/hero/Hero';
import MotifHero from '../../common/motif-components/MotifHero';

const CoreHero = (props) => {
  const { isWellpoint } = useUserStore((state) => state);
  return (
    <MotifHero title={props.heading} noPadding={true} image={props.src} yPosition={'center'} xPosition={'right'}>
      <Hero
        isWellpoint={isWellpoint}
        description={props.description}
        findPlanText={props.findplan}
        zipCodeText={props.zipcode}
        getQuoteText={props.submitLabel}
        planYearText={props.planYear}
        invalidCountryText={props.invalidCountry}
        invalidCountryLink={props.invalidCountryLink}
        zipCodeToolTip={props.zipCodeToolTip}
        planYearToolTip={props.planYearToolTip}
        invalidZipCodeText={props.invalidZipCode}
        disablePlanYear={props.disablePlanYear}
        jsonArea={props.jsonArea}
      ></Hero>
    </MotifHero>
  );
};
export default CoreHero;
export const CoreHeroIsEmpty = (props) => props.heading == null || props.heading.trim().length === 0;
