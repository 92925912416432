import { Modal } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import TwoFAModalBody from '../../../pages/public/two-fa-modal/TwoFAModalBody';
import { TWO_FA_USE_CASES } from '../../../pages/public/two-fa-modal/constants';
import { default as ContentTWOFA } from '../../../pages/public/two-fa-modal/twoFAModal.json';
import { LANGUAGE_CODES, LOGIN_TYPES, NAV_PATHS, PATH_TO_PAGE_NAME, SCREEN_NAMES, USER_ROLES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import FullPageLoader from '../full-page-loader/FullPageLoader';
import InsuranceBasics from '../insurance-basics/InsuranceBasics';
import SuccessModalBody from '../success-modal-body/SuccessModalBody';
import LoginModal from './../../../pages/public/login/login-modal/LoginModal';
import './PublicNavBar.scss';
import { getCurrentPageDetails, handleSaveData, saveNavigationInfo, updatePageDetails } from './SaveAndResumeLater.utils';
import { default as Content } from './publicNavBarMessages.json';

// const twoFAHeading = ContentTWOFA.data.iospagecontentList.items[0].pageContent.TWO_FA.HEADING;
const PublicNavBar = (props) => {
  // const GlobalContent = Content.data.iospagecontentList.items[0].pageContent.PublicNavigationBar;
  const navigate = useNavigate();
  const location = useLocation();
  const [showInsuranceBasicsSlider, setShowInsuranceBasicsSlider] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [pageContent, setContent] = useState<any>(null);
  const [twoFAContent, setTwoFAContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [showTwoFAModal, setShowTwoFAModal] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const { saveandResumeLaterFlag, updateSaveAndResumeLaterFlag, updateApplicantFormDetails, isBasicsPageUpdated, updateIsBasicsPageUpdated, loginType } = useGlobalStore((state) => state);
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const { progressSteps, agentSSOInfo } = useGlobalStore(state => state);
  const [isStepOne, setIsStepOne] = useState(true);
  const { loggedIn, shopperId, role } = useUserStore((state) => state);
  const [prevLocation, setPrevLocation] = useState('');
  let globalStore = useGlobalStore();
  let userStore = useUserStore();

  const { isAtk } = agentSSOInfo

  const stores = {
    globalStore: useGlobalStore.getState(),
    userStore: useUserStore.getState(),
    location: location.pathname
  };
  const { applicantFormDetails, updateVisitedPages, visitedPages } = globalStore;

  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.PUBLIC_NAV_BAR);
      const responseTwoFA = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.TWO_FA);
      let cmsResponse = response.data;
      setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.PublicNavigationBar);
      setTwoFAContent(responseTwoFA.data.data.iospagecontentList.items[0].pageContent.TWO_FA);
    } catch (error) {
      setContent(Content.data.iospagecontentList.items[0].pageContent.PublicNavigationBar);
      setTwoFAContent(ContentTWOFA.data.iospagecontentList.items[0].pageContent.TWO_FA);
      return false;
    } finally {
      setContentLoaded(true);
    }
    return true;
  }, []);

  const handleNavigatePages = (): Pages[] => {
    const pageName = PATH_TO_PAGE_NAME[location.pathname];
    let updatedVisitedPages: Pages[] = [];
    let updatedPages: Pages[] = [];
    if (pageName) {
      let currentPageDetails: Pages = getCurrentPageDetails({ pageName }, visitedPages);
      updatedVisitedPages = [...visitedPages, { ...currentPageDetails }];
      updatedPages = updatePageDetails(updatedVisitedPages, currentPageDetails) as Pages[];
      updateVisitedPages(updatedPages);
    }
    return updatedPages;
  }

  const handleModalClose = (e) => {
    setShowLoginModal(false);
  };

  const handleTwoFAModalClose = useCallback(() => {
    setShowTwoFAModal(false);
  }, [loggedIn]);

  const handleCloseSuccessModal = () => {
    updateSaveAndResumeLaterFlag(false);
    setShowSuccessModal(false);
  }

  const handleSubmitSaveButton = async () => {
    if (!loggedIn) {
      updateSaveAndResumeLaterFlag(true);
      setShowLoginModal(true);
    }
    else {
      updateSaveAndResumeLaterFlag(true);
    }
  };

  const handleSaveandProgress = async () => {
    if (saveandResumeLaterFlag && loggedIn) {
      const updatedStores = {
        globalStore: useGlobalStore.getState(),
        userStore: useUserStore.getState(),
        location: location.pathname
      };

      try {
        await handleSaveData(updatedStores);
        setShowSuccessModal(true);
        updateSaveAndResumeLaterFlag(false);
        updateIsBasicsPageUpdated(false)
        setTimeout(() => {
          setShowSuccessModal(false);
        }, 5000);

      }
      catch (error) {
        console.error('Error in handleSaveandProgress', error);
      }
    }
  }

  const isPathExcluded = (path: string): boolean => {
    const excludedPaths = [
      NAV_PATHS.ESTIMATE_COST_SAVINGS,
      NAV_PATHS.CREDIT_CALCULATOR,
      NAV_PATHS.HELP_ME_CHOOSE,
      NAV_PATHS.OPTIONS
    ];

    return !excludedPaths.includes(path);
  }


  const initiate = async () => {
    await getContent();
  }

  useEffect(() => {
    initiate()
  }, []);

  useEffect(() => {
    if (showSuccessModal)
      setTimeout(() => {
        setShowSuccessModal(false);
      }, 5000);
  }, [showSuccessModal]);

  useEffect(() => {
    if (location.pathname !== NAV_PATHS.BASICS_LANDING && loggedIn) {
      handleSaveandProgress();
    }
  }, [saveandResumeLaterFlag, loggedIn]);

  useEffect(() => {
    if (location.pathname) {
      let updatedVisitedPages = handleNavigatePages();
      if (loggedIn && stores.userStore.demographicInfo?.demographicId && loginType !== LOGIN_TYPES.MAC_LOGIN) {
        saveNavigationInfo(stores, updatedVisitedPages);
      }
      if (useUserStore.getState().loggedIn && ((location.pathname === NAV_PATHS.MEDICAL_LISTING && stores.globalStore.subsidyresponse?.subsidyCalc?.[0]?.subsidyAmount)
        || (location.pathname === NAV_PATHS.PLAN_SUMMARY))) {
        const updatedStores = {
          globalStore: useGlobalStore.getState(),
          userStore: useUserStore.getState(),
          location: location.pathname
        };
        handleSaveData(updatedStores);
      }
    }
    setPrevLocation(location.pathname);

  }, [location]);

  useEffect(() => {
    if (progressSteps && progressSteps[0].complete) {
      setIsStepOne(false);
    }
  }, [progressSteps]);

  useEffect(() => {
    if (isBasicsPageUpdated) {
      handleSaveandProgress();
    }

  }, [isBasicsPageUpdated]);

  return (
    !contentLoaded ? <FullPageLoader /> : <div className={'border-container ' + (props.isEmbed ? 'header-embed' : '')}>
      <div className='fwc-row top-menu-container'>
        {/*{(props.hideSaveAndResumeLater) && <a className='home-option' id={'insurance-basics-nav'} href={'javascript:void(0)'} onClick={() => setShowInsuranceBasicsSlider(true)}>*/}
        {/*  Back*/}
        {/*</a> }*/}
        {isAtk && location.pathname === NAV_PATHS.PRODUCT_SELECTION && <a data-analytics="backLinkHeaderIos" className='home-option' id={'back-to-policy-nav'} href={'javascript:void(0)'} onClick={() => navigate(NAV_PATHS.AGENTS_BASICS)}>
          {pageContent?.BACK_TO_POLICY}
        </a>
        }
        {loggedIn && !isAtk && (role === USER_ROLES.MEMBER || role === USER_ROLES.SHOPPER) && !(location?.pathname?.includes('dashboard')) && <a data-analytics="dashboardLinkHeaderIos" className='home-option flex-align-left dashboard' id={'dashboard-basics-nav'} href={'javascript:void(0)'} onClick={() => navigate(NAV_PATHS.DASHBOARD)}>
          {pageContent?.DASHBOARD}
        </a>}
        {!isAtk && <a data-analytics="insuranceBasicsHeaderIos" className='home-option flex-align-right' id={'insurance-basics-nav'} href={'javascript:void(0)'} onClick={() => setShowInsuranceBasicsSlider(true)}>
          {pageContent?.INSURANCE_BASICS}
        </a>
        }
        {!isAtk && props.hideSaveAndResumeLater && isPathExcluded(location.pathname) &&
          <a data-analytics="saveResumeLaterHeaderIos" className='home-option' id={'save-and-resume-later-id'} href={'javascript:void(0)'} onClick={() => {
            handleSubmitSaveButton();
          }}>
            {pageContent?.SAVE_AND_RESUME_LATER?.BUTTON_NAME}
          </a>
        }
      </div>

      <Modal open={showSuccessModal} onClose={handleCloseSuccessModal}>
        <Modal.Body>
          {<SuccessModalBody
            heading={pageContent?.SAVE_AND_RESUME_LATER.SUCCESS_POPUP_LABLES.HEADING}
            description={pageContent?.SAVE_AND_RESUME_LATER.SUCCESS_POPUP_LABLES.SUCCESS_MESSAGE}
            closeButtonText={pageContent?.SAVE_AND_RESUME_LATER.SUCCESS_POPUP_LABLES.OK}
            closeModal={() => {
              setShowSuccessModal(false);
            }}
          />
          }
        </Modal.Body>
      </Modal>
      <Modal open={showLoginModal} onClose={handleModalClose} title={'Save Your Quote'}>
        <Modal.Body>
          <LoginModal
            showModal={showLoginModal}
            save={true}
            closeLoginModal={() => {
              setShowLoginModal(false);
            }}
            initiateTwoFa={() => {
              setShowLoginModal(false);
              setModalHeading(twoFAContent);
              setShowTwoFAModal(true);
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal open={showTwoFAModal} onClose={handleTwoFAModalClose} title={modalHeading}>
        <Modal.Body>
          <TwoFAModalBody
            useCase={TWO_FA_USE_CASES.LOGIN}
            showModal={showTwoFAModal}
            showFooter={true}
            closeModal={handleTwoFAModalClose}
            hideModalHeading={() => setModalHeading('')}
            updateModalHeading={(heading) => setModalHeading(heading)}
          />
        </Modal.Body>
      </Modal>
      {
        showInsuranceBasicsSlider && (
          <InsuranceBasics
            showInsuranceBasicsSlider={showInsuranceBasicsSlider}
            CloseInsuranceBasicsSlider={() => setShowInsuranceBasicsSlider(false)}
          />
        )
      }
    </div >
  );
};

export default PublicNavBar;