import { default as Content } from './findYourDoctor.json';

const Strings = Content.data.iospagecontentList.items[0].pageContent.FindYourDoctor;

const SLIDER_STATES = {
  SEARCH_DOCTORS: 'SEARCH_DOCTORS',
  DOCTOR_LIST_UNAVAILABLE: 'SEARCH_RESULTS_UNAVAILABLE',
  DOCTOR_LIST_AVAILABLE: 'SEARCH_RESULT_AVAILABLE',
  DOCTORS_SELECTED: 'DOCTORS_SELECTED',
  LACTATION_COUNSELOR: 'LACTATION_COUNSELOR'
};

const DOCTOR_NAME_LABELS = (Strings: any) => {
  return {
    label1: Strings.DOCTOR_NAME,
    label2: Strings.OPTIONAL,
    label3: Strings.PLEASE_ENTER,
    placeHolder: Strings.EXAMPLE_NAME
  };
};

const DOCTORS_NOT_FOUND_LABELS = (Strings: any): NoResultsFoundProps => {
  return {
    label: Strings.DOCTOR_NOT_FOUND,
    description: Strings.WE_ARE_ALWAYS_WORKING,
    sublabel: Strings.PLEASE_SEARCH_AGAIN,
    hints: [Strings.CHECK_THE_SPELLING, Strings.VERIFY_THAT, Strings.WIDEN_YOUR_SEARCH, Strings.EXPAND_THE_SEARCH_RADIUS, Strings.CHECK_THE_ZIP_CODE]
  };
};

const SECONDARY_BUTTON_HIDDEN_STATES = [SLIDER_STATES.SEARCH_DOCTORS, SLIDER_STATES.DOCTOR_LIST_UNAVAILABLE, SLIDER_STATES.LACTATION_COUNSELOR];

const SPECIALITIES: Specialities = {
  ALL: {
    id: 'ALL',
    label: '',
    desc: '',
    value: '',
    isSelected: false
  },
  BEHAVIOURAL: {
    id: 'BEHAVIOURAL',
    label: '',
    desc: '',
    value: '6',
    isSelected: false
  },
  BEHAVIOURAL_HEALTH_GROUP: {
    id: 'BEHAVIOURAL_HEALTH_GROUP',
    label: '',
    desc: '',
    value: '8',
    isSelected: false
  },
  PSYCHIATRIC_NURSES: {
    id: 'PSYCHIATRIC_NURSES',
    label: '',
    desc: '',
    value: '112',
    isSelected: false
  },
  MARRIAGE_AND_FAMILY: {
    id: 'MARRIAGE_AND_FAMILY',
    label: '',
    desc: '',
    value: '147',
    isSelected: false
  },
  PSYCHOLOGIST: {
    id: 'PSYCHOLOGIST',
    label: '',
    desc: '',
    value: '19',
    isSelected: false
  },
  PROFESSIONAL_COUNCELLORS: {
    id: 'PROFESSIONAL_COUNCELLORS',
    label: '',
    desc: '',
    value: '42',
    isSelected: false
  },
  PSYCHAIATRY: {
    id: 'PSYCHAIATRY',
    label: '',
    desc: '',
    value: '74',
    isSelected: false
  },
  LICENSED_SOCIAL_WORKERS: {
    id: 'LICENSED_SOCIAL_WORKERS',
    label: '',
    desc: '',
    value: '86',
    isSelected: false
  },
  NURSE_PRACT_NURSES: {
    id: 'NURSE_PRACT_NURSES',
    label: '',
    desc: '',
    value: '106',
    isSelected: false
  },
  APP_BEHAVIOUR_ANALYS: {
    id: 'APP_BEHAVIOUR_ANALYS',
    label: '',
    desc: '',
    value: '226',
    isSelected: false
  },
  OTHERS: {
    id: 'OTHERS',
    label: '',
    desc: '',
    value: '60',
    isSelected: false
  },
  ACCUPUNCTURE: {
    id: 'ACCUPUNCTURE',
    label: '',
    desc: '',
    value: '238',
    isSelected: false
  },
  ALLERGY_IMMUNOLOGY: {
    id: 'ALLERGY_IMMUNOLOGY',
    label: '',
    desc: '',
    value: '5',
    isSelected: false
  },
  ANNESTHESIA: {
    id: 'ANNESTHESIA',
    label: '',
    desc: '',
    value: '116',
    isSelected: false
  },
  AUDIOLOGISTS: {
    id: 'AUDIOLOGISTS',
    label: '',
    desc: '',
    value: '10',
    isSelected: false
  },
  CARDIOLOGY: {
    id: 'CARDIOLOGY',
    label: '',
    desc: '',
    value: '12',
    isSelected: false
  },
  CHIROPRACTOR_SPCLST: {
    id: 'CHIROPRACTOR_SPCLST',
    label: '',
    desc: '',
    value: '16',
    isSelected: false
  },
  COLON_RECTAL: {
    id: 'COLON_RECTAL',
    label: '',
    desc: '',
    value: '20',
    isSelected: false
  },
  CRITICAL_CARE: {
    id: 'CRITICAL_CARE',
    label: '',
    desc: '',
    value: '233',
    isSelected: false
  },
  DENTAL: {
    id: 'DENTAL',
    label: '',
    desc: '',
    value: '218',
    isSelected: false
  },
  DERMITOLOGY: {
    id: 'DERMITOLOGY',
    label: '',
    desc: '',
    value: '21',
    isSelected: false
  },
  DIET_NUET: {
    id: 'DIET_NUET',
    label: '',
    desc: '',
    value: '78',
    isSelected: false
  },
  EMERGENCY_MEDICINE: {
    id: 'EMERGENCY_MEDICINE',
    label: '',
    desc: '',
    value: '131',
    isSelected: false
  },
  ENDOCRINOLOGY: {
    id: 'ENDOCRINOLOGY',
    label: '',
    desc: '',
    value: '133',
    isSelected: false
  },
  ENT: {
    id: 'ENT',
    label: '',
    desc: '',
    value: '61',
    isSelected: false
  },
  FAMILY_GENERAL: {
    id: 'FAMILY_GENERAL',
    label: '',
    desc: '',
    value: '25',
    isSelected: false
  },
  GASTRO: {
    id: 'GASTRO',
    label: '',
    desc: '',
    value: '99',
    isSelected: false
  },
  GERIATRICS: {
    id: 'GERIATRICS',
    label: '',
    desc: '',
    value: '30',
    isSelected: false
  },
  HEMO_ONCO: {
    id: 'HEMO_ONCO',
    label: '',
    desc: '',
    value: '140',
    isSelected: false
  },
  HOLISTIC_SPCL: {
    id: 'HOLISTIC_SPCL',
    label: '',
    desc: '',
    value: '49',
    isSelected: false
  },
  HOSPICE_MED: {
    id: 'HOSPICE_MED',
    label: '',
    desc: '',
    value: '249',
    isSelected: false
  },
  IMMUNOLOGY: {
    id: 'IMMUNOLOGY',
    label: '',
    desc: '',
    value: '142',
    isSelected: false
  },
  INFESTIOUS_DISEASE: {
    id: 'INFESTIOUS_DISEASE',
    label: '',
    desc: '',
    value: '143',
    isSelected: false
  },
  LOCATION_COUNCELOR: {
    id: 'LOCATION_COUNCELOR',
    label: '',
    desc: '',
    value: '82',
    isSelected: false
  },
  MAT_AND_FETAL: {
    id: 'MAT_AND_FETAL',
    label: '',
    desc: '',
    value: '148',
    isSelected: false
  },
  MEDICAL_GENETICS: {
    id: 'MEDICAL_GENETICS',
    label: '',
    desc: '',
    value: '149',
    isSelected: false
  },
  NEPHROLOGY: {
    id: 'NEPHROLOGY',
    label: '',
    desc: '',
    value: '152',
    isSelected: false
  },
  NEUROLOGY: {
    id: 'NEUROLOGY',
    label: '',
    desc: '',
    value: '50',
    isSelected: false
  },
  OBSTETRICS_GYNECOLOGY: {
    id: 'OBSTETRICS_GYNECOLOGY',
    label: '',
    desc: '',
    value: '52',
    isSelected: false
  },
  ONCOLOGY_CANCER: {
    id: 'ONCOLOGY_CANCER',
    label: '',
    desc: '',
    value: '54',
    isSelected: false
  },
  OPHTHALMOLOGY: {
    id: 'OPHTHALMOLOGY',
    label: '',
    desc: '',
    value: '55',
    isSelected: false
  },
  OPTICIAN: {
    id: 'OPTICIAN',
    label: '',
    desc: '',
    value: '246',
    isSelected: false
  },
  OPTOMETRY: {
    id: 'OPTOMETRY',
    label: '',
    desc: '',
    value: '245',
    isSelected: false
  },
  ORTHOPEDICS: {
    id: 'ORTHOPEDICS',
    label: '',
    desc: '',
    value: '57',
    isSelected: false
  },
  OT_PT: {
    id: 'OT_PT',
    label: '',
    desc: '',
    value: '104',
    isSelected: false
  },
  PAIN_MGMT: {
    id: 'PAIN_MGMT',
    label: '',
    desc: '',
    value: '164',
    isSelected: false
  },
  PEDIATRICS: {
    id: 'PEDIATRICS',
    label: '',
    desc: '',
    value: '67',
    isSelected: false
  },
  PHYSICAL_REHAB: {
    id: 'PHYSICAL_REHAB',
    label: '',
    desc: '',
    value: '102',
    isSelected: false
  },
  PODIATRISTS: {
    id: 'PODIATRISTS',
    label: '',
    desc: '',
    value: '71',
    isSelected: false
  },
  PULMONARY_DISEASE: {
    id: 'PULMONARY_DISEASE',
    label: '',
    desc: '',
    value: '196',
    isSelected: false
  },
  RADIOLOGY: {
    id: 'RADIOLOGY',
    label: '',
    desc: '',
    value: '75',
    isSelected: false
  },
  RHEUMATOLOGY: {
    id: 'RHEUMATOLOGY',
    label: '',
    desc: '',
    value: '202',
    isSelected: false
  },
  SURGERY: {
    id: 'SURGERY',
    label: '',
    desc: '',
    value: '97',
    isSelected: false
  },
  UROLOGY: {
    id: 'UROLOGY',
    label: '',
    desc: '',
    value: '95',
    isSelected: false
  },
  BEHAVIOUR_HEALTH_SUBSTANCE: {
    id: 'BEHAVIOUR_HEALTH_SUBSTANCE',
    label: '',
    desc: '',
    value: '4',
    isSelected: false
  },
  EXTENDED_CARE_HOSPITALS: {
    id: 'EXTENDED_CARE_HOSPITALS',
    label: '',
    desc: '',
    value: '23',
    isSelected: false
  },
  GENERAL_ACUTE: {
    id: 'GENERAL_ACUTE',
    label: '',
    desc: '',
    value: '28',
    isSelected: false
  },
  SURGICAL_CENTER: {
    id: 'SURGICAL_CENTER',
    label: '',
    desc: '',
    value: '92',
    isSelected: false
  },
  RENAL_DIALYSIS: {
    id: 'RENAL_DIALYSIS',
    label: '',
    desc: '',
    value: '103',
    isSelected: false
  },
  ALT_CARE: {
    id: 'ALT_CARE',
    label: '',
    desc: '',
    value: '110',
    isSelected: false
  },
  CLINICS: {
    id: 'CLINICS',
    label: '',
    desc: '',
    value: '231',
    isSelected: false
  },
  LABORATORIES: {
    id: 'LABORATORIES',
    label: '',
    desc: '',
    value: '18',
    isSelected: false
  },
  PATHOLOGY: {
    id: 'PATHOLOGY',
    label: '',
    desc: '',
    value: '96',
    isSelected: false
  },
  MEDICAL_SURGICAL_SUPPLIERS: {
    id: 'MEDICAL_SURGICAL_SUPPLIERS',
    label: '',
    desc: '',
    value: '47',
    isSelected: false
  },
  IPA_PMG: {
    id: 'IPA_PMG',
    label: '',
    desc: '',
    value: '236',
    isSelected: false
  },
  AMBULANCE_COMPANIES: {
    id: 'AMBULANCE_COMPANIES',
    label: '',
    desc: '',
    value: '7',
    isSelected: false
  },
  HOME_HEALTH_CARE: {
    id: 'HOME_HEALTH_CARE',
    label: '',
    desc: '',
    value: '33',
    isSelected: false
  },
  ORTHO_PROSTHETICS: {
    id: 'ORTHO_PROSTHETICS',
    label: '',
    desc: '',
    value: '58',
    isSelected: false
  },
  OFFICE_SURGERY_CENTER: {
    id: 'OFFICE_SURGERY_CENTER',
    label: '',
    desc: '',
    value: '248',
    isSelected: false
  },
  RETAIL_HEALTH_CLINICS: {
    id: 'RETAIL_HEALTH_CLINICS',
    label: '',
    desc: '',
    value: '219',
    isSelected: false
  },
  WALK_IN_DOCTOR_OFFICE: {
    id: 'WALK_IN_DOCTOR_OFFICE',
    label: '',
    desc: '',
    value: '224',
    isSelected: false
  },
  WALK_IN_CENTER: {
    id: 'WALK_IN_CENTER',
    label: '',
    desc: '',
    value: '228',
    isSelected: false
  },
  URGENT_CARE_CENTER: {
    id: 'URGENT_CARE_CENTER',
    label: '',
    desc: '',
    value: '237',
    isSelected: false
  },

  ORAL: {
    id: 'ORAL',
    label: '',
    desc: '',
    value: '212',
    isSelected: false
  },
  DENTAL_GROUP: {
    id: 'DENTAL_GROUP',
    label: '',
    desc: '',
    value: '234',
    isSelected: false
  },
  PEDIATRIC: {
    id: 'PEDIATRIC',
    label: '',
    desc: '',
    value: '239',
    isSelected: false
  },
  PROSTHODONTICS: {
    id: 'PROSTHODONTICS',
    label: '',
    desc: '',
    value: '240',
    isSelected: false
  },
  ENDODONTIC: {
    id: 'ENDODONTIC',
    label: '',
    desc: '',
    value: '241',
    isSelected: false
  },
  GENERAL: {
    id: 'GENERAL',
    label: '',
    desc: '',
    value: '242',
    isSelected: false
  },
  ORTHO: {
    id: 'ORTHO',
    label: '',
    desc: '',
    value: '243',
    isSelected: false
  },
  PERIODONTICS: {
    id: 'PERIODONTICS',
    label: '',
    desc: '',
    value: '244',
    isSelected: false
  },
  EXAMS: {
    id: 'EXAMS',
    label: '',
    desc: '',
    value: 'EXAMS',
    isSelected: false
  },
  MATERIALS: {
    id: 'MATERIALS',
    label: '',
    desc: '',
    value: 'MATERIALS',
    isSelected: false
  },
  BOTH: {
    id: 'BOTH',
    label: '',
    desc: '',
    value: 'BOTH',
    isSelected: false
  },
  SELECT: {
    id: 'SELECT',
    label: '',
    desc: '',
    value: '',
    isSelected: false
  }
};

const PROVIDER_TYPE_IDS = {
  DOCTOR_MEDICAL: 'DOCTOR_MEDICAL',
  BEHAVIOUR_HEALTH: 'BEHAVIOUR_HEALTH',
  MEDICAL_GROUP: 'MEDICAL_GROUP',
  HOSPITALS_FECILITIES: 'HOSPITALS_FECILITIES',
  LAB_PATHOLOGY: 'LAB_PATHOLOGY',
  MEDICAL_EQP: 'MEDICAL_EQP',
  URGENT_CARE: 'URGENT_CARE',
  OTHER: 'OTHER',
  DENTIST: 'DENTIST',
  VISION: 'VISION',
  SERVICES: 'SERVICES',
  DENTAL_PROVIDERS: 'DENTAL_PROVIDER'
};

const PROVIDER_TYPES: SelectOptionsString[] = [
  {
    id: PROVIDER_TYPE_IDS.DOCTOR_MEDICAL,
    label: '',
    value: 'P',
    isSelected: false
  },
  {
    id: PROVIDER_TYPE_IDS.BEHAVIOUR_HEALTH,
    label: '',
    value: 'B',
    isSelected: false
  },
  {
    id: PROVIDER_TYPE_IDS.MEDICAL_GROUP,
    label: '',
    value: 'G',
    isSelected: false
  },
  {
    id: PROVIDER_TYPE_IDS.HOSPITALS_FECILITIES,
    label: '',
    value: 'H',
    isSelected: false
  },
  {
    id: PROVIDER_TYPE_IDS.LAB_PATHOLOGY,
    label: '',
    value: 'L',
    isSelected: false
  },
  {
    id: PROVIDER_TYPE_IDS.MEDICAL_EQP,
    label: '',
    value: 'M',
    isSelected: false
  },
  {
    id: PROVIDER_TYPE_IDS.URGENT_CARE,
    label: '',
    value: 'U',
    isSelected: false
  },
  {
    id: PROVIDER_TYPE_IDS.OTHER,
    label: '',
    value: 'O',
    isSelected: false
  }
];

const PROVIDER_TYPES_DENTAL: SelectOptionsString[] = [
  {
    id: PROVIDER_TYPE_IDS.DENTIST,
    label: '',
    value: 'D',
    isSelected: false
  }
];

const PROVIDER_TYPES_DENTAL_PROVIDER: SelectOptionsString[] = [
  {
    id: PROVIDER_TYPE_IDS.DENTAL_PROVIDERS,
    label: Strings.PROVIDER_TYPE_DENTAL,
    value: 'D',
    isSelected: false
  }
];

const PROVIDER_TYPES_VISION: SelectOptionsString[] = [
  {
    id: PROVIDER_TYPE_IDS.VISION,
    label: '',
    value: 'V',
    isSelected: false
  }
];
const PROVIDER_TYPES_SERVICES: SelectOptionsString[] = [
  {
    id: PROVIDER_TYPE_IDS.SERVICES,
    label: '',
    value: 'V',
    isSelected: false
  }
];

const LOCATED_WITHIN_TYPES: SelectOptionsNumber[] = [
  {
    id: 'two-miles',
    label: '',
    value: 2,
    isSelected: false
  },
  {
    id: 'five-miles',
    label: '',
    value: 5,
    isSelected: false
  },
  {
    id: 'ten-miles',
    label: '',
    value: 10,
    isSelected: false
  },
  {
    id: 'twenty-miles',
    label: '',
    value: 20,
    isSelected: false
  },
  {
    id: 'thirty-miles',
    label: '',
    value: 30,
    isSelected: false
  },
  {
    id: 'fifty-miles',
    label: '',
    value: 50,
    isSelected: false
  },
  {
    id: 'seventy-five-miles',
    label: '',
    value: 75,
    isSelected: false
  },
  {
    id: 'hundred-miles',
    label: '',
    value: 100,
    isSelected: false
  }
];
const DEFAULT_SPECIALISATION_VALUES_BY_PROVIDER_TYPE = (SPECIALITIES: Specialities) => {
  return {
    [PROVIDER_TYPE_IDS.BEHAVIOUR_HEALTH]: SPECIALITIES.ALL.value,
    [PROVIDER_TYPE_IDS.DOCTOR_MEDICAL]: SPECIALITIES.FAMILY_GENERAL.value,
    [PROVIDER_TYPE_IDS.HOSPITALS_FECILITIES]: SPECIALITIES.GENERAL_ACUTE.value,
    [PROVIDER_TYPE_IDS.LAB_PATHOLOGY]: SPECIALITIES.ALL.value,
    [PROVIDER_TYPE_IDS.MEDICAL_EQP]: SPECIALITIES.ALL.value,
    [PROVIDER_TYPE_IDS.MEDICAL_GROUP]: SPECIALITIES.ALL.value,
    [PROVIDER_TYPE_IDS.OTHER]: SPECIALITIES.ALL.value,
    [PROVIDER_TYPE_IDS.URGENT_CARE]: SPECIALITIES.ALL.value,
    [PROVIDER_TYPE_IDS.DENTIST]: SPECIALITIES.ALL.value,
    [PROVIDER_TYPE_IDS.VISION]: SPECIALITIES.ALL.value
  }

};

const SPECIALISATION_BY_PROVIDER_TYPE = (SPECIALITIES: Specialities) => {
  return {
    [PROVIDER_TYPE_IDS.BEHAVIOUR_HEALTH]: [
      SPECIALITIES.ALL,
      SPECIALITIES.BEHAVIOURAL,
      SPECIALITIES.BEHAVIOURAL_HEALTH_GROUP,
      SPECIALITIES.PSYCHIATRIC_NURSES,
      SPECIALITIES.MARRIAGE_AND_FAMILY,
      SPECIALITIES.PSYCHOLOGIST,
      SPECIALITIES.PROFESSIONAL_COUNCELLORS,
      SPECIALITIES.PSYCHAIATRY,
      SPECIALITIES.LICENSED_SOCIAL_WORKERS,
      SPECIALITIES.NURSE_PRACT_NURSES,
      SPECIALITIES.APP_BEHAVIOUR_ANALYS,
      SPECIALITIES.OTHERS
    ],
    [PROVIDER_TYPE_IDS.DOCTOR_MEDICAL]: [
      SPECIALITIES.ALL,
      SPECIALITIES.ACCUPUNCTURE,
      SPECIALITIES.ALLERGY_IMMUNOLOGY,
      SPECIALITIES.ANNESTHESIA,
      SPECIALITIES.APP_BEHAVIOUR_ANALYS,
      SPECIALITIES.AUDIOLOGISTS,
      SPECIALITIES.CARDIOLOGY,
      SPECIALITIES.CHIROPRACTOR_SPCLST,
      SPECIALITIES.COLON_RECTAL,
      SPECIALITIES.CRITICAL_CARE,
      SPECIALITIES.DENTAL,
      SPECIALITIES.DERMITOLOGY,
      SPECIALITIES.DIET_NUET,
      SPECIALITIES.EMERGENCY_MEDICINE,
      SPECIALITIES.ENDOCRINOLOGY,
      SPECIALITIES.ENT,
      SPECIALITIES.FAMILY_GENERAL,
      SPECIALITIES.GASTRO,
      SPECIALITIES.GERIATRICS,
      SPECIALITIES.HEMO_ONCO,
      SPECIALITIES.HOLISTIC_SPCL,
      SPECIALITIES.HOSPICE_MED,
      SPECIALITIES.IMMUNOLOGY,
      SPECIALITIES.INFESTIOUS_DISEASE,
      SPECIALITIES.LOCATION_COUNCELOR,
      SPECIALITIES.LICENSED_SOCIAL_WORKERS,
      SPECIALITIES.MAT_AND_FETAL,
      SPECIALITIES.NEPHROLOGY,
      SPECIALITIES.NEUROLOGY,
      SPECIALITIES.NURSE_PRACT_NURSES,
      SPECIALITIES.OBSTETRICS_GYNECOLOGY,
      SPECIALITIES.ONCOLOGY_CANCER,
      SPECIALITIES.OPHTHALMOLOGY,
      SPECIALITIES.OPTICIAN,
      SPECIALITIES.OPTOMETRY,
      SPECIALITIES.ORTHOPEDICS,
      SPECIALITIES.OT_PT,
      SPECIALITIES.PAIN_MGMT,
      SPECIALITIES.PEDIATRICS,
      SPECIALITIES.PHYSICAL_REHAB,
      SPECIALITIES.PODIATRISTS,
      SPECIALITIES.PROFESSIONAL_COUNCELLORS,
      SPECIALITIES.PSYCHAIATRY,
      SPECIALITIES.PSYCHOLOGIST,
      SPECIALITIES.PULMONARY_DISEASE,
      SPECIALITIES.RADIOLOGY,
      SPECIALITIES.RHEUMATOLOGY,
      SPECIALITIES.SURGERY,
      SPECIALITIES.UROLOGY
    ],
    [PROVIDER_TYPE_IDS.HOSPITALS_FECILITIES]: [
      SPECIALITIES.ALL,
      SPECIALITIES.BEHAVIOUR_HEALTH_SUBSTANCE,
      SPECIALITIES.EXTENDED_CARE_HOSPITALS,
      SPECIALITIES.GENERAL_ACUTE,
      SPECIALITIES.SURGICAL_CENTER,
      SPECIALITIES.RENAL_DIALYSIS,
      SPECIALITIES.ALT_CARE,
      SPECIALITIES.BEHAVIOURAL,
      SPECIALITIES.BEHAVIOURAL_HEALTH_GROUP,
      SPECIALITIES.OTHERS,
      SPECIALITIES.PHYSICAL_REHAB,
      SPECIALITIES.CLINICS
    ],
    [PROVIDER_TYPE_IDS.LAB_PATHOLOGY]: [SPECIALITIES.ALL, SPECIALITIES.LABORATORIES, SPECIALITIES.RADIOLOGY, SPECIALITIES.PATHOLOGY],
    [PROVIDER_TYPE_IDS.MEDICAL_EQP]: [SPECIALITIES.ALL, SPECIALITIES.MEDICAL_SURGICAL_SUPPLIERS, SPECIALITIES.OTHERS],
    [PROVIDER_TYPE_IDS.MEDICAL_GROUP]: [SPECIALITIES.ALL, SPECIALITIES.CLINICS, SPECIALITIES.IPA_PMG, SPECIALITIES.PHYSICAL_REHAB],
    [PROVIDER_TYPE_IDS.OTHER]: [
      SPECIALITIES.ALL,
      SPECIALITIES.AMBULANCE_COMPANIES,
      SPECIALITIES.HOME_HEALTH_CARE,
      SPECIALITIES.ORTHO_PROSTHETICS,
      SPECIALITIES.OFFICE_SURGERY_CENTER,
      SPECIALITIES.NEUROLOGY,
      SPECIALITIES.OTHERS,
      SPECIALITIES.PHYSICAL_REHAB,
      SPECIALITIES.EXTENDED_CARE_HOSPITALS,
      SPECIALITIES.SURGICAL_CENTER,
      SPECIALITIES.RENAL_DIALYSIS,
      SPECIALITIES.CLINICS
    ],
    [PROVIDER_TYPE_IDS.URGENT_CARE]: [
      SPECIALITIES.ALL,
      SPECIALITIES.RETAIL_HEALTH_CLINICS,
      SPECIALITIES.WALK_IN_DOCTOR_OFFICE,
      SPECIALITIES.WALK_IN_CENTER,
      SPECIALITIES.URGENT_CARE_CENTER
    ],
    [PROVIDER_TYPE_IDS.DENTIST]: [
      SPECIALITIES.ALL,
      SPECIALITIES.ORAL,
      SPECIALITIES.DENTAL_GROUP,
      SPECIALITIES.PEDIATRIC,
      SPECIALITIES.PROSTHODONTICS,
      SPECIALITIES.ENDODONTIC,
      SPECIALITIES.GENERAL,
      SPECIALITIES.ORTHO,
      SPECIALITIES.PERIODONTICS
    ],
    [PROVIDER_TYPE_IDS.VISION]: [SPECIALITIES.OPHTHALMOLOGY, SPECIALITIES.OPTICIAN, SPECIALITIES.OPTOMETRY],
    [PROVIDER_TYPE_IDS.SERVICES]: [SPECIALITIES.SELECT, SPECIALITIES.EXAMS, SPECIALITIES.MATERIALS, SPECIALITIES.BOTH]

  }
}


const SORT_OPTION = 'distance';

const PROVIDER_TYPE_USECASES = {
  DOCTOR: 'DOCTOR',
  DENTIST: 'DENTIST',
  VISION: 'VISION'
};
const FORMHEADER_ICON_CLASSNAMES = {
  DOCTOR_ICON: 'sae-icon sae-icon-stethoscope right-xxs',
  DENTIST_ICON: 'sae-icon sae-icon-tooth right-xxs',
  VISION_ICON: 'sae-icon sae-icon-eye right-xxs'
};
const ALPHA_REGX_WITH_NONUM = /^[^\d]*$/;
export {
  ALPHA_REGX_WITH_NONUM,
  DEFAULT_SPECIALISATION_VALUES_BY_PROVIDER_TYPE, DOCTOR_NAME_LABELS, DOCTORS_NOT_FOUND_LABELS, FORMHEADER_ICON_CLASSNAMES,
  LOCATED_WITHIN_TYPES, PROVIDER_TYPE_IDS,
  PROVIDER_TYPE_USECASES, PROVIDER_TYPES,
  PROVIDER_TYPES_DENTAL,
  PROVIDER_TYPES_DENTAL_PROVIDER,
  PROVIDER_TYPES_SERVICES,
  PROVIDER_TYPES_VISION, SECONDARY_BUTTON_HIDDEN_STATES,
  SLIDER_STATES,
  SORT_OPTION,
  SPECIALISATION_BY_PROVIDER_TYPE,
  SPECIALITIES
};

