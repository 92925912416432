import { Button, Modal } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import { getCurrentPageDetails, handleSaveData, saveNavigationInfo, updatePageDetails } from '../../../components/common/public-nav-bar/SaveAndResumeLater.utils';
import { LANGUAGE_CODES, NAV_PATHS, PATH_TO_PAGE_NAME, SCREEN_NAMES, USER_ROLES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { logoutRedirect } from './../../public/login/login-modal/loginServices';
import { default as Content } from './LogoutMessages.json';
import './LogoutPopup.scss';
import { resetUserData } from './LogoutPupupUtils';

const content = Content.data.iospagecontentList.items[0].pageContent.logoutConfirmPopup;

const LogoutPopup: React.FC<LogoutPopupProps> = ({ showPopup, popupClose, remainingTime }) => {
    const location = useLocation();
    const [pageContent, setContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const { loggedIn, updateLoginStatus, resetUserStore, role, isPHILogin } = useUserStore(state => state);
    const { zipCodeRes, resetQuoteStore, updateVisitedPages, visitedPages } = useGlobalStore(state => state);
    const [loading, setLoading] = useState(false);

    const stores = {
        globalStore: useGlobalStore(),
        userStore: useUserStore(),
        location: location.pathname
    };

    const navigate = useNavigate();

    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.LOG_OUT_POPUP);
            let cmsResponse = response.data;
            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.logoutConfirmPopup);
        } catch (error) {
            setContent(content);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, []);

    const handleCancelButton = () => {
        popupClose();
    }
    const handleNavigatePages = (): Pages[] => {
        const pageName = PATH_TO_PAGE_NAME[location.pathname];
        let updatedVisitedPages: Pages[] = [];
        let updatedPages: Pages[] = [];
        if (pageName) {
            let currentPageDetails: Pages = getCurrentPageDetails({ pageName }, visitedPages);
            updatedVisitedPages = [...visitedPages, { ...currentPageDetails }];
            updatedPages = updatePageDetails(updatedVisitedPages, currentPageDetails) as Pages[];
            updateVisitedPages(updatedPages);
        }
        return updatedPages;
    }


    const handleLogoutButton = async () => {
        const userRole = role;
        updateLoginStatus(false);
        setLoading(true);
        if (location.pathname) {
            let updatedVisitedPages = handleNavigatePages();
            if (loggedIn && stores.userStore.demographicInfo?.demographicId) {
                saveNavigationInfo(stores, updatedVisitedPages);
            }
        }
        await handleSaveData(stores);
        popupClose();
        resetUserData();
        logoutRedirect(userRole);
        setLoading(false);
        if (userRole === USER_ROLES.MEMBER || isPHILogin) {
            navigate(NAV_PATHS.MAC_LOGIN);
        }
        else {
            navigate(NAV_PATHS.STANDALONE_LOGIN);
        }
    }

    const initiate = async () => {
        await getContent();
    }

    useEffect(() => {
        initiate();
    }, []);


    useEffect(() => {
        if (remainingTime === 1) {
            handleLogoutButton();
        }
    }, [remainingTime])

    return (
        <>
            {!contentLoaded || loading ? (<FullPageLoader />)
                :
                (<div className='logout-popup-wraper'>
                    <Modal open={showPopup} onClose={() => popupClose()} title={pageContent.TITLE}>
                        <Modal.Body className="popup-body">
                            <div id="popup-model">
                                <div className="popup-container" id="popup-container" data-testid="popup-container">
                                    <span><p id="popup-content">{pageContent.DESCRIPTION_1} {remainingTime} {pageContent.DESCRIPTION_2}</p>
                                    </span>
                                    <span> <p id="popup-content">{pageContent.DESCRIPTION_3}</p></span>
                                </div>

                                <Modal.Footer>
                                    <div className="popup-buttons" id="popup-buttons-wrapper">
                                        <Button data-analytics="logoutBtnLogoutModalIos" btnType="secondary" id="logout-button" onClick={() => handleLogoutButton()}
                                            data-testid="logout-button"
                                        >
                                            {pageContent.LOGOUT}
                                        </Button>
                                        <Button data-analytics="cancelBtnLogoutModalIos" btnType="primary" id="cancel-button" onClick={handleCancelButton}
                                            data-testid="cancel-button">
                                            {pageContent.CANCEL}
                                        </Button>
                                    </div>
                                </Modal.Footer>
                            </div>
                        </Modal.Body>
                    </Modal>
                </div>
                )}
        </>
    )
}

export default LogoutPopup;
