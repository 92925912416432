import { ResponsiveGrid } from '@adobe/aem-react-editable-components';
import { Modal } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import React, { useEffect, useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import { HTTP_STATUS_CODES } from '../../../api/constants';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import GlobalFooter from '../../../components/common/global-footer/GlobalFooter';
import GlobalHeader from '../../../components/common/global-header/GlobalHeader';
import EditableColumnCards from '../../../components/editable/ColumnCards/EditableColumnCards';

import EditableCoreHero from '../../../components/editable/Hero/EditableHero';
import EditableMediaCard from '../../../components/editable/MediaCard/EditableMediaCard';
import EditablePlanCard from '../../../components/editable/PlanCard/EditablePlanCard';
import EditableQuestioncards from '../../../components/editable/QuestionCards/EditableQuestionCards';
import EditableInfoBarText from '../../../components/editable/infoBar/EditableInfoBarText';
import { AEM_PATHS } from '../../../shared/globalConstants';
import { useUserStore } from '../../../store/userStore';
import { getValidateResetPwdIdSuccessPayload } from '../account-recovery/AccountRecoveryUtils';
import { default as Content } from '../account-recovery/accountRecovery.json';
import { validateResetPwdId } from '../account-recovery/accountRecoveryServices';
import { FORGOT_PASSWORD_ERROR_CODES, VALIDATERESETPWDID_RESPONSE_CODES } from '../account-recovery/constants';
import TwoFAModalBody from '../two-fa-modal/TwoFAModalBody';
import { TWO_FA_USE_CASES } from '../two-fa-modal/constants';
import './Home.scss';

const Strings = Content.data.iospagecontentList.items[0].pageContent.AccountRecovery;

function Home() {
  const [showTwoFAModal, setShowTwoFAModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [showValidateResetIdError, setShowValidateResetIdError] = useState({
    hasError: false,
    responseCode: '',
    message: ''
  });
  const [pagePath, setPagePath] = useState('');

  const { updateValidateResetIDResponse, isWellpoint } = useUserStore((state) => state);

  const navigate = useNavigate();
  const [resetPasswordParams] = useSearchParams();
  const resetId = resetPasswordParams.get('resetId');
  const brand = resetPasswordParams.get('brand');
  const searchParams = new URLSearchParams(window.location.search);
  const localeVal = searchParams.get('locale');
  const brandVal = searchParams.get('brand')?.toUpperCase();
  const locale = (localeVal === 'es_US') ? AEM_PATHS.SPANISH : AEM_PATHS.ENGLISH;
  const updateAemPagePath = () => {
    const isWellpointflag = window.location.href.includes('wellpoint.com/') || window.location.href.includes('/content/ios/wellpoint/')
    if (isWellpointflag) {
      setPagePath(`/content/ios/wellpoint/us/${locale}/individuals`)
    } else {
      setPagePath((brandVal && brandVal !== 'ABCBS') ? `/content/ios/anthem/us/${locale}/individuals-${brandVal}` : `/content/ios/anthem/us/${locale}/individuals`)
    }

  }
  useEffect(() => {
    updateAemPagePath();
    if (resetId) {
      initiateValidateResetPwdId();
    }
  }, [resetId]);

  const initiateValidateResetPwdId = () => {
    setLoading(true);
    const payload: ValidateResetPwdIdPayload = {
      resetId: resetId ? resetId : '',
      brand: brand ? brand : ''
    };
    validateResetPwdId(payload)
      .then((response) => {
        const data: ValidateResetPwdIdResponse = response.data;
        if (response.status === HTTP_STATUS_CODES.SUCCESS && data.responseMessage.responseCode === VALIDATERESETPWDID_RESPONSE_CODES.TWO_FA_NEEDED) {
          setLoading(false);
          //update User Store
          const validateResetPwdIdSuccessPayload = getValidateResetPwdIdSuccessPayload(data, resetId ? resetId : '', brand ? brand : '');
          updateValidateResetIDResponse(validateResetPwdIdSuccessPayload);
          // initiate 2fa flow
          setModalHeading(Strings.MODAL_HEADING);
          setShowTwoFAModal(true);
        } else {
          setModalHeading('');
          setShowTwoFAModal(true);
          setShowValidateResetIdError({
            hasError: true,
            responseCode: data.responseMessage.responseCode,
            message: data.responseMessage.message
          });

          setLoading(false);
        }
      })
      .catch((error: AxiosError<ValidateResetPwdIdErrorResponse>) => {
        if (isAxiosError(error)) {
          const errorObj = error.response?.data?.error;
          setModalHeading('');
          setShowTwoFAModal(true);
          setShowValidateResetIdError({
            hasError: true,
            responseCode: errorObj?.errorKey ? errorObj?.errorKey : '',
            message:
              errorObj?.errorKey === FORGOT_PASSWORD_ERROR_CODES.RESET_LINK_EXPIRED
                ? Strings.RESET_PWD_LINK_EXPIRED
                : `<b>${Strings.SYSTEM_EXCEPTION_HEADING}</b> <br> ${Strings.SYSTEM_EXCEPTION_MESSAGE}`
          });
        } else {
          setShowValidateResetIdError({
            hasError: true,
            responseCode: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
            message: `<b>${Strings.SYSTEM_EXCEPTION_HEADING}</b> <br> ${Strings.SYSTEM_EXCEPTION_MESSAGE}`
          });
        }
        setLoading(false);
      });
  };

  const infoAlert = {
    type: 'info',
    content:
      '<div>Open enrollment is here! Apply for your 2024 health insurance plan and learn if you qualify for financial help. Select <a href="javascript:void(0)">Shop Plans</a> to learn more.</div>',
    area: 'global'
  };

  const handleTwoFAModalClose = () => {
    setShowTwoFAModal(false);
    navigate('/individuals/');
  };

  return (
    <div className={isWellpoint ? 'wellpoint__aem' : 'anthem__aem'}>
      {loading && <FullPageLoader />}

      <GlobalHeader />

      <div className="Home">
        <div className="sae-content-wrapper">
          {pagePath && <ResponsiveGrid
            // @ts-ignore
            pagePath={pagePath}
            itemPath="root/responsivegrid"
          />}
        </div>

        {/* All the AEM Editable component are included with in the below invisible block */}
        <div style={{ display: 'none' }}>
          <EditablePlanCard />
          <EditableMediaCard />
          <EditableColumnCards />
          <EditableQuestioncards />
          <EditableInfoBarText />
          <EditableCoreHero />


        </div>
      </div>

      <GlobalFooter />
      <Modal open={showTwoFAModal} onClose={handleTwoFAModalClose} title={modalHeading}>
        <Modal.Body>
          {showValidateResetIdError.message ? (
            <p className="reset-id-error" dangerouslySetInnerHTML={{ __html: showValidateResetIdError.message }} />
          ) : (
            <TwoFAModalBody
              useCase={TWO_FA_USE_CASES.FORGOT_PASSWORD}
              showModal={showTwoFAModal}
              showFooter={true}
              closeModal={handleTwoFAModalClose}
              hideModalHeading={() => setModalHeading('')}
              updateModalHeading={(heading) => setModalHeading(heading)}
            />
          )}
        </Modal.Body>
      </Modal>
    </div>
  );
}

export default Home;
