import { PLAN_BENEFIT_CODES } from '../../../pages/public/medical-plans/constants';
import { PLAN_TYPES_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';

import { MAX_WORDS_ON_SHOW_LESS_DEDUCTIBLE, MAX_WORDS_ON_SPECIALIST_VISIT } from '../plan-card/constants';

const getOutOfPocketMaxBenefitValueMedical = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  let planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === PLAN_BENEFIT_CODES.OUT_OF_POCKET_MAX_IND);
  planBenefitObj = !planBenefitObj ? planBenefits.find((benefit) => benefit.planBenefitCode === PLAN_BENEFIT_CODES.OUT_OF_POCKET_MAX_FAM) : planBenefitObj;
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_DEDUCTIBLE) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_DEDUCTIBLE).join(' ') + '...';
    }
  }
};

const getBlueCardBenefitValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  let planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === PLAN_BENEFIT_CODES.BLUE_CARD);
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_DEDUCTIBLE) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_DEDUCTIBLE).join(' ') + '...';
    }
  }
};

const getSpecialistVisitBenefitValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  let planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === PLAN_BENEFIT_CODES.SPECIALIST_VISIT);
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SPECIALIST_VISIT) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SPECIALIST_VISIT).join(' ') + '...';
    }
  }
};

const getTierOneDrugBenefitValue = (plan: Plan, showMore: boolean) => {
  const { planBenefits } = plan;
  let planBenefitObj = planBenefits.find((benefit) => benefit.planBenefitCode === PLAN_BENEFIT_CODES.TIER_ONE_DRUG);
  const value = planBenefitObj?.planBenefitValue ? planBenefitObj?.planBenefitValue : '';

  if (showMore) {
    return value;
  } else {
    const words = value.split(' ');
    if (words.length <= MAX_WORDS_ON_SHOW_LESS_DEDUCTIBLE) {
      return value;
    } else {
      return words.slice(0, MAX_WORDS_ON_SHOW_LESS_DEDUCTIBLE).join(' ') + '...';
    }
  }
};

const getSelectedProduct = (
  medicalProduct: Contract,
  medicalApplicants: Applicant[],
  matchedDoctors?: Provider[],
  matchedMedications?: DrugDetails[],
  dentalProduct?: Contract,
  matchedDentists?: Provider[],
  visionProduct?: Contract,
  matchedEyeDoctors?: Provider[]
): SelectedProduct => {
  const selectedProduct: SelectedProduct = {
    medicalProduct,
    dentalProduct,
    visionProduct,
    matchedDoctors,
    matchedMedications,
    matchedDentists,
    matchedEyeDoctors,
    medicalApplicants,
    dentalApplicants: [],
    visionApplicants: []
  };

  return selectedProduct;
};

const isReviewedProduct = (primaryProductType: string, reviewedProducts: SelectedProducts, prodct: Contract): boolean => {
  if (primaryProductType === PLAN_TYPES_NAMES.MEDICAL_PRODUCT) {
    return reviewedProducts.find((product) => product?.medicalProduct?.contractCode === prodct.contractCode) ? true : false;
  } else if (primaryProductType === PLAN_TYPES_NAMES.DENTAL_PRODUCT) {
    return reviewedProducts.find((product) => product?.dentalProduct?.contractCode === prodct.contractCode) ? true : false;
  } else if (primaryProductType === PLAN_TYPES_NAMES.VISION_PRODUCT) {
    return reviewedProducts.find((product) => product?.visionProduct?.contractCode === prodct.contractCode) ? true : false;
  }

  return false;
};

const getDentalProduct = (contract: Contract): SelectedDentalProduct => {
  const { selectedDentalProducts } = useGlobalStore.getState();
  const selectedProduct = selectedDentalProducts.find((selectedProduct) => selectedProduct.primaryProductContractCode === contract.contractCode);

  return selectedProduct ?? ({} as SelectedDentalProduct);
};

const getVisionProduct = (contract: Contract): SelectedVisionProduct => {
  const { selectedVisionProducts } = useGlobalStore.getState();
  const selectedProduct = selectedVisionProducts.find((selectedProduct) => selectedProduct.primaryProductContractCode === contract.contractCode);

  return selectedProduct ?? ({} as SelectedVisionProduct);
};

const getMatchedProvidersTitle = (primaryProductType: string, labels: any) => {
  switch (primaryProductType) {
    case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
      return labels.MEDICATIONS_LIST_LABEL;
    case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
      return labels.DENTISTS_LIST_LABEL;
    default:
      return labels.VISION_LIST_LABEL;
  }
};

const getMatchedProvidersShowMoreOrLessLabel = (primaryProductType: string, labels: any) => {
  switch (primaryProductType) {
    case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
      return labels.DOCTORS;
    case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
      return labels.DENTISTS;
    default:
      return labels.EYE_DOCTORS;
  }
};

export {
  getBlueCardBenefitValue,
  getDentalProduct,
  getMatchedProvidersShowMoreOrLessLabel,
  getMatchedProvidersTitle,
  getOutOfPocketMaxBenefitValueMedical,
  getSelectedProduct,
  getSpecialistVisitBenefitValue,
  getTierOneDrugBenefitValue,
  getVisionProduct,
  isReviewedProduct
};
