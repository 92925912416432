import { Tabs } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import CompareTray from "../../../components/common/compare-tray/CompareTray";
import EmptyPlans from '../../../components/common/empty-plans/EmptyPlans';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import GlobalHeader from '../../../components/common/global-header/GlobalHeader';
import PlanCard from '../../../components/common/plan-card/PlanCard';
import PlanCoverageAccordion from '../../../components/common/plan-coverage-accordion/PlanCoverageAccordion';
import PlanListingTitles from '../../../components/common/plan-listing-titles/PlanListingTitles';
import { getProviderTypeCode } from '../../../components/common/public-nav-bar/SaveAndResumeLater.utils';
import SelectedPlans from '../../../components/common/selected-plans/SelectedPlans';
import { APPLICANT_TYPES, COVERAGE_TYPES, ENTITY_TYPES, LANGUAGE_CODES, MACFLOW_POPUP_TYPE, NAV_PATHS, PLAN_TYPES, PLAN_TYPES_NAMES, SCREEN_NAMES, USER_ROLES } from '../../../shared/globalConstants';
import { getNotMedicaidEligibleDependents } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import DentalPlanDetails from '../dental-plan-details/DentalPlanDetails';
import FindYourDentist from '../find-your-dentist/findYourDentist';
import { PROVIDER_TYPE_IDS } from '../find-your-doctor/constants';
import { MEDICAL_PLANS_TABS, MONTHLY_COST_SORT_OPTION } from '../medical-plans/constants';
import { fetchPlanList, getProviderListById } from '../medical-plans/medicalPlansServices';
import {
  getContractCodes,
  getEmptyPlansUseCase,
  getFavouritePlans,
  getFormattedApplicantDetails,
  getMatchedDoctorsForPlan, getPlansForTab,
  getSortedPlans,
  getUpdatedFavouritePlans
} from '../medical-plans/medicalPlansUtils';
import ProviderDetails from '../provider-details/ProviderDetails';
import { PLAN_ERROR_CODES } from '../vision-plans/constants';
import './DentalPlans.scss';
import { DENTAL_SORT_OPTIONS } from './constants';
import { default as Content } from './dentalPlans.json';
import { getDentalPlanCardLabels, getDentalPlansPayload, getSelectedPlans, getSortOptionsWithLabels } from './dentalPlansUtils';


const DentalPlans = () => {
  const [selectedPlanToUpdate, setSelectedPlanToUpdate] = useState<SelectedPlan>({} as SelectedPlan);
  const [filteredPlans, setFilteredPlans] = useState<Contracts>([]);
  const [loading, setLoading] = useState(false);
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [showFindYourDentist, setShowFindYourDentist] = useState(false);
  const [serverError, setServerError] = useState(false);
  const [isCurrentCoveragePlan, setIsCurrentCoveragePlan] = useState(false);
  const [contract, setContract] = useState<Contract | null>(null);
  const [isSkip, setIsSkip] = useState(false);
  const [noPopupDisplay, setNoPopupDisplay] = useState(false);

  const importantNotice = useRef<HTMLDivElement>(null);

  const { role, loggedIn, demographicInfo } = useUserStore((store) => store)
  const useGlobalStoreObj = useGlobalStore((state) => state);
  const {
    zipCodeRes,
    dentalPlans,
    comparedDentalPlans,
    matchDentistsToPlans,
    savedDentists,
    selectedPlan,
    selectedVisionPlan,
    selectedDentalPlan,
    dentalSortOptions,
    providerMetaData,
    coverageType,
    selectedDentalPlanTab,
    favouriteDentalPlans,
    showFavourites,
    memberInfo,
    basicPlanType,
    closeDepModal,
    currentCoverageDentalPlan,
    openDepModal,
    applicantFormDetails,
    updateDentalSortOptions,
    updateDentalPlans,
    updateSelectedDentalPlan,
    updateSelectedVisionPlan,
    updateComparedDentalPlans,
    updateBasicPlanType,
    updateSelectedDentalPlanTab,
    updateOpenDepModal,
    updateFavouriteDentalPlans,
    updateShowFavourites,
    updateCloseDepModal,
    updateShowRecommendationModal,
    resetVisionPlanStates,
    previousStep,
    updateSavedDentists,
    updateMatchDentistsToPlansStatus
  } = useGlobalStoreObj;
  useEffect(() => {
    if (selectedDentalPlan.plan !== null && selectedDentalPlan.plan !== undefined) {
      setIsSkip(false);
    } else {
      setIsSkip(true);
    }
  }, [selectedDentalPlan])
  const { currentCoverages } = memberInfo

  const showTabs = coverageType === COVERAGE_TYPES.DENTAL_WITH_VISION;

  const isColaradoPlanSelected = selectedPlan && selectedPlan?.plan?.poPlan;

  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;

    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.DENTAL_PLANS);
      let cmsResponse = response.data;

      content = cmsResponse.data.iospagecontentList.items[0].pageContent?.DentalPlans;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent.DentalPlans;

      return false;
    } finally {
      setPageContent(content);
      updateDentalSortOptions(getSortOptionsWithLabels(DENTAL_SORT_OPTIONS, content));
      setContentLoaded(true);
    }
    return true;
  }, [updateDentalSortOptions, zipCodeRes.stateCode]);

  const parseInitialPlans = (plans: Contracts) => {
    const sortedPlans = getSortedPlans(plans, MONTHLY_COST_SORT_OPTION);
    const filteredDetalPlans = showTabs ? getPlansForTab(selectedDentalPlanTab, sortedPlans) : sortedPlans

    updateDentalPlans(sortedPlans);
    setFilteredPlans(filteredDetalPlans)
  };

  useEffect(() => {
    if (!openDepModal.show) {
      setLoading(true)
      loadDentalPlans()
    }
  }, [openDepModal.show])

  const loadDentalPlans = () => {
    if (selectedPlan && isColaradoPlanSelected) {
      setLoading(false);
      updateDentalPlans([])
    } else {
      setServerError(false);
      if (dentalPlans.length === 0) {
        const payload = getDentalPlansPayload(useGlobalStoreObj, selectedPlan.plan);

        fetchPlanList(payload)
          .then((response) => {
            const data: PlanSummaryResponse = response.data;
            const dentalPlans = data.planSummaryResponse.plans.contract;

            setServerError(false);
            parseInitialPlans(dentalPlans);
            setLoading(false);
          })
          .catch((error) => {
            if (error?.response?.data?.error?.errorKey === PLAN_ERROR_CODES.NO_PLANS_FOUND) {
              setServerError(false);
              updateDentalPlans([])
              setLoading(false);
            }
            else {
              setServerError(true);
              setLoading(false);
            }
          });
      } else {
        if (showFavourites) {
          const favouritePlans = getFavouritePlans(getPlansForTab(selectedDentalPlanTab, dentalPlans), favouriteDentalPlans);

          setFilteredPlans(favouritePlans);
        } else {
          parseInitialPlans(dentalPlans);
        }

        setLoading(false);

      }
    }
  };

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    if (comparedDentalPlans.length === 0) {
      document.body.classList.remove("no-scroll");
    }
  }, [comparedDentalPlans]);

  useEffect(() => {
    updateCloseDepModal(basicPlanType, false, false)
    updateBasicPlanType(PLAN_TYPES_NAMES.DENTAL)
    initiate();
    updateComparedDentalPlans([]);
  }, []);

  const navigate = useNavigate();

  useEffect(() => {
    if (closeDepModal.cancel && closeDepModal.planType === PLAN_TYPES_NAMES.DENTAL) {
      setSelectedPlanToUpdate({} as SelectedPlan);
    }
  }, [closeDepModal.cancel])


  useEffect(() => {
    if (closeDepModal.continue && closeDepModal.planType === PLAN_TYPES_NAMES.DENTAL) {
      continueWithSelectedPlan();
      updateCloseDepModal(basicPlanType, false, false)
    }
  }, [closeDepModal.continue])

  useEffect(() => {
    if (noPopupDisplay) {
      continueWithSelectedPlan();
    }
  }, [noPopupDisplay])

  useEffect(() => {
    if (demographicInfo?.savedUserEntity?.length && dentalPlans.length && savedDentists.length === 0) {
      let providersData = [];
      demographicInfo?.savedUserEntity?.map((data) => {
        if (data.entityType === ENTITY_TYPES.PROVIDER && data.entitySubType === PROVIDER_TYPE_IDS.DENTIST) {
          const providerDetail = {
            providerIdentifier: data.entityId,
            addressIdentifier: data.compositeEntityId,
            type: getProviderTypeCode(data.entitySubType)
          };
          providersData.push(providerDetail as never);
        }
      })
      if (providersData.length) {
        const payload = {
          providerDetail: providersData,
          brand: useGlobalStoreObj.brand,
          state: useGlobalStoreObj.zipCodeRes.stateCode,
          latitude: useGlobalStoreObj.zipCodeRes.latitude,
          longitude: useGlobalStoreObj.zipCodeRes.longitude,
          contractCodes: getContractCodes(useGlobalStore.getState().dentalPlans)
        };
        getProviderListById(payload)
          .then((response) => {
            const data = response.data;
            const providerDetails = data.providerList;
            updateSavedDentists(providerDetails);
            providerDetails.length && updateMatchDentistsToPlansStatus(true);
          })
          .catch((error) => {
            console.warn('retrieving the provider details error :', error);
          });
      }
    }
  }, [dentalPlans])

  const handelMacFlowPopups = () => {
    const isHavingVisionPlan = currentCoverages.filter((item) => item.planType === PLAN_TYPES_NAMES.VISION);
    const isDependentAdded = applicantFormDetails.filter((item) => item.applicantType != APPLICANT_TYPES.PRIMARY)
    if (isHavingVisionPlan?.length > 0 && isDependentAdded?.length > 0) {
      updateOpenDepModal(basicPlanType, true, true, MACFLOW_POPUP_TYPE.VISIONWITHDEPENDENTS);
    } else if (isHavingVisionPlan?.length > 0 && isDependentAdded?.length <= 0) {
      updateOpenDepModal(basicPlanType, true, true, MACFLOW_POPUP_TYPE.VISIONWITHOUTDEPENDENTS);
    } else if (isHavingVisionPlan?.length <= 0 && isDependentAdded?.length > 0) {
      updateOpenDepModal(basicPlanType, true, true, MACFLOW_POPUP_TYPE.VISIONWITHOUTPLANWITHDEPENDENTS);
    } else {
      setNoPopupDisplay(true);
    }
  }

  const updateDentalPlanSelection = (contract: Contract, matchedProviders: Provider[], isSelected: boolean) => {
    if (loggedIn && role === USER_ROLES.MEMBER) {
      if (isSelected) {
        const updatedSelectedPlan: SelectedPlan = {
          plan: null,
          applicants: selectedDentalPlan.applicants,
          matchedProviders
        };
        updateSelectedDentalPlan(updatedSelectedPlan);
      } else {
        const updatedSelectedPlan: SelectedPlan = {
          plan: contract,
          applicants: [],
          matchedProviders
        };
        setSelectedPlanToUpdate(updatedSelectedPlan);
        if (contract.onExchange) {
          updateSelectedDentalPlan({
            plan: contract,
            applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(useGlobalStoreObj.applicantFormDetails)),
            matchedProviders: matchedProviders
          });
          resetVisionPlanStates();
          updateSelectedVisionPlan({
            plan: null,
            applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(useGlobalStoreObj.applicantFormDetails)),
            matchedProviders: []
          });
          navigate(NAV_PATHS.VISION_LISTING);
        } else {
          handelMacFlowPopups();
        }
      }
    } else {
      if (isSelected) {
        const updatedSelectedPlan: SelectedPlan = {
          plan: null,
          applicants: selectedDentalPlan.applicants,
          matchedProviders
        };
        updateSelectedDentalPlan(updatedSelectedPlan);
      } else if (!isSelected && useGlobalStoreObj.applicantFormDetails.length === 1) {
        updateSelectedDentalPlan({
          plan: contract,
          applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(useGlobalStoreObj.applicantFormDetails)),
          matchedProviders: matchedProviders
        });
        resetVisionPlanStates();
        updateSelectedVisionPlan({
          plan: null,
          applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(useGlobalStoreObj.applicantFormDetails)),
          matchedProviders: []
        });
        updateShowRecommendationModal(!contract.onExchange);
        contract.onExchange && navigate(NAV_PATHS.VISION_LISTING);
      } else {
        const updatedSelectedPlan: SelectedPlan = {
          plan: contract,
          applicants: [],
          matchedProviders
        };
        setSelectedPlanToUpdate(updatedSelectedPlan);
        if (contract.onExchange) {
          updateSelectedDentalPlan({
            plan: contract,
            applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(useGlobalStoreObj.applicantFormDetails)),
            matchedProviders: matchedProviders
          });
          resetVisionPlanStates();
          updateSelectedVisionPlan({
            plan: null,
            applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(useGlobalStoreObj.applicantFormDetails)),
            matchedProviders: []
          });
          navigate(NAV_PATHS.VISION_LISTING);
        } else {
          updateOpenDepModal(basicPlanType, true, false, MACFLOW_POPUP_TYPE.VISIONDEPENDENTMODAL)
        }
      }
    }
  };

  const continueWithSelectedPlan = () => {
    updateSelectedDentalPlan({
      plan: selectedPlanToUpdate.plan,
      applicants: selectedDentalPlan.applicants,
      matchedProviders: selectedPlanToUpdate.matchedProviders,
    });
    resetVisionPlanStates();
    if (useGlobalStoreObj.applicantFormDetails.length === 1) {
      updateSelectedVisionPlan({
        plan: null,
        applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(useGlobalStoreObj.applicantFormDetails)),
        matchedProviders: []
      });
    } else {
      updateSelectedVisionPlan({
        plan: null,
        applicants: getFormattedApplicantDetails(selectedVisionPlan.applicants),
        matchedProviders: []
      });
    }
    setNoPopupDisplay(false);
    updateShowRecommendationModal(true);
  };

  const openDentalDetailsSlider = (contract: Contract, isCurrentCoveragePlan: boolean) => {
    setContract(contract);
    setIsCurrentCoveragePlan(isCurrentCoveragePlan)
  };

  const handleTabChange = (tab: string) => {
    updateSelectedDentalPlanTab(tab);
    if (useGlobalStore.getState().showFavourites) {
      const favouritePlans = getFavouritePlans(getPlansForTab(tab, useGlobalStore.getState().dentalPlans), favouriteDentalPlans);
      setFilteredPlans(favouritePlans);
    } else {
      setFilteredPlans(getPlansForTab(tab, useGlobalStore.getState().dentalPlans))
    }
  };

  const handleChangeClick = (palnType: string) => {
    switch (palnType) {
      case PLAN_TYPES_NAMES.MEDICAL:
        return navigate('/individuals/shop/basics/medical-plans');
      case PLAN_TYPES_NAMES.VISION:
        if (selectedDentalPlanTab !== MEDICAL_PLANS_TABS.MARKET_PLANS) {
          return updateShowRecommendationModal(true);
        } else {
          updateShowRecommendationModal(false);
          return navigate(NAV_PATHS.VISION_LISTING);
        }
      default:
        break;
    }
  };

  const getFavouritePlan = (favouritePlanContractCode: string) => {
    const updatedFavouritePlans = getUpdatedFavouritePlans(favouritePlanContractCode, favouriteDentalPlans);
    if (updatedFavouritePlans.length > 0) {
      updateFavouriteDentalPlans(updatedFavouritePlans)
      if (showFavourites) {
        const favouritePlans = getFavouritePlans(getPlansForTab(selectedDentalPlanTab, dentalPlans), updatedFavouritePlans);
        setFilteredPlans(favouritePlans);
      }
    }
    else {
      parseInitialPlans(dentalPlans);
      updateShowFavourites(false)
    }
  }

  const hideOrShowFavouritePlans = () => {
    if (!showFavourites) {
      const favouritePlans = getFavouritePlans(getPlansForTab(selectedDentalPlanTab, dentalPlans), favouriteDentalPlans);
      setFilteredPlans(favouritePlans);
    } else {
      parseInitialPlans(dentalPlans);
    }

    updateShowFavourites(!showFavourites);

  }

  const renderFavouriteToggle = () => {
    return (
      <div className="fwc-row">
        <div className="show-fav-all-plans-wrapper">
          {favouriteDentalPlans.length > 0 &&
            <span className="show-fav-plans" onClick={() => hideOrShowFavouritePlans()}>
              <span id="favourite-icon" className={`sae-icon ${!showFavourites ? 'sae-icon-star' : 'sae-icon-tooth'}`} />
              {!showFavourites ? `${pageContent.SHOW_MY} ${favouriteDentalPlans?.length} ${pageContent.FAVOURITE_PLANS}` : `${pageContent.SHOW_ALL} ${dentalPlans.length} ${pageContent.DENTAL_PLANS}`}
            </span>
          }
        </div>
      </div>
    )
  }

  const skipThisStep = () => {
    if (!isSkip) {
      const updateDentalPlan: SelectedPlan = {
        plan: selectedDentalPlan.plan,
        applicants: selectedDentalPlan.applicants,
        matchedProviders: selectedDentalPlan.matchedProviders
      };
      setSelectedPlanToUpdate(updateDentalPlan);
    }
    if (selectedVisionPlan.plan && Object.keys(selectedVisionPlan?.plan).length) {
      updateShowRecommendationModal(selectedDentalPlanTab !== MEDICAL_PLANS_TABS.MARKET_PLANS);
    } else {
      resetVisionPlanStates();
      updateSelectedVisionPlan({
        plan: null,
        applicants: getFormattedApplicantDetails(useGlobalStoreObj.applicantFormDetails),
        matchedProviders: []
      });
      if (selectedPlan.plan && selectedPlan.plan.onExchange) {
        navigate(NAV_PATHS.VISION_LISTING);
      } else {
        if (selectedPlan?.applicants?.length > 1) {
          updateOpenDepModal(basicPlanType, true, false, MACFLOW_POPUP_TYPE.VISIONDEPENDENTMODAL);
        } else {
          updateOpenDepModal(basicPlanType, false, false, MACFLOW_POPUP_TYPE.VISIONDEPENDENTMODAL);
          updateShowRecommendationModal(true);
        }
      }
    }
  }
  const handleDentalBack = () => {
    navigate(previousStep);
  }
  const handleCompareTraySelect = (contract) => {
    if (contract.contractCode === selectedDentalPlan?.plan?.contractCode) {
      const updatedSelectedPlan: SelectedPlan = {
        plan: null,
        applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(useGlobalStoreObj.applicantFormDetails)),
        matchedProviders: getMatchedDoctorsForPlan(matchDentistsToPlans, savedDentists, contract.contractCode)
      };
      updateSelectedDentalPlan(updatedSelectedPlan);
      window.scrollTo(0, 0);
    } else {
      updateSelectedDentalPlan({
        plan: contract,
        applicants: getFormattedApplicantDetails(getNotMedicaidEligibleDependents(useGlobalStoreObj.applicantFormDetails)),
        matchedProviders: getMatchedDoctorsForPlan(matchDentistsToPlans, savedDentists, contract.contractCode),
      });
      window.scrollTo(0, 0);
    }
  };
  const renderSubText = () => {
    return (
      <>
        {(role === USER_ROLES.MEMBER) &&
          <div className="important-label-container">
            <span id="dental-plans-coverage-help-icon" className={'sae-icon sae-icon-info info-icon'} />
            <p id="dental-plans-coverage-important-text" className="btm-sm">
              {pageContent.MAC_NOTICE}
            </p>
          </div>
        }
        <div className="important-label-container">
          <span id="dental-plans-coverage-help-icon" className={'sae-icon sae-icon-info info-icon'} />
          <p id="dental-plans-coverage-important-text" className="btm-sm">
            {pageContent.SEE + ' '}
            <a data-analytics="importantNoticeDentalPlansIos" href={'javascript:void(0)'} onClick={() => {
              importantNotice.current && importantNotice.current.scrollIntoView({ behavior: 'smooth' })
            }} >{pageContent.IMPORTANT}</a>
            {' ' + pageContent.NOTICE_ABOUT}
          </p>
        </div>
      </>
    );
  };

  const getCurrentCoverage = () => {
    if (loggedIn && role === USER_ROLES.MEMBER && currentCoverageDentalPlan.contractCode) {
      return <PlanCard
        key={currentCoverageDentalPlan.contractCode}
        labels={getDentalPlanCardLabels(pageContent?.PLAN_CARD_LABELS)}
        contract={currentCoverageDentalPlan}
        selectedPlan={selectedDentalPlan}
        matchedProviders={[]}
        matchedMedicationsFlag={false}
        matchedProvidersFlag={false}
        isFavouritePlan={favouriteDentalPlans.includes(currentCoverageDentalPlan.contractCode)}
        onPlanNameClick={(clickedPlan: Contract) => openDentalDetailsSlider(clickedPlan, true)}
        onSelectClick={() => { }}
        onFavouriteClick={(favouritePlanContractCode) => getFavouritePlan(favouritePlanContractCode)}
        planType={'dental'}
        isCurrentCoveragePlan={true}
      />
    }

    return <div />
  }

  if (!contentLoaded) {
    return <FullPageLoader />;
  } else {
    return (
      <div className="dental-plans-container">
        <div className={'fwc-row'}>
          <a data-analytics="backHeaderIos" className='global-back-link' id={'dental-back'} href={'javascript:void(0)'} onClick={() => handleDentalBack()}>
            Back
          </a>
        </div>
        {loading && <FullPageLoader />}
        {showFindYourDentist && <FindYourDentist showFindYourDentist={showFindYourDentist}
          contractCodes={getContractCodes(dentalPlans)}
          closeFindYourDentist={() => setShowFindYourDentist(false)} />}
        {contract && (
          <DentalPlanDetails
            closeDentalPlanDetails={() => setContract(null)}
            contract={contract}
            isCurrentCoveragePlan={isCurrentCoveragePlan}
          />
        )}
        <GlobalHeader />
        <div className="medical-plans-body">
          <SelectedPlans
            plans={getSelectedPlans(selectedPlan, selectedDentalPlan, selectedVisionPlan)}
            handleChangeClick={(planType) => handleChangeClick(planType)}
            screenPlanType={PLAN_TYPES_NAMES.DENTAL}
          />
          <div className="fwc-row j-between" data-testid='title-id'>
            <PlanListingTitles title={pageContent?.VIEW_AND_SELECT_DENTAL_PLANS} description={''} renderSubText={() => renderSubText()} />
            {PLAN_TYPES.DENTAL !== coverageType &&
              <button data-analytics={`${isSkip ? "skipStepBtnDentalPlansIos" : "continueBtnDentalPlansIos"}`} className={"fwc-btn fwc-btn-secondary small"} onClick={() => skipThisStep()}>{isSkip ? pageContent?.SELECTED_PLAN_CARD_LABELS.SKIP : pageContent?.SELECTED_PLAN_CARD_LABELS.CONTINUE}</button>
            }
            {(getSelectedPlans(selectedPlan, selectedDentalPlan, selectedVisionPlan).length > 0) && (PLAN_TYPES.DENTAL === coverageType) && <button data-analytics="continueBtnDentalPlansIos" className={"fwc-btn fwc-btn-secondary small"} onClick={() => skipThisStep()}>{pageContent?.SELECTED_PLAN_CARD_LABELS.CONTINUE}</button>
            }
          </div>
          <div className="fwc-row">
            <PlanCoverageAccordion planType={PLAN_TYPES_NAMES.DENTAL} labels={pageContent?.ACCORDION_LABELS} colaradoPlans={false} />
          </div>
          {showTabs && <div className="fwc-row">
            <div className="tabs-container">
              <Tabs value={selectedDentalPlanTab || MEDICAL_PLANS_TABS.OFF_MARKET_PLANS} onChange={handleTabChange}>
                <Tabs.Tab
                  id="dental-plans-market-plans-tab-off-market-plans"
                  value={MEDICAL_PLANS_TABS.OFF_MARKET_PLANS}
                  label={pageContent?.OFF_MARKET_PLANS}
                />
                <Tabs.Tab
                  id="dental-plans-off-market-plans-tab-market-plans"
                  value={MEDICAL_PLANS_TABS.MARKET_PLANS}
                  label={pageContent?.MARKET_PLANS}
                />
              </Tabs>
            </div>
          </div>
          }
          {!loading && (filteredPlans.length === 0 || dentalPlans.length == 0) ?
            <>
              <div className="filters-list-outer-wrapper">
                {renderFavouriteToggle()}
                <EmptyPlans useCase={getEmptyPlansUseCase(serverError, dentalPlans)} labels={dentalPlans.length == 0 ? pageContent?.DENTAL_EMPTY_PLANS : pageContent?.EMPTY_PLANS} />
              </div>
            </>
            : (
              <div>
                <div className="fwc-row">
                  <div className="utilities-container">
                    <a data-analytics="showDentistDentalPlansIos" href={'javascript:void(0)'} id="dental-plans-show-my-doctor" className="utility-option" data-testid='show-my-dentist-button' onClick={() => setShowFindYourDentist(true)}>
                      <span id="plan-coverage-help" className={'sae-icon sae-icon-tooth utility-icon'} />
                      <span>{pageContent?.SHOW_MY_DOCTOR}</span>
                    </a>
                  </div>
                </div>
                <div className="filters-list-outer-wrapper">
                  <div className="fwc-row label-sort-wrapper">
                    {showFavourites && filteredPlans.length > 0 ?
                      <span className="view-count-text">
                        <>{pageContent.YOU_ARE_VIEWING} {pageContent.YOUR} <b>{favouriteDentalPlans.length} {pageContent.FAVOURITE} {pageContent.DENTAL_PLANS}</b></>
                      </span>
                      :
                      <span className="btm-sm">
                        {pageContent?.YOU_ARE_VIEWING + ' '}
                        <span className="dental-plans-label">
                          {filteredPlans.length} {pageContent?.DENTAL_PLANS}
                        </span>
                      </span>
                    }
                    <div>
                      {renderFavouriteToggle()}
                    </div>
                  </div>
                  <div className="fwc-row">
                    <div className="plan-list-container">
                      {getCurrentCoverage()}
                      {filteredPlans.map((contract: Contract) => {
                        return (
                          <PlanCard
                            key={contract.contractCode}
                            labels={getDentalPlanCardLabels(pageContent?.PLAN_CARD_LABELS)}
                            contract={contract}
                            selectedPlan={selectedDentalPlan}
                            matchedProviders={getMatchedDoctorsForPlan(matchDentistsToPlans, savedDentists, contract.contractCode)}
                            matchedMedicationsFlag={false}
                            matchedProvidersFlag={matchDentistsToPlans}
                            isFavouritePlan={favouriteDentalPlans.includes(contract.contractCode)}
                            onPlanNameClick={(clickedPlan: Contract) => openDentalDetailsSlider(clickedPlan, false)}
                            onSelectClick={(contract, matchedProviders, matchedMedications, isSelected) => {
                              updateDentalPlanSelection(contract, matchedProviders, isSelected);
                            }}
                            onFavouriteClick={(favouritePlanContractCode) => getFavouritePlan(favouritePlanContractCode)}
                            planType={'dental'}
                          />
                        );
                      })}
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="footer-wrapper">
            <div className="fwc-row footer-buttons-container" ref={importantNotice}>
              <button
                id="medical-plans-back-to-estimate"
                data-analytics="backBtnDentalPlansIos"
                className="footer-button fwc-btn fwc-btn-secondary"
                onClick={() => {
                  handleDentalBack();
                }}
              >
                {pageContent?.BACK}
              </button>
              {PLAN_TYPES.DENTAL !== coverageType &&
                <button data-analytics={`${isSkip ? "skipStepBtnDentalPlansIos" : "continueBtnDentalPlansIos"}`} id="dental-skip-step" className={"fwc-btn fwc-btn-secondary small"} onClick={() => skipThisStep()}>{isSkip ? pageContent?.SELECTED_PLAN_CARD_LABELS.SKIP : pageContent?.SELECTED_PLAN_CARD_LABELS.CONTINUE}</button>
              }
              {(getSelectedPlans(selectedPlan, selectedDentalPlan, selectedVisionPlan).length > 0) && (PLAN_TYPES.DENTAL === coverageType) && <button data-analytics="continueBtnDentalPlansIos" className={"fwc-btn fwc-btn-secondary small"} onClick={() => skipThisStep()}>{pageContent?.SELECTED_PLAN_CARD_LABELS.CONTINUE}</button>
              }

              {
                PLAN_TYPES.DENTAL === coverageType && <button
                  id="dental-plans-go-to-top"
                  className="footer-button fwc-btn fwc-btn-secondary"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {pageContent?.GO_TO_TOP}
                </button>
              }
            </div>
            <div className="fwc-row">
              <div className="important-message">
                <p>
                  <b>{pageContent?.IMPORTANT}</b>
                </p>
                <p dangerouslySetInnerHTML={{ __html: pageContent?.IMPORTNAT_NOTICE_PARA_ONE }}></p>
                <p dangerouslySetInnerHTML={{ __html: pageContent?.IMPORTNAT_NOTICE_PARA_TWO }}></p>
                <p dangerouslySetInnerHTML={{ __html: pageContent?.IMPORTNAT_NOTICE_PARA_THREE }}></p>
              </div>
            </div>
          </div>
        </div >
        {
          providerMetaData && (
            <ProviderDetails
              useCase={PLAN_TYPES_NAMES.DENTAL}
            />
          )
        }
        {comparedDentalPlans.length > 0 &&
          <CompareTray planType={'dental'}
            plans={comparedDentalPlans}
            matchedProvidersFlag={matchDentistsToPlans}
            handleSelectPlan={handleCompareTraySelect}
            labels={getDentalPlanCardLabels(pageContent?.PLAN_CARD_LABELS)}
            onFavouriteClick={getFavouritePlan}
          ></CompareTray>}
      </div >
    );
  }
};

export default DentalPlans;
