import { Modal } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../../api/cms/cmsUtil';
import { HTTP_STATUS_CODES } from '../../../../api/constants';
import DateInput from '../../../../components/common/date-input/DateInput';
import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import { formatPhoneNumber } from '../../../../components/common/global-header/contact-us/contactUsUtils';
import InputSelect from '../../../../components/common/input-select-combo/InputSelect';
import SuccessModalBody from '../../../../components/common/success-modal-body/SuccessModalBody';
import { LANGUAGE_CODES, REMEMBER_MY_DEVICE_NO, REMEMBER_MY_DEVICE_YES, SCREEN_NAMES } from '../../../../shared/globalConstants';
import { useGlobalStore } from '../../../../store/globalStore';
import { useUserStore } from '../../../../store/userStore';
import { validateDob, validateEmail, validateFirstName, validateLastName } from '../../../public/account-recovery/AccountRecoveryUtils';
import { unFormatePhoneNumber } from '../../../public/registration/personal-info/personalInfoUtils';
import './UpdatePersonalInformation.scss';
import { INPUT_TYPES, REQUIRED_FIELD, UPDATE_SHOPPER_PERSONAL_INFORMATION_RESPONSE_CODES } from './constants';
import { updateShopperProfileInformation } from './updatePersonalInformationServices';
import { getUpdateProfileInformationPayload, validatePhoneNumber } from './updatePersonalInformationServicesUtils';
import { default as Content } from './updatepersonalinfo.json';


const UpdatePersonalInformation = ({
  userName,
  userFirstName,
  userLastName,
  userDateOfBirth,
  userEmailAddress,
  userPhoneNumber,
  userPhoneNumberType,
  userSMSConsent,
  webAccountGUID,
  shopperId,
  role
}: {
  userName: string;
  userFirstName: string;
  userLastName: string;
  userDateOfBirth: string;
  userEmailAddress: string;
  userPhoneNumber: string;
  userPhoneNumberType: string;
  userSMSConsent: string;
  webAccountGUID: string;
  shopperId: string;
  role: string;
}) => {
  const [pageContent, setPageContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const { brand, zipCodeRes } = useGlobalStore((store) => store);
  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.UPDATE_PERSONAL_INFO);
      content = response.data.data.iospagecontentList.items[0].pageContent?.UpdatePersonalInfo;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent.UpdatePersonalInfo;
      return false;
    } finally {
      setPageContent(content);
      setContentLoaded(true);
    }
    return true;
  }, []);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    initiate();
  }, []);
  const [showSuccessModel, setShowSuccessModel] = useState(false);
  const [loading, setLoading] = useState(false);
  const [firstName, setFirstName] = useState(userFirstName);
  const [lastName, setLastName] = useState(userLastName);
  const [dateOfBirth, setDateOfBirth] = useState(userDateOfBirth);
  const [emailAddress, setEmailAddress] = useState(userEmailAddress);
  const [phoneNumberType, setPhoneNumberType] = useState(userPhoneNumberType);
  const [phoneNumber, setPhoneNumber] = useState(userPhoneNumber);
  const [emailConsent, setEmailConsent] = useState(userSMSConsent);
  const [firstNameError, setFirstNameError] = useState('');
  const [lastNameError, setLastNameError] = useState('');
  const [dobError, setDOBError] = useState('');
  const [revertedDob, setRevertedDob] = useState('');
  const [emailError, setEmailError] = useState({
    title: '',
    label1: '',
    label2: '',
    label3: ''
  });
  const [phoneError, setPhoneError] = useState('');
  const { updateProfileInformation } = useUserStore((store) => store);
  const [updateShopperProfileInfoError, setUpdateShopperProfileInfoError] = useState({
    hasError: false,
    errorStatus: '',
    message: ''
  });
  useEffect(() => {
    setRevertedDob('');
  }, [dateOfBirth])

  const handleFirstNameChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setFirstNameError('');
    setFirstName(e.target.value);
  };

  const handleLastNameChange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setLastNameError('');
    setLastName(e.target.value);
  };

  const handleEmailAddresschange = (e: { target: { value: React.SetStateAction<string> } }) => {
    setEmailError({
      title: '',
      label1: '',
      label2: '',
      label3: ''
    });
    setEmailAddress(e.target.value);
  };

  const checkDob = (date: any, isNextClicked = false) => {
    handleDateOfBirthChange(date?.target?.value ? date?.target?.value : dateOfBirth);
    const { isValidDob, dobErrorMessage } = validateDob(date?.target?.value ? date?.target?.value : dateOfBirth, isNextClicked);

    if (!isValidDob) {
      setDOBError(dobErrorMessage);
    } else {
      setDOBError('');
    }

    return isValidDob;
  };

  const handleDateOfBirthChange = (dob) => {
    setDOBError('');
    if (dob) {
      //const updatedDobValue = formatDateToMMDDYYY(dob);
      const updatedDobValue = dayjs(dob).format('YYYY-MM-DD');
      setDateOfBirth(updatedDobValue);
    } else {
      setDateOfBirth(REQUIRED_FIELD);
    }
  };

  const onNumberTypeChange = (e: any) => {
    setPhoneError('');
    setPhoneNumberType(e.target.value);
  };
  const onNumberInputChange = (e: any) => {
    setPhoneError('');
    const formatedNumber = formatPhoneNumber(e.target.value);
    setPhoneNumber(formatedNumber);
  };

  const handleEmailConsentChange = () => {
    const updatedEmailConsent = emailConsent === REMEMBER_MY_DEVICE_NO ? REMEMBER_MY_DEVICE_YES : REMEMBER_MY_DEVICE_NO;
    setEmailConsent(updatedEmailConsent);
  };

  const resetFieldErrors = () => {
    setFirstNameError('');
    setLastNameError('');
    setEmailError({
      title: '',
      label1: '',
      label2: '',
      label3: ''
    });
    setDOBError('');
    setPhoneError('');
  };

  const checkFirstName = () => {
    const { isValidFirstName, firstNameErrorMessage } = validateFirstName(firstName);

    if (!isValidFirstName) {
      setFirstNameError(firstNameErrorMessage);
    } else {
      setFirstNameError('');
    }

    return isValidFirstName;
  };


  const checkLastName = () => {
    const { isValidLastName, lastNameErrorMessage } = validateLastName(lastName);

    if (!isValidLastName) {
      setLastNameError(lastNameErrorMessage);
    } else {
      setLastNameError('');
    }

    return isValidLastName;
  };

  const checkEmail = () => {
    setEmailAddress(emailAddress.trim());
    const { isValidEmail, emailErrorObj } = validateEmail(emailAddress.trim());

    if (!isValidEmail) {
      setEmailError(emailErrorObj);
    } else {
      setEmailError({
        title: '',
        label1: '',
        label2: '',
        label3: ''
      });
    }

    return isValidEmail;
  };

  const checkPhoneNumber = () => {
    const { isValidPhoneNumber, phoneNumberErrorMessage } = validatePhoneNumber(phoneNumber, phoneNumberType, pageContent);

    if (!isValidPhoneNumber) {
      setPhoneError(phoneNumberErrorMessage);
    } else {
      setPhoneError('');
    }

    return isValidPhoneNumber;
  };



  const onClickOfCancelOption = (e) => {
    e.preventDefault();
    setFirstName(userFirstName);
    setLastName(userLastName);
    setDateOfBirth(userDateOfBirth);
    setRevertedDob(userDateOfBirth);
    setEmailAddress(userEmailAddress.trim());
    setPhoneNumber(userPhoneNumber);
    setPhoneNumberType(userPhoneNumberType);
    setEmailConsent(userSMSConsent);
  };

  const onClickSaveOption = (e) => {
    e.preventDefault();
    //setContinueClickToggle(true);
    const isValidFirstName = checkFirstName();
    const isValidLastName = checkLastName();
    const isValidEmail = checkEmail();
    const isValidDob = checkDob(dateOfBirth);
    const isValidPhoneNumber = checkPhoneNumber();
    if (isValidFirstName && isValidLastName && isValidEmail && isValidDob && isValidPhoneNumber) {
      resetFieldErrors();


      updateProfileInformation({
        userFirstName: firstName,
        userLastName: lastName,
        userEmailAddress: emailAddress,
        userPhoneNumber: unFormatePhoneNumber(phoneNumber),
        userPhoneNumberType: phoneNumberType,
        userSMSConsent: emailConsent,
        userDateOfBirth: dayjs(dateOfBirth).format('YYYY-MM-DD'),
      });

      const payload: UpdatePersonalInformationPayload = getUpdateProfileInformationPayload(
        userName,
        webAccountGUID,
        shopperId,
        role,
        firstName.trim(),
        lastName.trim(),
        dayjs(dateOfBirth).format('YYYY-MM-DD'),
        emailAddress.trim(),
        emailConsent,
        phoneNumber,
        phoneNumberType
      );

      updateShopperProfileInformation(payload)
        .then((response) => {
          const data: UpdateShopperProfileInformationResponse = response.data;

          if (
            response.status === HTTP_STATUS_CODES.SUCCESS &&
            data.responseMessage.responseCode === UPDATE_SHOPPER_PERSONAL_INFORMATION_RESPONSE_CODES.RESPONSE_CODE
          ) {
            //alert('Profile Information Saved');
            setShowSuccessModel(true);

          } else {
            setUpdateShopperProfileInfoError({
              hasError: true,
              errorStatus: data.status,
              message: data.responseMessage.message
            });
          }
        })
        .catch((error: AxiosError<RegisterShopperErrorResponse>) => {
          console.warn('Register Shopper Error response :', error);
          if (isAxiosError(error)) {
            const errorObj = error.response?.data?.error;

            setUpdateShopperProfileInfoError({
              hasError: true,
              errorStatus: errorObj?.errorKey ? errorObj?.errorKey : '',
              message: 'Profile Information Save Failed'
            });
          } else {
            setUpdateShopperProfileInfoError({
              hasError: true,
              errorStatus: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
              message: 'Sorry, Something went wrong!'
            });
          }
          setLoading(false);
        });

    }

  };

  function closeModal() {

    setShowSuccessModel(false);

  }

  return (!contentLoaded ? <FullPageLoader /> :
    <div className={'personal-container'}>
      <div className={'tab-title-container'}>
        <h3 id="tab-title">
          <span className="sae-icon sae-icon-contacts right-xxs"></span>
          {pageContent?.UPDATE_PROFILE}
        </h3>
      </div>
      <hr />
      <div>
        <div className="fwc-row fwc-row-bm">
          <div className="fwc-col-6">
            <div className="fwc-input">
              <label id="update-personal-info-first-name-label" className="fwc-label" htmlFor={'update-personal-info-first-name-input'}>
                {pageContent?.FIRST_NAME}
              </label>
              <input value={firstName} onChange={handleFirstNameChange} onBlur={checkFirstName} />
              {firstNameError.length > 0 && (
                <div className="fwc-row fwc-row-bm fwc-col-12">
                  <div className="fwc-input">
                    <span id="update-personal-info-firstname-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                      {' '}
                      {firstNameError}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="fwc-col-6 fwc-row-bm">
            <div className="fwc-input">
              <label id="update-personal-info-last-name-label" className="fwc-label" htmlFor={'update-personal-info-last-name-input'}>
                {pageContent?.LAST_NAME}
              </label>
              <input value={lastName} onChange={handleLastNameChange} onBlur={checkLastName} />
              {lastNameError.length > 0 && (
                <div className="fwc-row fwc-row-bm fwc-col-12">
                  <div className="fwc-input">
                    <span id="update-personal-info-lastname-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                      {' '}
                      {lastNameError}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="fwc-row fwc-row-bm">
          <div className="fwc-col-6 fwc-input">
            <DateInput
              className="update-profile-date-picker"
              id="update-personal-info-date-birth-input"
              label={pageContent?.DATE_OF_BIRTH}
              defaultDate={dayjs(dateOfBirth).format('YYYY-MM-DD')}
              name={INPUT_TYPES.DOB}
              onBlur={checkDob}
              maxDate={dayjs().format('YYYY-MM-DD')}
              updateValue={revertedDob}
            />
            {dobError && (
              <span id="update-profile-dob-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                {dobError}
              </span>
            )}
          </div>

          <div className="fwc-col-6 sm-12">
            <div className="fwc-input">
              <label id="update-personal-info-last-name-label" className="fwc-label" htmlFor={'update-personal-info-last-name-input'}>
                {pageContent?.EMAIL_ADDRESS}
              </label>
              <input type="text"
                id={'update-personal-info-email-input'}
                value={emailAddress}
                onChange={(e) => setEmailAddress(e.target.value)}
                onBlur={checkEmail} />
              {emailError.title && (
                <div>
                  <span id="update-profile-email-error" role="alert" className="fwc-inline-icon fwc-icon-delete padding-top">
                    {emailError.title}
                  </span>
                  <ul>
                    {emailError.label1 && <li className="error-message">{emailError.label1}</li>}
                    {emailError.label2 && <li className="error-message">{emailError.label2}</li>}
                    {emailError.label3 && <li className="error-message">{emailError.label3}</li>}
                  </ul>
                </div>
              )}
            </div>
          </div>
        </div>

        <div className="fwc-row fwc-row-bm">
          <div className="fwc-col-6 sm-12">
            <div className="fwc-input">
              <InputSelect
                label={pageContent?.PHONE_NUMBER}
                inputId={'update-personal-info-phone-number'}
                inputValue={phoneNumber}
                inputName={phoneNumber}
                onSelectChange={onNumberTypeChange}
                onInputChange={onNumberInputChange}
                onInputBlur={checkPhoneNumber}
                options={pageContent?.MOBILE_INPUT_OPTIONS}
                selectId={'update-personal-info-phone-number-select'}
                selectName={'Test'}
                selectValue={phoneNumberType}
              ></InputSelect>
              {phoneError && (
                <div className="fwc-row fwc-row-bm fwc-col-12">
                  <div className="fwc-input">
                    <span id="update-personal-info-phone-error" role="alert" className="fwc-inline-icon fwc-icon-delete">
                      {phoneError}
                    </span>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div className="fwc-col-6 sm-12">
            <label id="update-personal-info-last-name-label" className="fwc-label" htmlFor={'update-personal-info-last-name-input'}>
              {pageContent?.PERMISSION_TO_SMS}
            </label>
            <div className="fwc-toggle ">
              <input
                type="checkbox"
                name="sms-consent"
                checked={emailConsent === REMEMBER_MY_DEVICE_YES}
                id="update-sms-consent"
                onClick={() => handleEmailConsentChange()}
                onChange={() => { }}
              />
              <label className="fwc-label" htmlFor="update-sms-consent"></label>
            </div>
          </div>
        </div>
        <div className={'fwc-row fwc-row-bm'}>
          <div className={'fwc-col-6 sm-12'}>
            <label className={'fwc-label'}>{pageContent?.USER_NAME}</label>
            <p>{userName}</p>
          </div>
        </div>
        <div className={'fwc-flex j-center btm-md top-sm'}>
          <button className={'fwc-btn fwc-btn-secondary'} id="update-pi-cancel" onClick={(e) => onClickOfCancelOption(e)}>
            {pageContent?.CANCEL_BUTTON}
          </button>
          <button className={'fwc-btn- fwc-btn-primary'} id="update-pi-save" onClick={(e) => onClickSaveOption(e)}>
            {pageContent?.SUBMIT_BUTTON}
          </button>

        </div>
      </div>
      <Modal open={showSuccessModel}>
        <Modal.Body>
          {showSuccessModel && <SuccessModalBody
            heading={pageContent?.PI_SUCCESS_MODAL.HEADING}
            description={pageContent?.PI_SUCCESS_MODAL.DESC}
            closeButtonText={pageContent?.PI_SUCCESS_MODAL.CB_TEXT}
            closeModal={() => {
              closeModal();
            }}
          />
          }
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default UpdatePersonalInformation;
