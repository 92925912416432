import { Button } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import CompareTray from "../../../components/common/compare-tray/CompareTray";
import EmptyPlans from '../../../components/common/empty-plans/EmptyPlans';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import GlobalHeader from '../../../components/common/global-header/GlobalHeader';
import PlanCard from '../../../components/common/plan-card/PlanCard';
import PlanCoverageAccordion from '../../../components/common/plan-coverage-accordion/PlanCoverageAccordion';
import PlanListingTitles from '../../../components/common/plan-listing-titles/PlanListingTitles';
import { getProviderTypeCode } from '../../../components/common/public-nav-bar/SaveAndResumeLater.utils';
import SelectedPlans from '../../../components/common/selected-plans/SelectedPlans';
import { ENTITY_TYPES, LANGUAGE_CODES, NAV_PATHS, PLAN_TYPES, PLAN_TYPES_NAMES, SCREEN_NAMES, USER_ROLES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { getSelectedPlans } from '../dental-plans/dentalPlansUtils';
import { PROVIDER_TYPE_IDS } from '../find-your-doctor/constants';
import FindYourEyeDoctor from '../find-your-eye-doctor/findYourEyeDoctor';
import { MONTHLY_COST_SORT_OPTION } from '../medical-plans/constants';
import { fetchPlanList, getProviderListById } from '../medical-plans/medicalPlansServices';
import {
  getContractCodes,
  getEmptyPlansUseCase,
  getFavouritePlans,
  getFormattedApplicantDetails,
  getMatchedDoctorsForPlan,
  getSortedPlans,
  getUpdatedFavouritePlans
} from '../medical-plans/medicalPlansUtils';
import ProviderDetails from '../provider-details/ProviderDetails';
import VisionPlanDetails from '../vision-plan-details/VisionPlanDetails';
import './VisionPlans.scss';
import { PLAN_ERROR_CODES } from './constants';
import { default as Content } from './visionPlans.json';
import { checkForOnExchange, getStandAloneVisionPlans, getVisionPlanCardLabels, getVisionPlansPayload, getselectedVisionPlan, updateVisionPlansWithRecommendation } from './visionPlansUtils';

const VisionPlans = () => {

  const [loading, setLoading] = useState(false);
  const [pageContent, setContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [showFindYourEyeDoctor, setShowFindYourEyeDoctor] = useState(false);
  const [isCurrentCoveragePlan, setIsCurrentCoveragePlan] = useState(false);
  const [contract, setContract] = useState<Contract | null>(null);
  const [serverError, setServerError] = useState(false);
  const [filteredPlans, setFilteredPlans] = useState<Contracts>([]);
  const [isSkip, setIsSkip] = useState(false);


  const importantNotice = useRef<HTMLDivElement>(null);

  const { role, loggedIn, demographicInfo } = useUserStore((store) => store)
  const useGlobalStoreObj = useGlobalStore((state) => state);

  const {
    zipCodeRes,
    visionPlans,
    comparedVisionPlans,
    matchEyeDoctorsToPlans,
    savedEyeDoctors,
    selectedPlan,
    selectedDentalPlan,
    selectedVisionPlan,
    visionSortOptions,
    providerMetaData,
    favouriteVisionPlans,
    showVisionFavourites,
    memberInfo,
    basicPlanType,
    coverageType,
    currentCoverageVisionPlan,
    openDepModal,
    visionRecommendations,
    updateVisionPlans,
    updateComparedVisionPlans,
    updateSelectedVisionPlan,
    updateBasicPlanType,
    updateCloseDepModal,
    updateFavouriteVisionPlans,
    updateShowVisionFavourites,
    updateVisionRecommendations,
    previousStep,
    updateSavedEyeDoctors,
    updateMatchEyeDoctorsToPlansStatus,
    selectedTab
  } = useGlobalStoreObj;

  useEffect(() => {
    if (selectedVisionPlan.plan !== null && selectedVisionPlan.plan !== undefined) {
      setIsSkip(false);
    } else {
      setIsSkip(true);
    }
  }, [selectedVisionPlan])

  const { currentCoverages } = memberInfo

  const navigate = useNavigate();

  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.VISION_PLANS);
      let cmsResponse = response.data;

      setContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.VisionPlans);

    } catch (error) {
      setContent(Content.data.iospagecontentList.items[0].pageContent.VisionPlans);

      return false;
    } finally {
      setContentLoaded(true);
    }
    return true;
  }, [zipCodeRes.stateCode]);

  const parseInitialPlans = (plans: Contracts) => {
    let visionPlans: Contracts = plans;
    if (coverageType === PLAN_TYPES.VISION) {
      visionPlans = getStandAloneVisionPlans(plans);
    }
    const { updatedVisionPlans, originalVisionPlans } = updateVisionPlansWithRecommendation(visionPlans, visionRecommendations.recommendedPlanContractCodes);
    updateVisionRecommendations(visionRecommendations.recommendedPlanContractCodes, originalVisionPlans);
    const sortedPlans = getSortedPlans(updatedVisionPlans, MONTHLY_COST_SORT_OPTION);
    updateVisionPlans(sortedPlans);
    setFilteredPlans(sortedPlans)
  };

  useEffect(() => {
    if (!openDepModal.show) {
      setLoading(true)
      loadVisionPlans()
    }
  }, [openDepModal.show])


  useEffect(() => {
    if (demographicInfo?.savedUserEntity?.length && visionPlans.length && savedEyeDoctors.length === 0) {
      let providersData = [];
      demographicInfo?.savedUserEntity?.map((data) => {
        if (data.entityType === ENTITY_TYPES.PROVIDER && data.entitySubType === PROVIDER_TYPE_IDS.VISION) {
          const providerDetail = {
            providerIdentifier: data.entityId,
            addressIdentifier: data.compositeEntityId,
            type: getProviderTypeCode(data.entitySubType)
          };
          providersData.push(providerDetail as never);
        }
      })
      if (providersData.length) {
        const payload = {
          providerDetail: providersData,
          brand: useGlobalStoreObj.brand,
          state: useGlobalStoreObj.zipCodeRes.stateCode,
          latitude: useGlobalStoreObj.zipCodeRes.latitude,
          longitude: useGlobalStoreObj.zipCodeRes.longitude,
          contractCodes: getContractCodes(useGlobalStore.getState().visionPlans)
        };
        getProviderListById(payload)
          .then((response) => {
            const data = response.data;
            const providerDetails = data.providerList;
            updateSavedEyeDoctors(providerDetails);
            providerDetails.length && updateMatchEyeDoctorsToPlansStatus(true);
          })
          .catch((error) => {
            console.warn('retrieving the Eye doctor details error :', error);
          });
      }
    }
  }, [visionPlans])

  const loadVisionPlans = () => {
    setServerError(false);
    if (selectedPlan && selectedPlan.plan?.poPlan) {
      setLoading(false);
      updateVisionPlans([]);
    } else {
      setServerError(false);
      if (visionPlans.length === 0) {
        const payload = getVisionPlansPayload(useGlobalStoreObj, selectedDentalPlan.plan, selectedPlan.plan);
        fetchPlanList(payload)
          .then((response) => {
            const data: PlanSummaryResponse = response.data;
            const visionPlans = data.planSummaryResponse.plans.contract;
            setServerError(false);
            parseInitialPlans(visionPlans);
            setLoading(false);
          })
          .catch((error) => {
            if (error?.response?.data?.error?.errorKey === PLAN_ERROR_CODES.NO_PLANS_FOUND) {
              setServerError(false);
              updateVisionPlans([])
              setLoading(false);
            }
            else {
              setServerError(true);
              setLoading(false);
            }
          });
      } else {
        setLoading(false);
        if (showVisionFavourites) {
          const favouritePlans = getFavouritePlans(visionPlans, favouriteVisionPlans);
          setFilteredPlans(favouritePlans);
        } else {
          parseInitialPlans(visionPlans);
        }

      }
    }
  };

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    if (comparedVisionPlans.length === 0) {
      document.body.classList.remove("no-scroll");
    }
  }, [comparedVisionPlans]);

  useEffect(() => {
    updateCloseDepModal(basicPlanType, false, false)
    updateBasicPlanType(PLAN_TYPES_NAMES.VISION)
    initiate();

    updateComparedVisionPlans([]);
  }, []);


  const onSortValueChange = (e: React.ChangeEvent<HTMLSelectElement>) => { };

  const handleChangeClick = (palnType: string) => {
    switch (palnType) {
      case PLAN_TYPES_NAMES.MEDICAL:
        return navigate('/individuals/shop/basics/medical-plans');
      case PLAN_TYPES_NAMES.DENTAL:
        return navigate('/individuals/shop/basics/dental-plans');
      default:
        break;
    }
  };

  const updateVisionPlanSelection = (contract: Contract, matchedProviders: Provider[], isSelected: boolean) => {
    const updatedSelectedPlan: SelectedPlan = {
      plan: isSelected ? null : getselectedVisionPlan(contract, visionRecommendations),
      applicants: getFormattedApplicantDetails(selectedVisionPlan.applicants),
      matchedProviders: isSelected ? [] : matchedProviders
    };

    updateSelectedVisionPlan(updatedSelectedPlan);

    if (!isSelected) {
      navigate(NAV_PATHS.PLAN_SUMMARY);
    }
  };
  const handleCompareTraySelect = (contract) => {
    if (contract.contractCode === selectedVisionPlan?.plan?.contractCode) {
      const updatedSelectedPlan: SelectedPlan = {
        plan: null,
        applicants: getFormattedApplicantDetails(selectedVisionPlan.applicants),
        matchedProviders: getMatchedDoctorsForPlan(matchEyeDoctorsToPlans, savedEyeDoctors, contract.contractCode)
      };
      updateSelectedVisionPlan(updatedSelectedPlan);
      window.scrollTo(0, 0);
    } else {
      updateSelectedVisionPlan({
        plan: contract,
        applicants: getFormattedApplicantDetails(useGlobalStoreObj.applicantFormDetails),
        matchedProviders: getMatchedDoctorsForPlan(matchEyeDoctorsToPlans, savedEyeDoctors, contract.contractCode)
      });
      window.scrollTo(0, 0);
    }
  };
  const handleVisionBack = () => {
    navigate(previousStep);
  }
  const renderSubText = () => {
    return (
      <div className="important-label-container">
        <span id="dental-plans-coverage-help-icon" className={'sae-icon sae-icon-info info-icon'} />
        <p id="dental-plans-coverage-important-text" className="btm-sm">
          {pageContent.SEE + ' '}
          <a data-analytics="importantNoticeVisionPlansIos" href={'javascript:void(0)'} onClick={() => {
            importantNotice.current && importantNotice.current.scrollIntoView({ behavior: 'smooth' })
          }} >{pageContent.IMPORTANT}</a>
          {' ' + pageContent.NOTICE_ABOUT}
        </p>
      </div>
    );
  };

  const getFavouritePlan = (favouritePlanContractCode: string) => {
    const result = getUpdatedFavouritePlans(favouritePlanContractCode, favouriteVisionPlans);
    if (result.length > 0) {
      updateFavouriteVisionPlans(result);
      if (showVisionFavourites) {
        const favouritePlans = getFavouritePlans(visionPlans, result);
        setFilteredPlans(favouritePlans);
      }
    }
    else {
      setFilteredPlans(visionPlans)
      updateShowVisionFavourites(false);
    }
  }

  const hideOrShowFavouritePlans = () => {
    if (!showVisionFavourites) {
      const favouritePlans = getFavouritePlans(visionPlans, favouriteVisionPlans);
      setFilteredPlans(favouritePlans);
    }
    else {
      setFilteredPlans(visionPlans);
    }
    updateShowVisionFavourites(!showVisionFavourites);
  }

  const renderFavouriteToggle = () => {
    return (
      <div className="fwc-row">
        <div className="show-fav-all-plans-wrapper">
          {
            (favouriteVisionPlans.length > 0) &&
            <>
              <span className="show-fav-plans" onClick={() => hideOrShowFavouritePlans()}>
                <span id="favourite-icon" className={`sae-icon ${!showVisionFavourites ? 'sae-icon-star' : 'sae-icon-eye'}`} />
                {!showVisionFavourites ? `${pageContent?.SHOW_MY} ${favouriteVisionPlans?.length} ${pageContent?.FAVOURITE_PLANS}` : `${pageContent?.SHOW_ALL} ${visionPlans.length} ${pageContent?.VISION_PLANS}`}
              </span>
            </>
          }
        </div>
      </div>
    )
  }

  const openVisionDetailsSlider = (contract: Contract, isCurrentCoveragePlan: boolean) => {
    setContract(contract);
    setIsCurrentCoveragePlan(isCurrentCoveragePlan);
  };

  const getCurrentCoverage = () => {
    if (loggedIn && role === USER_ROLES.MEMBER && currentCoverageVisionPlan.contractCode) {
      return <PlanCard
        key={currentCoverageVisionPlan.contractCode}
        labels={getVisionPlanCardLabels(pageContent?.PLAN_CARD_LABELS)}
        contract={currentCoverageVisionPlan}
        selectedPlan={selectedVisionPlan}
        matchedProviders={[]}
        matchedMedicationsFlag={false}
        matchedProvidersFlag={false}
        isFavouritePlan={favouriteVisionPlans.includes(currentCoverageVisionPlan.contractCode)}
        onPlanNameClick={(clickedPlan: Contract) => openVisionDetailsSlider(clickedPlan, true)}
        onSelectClick={() => { }}
        onFavouriteClick={(favouritePlanContractCode) => getFavouritePlan(favouritePlanContractCode)}
        planType={'vision'}
        isCurrentCoveragePlan={true}
      />
    }

    return <div />
  }

  if (!contentLoaded) {
    return <FullPageLoader />;
  } else {
    return (
      <div className="vision-plans-container">
        <div className={'fwc-row'}>
          <a data-analytics="backHeaderIos" className='global-back-link' id={'vision-back'} href={'javascript:void(0)'} onClick={() => handleVisionBack()}>
            Back
          </a>
        </div>
        {loading && <FullPageLoader />}
        {showFindYourEyeDoctor && (
          <FindYourEyeDoctor showFindYourEyeDoctor={showFindYourEyeDoctor}
            contractCodes={getContractCodes(visionPlans)}
            closeFindYourEyeDoctor={() => setShowFindYourEyeDoctor(false)} />
        )}
        {contract && (
          <VisionPlanDetails
            isCurrentCoveragePlan={isCurrentCoveragePlan}
            contract={contract}
            closeVisionPlanDetails={() => setContract(null)}
          />
        )}
        <GlobalHeader />
        <div className="medical-plans-body">
          <SelectedPlans
            plans={getSelectedPlans(selectedPlan, selectedDentalPlan, selectedVisionPlan)}
            handleChangeClick={(planType) => handleChangeClick(planType)}
            screenPlanType={PLAN_TYPES_NAMES.VISION}
          />
          <div className="fwc-row j-between">
            <PlanListingTitles title={pageContent?.VIEW_AND_SELECT_VISION_PLANS} description={''} renderSubText={() => renderSubText()} />
            {
              PLAN_TYPES.VISION !== coverageType && <button data-analytics={`${isSkip ? "skipStepBtnVisionPlansIos" : "continueBtnVisionPlansIos"}`} className={"fwc-btn fwc-btn-secondary small"} onClick={() => navigate(NAV_PATHS.PLAN_SUMMARY)}>{isSkip ? pageContent?.SELECTED_PLAN_CARD_LABELS.SKIP : pageContent?.SELECTED_PLAN_CARD_LABELS.CONTINUE}</button>
            }
            {(getSelectedPlans(selectedPlan, selectedDentalPlan, selectedVisionPlan).length > 0) && (PLAN_TYPES.VISION === coverageType) && <button data-analytics="continueBtnVisionPlansIos" className={"fwc-btn fwc-btn-secondary small"} onClick={() => navigate(NAV_PATHS.PLAN_SUMMARY)}>{pageContent?.SELECTED_PLAN_CARD_LABELS.CONTINUE}</button>
            }
          </div>
          <div className="fwc-row">
            <PlanCoverageAccordion planType={PLAN_TYPES_NAMES.VISION} labels={pageContent?.ACCORDION_LABELS} colaradoPlans={false} />
          </div>
          {!loading && visionPlans.length === 0 ?
            <div>
              {renderFavouriteToggle()}
              <EmptyPlans useCase={getEmptyPlansUseCase(serverError, visionPlans)} labels={checkForOnExchange() ? pageContent?.MARKETPLACE_EMPTY_PLANS : pageContent?.EMPTY_PLANS} />
            </div> : (
              <div>
                <div className="fwc-row">
                  <div className="utilities-container">
                    <a data-analytics="showEyeDrVisionPlansIos" href={'javascript:void(0)'} id="vision-plans-show-my-doctor" className="utility-option" onClick={() => setShowFindYourEyeDoctor(true)}>
                      <span id="plan-coverage-help" className={'sae-icon sae-icon-eye utility-icon'} />
                      <span>{pageContent?.SHOW_MY_EYE_DOCTOR}</span>
                    </a>
                  </div>
                </div>

                <div className="filters-list-outer-wrapper">
                  <div className="fwc-row label-sort-wrapper">
                    {showVisionFavourites && favouriteVisionPlans.length > 0 ?
                      <span className="view-count-text">
                        <>{pageContent.YOU_ARE_VIEWING} {pageContent.YOUR} <b>{favouriteVisionPlans.length} {pageContent.FAVOURITE} {pageContent.VISION_PLANS}</b></>
                      </span>
                      :
                      <span className="btm-sm">
                        {pageContent?.YOU_ARE_VIEWING + ' '}
                        <span className="vision-plans-label">
                          {visionPlans.length} {pageContent?.VISION_PLANS}
                        </span>
                      </span>
                    }
                    <div>
                      {renderFavouriteToggle()}
                    </div>
                  </div>


                  <div className='filters-list-outer-wrapper'>

                    <div className="fwc-row">
                      <div className="plan-list-container">
                        {getCurrentCoverage()}
                        {filteredPlans.map((contract: Contract) => {
                          return (
                            <PlanCard
                              key={contract.contractCode}
                              labels={getVisionPlanCardLabels(pageContent?.PLAN_CARD_LABELS)}
                              contract={contract}
                              selectedPlan={selectedVisionPlan}
                              matchedProviders={getMatchedDoctorsForPlan(matchEyeDoctorsToPlans, savedEyeDoctors, contract.contractCode)}
                              matchedMedicationsFlag={false}
                              matchedProvidersFlag={matchEyeDoctorsToPlans}
                              isFavouritePlan={favouriteVisionPlans.includes(contract.contractCode)}
                              onPlanNameClick={(clickedPlan: Contract) => openVisionDetailsSlider(clickedPlan, false)}
                              onSelectClick={(contract, matchedProviders, matchedMedications, isSelected) => updateVisionPlanSelection(contract, matchedProviders, isSelected)}
                              onFavouriteClick={(favouritePlanContractCode) => getFavouritePlan(favouritePlanContractCode)}
                              planType={'vision'}
                            />
                          );
                        })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            )}
          <div className="footer-wrapper">
            <div className="fwc-row footer-buttons-container" ref={importantNotice}>
              <Button
                id="medical-plans-back-to-estimate"
                data-analytics="backBtnVisionPlansIos"
                btnType="secondary"
                className="footer-button"
                onClick={() => handleVisionBack()}
              >
                {pageContent?.BACK}
              </Button>
              {
                PLAN_TYPES.VISION !== coverageType && <button data-analytics={`${isSkip ? "skipStepBtnVisionPlansIos" : "continueBtnVisionPlansIos"}`} id="medical-plans-go-to-top" className={"fwc-btn fwc-btn-secondary small"} onClick={() => navigate(NAV_PATHS.PLAN_SUMMARY)}>{isSkip ? pageContent?.SELECTED_PLAN_CARD_LABELS.SKIP : pageContent?.SELECTED_PLAN_CARD_LABELS.CONTINUE}</button>
              }
              {(getSelectedPlans(selectedPlan, selectedDentalPlan, selectedVisionPlan).length > 0) && (PLAN_TYPES.VISION === coverageType) && <button data-analytics="continueBtnVisionPlansIos" className={"fwc-btn fwc-btn-secondary small"} onClick={() => navigate(NAV_PATHS.PLAN_SUMMARY)}>{pageContent?.SELECTED_PLAN_CARD_LABELS.CONTINUE}</button>
              }

              {
                PLAN_TYPES.VISION === coverageType && <Button
                  id="vision-plans-go-to-top"
                  btnType="secondary"
                  className="footer-button"
                  onClick={() => {
                    window.scrollTo(0, 0);
                  }}
                >
                  {pageContent?.GO_TO_TOP}
                </Button>
              }

            </div>
            <div className="fwc-row">
              <div className="important-message">
                <p>
                  <b>{pageContent?.IMPORTANT}</b>
                </p>
                <p dangerouslySetInnerHTML={{ __html: pageContent?.IMPORTNAT_NOTICE_PARA_ONE }}></p>
                <p dangerouslySetInnerHTML={{ __html: pageContent?.IMPORTNAT_NOTICE_PARA_TWO }}></p>
                <p dangerouslySetInnerHTML={{ __html: pageContent?.IMPORTNAT_NOTICE_PARA_THREE }}></p>
              </div>
            </div>
          </div>
        </div>
        {providerMetaData && (
          <ProviderDetails
            useCase={PLAN_TYPES_NAMES.VISION}
          />
        )}
        {comparedVisionPlans.length > 0 &&
          <CompareTray
            planType={'vision'}
            plans={comparedVisionPlans}
            matchedProvidersFlag={matchEyeDoctorsToPlans}
            handleSelectPlan={handleCompareTraySelect}
            labels={getVisionPlanCardLabels(pageContent?.PLAN_CARD_LABELS)}
            onFavouriteClick={getFavouritePlan}
          ></CompareTray>}
      </div >
    );
  }
};

export default VisionPlans;
