import { Button, ModalSlide } from '@sydney-broker-ui/ios';
import React, { memo, useCallback, useEffect, useRef, useState } from 'react';
import { getCMSStaticContent, getCMSdynamicContent } from '../../../api/cms/cmsUtil';
import { STATUSES } from '../../../api/constants';
import EmailPrint from '../../../components/common/email-print/EmailPrint';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import colaradoPlan from '../../../images/colaradoPlan.svg';
import { AGENT_ROLES, APPLICATION_TYPE, LANGUAGE_CODES, PAGE_BLOCKS, PAGE_IDS, PLAN_TYPES, SCREEN_NAMES } from '../../../shared/globalConstants';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { fetchAlphaPrefix } from '../medical-plans/medicalPlansServices';
import { getCoverageTypeForDynamicAEM } from '../plan-recommendations/planRecommendationsUtils';
import './VisionPlanDetails.scss';
import { default as Content } from './visionPlanDetails.json';

const VisionPlanDetailsSlider: React.FC<VisionPlanDetailsProps> = ({ contract, isCurrentCoveragePlan, closeVisionPlanDetails, showCloseButton }) => {
    const [loading, setLoading] = useState(false);
    const [pageContent, setContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [alphaPrefixCode, setAlphaPrefixCode] = useState<string>('');
    const [pdfBrochureUrl, setPdfBrochureUrl] = useState<string>('');

    const impRef = useRef<any>(null)

    const globalStore = useGlobalStore((store) => store);
    const { role } = useUserStore((store) => store);
    const { zipCodeRes, state, brand, year, agentState, agentBrand, agentYear, agentSSOInfo, agentZipCodeRes } = globalStore;
    const { isAtk } = agentSSOInfo;
    const getPdfBroucher = async () => {
        let queryParam = '';
        if (isAtk) {
            queryParam = ';state=' + agentState + ';brand=' + agentBrand + ';role=' + AGENT_ROLES.BROKER.toLowerCase() + ';plantype=' + (contract.onExchange ? APPLICATION_TYPE.ON_EXCHANGE.toLowerCase() : APPLICATION_TYPE.OFF_EXCHANGE.toLowerCase()) + ';coveragetype=' + getCoverageTypeForDynamicAEM(SCREEN_NAMES.VISION_PLAN_DETAILS) + ';year=' + agentYear;
        } else {
            queryParam = ';state=' + state + ';brand=' + brand + ';role=' + role.toLowerCase() + ';plantype=' + (contract.onExchange ? APPLICATION_TYPE.ON_EXCHANGE.toLowerCase() : APPLICATION_TYPE.OFF_EXCHANGE.toLowerCase()) + ';coveragetype=' + getCoverageTypeForDynamicAEM(SCREEN_NAMES.VISION_PLAN_DETAILS) + ';year=' + year;
        }
        await getCMSdynamicContent(PAGE_IDS.PRODUCT_BROUCHER_LINK_ALL, PAGE_BLOCKS.PRODUCT_BROUCHER_LINK, queryParam)
            .then((response) => {
                setPdfBrochureUrl(response?.data.data?.iosdynamiccontentList?.items[0]?.textsummary);
            })
            .catch((error) => {
                console.warn('VisionPlanDetails.tsx getPdfBroucher error :', error);
            });
    };

    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.VISION_PLAN_DETAILS);
            let cmsResponse = response.data;

            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.VisionPlanDetails);
            // setPdfBrochureUrl(cmsResponse.data.iospagecontentList.items[0].pageContent?.VisionPlanDetails?.PRODUCT_BROUCHER_LINK);
        } catch (error) {
            setContent(Content.data.iospagecontentList.items[0].pageContent.VisionPlanDetails);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, []);

    const initiate = async () => {
        await getPdfBroucher();
        await getContent();
        const aplhaPrefixPayload: AlpaPrefixReq = {
            contractCode: contract.contractCode
        }
        getAlphaPrefix(aplhaPrefixPayload)
    };

    const getAlphaPrefix = (payload: AlpaPrefixReq) => {
        fetchAlphaPrefix(payload).then((response) => {
            if (response?.data?.message === STATUSES.SUCCESS) {
                setAlphaPrefixCode(response?.data?.alphaPrefixCode)
            } else {
                setAlphaPrefixCode('')
            }
        })
            .catch((error) => {
                setAlphaPrefixCode('')
            });
    }

    useEffect(() => {
        initiate();
    }, []);

    const handleSliderClose = useCallback(() => {
        closeVisionPlanDetails();
    }, [closeVisionPlanDetails]);

    return (
        <>
            {!contentLoaded && <FullPageLoader />}
            <ModalSlide
                direction={'right'}
                open={contract ? true : false}
                onClose={() => handleSliderClose()}
            >
                <ModalSlide.Body>
                    <div className={'slider-container'}>
                        <div className='fwc-row fwc-row-wrap fwc-row-bm'>
                            <div className='fwc-col-12 sm-12 md-12 vision-plan-details-container'>
                                <h2 className='btm-xxs title fwc-row-bm' id='medical-plan-detail-title'>
                                    <span
                                        id='medical-plan-detail-heading'
                                        className=' heart-health-icon sae-icon sae-icon-heart-health  right-xxs'
                                    ></span>
                                    {pageContent?.VISION_PLAN_DETAILS}
                                </h2>
                                <br />
                                {!loading && <>
                                    <div className='fwc-row info-div-align fwc-row-bm'>
                                        <div className='fwc-col-10 sm-8 md-8 important-info'>
                                            <span className='sae-icon sae-icon-info info-icon-size right-xxs'
                                            ></span>
                                            <p id='see'>{pageContent?.SEE} <span id={'important-link'} className='important-link' onClick={() => impRef.current.scrollIntoView({ behavior: 'smooth' })}> {pageContent?.IMPORTANT} </span>{pageContent?.NOTICE_ABOUT}</p>
                                        </div>


                                        {!isAtk && <div className='fwc-row hmo-row fwc-row-bm'>
                                            <div>
                                                <EmailPrint isCurrentCoveragePlan={isCurrentCoveragePlan} pdfBrochureUrl={pdfBrochureUrl ? pdfBrochureUrl : ''} type={PLAN_TYPES.VISION} alphaPrefix={alphaPrefixCode} emailPrintContract={contract} />
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div className='plan-div fwc-row-bm'>
                                        <div className='fwc-row fwc-row-bm'>
                                            <div className='fwc-col-8 sm-12 md-12'>
                                                <p className='plan-name' id='plan-name'> {contract?.planMarketingName} - ({contract?.contractCode})</p>
                                            </div>

                                        </div>
                                        {
                                            contract?.poPlan && <div className='fwc-row fwc-row-bm'>
                                                <div className='fwc-col-4 sm-3 md-3'>
                                                    <img id="co-logo" alt="" src={colaradoPlan} />
                                                </div>
                                            </div>
                                        }
                                        {!isCurrentCoveragePlan && <div className='fwc-row'>
                                            <div className='fwc-col-3 sm-12 md-3 plan-div-last-row'>
                                                <span className='sae-icon sae-icon-pdf  right-xxs icon-style' onClick={() => window.open(pdfBrochureUrl, "_blank")}
                                                ></span>
                                                <p className='highlight-text' id='pdf-brocher' onClick={() => window.open(pdfBrochureUrl, "_blank")}>{pageContent?.PDF_BROCHURE}</p>
                                            </div>
                                        </div>
                                        }
                                    </div>
                                    <div>
                                        <table>
                                            <thead>
                                                <tr>
                                                    <th>{pageContent?.BENEFIT} </th>
                                                    <th>{pageContent?.BENEFIT_DETAILS}</th>
                                                </tr>
                                            </thead>
                                            <tbody>

                                                {(contract?.rateData?.rate && Object.keys(contract?.rateData?.rate).length) ?
                                                    <tr>
                                                        <td> <h6>{pageContent?.MONTHLY_COST}</h6>  </td>
                                                        <td>
                                                            <div className='fwc-row'>
                                                                <div className='fwc-col-3 sm-12 md-4'>
                                                                    <h5 className="plan-label-text" >${contract?.rateData.rate.totSubsidizedPrem}</h5>
                                                                    <p className="plan-label-text">{pageContent?.MONTHLY_PREMIUM}</p>
                                                                </div>
                                                                <div className='fwc-col-9 sm-12 md-8'>
                                                                    <h6 className="plan-label-text">{pageContent?.MONTHLY_COST_BREAK}</h6>
                                                                    <div>
                                                                        <div className='fwc-row gap-breakdown'>
                                                                            <div className='fwc-col-6 sm-6 md-6 plan-label-text'>
                                                                                {pageContent?.TOTAL_PLAN_COST}
                                                                            </div>
                                                                            <div className='fwc-col-6 sm-6 md-6 plan-label-text-result'>
                                                                                ${contract?.rateData.rate.totalPremium}
                                                                            </div>
                                                                        </div>
                                                                        <div className='fwc-row gap-breakdown'>
                                                                            <div className='fwc-col-6 sm-6 md-6 plan-label-text'>
                                                                                {pageContent?.SUBSIDY_APPLIED}
                                                                            </div>
                                                                            <div className='fwc-col-6 sm-6 md-6 plan-label-text-result'>
                                                                                ${contract?.rateData.rate.subsidyAmtApplied?.toFixed(2)}
                                                                            </div>
                                                                        </div>
                                                                        <div className='fwc-row gap-total-mem'>
                                                                            <div className='fwc-col-6 sm-6 md-6 plan-label-text'>
                                                                                <h6 className='total-mem-wrap'>{pageContent?.TOTAL_MEMBER_RESP}</h6>
                                                                            </div>
                                                                            <div className='fwc-col-6 sm-6 md-6 plan-label-text-result plan-result'>
                                                                                <h5>${contract?.rateData.rate.totSubsidizedPrem}</h5>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </td>
                                                    </tr> : null}

                                                {
                                                    contract?.plan[0]?.planBenefits?.map((item, index) => {
                                                        return (
                                                            <tr key={index}>
                                                                <td><h6>{pageContent?.PLAN_BENEFIT_NAMES[item.planBenefitCode]} </h6> </td>
                                                                <td><span dangerouslySetInnerHTML={{ __html: item.planBenefitValue }}></span></td>
                                                            </tr>
                                                        )
                                                    })
                                                }
                                            </tbody>
                                        </table>
                                    </div>
                                </>
                                }

                            </div>
                        </div>
                        {!loading && <div id="important-link-div" ref={impRef} className='fwc-row-bm'>
                            <h6 id='important'>{pageContent?.IMPORTANT}</h6>
                            <div id='important-1' className='fwc-row fwc-row-bm'>
                                {pageContent?.IMPORTANT_1}
                            </div>
                            <div id='important-2' className='fwc-row fwc-row-bm'>
                                {pageContent?.IMPORTANT_2}
                            </div>
                        </div>}
                    </div>
                </ModalSlide.Body>
                <ModalSlide.Footer>
                    <Button
                        btnType='secondary'
                        id='modal-slide-cancel'
                        className='right-xs'
                        onClick={handleSliderClose}
                    >
                        {showCloseButton ? pageContent?.CLOSE : pageContent?.VIEW_ALL_VISION_PLANS}
                    </Button>
                </ModalSlide.Footer>
            </ModalSlide>
        </>
    );
}

const VisionPlanDetails = memo(VisionPlanDetailsSlider);

export default VisionPlanDetails;
