import { Toggle } from '@sydney-broker-ui/ios';
import React from 'react';

import { getUpdatedSwitchFilters } from './switchFIlterUtils';

import { recordActionSatelliteTrack } from '../../../shared/utils/globalUtils';
import './SwitchListFilter.scss';

const SwitchListFilter: React.FC<SwitchListFilterProps> = ({ switchFilters, onSwitchFilterApply }) => {
  const toggleSwitch = (option: SwitchFilter) => {
    if (option.label === 'Recommended' && !option.enabled) {
      recordActionSatelliteTrack("toggleRecFilterMedicalPlansIos");
    } else if (option.label === 'Most Popular' && !option.enabled) {
      recordActionSatelliteTrack("togglePopularFilterMedicalPlansIos");
    } else if (option.label === 'HSA Compatible' && !option.enabled) {
      recordActionSatelliteTrack("toggleHsaFilterMedicalPlansIos");
    } else if (option.label === 'Show My Doctor' && !option.enabled) {
      recordActionSatelliteTrack("toggleDoctorFilterMedicalPlansIos");
    } else if (option.label === 'Show My Medications' && !option.enabled) {
      recordActionSatelliteTrack("toggleMedicationFilterMedicalPlansIos");
    }
  }

  const onSwitchClick = (option: SwitchFilter) => {
    !option.disabled && toggleSwitch(option);
    !option.disabled && onSwitchFilterApply(getUpdatedSwitchFilters(switchFilters, option))
  }

  return (
    <div className="switch-list-container">
      <ul className="filter-options-wrapper">
        {switchFilters.map((option) => {
          return (
            <div key={option.key} className="switch-filter-row">
              <div className="label-tooltip-container">
                <span className="switch-label">{option.label}</span>
              </div>
              <div data-testid={option.key} onClick={() => { onSwitchClick(option) }}>
                <Toggle
                  onChange={() => { }}
                  className="switch-filter-toggle"
                  disabled={option.disabled}
                  value={option.enabled}
                  checked={option.enabled}
                />
              </div>
            </div>
          );
        })}
      </ul>
    </div>
  );
};

export default SwitchListFilter;
