import React, { useEffect } from 'react';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import './App.scss';
import AtkSessionEnd from './components/common/atk-session-end/AtkSessionEnd';
import HelpMeChoose from './components/common/help-me-choose/HelpMeChoose';
import { ShopperProvider } from './context/shopper-context/ShopperContext';
import AccountRecovery from './pages/public/account-recovery/AccountRecovery';
import { ACCOUNT_RECOVERY_USE_CASES } from './pages/public/account-recovery/constants';
import AgentSSOLogin from './pages/public/agent-sso/AgentSSOLogin';
import AppErrorPage from './pages/public/app-error-page/AppErrorPage';
import Basics from './pages/public/basics/Basics';
import BasicsLanding from './pages/public/basics/basics-landing/BasicsLanding';
import EstimateCostSavings from './pages/public/basics/estimate-cost-savings/EstimateCostSavings';
import DentalPlans from './pages/public/dental-plans/DentalPlans';
import EstimateSavings from './pages/public/estimate-savings/EstimateSavings';
import EstimateSavingsLandingPage from './pages/public/estimate-savings/EstimateSavingsLandingPage';
import HereAreOptions from './pages/public/estimate-savings/HereAreOptions';
import Home from './pages/public/home/Home';
import AgentBrokerLogin from './pages/public/login/agent-broker-login/AgentBrokerLogin';
import MacLogin from './pages/public/login/mac-login/MacLogin';
import StandAloneLogin from './pages/public/login/standalone-login/StandAloneLoginPage';
import MedicalPlans from './pages/public/medical-plans/MedicalPlans';
import MemberSSOLogin from './pages/public/member-sso/MemberSSOLogin';
import PlanRecommendations from './pages/public/plan-recommendations/PlanRecommendations';
import PlanSelectionSummary from './pages/public/plan-selection-summary/PlanSelectionSummary';
import ProposalPlanDetails from './pages/public/proposal-plan-details/ProposalPlanDetails';
import Registration from './pages/public/registration/Registration';
import VisionPlans from './pages/public/vision-plans/VisionPlans';
import AgentBasicsLanding from './pages/secure/agent-basics-landing/AgentBasicsLanding';
import AtkPlanSelectionSummary from './pages/secure/atk-plan-selection-summary/AtkPlanSelectionSummary';
import Dashboard from './pages/secure/dashboard/Dashboard';
import ProductSelection from './pages/secure/product-selection/ProductSelection';
import ProposalQuoteOverview from './pages/secure/proposal-quote-overview/ProposalQuoteOverview';
import ReviewPlans from './pages/secure/review-plans/ReviewPlans';
import UpdateProfile from './pages/secure/update-profile/UpdateProfile';
import GlobalAnalytics from "./shared/globalAnalytics";
import { AEM_PATHS, NAV_PATHS, USER_DOMAINS } from './shared/globalConstants';
import GlobalUrlRedirections from './shared/globalUrlRedirection';
import { setWellpointStyles } from './shared/utils/globalUtils';
import ScrollToTop from './shared/utils/scrollToTop';
import { useGlobalStore } from './store/globalStore';
import { useUserStore } from './store/userStore';
function App() {
  const { updateBrandSite, isWellpoint } = useUserStore((state) => state);

  useEffect(() => {
    if (!useGlobalStore.getState().brand) {
      const globalStore = useGlobalStore.getState();
      const isWellpointflag = window.location.href.includes('wellpoint.com/') || window.location.href.includes('/content/ios/wellpoint/')
      updateBrandSite(isWellpointflag);
      let brand: string;
      if (isWellpointflag) {
        brand = USER_DOMAINS.WELLPOINT_BRAND;
      } else if (globalStore.deepLinkInfo.brand) {
        brand = globalStore.deepLinkInfo.brand;
      } else {
        brand = USER_DOMAINS.BRAND;
      }
      globalStore.updateBrand(brand);
    }
  }, []);

  const locale = window.location.href.includes('/es/') ? AEM_PATHS.SPANISH : AEM_PATHS.ENGLISH;
  const AUTHOR_PAGE_PATH = isWellpoint ? `/content/ios/wellpoint/us/${locale}/individuals` : `/content/ios/anthem/us/${locale}/individuals`;
  if (isWellpoint) {
    setWellpointStyles();
  }
  return (
    <ShopperProvider>
      <div className="sae-page-wrapper">
        <Router basename="/">
          <ScrollToTop />
          <div className="App">
            <header></header>
            <GlobalAnalytics />
            <GlobalUrlRedirections />
            <Routes>
              <Route path="/individuals/shop/basics" element={<Basics />}>
                <Route path="" element={<BasicsLanding />} />
                <Route path="estimate-cost-savings" element={<EstimateCostSavings />}>
                  <Route path="" element={<EstimateSavingsLandingPage />} />
                </Route>
                <Route path="credit-calculator" element={<EstimateSavings />} />
                <Route path="help-me-choose" element={<HelpMeChoose />}></Route>
                <Route path="options" element={<HereAreOptions />} />
                <Route path="medical-plans" element={<MedicalPlans />} />
                <Route path="dental-plans" element={<DentalPlans />} />
                <Route path="vision-plans" element={<VisionPlans />} />
                <Route path="plan-selection-summary" element={<PlanSelectionSummary />} />
              </Route>
              <Route path={NAV_PATHS.AGENTS_BASICS} element={<Basics />}>
                <Route path="" element={<AgentBasicsLanding />} />
                <Route path={NAV_PATHS.PLAN_SELECTION_NESTED} element={<ProductSelection />} />
                <Route path={NAV_PATHS.AGENT_PLAN_SELECTION_SUMMARY} element={<AtkPlanSelectionSummary />} />
              </Route>
              <Route path={NAV_PATHS.AGENTS_QUOTE_OVERVIEW} element={<ProposalQuoteOverview />} />
              <Route path={NAV_PATHS.MEMBER_SSO} element={<MemberSSOLogin />}></Route>
              <Route path={NAV_PATHS.AGENT_SSO} element={<AgentSSOLogin />}></Route>
              <Route path={NAV_PATHS.PROPOSAL_PLAN_DETAILS} element={<ProposalPlanDetails />}></Route>
              <Route path={NAV_PATHS.ATK_SESSION_END} element={<AtkSessionEnd />} />
              <Route
                path={NAV_PATHS.STANDALONE_LOGIN}
                element={<StandAloneLogin />}
              ></Route>
              <Route path={NAV_PATHS.MAC_LOGIN} element={<MacLogin />} />
              <Route path={NAV_PATHS.GUEST_LOGIN} element={<AgentBrokerLogin />} />
              <Route path="/individuals/profile/registration" element={<Registration />}></Route>
              <Route path={AUTHOR_PAGE_PATH + "/profile/registration.html"} element={<Registration />}></Route>
              <Route path={AUTHOR_PAGE_PATH + "/shop/basics.html"} element={<Basics />}></Route>
              <Route path={AUTHOR_PAGE_PATH + ".html"} element={<Home />}></Route>
              <Route path={AUTHOR_PAGE_PATH + "-abc.html"} element={<Home />}></Route>
              <Route path={AUTHOR_PAGE_PATH + "-ABC.html"} element={<Home />}></Route>
              <Route path="/individuals/profile/forgot-username" element={<AccountRecovery useCase={ACCOUNT_RECOVERY_USE_CASES.FORGOT_USERNAME} />}></Route>
              <Route path="/individuals/profile/forgot-password" element={<AccountRecovery useCase={ACCOUNT_RECOVERY_USE_CASES.FORGOT_PASSWORD} />}></Route>
              <Route path={NAV_PATHS.DASHBOARD} element={<Dashboard />}></Route>
              <Route path={NAV_PATHS.UPDATE_PROFILE} element={<UpdateProfile />}></Route>
              <Route path="/individuals/" element={<Home />} />
              <Route path="/individuals/home" element={<Home />}></Route>
              <Route path={NAV_PATHS.INDIVIDUALS_ES} element={<Home />}></Route>
              <Route path={NAV_PATHS.INDIVIDUALS_ES_HOME} element={<Home />}></Route>
              <Route path={NAV_PATHS.PLAN_RECOMMENDATIONS} element={<PlanRecommendations />} />
              <Route path={NAV_PATHS.APP_ERROR_PAGE} element={<AppErrorPage />} />
              <Route path={NAV_PATHS.REVIEW_PLANS} element={<ReviewPlans />} />
            </Routes>
          </div>
        </Router>
      </div>
    </ShopperProvider>
  );
}

export default App;
