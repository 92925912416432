import { USER_DOMAINS } from '../../../../shared/globalConstants';
import { useGlobalStore } from '../../../../store/globalStore';

const getChangePasswordPayload = (webAccountGUID: string, oldPassword: string, newPassword: string): ChangeShopperPasswordPayload => {
  return {
    userDomain: {
      app: USER_DOMAINS.APP,
      marketSegment: USER_DOMAINS.MARKET_SEGMENT,
      brand: useGlobalStore.getState().brand
    },
    changePassword: {
      webAccountGUID: webAccountGUID,
      oldPassword: oldPassword,
      newPassword: newPassword
    }
  };
};

const hasPasswordContainsUsername = (username: string, password: string): boolean => {
  for (let i = 0; i + 2 < username.length; i++) {
    const substring = username.substring(i, i + 3);
    if (password.includes(substring)) {
      return true;
    }
  }
  return false;
};

export { getChangePasswordPayload, hasPasswordContainsUsername };
