const STANDALONE_LOGIN_REDIRECTION_USE_CASES = {
  AGENT_TELESALES_PROPOSAL: 'AGENT_TELESALES_PROPOSAL',
  SELF_SENT_EMAIL: 'SELF_SENT_EMAIL',
  USER_LOGIN: 'USER_LOGIN',
  EVENDOR: 'EVENDOR'
};
const STANDALONE_LOGIN_URL_PARAMS = {
  BRAND: 'brand',
  QUOTE_ID: 'quoteId',
  STATE: 'state',
  PROPOSAL_ID: 'proposalID',
  MEDICAL_CONTRACT: 'medicalContractCode',
  DENTAL_CONTRACT: 'dentalContractCode',
  VISION_CONTRACT: 'visionContractCode',
  QUOTE_SRC: 'quoteSrc',
  LANGUAGE: 'language',
  DEMOGRAPHICS_ID: 'demographicsId',
  EVENDORID: 'evendorid',
  SOURCE: 'source'
};

const CREATE_PROFILE_REDIRECTION_USE_CASES = [STANDALONE_LOGIN_REDIRECTION_USE_CASES.AGENT_TELESALES_PROPOSAL, STANDALONE_LOGIN_REDIRECTION_USE_CASES.EVENDOR];

export { CREATE_PROFILE_REDIRECTION_USE_CASES, STANDALONE_LOGIN_REDIRECTION_USE_CASES, STANDALONE_LOGIN_URL_PARAMS };
