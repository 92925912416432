import { StateCreator } from 'zustand';

export const createStateConfigSlice: StateCreator<StateConfigStore> = (set) => ({
  coverageRule: [],
  enrollmentYear: '',
  globalEnrollmentPeriods: [],
  updateStateCofig: (coverageRule: coverageRule[]) => set({ coverageRule: coverageRule }),
  updateEnrollmentYear: (enrollmentYear: string) => set({ enrollmentYear: enrollmentYear }),
  updateGlobalEnrollmentPeriods: (coverageRule: coverageRule[]) => set({globalEnrollmentPeriods: coverageRule})
});
