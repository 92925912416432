// import { DevTool } from "@hookform/devtools";
import { Button, Modal, Tooltip } from '@sydney-broker-ui/ios';
import dayjs from "dayjs";
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, SubmitHandler, useFieldArray, useForm } from "react-hook-form";
import { useLocation, useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { getMarketingIdValidated, getZipcodeValidation } from '../../../api/services/utilService';
import CustomRadioGroup from '../../../components/common/custom-radio/CustomRadio';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import { AGENT_ROLES, ALPHA_NUMERIC_REGX, ALPHA_REGX_WITH_SPACE_HYPEN, BRAND_NAMES, CALIFORNIA_CODE, DATE_FORMATS, DEFAULT_YEAR, DENTAL_PLAN_TYPES, LANGUAGE_CODES, MEDICAL_PLAN_TYPES, NAV_PATHS, NV_STATE, PARENT_VALUES, PLAN_TYPES, RELATIONSHIP, SCREEN_NAMES, SET_APPLICATION_CONSTANTS, STATE_CODES, TOBACCO_STATE_CODES } from '../../../shared/globalConstants';
import { arraysEqual, convertAgentApiDatatoFormDataFormat, filterDependentsOnParentRelationType, isApplicantDateInRange, isApplicantSpouseInRange, isDateinFuture, isDependentChildDateInRange, isInValidDate, isNullOrUndefined, replaceKeysInArrayOfObjects } from "../../../shared/utils/globalUtils";
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import { MEDICAL_PLANS_TABS } from '../../public/medical-plans/constants';
import { getFormattedApplicantDetails, getSubsidyAmount } from '../../public/medical-plans/medicalPlansUtils';
import SubsidyBlockForm from '../subsidy-block-form/subsidyBlockForm';
import './AgentBasicsLanding.scss';
import { default as Content } from './agentBasicsLanding.json';

const AgentBasicsLanding: React.FC = (props) => {
    const location = useLocation();

    const [loading, setLoading] = useState(false)
    const [pageContent, setPageContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [coverageTypeOptions, setCoverageTypeOptions] = useState<any>([]);
    const [countyList, setCountyList] = useState<any>([]);
    const [zipError, setZipError] = useState<boolean>(false)
    const [marketingError, setMarketingError] = useState<boolean>(false)
    const [agentStateError, setAgentStateError] = useState<boolean>(false);
    const [showSubsidyBlock, setShowSubsidyBlock] = useState<boolean>(false);
    const [openAlertModal, setAlertModalOpen] = useState(false);
    const [editFlow, setEditFlow] = useState<boolean>(location?.state?.editFlow ? location?.state?.editFlow : false)

    const navigate = useNavigate()

    const { selectedProduct, updateOpenATKDepModal, updateSelectedProduct } = useGlobalStore();
    /* --------- stores ------------*/

    const stores = {
        globalStore: useGlobalStore(),
        userStore: useUserStore(),
    };

    /* --------- stores ------------*/


    /* --------- Get Content ------------*/
    useEffect(() => {
        initiate();
        if (stores.globalStore.agentZipCodeRes.zipCode === stores.globalStore.agentZipcode) {
            setCountyList(stores.globalStore.agentZipCodeRes.countyList.county);
        }
    }, []);



    useEffect(() => {
        if (pageContent && JSON.parse(pageContent?.ZIPCODE_DYNAMIC_DATA.JSON_AREA).planYear.length !== 0) {
            setValue('year', JSON.parse(pageContent?.ZIPCODE_DYNAMIC_DATA.JSON_AREA).planYear[0]);
        }
    }, [stores.globalStore.agentZipcode]);


    useEffect(() => {
        if (stores.globalStore.agentFormSubmitedWithSubsidy) {
            setShowSubsidyBlock(true);
        }
    }, [stores.globalStore.agentFormSubmitedWithSubsidy])

    const initiate = async () => {
        setLoading(true);
        await getContent();
    };


    const getContent = useCallback(async (): Promise<boolean> => {
        let content: any = null;
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.AGENT_LANDING);
            let cmsResponse = response.data;
            content = cmsResponse.data.iospagecontentList.items[0].pageContent?.agentBasicsLanding;

        } catch (error) {
            console.error('agentBasicsLanding.tsx getContent error: ', error);
            content = Content?.data.iospagecontentList.items[0].pageContent?.agentBasicsLanding;
            return false;
        }
        finally {
            setPageContent(content);
            setCoverageTypeOptions(stores.userStore.isWellpoint ? content?.ATK_WELLPOINT_OPTIONS?.options : content?.ATK_ANTHEM_OPTIONS?.options);
            setContentLoaded(true);
        }
        return true;
    }, []);

    /* --------- Get Content ------------*/

    /*----------------- Agent SSO get Methods -----------*/
    const getAgentStates = (agentSSO: AgentSSOInfo, stateCode: string) => {
        if (agentSSO.agentInfo.agent.length > 0) {
            const stateNames = agentSSO.agentInfo.agent[0].states.state.map((state) => state.stateName);
            const uniqueStateNames = [...new Set(stateNames)];
            return uniqueStateNames.includes(stateCode);
        }
    }

    const showAgentSubsidy = (agentSSO: AgentSSOInfo, stateCode: string, coverageType: string, data?) => {
        if (agentSSO.agentInfo.agent.length > 0) {
            const selectedStateObj = agentSSO.agentInfo.agent[0].states.state.find((state) => state.stateName === stateCode);
            const agentType = agentSSO.agentType;
            if (agentType === AGENT_ROLES.HPA && (coverageType === PLAN_TYPES.MEDICAL || coverageType === PLAN_TYPES.MDV)) {
                setShowSubsidyBlock(true);
                return true;
            } else if (agentType !== AGENT_ROLES.HPA && stateCode === NV_STATE && (selectedStateObj && selectedStateObj.licenseNumber && selectedStateObj.licenseNumber !== '') && (selectedStateObj && selectedStateObj.FFM && selectedStateObj.FFM !== '') && (coverageType === PLAN_TYPES.MEDICAL || coverageType === PLAN_TYPES.MDV)) {
                setShowSubsidyBlock(true);
                return true;
            } else if (agentType !== AGENT_ROLES.HPA && ((selectedStateObj && selectedStateObj.licenseNumber && selectedStateObj.licenseNumber !== '') && ((selectedStateObj && selectedStateObj.FFM && selectedStateObj.FFM !== '') || (selectedStateObj && selectedStateObj.certNo && selectedStateObj.certNo !== ''))) && (coverageType === PLAN_TYPES.MEDICAL || coverageType === PLAN_TYPES.MDV)) {
                setShowSubsidyBlock(true);
                return true;
            } else {
                setShowSubsidyBlock(false);
                if (data) {
                    formSubmit(data);
                }
                return false;
            }
        } else {
            setShowSubsidyBlock(false);
            if (data) {
                formSubmit(data);
            }
            return false;
        }
    }
    /*----------------- Agent SSO get Methods -----------*/

    /*----------------- store data --------------*/
    let storeFormData = stores.globalStore.agentApplicantFormDetails.length > 0 ? convertAgentApiDatatoFormDataFormat(stores.globalStore.agentApplicantFormDetails) : convertAgentApiDatatoFormDataFormat(replaceKeysInArrayOfObjects(stores.globalStore.agentSSOInfo.applicantInfo?.applicants?.applicant, ['relationship', 'tobaccoUsage'], ['applicantType', 'isTabaccoUser']));
    /* ------------------ FORM -----------------*/

    const {
        register,
        handleSubmit,
        watch,
        control,
        setValue,
        getValues,
        trigger,
        setError,
        formState: { errors },
    } = useForm<AgentBasicsForm>({
        mode: 'onChange',
        defaultValues: {
            zipcode: '',
            county: '',
            year: '',
            coverageType: '',
            agentTin: '',
            marketingRefId: '',
            applicant: {
                firstName: '',
                lastName: '',
                dateOfBirth: '',
                applicantType: 'PRIMARY',
                isTabaccoUser: (stores.globalStore.agentState && stores.globalStore.agentState === SET_APPLICATION_CONSTANTS.KY && stores.globalStore.agentYear !== DEFAULT_YEAR) ? SET_APPLICATION_CONSTANTS.NO : !((stores.globalStore.agentState && TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) ? SET_APPLICATION_CONSTANTS.NO : '',
                email: '',
                gender: undefined,
                SSN: '',
                applicantId: '0'
            },
            spouse: [],
            dependents: []
        },
        values: {
            zipcode: stores.globalStore.agentZipcode,
            county: stores.globalStore.agentCounty,
            year: stores.globalStore.agentYear ? stores.globalStore.agentYear : pageContent ? JSON.parse(pageContent?.ZIPCODE_DYNAMIC_DATA.JSON_AREA).planYear[0] : '',
            coverageType: stores.globalStore.agentCoverageType,
            agentTin: stores.globalStore.agentTin,
            marketingRefId: stores.globalStore.agentMarketingRefId,
            applicant: storeFormData.applicant,
            spouse: storeFormData.spouse,
            dependents: storeFormData.dependents
        },
        resetOptions: {
            keepDirtyValues: true, // user-interacted input will be retained
            keepErrors: true, // input errors will be retained with value update
        },
    });


    useEffect(() => {
        if (stores.globalStore.agentSSOInfo.applicantInfo?.zipCode.length === 5) {
            setValue('zipcode', stores.globalStore.agentSSOInfo.applicantInfo.zipCode);
        }
    }, [stores.globalStore.agentSSOInfo.applicantInfo?.zipCode]);

    /* ---- Field Array Methods -----*/

    const {
        fields: dependentFields,
        append: dependentAppend,
        remove: dependentRemove,
    } = useFieldArray({ control, name: "dependents" });
    const {
        fields: spouseFields,
        append: spouseAppend,
        remove: spouseRemove,
    } = useFieldArray({ control, name: "spouse" });


    const spouseFieldsWatch = watch("spouse");
    useEffect(() => {
        if (spouseFieldsWatch.length > 0 && spouseFields.length === 0) {
            setValue('spouse', spouseFieldsWatch);
        }
    }, [spouseFieldsWatch]);
    const dependentFieldsWatch = watch("dependents");
    useEffect(() => {
        if (dependentFieldsWatch.length > 0 && dependentFields.length === 0) {
            setValue('dependents', dependentFieldsWatch);
        }
    }, [dependentFieldsWatch]);

    /* ---- Add Spouse and Dependents Method -----*/
    const addDepSpouseFormFields = (e, type: string) => {
        let length = spouseFields.length + dependentFields.length;
        e.preventDefault();
        if (type === "dependent") {
            dependentAppend({
                applicantType: (stores.globalStore.agentState === CALIFORNIA_CODE && MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType)) ? "" : RELATIONSHIP.CHILD,
                firstName: "",
                lastName: "",
                dateOfBirth: "",
                isTabaccoUser: (stores.globalStore.agentState && stores.globalStore.agentState === SET_APPLICATION_CONSTANTS.KY && stores.globalStore.agentYear !== DEFAULT_YEAR) ? SET_APPLICATION_CONSTANTS.NO : !((stores.globalStore.agentState && TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) ? SET_APPLICATION_CONSTANTS.NO : '',
                applicantId: (length === 0) ? (1).toString() : (length + 1).toString()
            });
        } else {
            spouseAppend({
                applicantType: "SPOUSE",
                firstName: "",
                lastName: "",
                dateOfBirth: "",
                isTabaccoUser: (stores.globalStore.agentState && stores.globalStore.agentState === SET_APPLICATION_CONSTANTS.KY && stores.globalStore.agentYear !== DEFAULT_YEAR) ? SET_APPLICATION_CONSTANTS.NO : !((stores.globalStore.agentState && TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) ? SET_APPLICATION_CONSTANTS.NO : '',
                applicantId: (length === 0) ? (1).toString() : (length + 1).toString(),
            });
        }
    };
    /* ---- Add Spouse and Dependents Method -----*/

    /* -------  revalidate arary methods --------- */

    useEffect(() => {
        if (!isNullOrUndefined(getValues('applicant.dateOfBirth')) && getValues('applicant.dateOfBirth') !== '') {
            trigger('applicant.dateOfBirth');
        }
        revalidateFieldArray();
    }, [spouseFields])

    const revalidateFieldArray = async () => {
        // Trigger validation for all fields in the array
        await Promise.all(
            dependentFields.map(async (field, index) => {
                if (getValues(`dependents.${index}.applicantType`)) {
                    await trigger(`dependents.${index}.applicantType`)
                }
                // Add more fields if needed
            })
        );
    };
    /* -------  revalidate arary methods --------- */


    /* ---- Field Array Methods -----*/


    /* ------ form submit ---------*/

    const onSubmit: SubmitHandler<AgentBasicsForm> = (data) => {
        const formData = [{ ...data.applicant }, ...data.spouse, ...data.dependents];
        stores.globalStore.agentUpdateApplicantFormDetails(formData);
        stores.globalStore.agentUpdateTinandMarketingRefId(data.agentTin, data.marketingRefId);
        stores.globalStore.agentUpdateYear(data.year);
        showAgentSubsidy(stores.globalStore.agentSSOInfo, stores.globalStore.agentZipCodeRes.stateCode, data.coverageType, data);
    }

    const formSubmit = (data) => {
        const formData = [{ ...data.applicant }, ...data.spouse, ...data.dependents];
        stores.globalStore.resetProductStates();
        stores.globalStore.resetDrugStoreStates();
        stores.globalStore.resetProviderStates();
        const isSubsidyApplied = getSubsidyAmount(useGlobalStore.getState().subsidyresponse)
        updateSelectedProduct({
            ...selectedProduct,
            medicalApplicants: [],
            dentalApplicants: [],
            visionApplicants: []
        })
        if (isSubsidyApplied) {
            useGlobalStore.getState().updateSelectedProductTab(MEDICAL_PLANS_TABS.MARKET_PLANS)
        } else {
            useGlobalStore.getState().updateSelectedProductTab(MEDICAL_PLANS_TABS.OFF_MARKET_PLANS)
        }

        const dependentsLength = [...data.spouse, ...data.dependents].length;
        const isAnthemBrand = !stores.userStore.isWellpoint;
        const updatedSelectedProduct = { ...selectedProduct };

        if (dependentsLength > 0 && isAnthemBrand && stores.globalStore.agentCoverageType !== PLAN_TYPES.VISION) {
            switch (stores.globalStore.agentCoverageType) {
                case PLAN_TYPES.MDV:
                    updatedSelectedProduct.medicalApplicants = getFormattedApplicantDetails(formData);
                    updateSelectedProduct(updatedSelectedProduct)
                    break;
                case PLAN_TYPES.DENTAL:
                    updatedSelectedProduct.dentalApplicants = getFormattedApplicantDetails(formData);
                    updateSelectedProduct(updatedSelectedProduct)
                    break;
                default:
                    navigate(NAV_PATHS.PRODUCT_SELECTION);
                    break;
            }
            updateOpenATKDepModal(stores.globalStore.agentCoverageType, true, false, '');
        } else {

            switch (stores.globalStore.agentCoverageType) {
                case PLAN_TYPES.MEDICAL:
                    updatedSelectedProduct.medicalApplicants = getFormattedApplicantDetails(formData);
                    updateSelectedProduct(updatedSelectedProduct)
                    break;
                case PLAN_TYPES.MDV:
                    updatedSelectedProduct.medicalApplicants = getFormattedApplicantDetails(formData);
                    updatedSelectedProduct.dentalApplicants = getFormattedApplicantDetails(formData);
                    updatedSelectedProduct.visionApplicants = getFormattedApplicantDetails(formData);
                    updateSelectedProduct(updatedSelectedProduct)
                    break;
                case PLAN_TYPES.DENTAL:
                    updatedSelectedProduct.dentalApplicants = getFormattedApplicantDetails(formData);
                    updatedSelectedProduct.visionApplicants = getFormattedApplicantDetails(formData);
                    updateSelectedProduct(updatedSelectedProduct)
                    break;
                case PLAN_TYPES.VISION:
                    updatedSelectedProduct.visionApplicants = getFormattedApplicantDetails(formData);
                    updateSelectedProduct(updatedSelectedProduct)
                    break;
                default:
                    navigate(NAV_PATHS.PRODUCT_SELECTION);
                    break;
            }
            navigate(NAV_PATHS.PRODUCT_SELECTION);
        }
        if (showSubsidyBlock) {
            stores.globalStore.agentUpdateFormSubmitedWithSubsidy(true);
        }
    }

    const editBasicsForm = () => {
        stores.globalStore.resetHouseHoldMemberValues();
        stores.globalStore.resetEstimateSavings();
        stores.globalStore.agentUpdateFormSubmitedWithSubsidy(false);
        setShowSubsidyBlock(false);
    }
    /* ------ form submit ---------*/



    /*-------- subscribe with slectors -----------*/
    let agentPlanTypesdestroyed = false;

    const unsub = useGlobalStore.subscribe(
        (currState) => currState.agentPlanTypes,
        (agentPlanTypes, prevState) => {
            if (agentPlanTypes.length > 0 && !arraysEqual(prevState, agentPlanTypes)) {
                updateCoverageTypeOptions(stores.userStore.isWellpoint, stores.userStore.isWellpoint ? Content?.data.iospagecontentList.items[0].pageContent?.agentBasicsLanding?.ATK_WELLPOINT_OPTIONS?.options : Content?.data.iospagecontentList.items[0].pageContent?.agentBasicsLanding?.ATK_ANTHEM_OPTIONS?.options, agentPlanTypes);
                agentPlanTypesdestroyed = true;
            }
        },
        { fireImmediately: true }
    );

    if (agentPlanTypesdestroyed) {
        unsub();
    }

    let agentTindestroyed = false;

    const tinSub = useGlobalStore.subscribe(
        (currState) => currState.agentTin,
        (agentTin, prevState) => {
            if (agentTin && prevState !== agentTin) {
                setValue('agentTin', agentTin);
            }
        },
        { fireImmediately: true }
    );

    if (agentTindestroyed) {
        tinSub();
    }
    /*-------- subscribe with slectors -----------*/

    /* ------------------ FORM -----------------*/

    /*------------  FORM MEHTODS --------------*/

    const handleZipCodeChange = (event) => {
        const value = event.target.value.replace(/[^0-9]/g, '');
        event.target.value = value;
    };


    const handelChange = (event) => {
        stores.globalStore.agentUpdateCoverageType(event.target.value);
        showAlertModal(event);
    }

    const showAlertModal = (event) => {
        if (event && event.target.value && stores.globalStore.agentState === CALIFORNIA_CODE && DENTAL_PLAN_TYPES.includes(event && event.target.value) && filterDependentsOnParentRelationType(getValues().dependents).length > 0) {
            setAlertModalOpen(true);
            removeDeps();

        } else if (stores.globalStore.agentState !== CALIFORNIA_CODE && filterDependentsOnParentRelationType(getValues().dependents).length > 0) {
            setAlertModalOpen(true);
            removeDeps();
        }
    }

    const removeDeps = () => {
        let values: number[] = []
        getValues().dependents.forEach((element, index) => {
            if (element.applicantType && PARENT_VALUES.includes(element.applicantType)) {
                values.push(index);
            }
        });
        dependentRemove(values);
    }

    const changePlanYear = (event) => {
        stores.globalStore.agentUpdateYear(event.target.value);
        stores.globalStore.agentUpdateCoverageDates(stores.globalStore.agentZipCodeRes.coverageEffectiveDate.coverageDt, event.target.value);
        stores.globalStore.agentupdatePlanTypes(getValues('county'), getValues('year'), stores.globalStore.agentSSOInfo.agentType);
        trigger();
    }

    /*------------  FORM MEHTODS --------------*/

    /*----- Dependents alert Alert modal modal ----*/
    const closeAlertPopup = () => {
        setAlertModalOpen(false);
    }
    /*----- Dependents alert Alert modal modal ----*/

    /*---------------- FORM VALIDATIONS -------------------*/

    const martketIDWatch = watch('marketingRefId');

    useEffect(() => {
        if ((ALPHA_NUMERIC_REGX.test(martketIDWatch) && martketIDWatch.length === 4)) {
            validateMarketIDApi(martketIDWatch);
        } else {
            setMarketingError(false);
        }
    }, [martketIDWatch])

    const validateMarketIDApi = async (value) => {
        const response = await getMarketingIdValidated(value);
        if (response?.data?.valid !== 'Y') {
            setMarketingError(true);
        } else {
            setMarketingError(false);
        }
    }

    const validateFirstName = (value) => {
        return ALPHA_REGX_WITH_SPACE_HYPEN.test(value);
    }


    const validateTobacco = (value) => {
        if (value) {
            return value === SET_APPLICATION_CONSTANTS.YES || value === SET_APPLICATION_CONSTANTS.NO;
        } else {
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD;
        }
    }

    const updateCoverageTypeOptions = (isWellpoint, options, newPlanTypes?) => {
        if (isWellpoint) {
            setCoverageTypeOptions(options);
            stores.globalStore.agentUpdateCoverageType(options[0]?.value);
            setValue('coverageType', options[0]?.value);
        } else {
            const updateOptions = options?.filter((type) => {
                return newPlanTypes.indexOf(type.planType) !== -1;
            });
            setCoverageTypeOptions(updateOptions);
            stores.globalStore.agentUpdateCoverageType(updateOptions[0]?.value);
            setValue('coverageType', updateOptions[0]?.value);
        }
    };

    const validateRealationShipType = (id: 'applicant' | `dependents.${any}` | `spouse.${any}`, value) => {
        if (getValues(`${id}.dateOfBirth`) !== '') {
            trigger(`${id}.dateOfBirth`);
        }
        if (value === RELATIONSHIP.PARENTINLAW && spouseFields.length === 0) {
            setError(`${id}.applicantType`, { type: 'validate' });
            return false;
        }
        return value !== '';
    }

    const validateMarketingId = async (value) => {
        if (!(ALPHA_NUMERIC_REGX.test(value) && value.length === 4)) {
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.MARKETING_ID;
        }
    }

    const validateAge = (type, value, id?: 'applicant' | `dependents.${any}` | `spouse.${any}`, relationType?) => {
        let spouseLength = spouseFields.length;
        let invalidDate = isInValidDate(value);
        if (value === '' && invalidDate) {
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD;
        } else if (invalidDate) {
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_DATE;
        } else if (isDateinFuture(stores.globalStore.agentZipCodeRes.currentDateTimeInZone, value)) {
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.FUTURE_DATE;
        } else if (type === 'applicant' && value) {
            if (spouseLength > 0) {
                let val1 = isApplicantDateInRange(stores.globalStore.agentCoverageDate, value);
                if (val1) {
                    let val = isApplicantSpouseInRange(stores.globalStore.agentCoverageDate, value);
                    if (!val) {
                        return pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_SPOUSE_DOB_ERROR;
                    }
                } else {
                    return stores.globalStore.agentCoverageType ? (MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType) ? pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_MEDICAL_ERROR : pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_DENTAL_VISION_ERROR) : '';
                }
                return val1;
            } else {
                let val = isApplicantDateInRange(stores.globalStore.agentCoverageDate, value);
                if (!val) {
                    return stores.globalStore.agentCoverageType ? (MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType) ? pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_MEDICAL_ERROR : pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_DENTAL_VISION_ERROR) : '';
                }
                return val;
            }
        } else if (type === 'spouse' && value) {
            let val1 = isApplicantDateInRange(stores.globalStore.agentCoverageDate, value);
            let val = isApplicantSpouseInRange(stores.globalStore.agentCoverageDate, value);
            if (val1) {
                if (getValues('applicant.dateOfBirth') !== '') {
                    trigger('applicant.dateOfBirth');
                }
                if (!val) {
                    return pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_SPOUSE_DOB_ERROR;
                }
            } else {
                return stores.globalStore.agentCoverageType ? (MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType) ? pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_MEDICAL_ERROR : pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_DENTAL_VISION_ERROR) : '';
            }
            return val;
        } else if (type === 'dependent' && value) {
            //isDependentChildDateInRange
            if (stores.globalStore.agentState === CALIFORNIA_CODE && MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType)) {
                if ((relationType === RELATIONSHIP.CHILD || relationType === RELATIONSHIP.OTHER)) {
                    let val = isDependentChildDateInRange(stores.globalStore.agentCoverageDate, value);
                    if (!val) {
                        return pageContent?.FORM_CONTENT.ERROR_MESSAGES.DEPENDENT_DOB_ERROR;
                    }
                    return val;
                } else {
                    let val = isApplicantDateInRange(stores.globalStore.agentCoverageDate, value);
                    if (!val) {
                        return pageContent?.FORM_CONTENT.ERROR_MESSAGES.APPLICANT_ONLY_DOB_MEDICAL_ERROR;
                    } else {
                        return true;
                    }
                }
            } else {
                let val = isDependentChildDateInRange(stores.globalStore.agentCoverageDate, value);
                if (!val) {
                    return pageContent?.FORM_CONTENT.ERROR_MESSAGES.DEPENDENT_DOB_ERROR;
                }
                return val;
            }
        }
    }


    const isNotSelect = (value, countyList) => {
        if (value === 'select') {
            return pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD;
        } else {
            let data = countyList.find((county) => {
                return county.countyName === value;
            });
            if (data?.inService.value === 'Y' && getAgentStates(stores.globalStore.agentSSOInfo, data.stateCode) && (stores.userStore.isWellpoint ? data.brand === BRAND_NAMES.WELLPOINT_BRAND : data.brand != BRAND_NAMES.WELLPOINT_BRAND)) {
                setZipError(false);
                getAgentTin(data.stateCode);
                stores.globalStore.agentUpdateCounty(data.countyName, data.countyCode, data.brand);
                stores.globalStore.agentupdatePlanTypes(data.countyName, getValues('year'), stores.globalStore.agentSSOInfo.agentType);
                return true;
            } else {
                setZipError(true);
                setValue('coverageType', '');
                getAgentTin('');
                stores.globalStore.agentUpdateCoverageType('');
                showAgentSubsidy(stores.globalStore.agentSSOInfo, '', '');
                stores.globalStore.resetAgentPlanTypes();
                return false
            }
        }
    };


    const zipcodeWatch = watch('zipcode');

    useEffect(() => {
        if ((editFlow && stores.globalStore?.householdMemberValues?.houseHoldIncome)) {
            setShowSubsidyBlock(true)
        }
        if (editFlow || (zipcodeWatch && zipcodeWatch.length === 5 && stores.globalStore.agentZipcode !== zipcodeWatch)) {
            validateZipCodeWithAPICall(zipcodeWatch);
        }
    }, [zipcodeWatch]);

    /*---------------- FORM VALIDATIONS -------------------*/

    /*-------------- get AGebt TIn ----------------*/
    const getAgentTin = (state) => {
        if (state) {
            const agentInfo = stores.globalStore.agentSSOInfo.agentInfo.agent[0];
            const filteredAgentState = stores.globalStore.agentSSOInfo.agentInfo.agent[0].states.state.find((stateObj) => {
                return state === stateObj.stateName
            });
            stores.globalStore.agentUpdateTin(
                (agentInfo.agentFName ? (agentInfo.agentFName + "-") : '') +
                (agentInfo.writingTIN ? (agentInfo.writingTIN + "-") : '') +
                (filteredAgentState?.agencyInfo.agency.paidTIN ? (filteredAgentState?.agencyInfo.agency.paidTIN + "-") : '') +
                (filteredAgentState?.agencyInfo.agency.parentTIN ? (filteredAgentState?.agencyInfo.agency.parentTIN + "-") : '') +
                filteredAgentState?.agencyInfo.agency.agentCode);
            setValue('agentTin',
                (agentInfo.agentFName ? (agentInfo.agentFName + "-") : '') +
                (agentInfo.writingTIN ? (agentInfo.writingTIN + "-") : '') +
                (filteredAgentState?.agencyInfo.agency.paidTIN ? (filteredAgentState?.agencyInfo.agency.paidTIN + "-") : '') +
                (filteredAgentState?.agencyInfo.agency.parentTIN ? (filteredAgentState?.agencyInfo.agency.parentTIN + "-") : '') +
                filteredAgentState?.agencyInfo.agency.agentCode)
        } else {
            stores.globalStore.agentUpdateTin('');
            setValue('agentTin', '');
        }
    }


    /* -------------- API CALLS --------------*/

    const validateZipCodeWithAPICall = async (zipcode) => {
        setAgentStateError(false);
        try {
            setLoading(true);
            const response = await getZipcodeValidation({ zipCode: zipcode, marketSegment: 'UNDER_65' });
            if (response.data.zipCodeResponse.responseMessage.message === 'SUCCESSFULLY FETCHED') {
                editFlow ? stores.globalStore.agentupdateZipCodeResponseOnEdit(response.data.zipCodeResponse.zipCode, response.data.zipCodeResponse.zipCode.countyList.county, getValues('year')) : stores.globalStore.agentupdateZipCodeResponse(response.data.zipCodeResponse.zipCode, response.data.zipCodeResponse.zipCode.countyList.county, getValues('year'))
                editFlow && getAgentTin(stores.globalStore.agentState)
                setEditFlow(false)
                setCountyList(response.data.zipCodeResponse.zipCode.countyList.county);
                setZipcodeFieldsandErrors(response);
            }
            else if (response.data.zipCodeResponse.responseMessage.message === 'INVALID ZIPCODE') {
                stores.globalStore.resetAgentUpdateZipCode();
                setCountyList([]);
                setError('zipcode', { type: 'validate', message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_ZIP });
                getAgentTin('');
            } else {
                setError('zipcode', { type: 'validate', message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_ZIP });
                getAgentTin('');
            }
        } catch (error) {
            console.error('Error fetching county data:', error);
        } finally {
            setLoading(false);
        }
        return true
    };


    const setZipcodeFieldsandErrors = (response) => {
        if (response.data.zipCodeResponse.zipCode.countyList.county.length === 1) {
            if (getAgentStates(stores.globalStore.agentSSOInfo, response.data.zipCodeResponse.zipCode.stateCode) && response.data.zipCodeResponse.zipCode.countyList.county[0].inService.value === 'Y' && (stores.userStore.isWellpoint ? response.data.zipCodeResponse.zipCode.countyList.county[0].brand === BRAND_NAMES.WELLPOINT_BRAND : response.data.zipCodeResponse.zipCode.countyList.county[0].brand != BRAND_NAMES.WELLPOINT_BRAND)) {
                setAgentStateError(false);
                setValue('county', response.data.zipCodeResponse.zipCode.countyList.county[0].countyName);
                stores.globalStore.agentupdatePlanTypes(response.data.zipCodeResponse.zipCode.countyList.county[0].countyName, getValues('year'), stores.globalStore.agentSSOInfo.agentType);
                getAgentTin(response.data.zipCodeResponse.zipCode.stateCode);
                setZipError(false);
                if (stores.userStore.isWellpoint) {
                    stores.globalStore.agentUpdateCoverageType(PLAN_TYPES.MEDICAL);
                }
            } else {
                if (response.data.zipCodeResponse.zipCode.countyList.county[0].inService.value === 'N') {
                    setZipError(true);
                } else if (response.data.zipCodeResponse.zipCode.countyList.county[0].inService.value === 'Y' && !getAgentStates(stores.globalStore.agentSSOInfo, response.data.zipCodeResponse.zipCode.stateCode)) {
                    setAgentStateError(true);
                } else {
                    setError('zipcode', { type: 'validate', message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_ZIP });
                }

                if (!editFlow) {
                    setValue('county', response.data.zipCodeResponse.zipCode.countyList.county[0].countyName);
                    getAgentTin('');
                    stores.globalStore.resetAgentPlanTypes();
                }


            }
        }
    }
    /* -------------- API CALLS --------------*/


    if (!contentLoaded) {
        return <FullPageLoader />
    } else {
        return (
            <>
                <div className={'agent-landing-container'}>
                    <div className='fwc-row'>
                        <div className='fwc-col-12'>
                            <div className='title-wrapper'>
                                <h2 className='title'>{pageContent.CONTENT.TITLE}</h2>
                                <p>{pageContent.CONTENT.TITLE_DESC}</p>
                            </div>
                            <h4 className='sub-title'>{pageContent.CONTENT.SUB_TITLE}</h4>
                        </div>
                    </div>

                    <form onSubmit={handleSubmit(onSubmit)} noValidate>
                        <div className='fwc-row row-relative'>
                            {showSubsidyBlock && <div className='overlayCustom'></div>}
                            <div className='fwc-col-12'>
                                <div className='fwc-row'>
                                    <div className='fwc-col-4 md-6 sm-12'>
                                        <div className='form-group more'>
                                            <div className="fwc-input">
                                                <label className="fwc-label" htmlFor="zipcode">
                                                    {pageContent?.FORM_CONTENT.LABELS.ZIPCODE}
                                                </label>
                                                <div className='zipcode-wrapper'>
                                                    <input
                                                        id="zipcode"
                                                        maxLength={5}
                                                        placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.ZIP_CODE}
                                                        type="text"
                                                        {...register('zipcode', {
                                                            required: {
                                                                value: true,
                                                                message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                                            },
                                                            minLength: {
                                                                value: 5,
                                                                message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_ZIP
                                                            },
                                                            onChange: (e) => {
                                                                handleZipCodeChange(e);
                                                            }
                                                        })}
                                                    />
                                                    <span className='sae-icon sae-icon-marker'></span>
                                                </div>
                                                {errors.zipcode?.message && errors.zipcode.type !== "validate" && (
                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                                                        {errors.zipcode?.message}
                                                    </span>
                                                )}
                                                {/* {errors && errors.zipcode && errors.zipcode && errors.zipcode.type === "required" && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                </span>
                                            )} */}
                                                {errors && errors.zipcode && errors.zipcode && errors.zipcode.type === "validate" && (
                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                        <span dangerouslySetInnerHTML={{ __html: errors.zipcode.message || '' }}></span>
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    {countyList && countyList.length === 1 && <div className='fwc-col-4'>
                                        <div className="fwc-input ">
                                            <label className="fwc-label" htmlFor="county-select">
                                                {pageContent?.FORM_CONTENT.LABELS.COUNTY}
                                            </label>
                                            <input disabled type="text" {...register('county', { value: countyList[0].countyName })} />
                                        </div>
                                    </div>}
                                    {countyList && countyList.length > 1 && <div className='fwc-col-4'>
                                        <div className="fwc-select-list ">
                                            <label className="fwc-label" htmlFor="county-select">
                                                {pageContent?.FORM_CONTENT.LABELS.COUNTY}
                                            </label>
                                            <Controller
                                                name="county"
                                                control={control}
                                                defaultValue={'select'}
                                                rules={{ validate: (value) => isNotSelect(value, countyList) }}
                                                render={({ field }) => (
                                                    <select id="county-select" {...field} onChange={(e) => {
                                                        field.onChange(e);
                                                    }}>
                                                        <option value="select" selected>{pageContent?.FORM_CONTENT.LABELS.SELECT}</option>
                                                        {countyList.map((county, index) => {
                                                            return (
                                                                <option key={index} value={county['countyName']}>
                                                                    {county['countyName']}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                )}
                                            />
                                            {errors.county?.message && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                                                    {errors.county?.message}
                                                </span>
                                            )}
                                        </div>
                                    </div>}
                                    <div className="fwc-col-3 md-6 sm-12">
                                        <div className={'fwc-flex'}>
                                            <label className="fwc-label right-xxs" htmlFor="year-select">
                                                {pageContent?.ZIPCODE_DYNAMIC_DATA.PLAN_YEAR}{' '}
                                            </label>
                                            <Tooltip icon message={pageContent?.ZIPCODE_DYNAMIC_DATA.PLAN_YEAR_TOOLTIP}></Tooltip>
                                        </div>

                                        <div className="fwc-select-list">
                                            <Controller
                                                name="year"
                                                control={control}
                                                defaultValue={JSON.parse(pageContent?.ZIPCODE_DYNAMIC_DATA.JSON_AREA).planYear[0]}
                                                rules={{
                                                    required: {
                                                        value: true,
                                                        message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                                    }
                                                }}
                                                render={({ field }) => (
                                                    <select
                                                        data-analytics={'planYearLpIos'}
                                                        {...field}
                                                        onChange={(e) => {
                                                            changePlanYear(e)
                                                        }}
                                                    >
                                                        {JSON.parse(pageContent?.ZIPCODE_DYNAMIC_DATA.JSON_AREA).planYear.map((yearvalue, index) => {
                                                            return (
                                                                <option key={index} value={yearvalue} data-analytics={yearvalue.toString() === new Date().getFullYear().toString() ? 'currentYearLpIos' : 'futureYearLpIos'}>
                                                                    {yearvalue}
                                                                </option>
                                                            );
                                                        })}
                                                    </select>
                                                )}
                                            />
                                        </div>

                                    </div>
                                </div>
                                {zipError && <div className="fwc-row">
                                    <div className="fwc-col-12 form-group">
                                        <div className="error-callout">
                                            <div className="fwc-flex a-start">
                                                <div className="fwc-inline-icon fwc-icon-info error-icon"></div>
                                                <div>
                                                    <div id="zip_error" dangerouslySetInnerHTML={{ __html: stores.userStore.isWellpoint ? pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_COUNTY_LINK_FOR_WELLPOINT : pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_COUNTY_LINK_FOR_ANTHEM }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {agentStateError && <div className="fwc-row">
                                    <div className="fwc-col-12 form-group">
                                        <div className="error-callout">
                                            <div className="fwc-flex a-start">
                                                <div className="fwc-inline-icon fwc-icon-info error-icon"></div>
                                                <div>
                                                    <div id="agent_state_error" dangerouslySetInnerHTML={{ __html: pageContent?.FORM_CONTENT.ERROR_MESSAGES.AGENT_STATES_NOT_ALLOWED }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                {stores.globalStore.agentState === STATE_CODES.NY && <div className='fwc-row'>
                                    <div className='fwc-col-12 form-group'>
                                        <div className='error-callout'>
                                            <div className="fwc-flex a-start">
                                                <div className="fwc-inline-icon fwc-icon-info error-icon"></div>
                                                <div>
                                                    <div id="agent_state_info" dangerouslySetInnerHTML={{ __html: pageContent?.FORM_CONTENT.ERROR_MESSAGES.AGENT_STATE_INFO }}>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                                <div className='fwc-row'>
                                    <div className='fwc-col-12'>
                                        <div className='form-group more'>
                                            {coverageTypeOptions.length > 0 && (
                                                <>
                                                    <Controller
                                                        name="coverageType"
                                                        control={control} // Set your desired default value
                                                        rules={{ required: { value: true, message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD } }}
                                                        render={({ field }) => (
                                                            <CustomRadioGroup
                                                                field={field}
                                                                label={stores.userStore.isWellpoint ? pageContent?.ATK_WELLPOINT_OPTIONS.label : pageContent?.ATK_ANTHEM_OPTIONS.label}
                                                                name={stores.userStore.isWellpoint ? pageContent?.ATK_WELLPOINT_OPTIONS.name : pageContent?.ATK_ANTHEM_OPTIONS.name}
                                                                options={coverageTypeOptions}
                                                                onCustomRadioSelect={handelChange} // Set your desired default value
                                                                role={''}
                                                            />
                                                        )}
                                                    />
                                                    <div className="fwc-row">
                                                        <div className="fwc-col-12">
                                                            {errors.coverageType?.message && (
                                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                                                                    {errors.coverageType?.message}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </>
                                            )}
                                        </div>
                                    </div>
                                </div>
                                <div className='fwc-row'>
                                    <div className='fwc-col-6'>
                                        <div className='form-group more'>
                                            <div className="fwc-input">
                                                <label className="fwc-label" htmlFor="agentTin">
                                                    {pageContent?.FORM_CONTENT.LABELS.AGENT_TIN}
                                                </label>
                                                <input
                                                    id="agentTin"
                                                    readOnly
                                                    placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.AGENT_TIN}
                                                    type="text"
                                                    {...register('agentTin', {
                                                        required: {
                                                            value: true,
                                                            message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                                        }
                                                    })}
                                                />
                                            </div>
                                        </div>
                                    </div>
                                    <div className='fwc-col-6'>
                                        <div className='form-group more'>
                                            <div className="fwc-input">
                                                <div className='fwc-flex'>
                                                    <label className="fwc-label" htmlFor="marketingRefId">
                                                        {pageContent?.FORM_CONTENT.LABELS.MARKETING_REFERENCE}
                                                    </label>
                                                    <Tooltip icon message={pageContent?.FORM_CONTENT.TOOL_TIPS.MARKETING_ID} />
                                                </div>
                                                <input
                                                    id="marketingRefId"
                                                    placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.MARKETING_REFERENCE}
                                                    type="text"
                                                    {...register('marketingRefId', {
                                                        validate: (value) => value === '' || validateMarketingId(value)
                                                    })}
                                                />
                                                {errors && errors.marketingRefId && errors.marketingRefId?.type === "validate" && (
                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                        {errors.marketingRefId?.message}
                                                    </span>
                                                )}
                                                {marketingError && (
                                                    <div className="banner">
                                                        <div className="fwc-row">
                                                            <div className="fwc-col-12">
                                                                <p><span className="sae-icon sae-icon-high-importance"></span>
                                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.MARKETING_ID_NOT_Valid}</p>
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {stores.globalStore.agentCoverageType && <div className='fwc-row'>
                                    <div className='fwc-col-12'>
                                        <div className="update-zipcode-block">
                                            <div className="autopopulatefiedls">
                                                <p id="coverage-date"><span className="sae-icon sae-icon-checked"></span>{pageContent?.FORM_CONTENT.LABELS.COVERAGE_DATE}<span>{dayjs(stores.globalStore.agentCoverageDate).format(DATE_FORMATS.MMDDYYYY)}</span></p>
                                            </div>
                                        </div>
                                    </div>
                                </div>}
                            </div>
                        </div>
                        {
                            stores.globalStore.agentState && !agentStateError && !zipError && <div className='demographic-data'>
                                {showSubsidyBlock && <div className='overlayCustom'></div>}
                                <div className='fwc-row'>
                                    <div className='fwc-col-12'>
                                        <h4 className='sub-title'>{pageContent?.CONTENT.APPLICANT_DEMOGRAPHICS}</h4>
                                    </div>
                                </div>
                                <div className='fwc-row'>
                                    <div className='fwc-col-5 md-6 sm-12'>
                                        <div className='form-group'>
                                            <div className="fwc-input">
                                                <label className="fwc-label" htmlFor="firstName">
                                                    {pageContent?.FORM_CONTENT.LABELS.FIRST_NAME}
                                                </label>
                                                <input
                                                    id="firstName"
                                                    placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.FIRST_NAME}
                                                    type="text"
                                                    {...register('applicant.firstName', {
                                                        required: {
                                                            value: true,
                                                            message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                                        },
                                                        validate: validateFirstName
                                                    })}
                                                />
                                                {errors && errors.applicant?.firstName && errors.applicant?.firstName && errors.applicant?.firstName.type === "required" && (
                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                    </span>
                                                )}
                                                {errors && errors.applicant?.firstName && errors.applicant?.firstName && errors.applicant?.firstName.type === "validate" && (
                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_CHARACTER}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                    <Controller
                                        control={control}
                                        defaultValue={'0'}
                                        render={({ field }) => (
                                            <input type="hidden" {...field} />
                                        )}
                                        name={`applicant.applicantId`}
                                    />
                                    <div className='fwc-col-5 md-6 sm-12'>
                                        <div className='form-group'>
                                            <div className="fwc-input">
                                                <label className="fwc-label" htmlFor="lastName">
                                                    {pageContent?.FORM_CONTENT.LABELS.LAST_NAME}
                                                </label>
                                                <input
                                                    id="lastName"
                                                    placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.LAST_NAME}
                                                    type="text"
                                                    {...register('applicant.lastName', {
                                                        required: {
                                                            value: true,
                                                            message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                                        },
                                                        validate: validateFirstName
                                                    })}
                                                />
                                                {errors && errors.applicant?.lastName && errors.applicant?.lastName && errors.applicant?.lastName.type === "required" && (
                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                    </span>
                                                )}
                                                {errors && errors.applicant?.lastName && errors.applicant?.lastName && errors.applicant?.lastName.type === "validate" && (
                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_CHARACTER}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='fwc-row wrap-flex'>
                                    <div className='fwc-col-5 md-6 sm-12'>
                                        <div className='fwc-row'>
                                            <div className='fwc-col-6'>
                                                <div className='form-group'>
                                                    <div className="fwc-input dob">
                                                        <label className="fwc-label" htmlFor="applicant.dateOfBirth">
                                                            {pageContent?.FORM_CONTENT.LABELS.DATE_OF_BIRTH}
                                                        </label><span><Tooltip icon message={pageContent?.FORM_CONTENT.LABELS.HELP_DATE_OF_BIRTH} /></span>
                                                        <input type="date" id="applicant.dateOfBirth"
                                                            placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.DOB} min={dayjs('1900-01-01').format('YYYY-MM-DD')} max={dayjs().format('YYYY-MM-DD')} {...register(`applicant.dateOfBirth`, {
                                                                validate: (value) => validateAge('applicant', value, `applicant`),
                                                            })} />
                                                        {errors && errors.applicant?.dateOfBirth && errors.applicant?.dateOfBirth && errors.applicant?.dateOfBirth.type === "validate" && (
                                                            <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                {errors.applicant?.dateOfBirth.message}
                                                            </span>
                                                        )}
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    {((stores.globalStore.agentState && stores.globalStore.agentState === SET_APPLICATION_CONSTANTS.KY ? (TOBACCO_STATE_CODES.includes(stores.globalStore.agentState) && stores.globalStore.agentYear === DEFAULT_YEAR) : TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) && <div className='fwc-col-5 md-6 sm-12'>
                                        <div className='form-group'>
                                            <fieldset className='tobacco-fieldset'>
                                                <legend className="fwc-label tobacco">
                                                    {pageContent?.FORM_CONTENT.LABELS.TOBACCO}<span></span>
                                                </legend>
                                                <div className="fwc-radio">
                                                    <input
                                                        {...register('applicant.isTabaccoUser', {
                                                            validate: (value) => validateTobacco(value)
                                                        })}
                                                        type="radio"
                                                        value="YES"
                                                        id="applicant-tobacco-yes"
                                                    />
                                                    <label htmlFor="applicant-tobacco-yes">Yes</label>
                                                </div>
                                                <div className="fwc-radio">
                                                    <input
                                                        {...register('applicant.isTabaccoUser', {
                                                            validate: (value) => validateTobacco(value)
                                                        })}
                                                        type="radio"
                                                        value="NO"
                                                        id="applicant-tobacco-no"
                                                    />
                                                    <label htmlFor="applicant-tobacco-no">No</label>
                                                </div>
                                            </fieldset>
                                            {errors && errors.applicant && errors.applicant.isTabaccoUser && errors.applicant.isTabaccoUser.type === "validate" && (
                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                </span>
                                            )}
                                        </div>
                                    </div>}
                                    {!((stores.globalStore.agentState && TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) && (
                                        <Controller
                                            control={control}
                                            defaultValue={'NO'}
                                            render={({ field }) => (
                                                <input type="hidden" {...field} />
                                            )}
                                            name={`applicant.isTabaccoUser`}
                                        />
                                    )}
                                    <div className='fwc-col-5 md-6 sm-12'>
                                        <div className='form-group'>
                                            <div className="fwc-input">
                                                <label className="fwc-label" htmlFor="email">
                                                    {pageContent?.FORM_CONTENT.LABELS.EMAIL}
                                                </label>
                                                <input
                                                    id="email"
                                                    placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.EMAIL}
                                                    type="email"
                                                    {...register('applicant.email', {
                                                        required: {
                                                            value: true,
                                                            message: pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD
                                                        }
                                                    })}
                                                />
                                                {errors && errors.applicant?.email && errors.applicant?.email && errors.applicant?.email.type === "required" && (
                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                    </span>
                                                )}
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {spouseFields.map((element, index) => {
                                    return (
                                        <>
                                            <div className={"fwc-row "} key={element.id}>
                                                <div className="fwc-col-5 md-6 sm-12">
                                                    <div className='form-group'>
                                                        <div className="fwc-input">
                                                            <label
                                                                className="fwc-label"
                                                                htmlFor={`spouse.${index}.firstName`}
                                                            >
                                                                {pageContent?.FORM_CONTENT.LABELS.SPOUSE_DOMESTIC_PARTNER.FIRST_NAME}
                                                            </label>

                                                            <input
                                                                {...register(`spouse.${index}.firstName`, {
                                                                    required: {
                                                                        value: true,
                                                                        message: pageContent?.REQUIRED_FIELD,
                                                                    },
                                                                    validate: validateFirstName,
                                                                })}
                                                                id={`spouse.${index}.firstName`}
                                                                maxLength={30}
                                                                placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.FIRST_NAME}
                                                                type="text"
                                                            />
                                                            {errors.spouse?.[index]?.firstName?.type === "required" && (
                                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                                </span>
                                                            )}
                                                            {errors.spouse?.[index]?.firstName?.type === "validate" && (
                                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_CHARACTER}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="fwc-col-5 md-6 sm-12">
                                                    <div className='form-group'>
                                                        <div className="fwc-input">
                                                            <label
                                                                className="fwc-label"
                                                                htmlFor={`spouse.${index}..lastName`}
                                                            >
                                                                {pageContent?.FORM_CONTENT.LABELS.SPOUSE_DOMESTIC_PARTNER.LAST_NAME}
                                                            </label>

                                                            <input
                                                                {...register(`spouse.${index}.lastName`, {
                                                                    required: {
                                                                        value: true,
                                                                        message: pageContent?.REQUIRED_FIELD,
                                                                    },
                                                                    validate: validateFirstName,
                                                                })}
                                                                id={`spouse.${index}.lastName`}
                                                                maxLength={30}
                                                                placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.LAST_NAME}
                                                                type="text"
                                                            />
                                                            {errors.spouse?.[index]?.lastName?.type === "required" && (
                                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                                </span>
                                                            )}
                                                            {errors.spouse?.[index]?.lastName?.type === "validate" && (
                                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_CHARACTER}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>

                                                <div className="fwc-col-2 md-6 sm-12 desktop">
                                                    <i
                                                        className="sae-icon sae-icon-trash trash-icon"
                                                        id={`remove-spouse${index}`}
                                                        onClick={() => { spouseRemove(index); }}
                                                    ></i>
                                                </div>
                                            </div>
                                            <div className="fwc-row dateofbirth">
                                                <div className="fwc-col-5 md-6 sm-12">
                                                    <div className="fwc-row">
                                                        <div className="fwc-col-5 md-6 sm-12">
                                                            <div className='form-group'>
                                                                <div className="fwc-input dob">
                                                                    <label
                                                                        className="fwc-label"
                                                                        htmlFor={`spouse.${index}.dateOfBirth`}
                                                                    >
                                                                        {pageContent?.FORM_CONTENT.LABELS.DATE_OF_BIRTH}{" "}
                                                                    </label><span>
                                                                        <Tooltip icon message={pageContent?.FORM_CONTENT.LABELS.HELP_DATE_OF_BIRTH} /></span>
                                                                    <input type="date" min={dayjs('1900-01-01').format('YYYY-MM-DD')} max={dayjs().format('YYYY-MM-DD')} {...register(`spouse.${index}.dateOfBirth`, {
                                                                        validate: (value) => validateAge('spouse', value, `spouse.${index}`),
                                                                    })} />
                                                                    {errors.spouse?.[index]?.dateOfBirth?.type === "required" && (
                                                                        <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                            {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                                        </span>
                                                                    )}
                                                                    {errors.spouse?.[index]?.dateOfBirth?.type === "validate" && (
                                                                        <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                            {errors.spouse[index]?.dateOfBirth?.message}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {((stores.globalStore.agentState && stores.globalStore.agentState === SET_APPLICATION_CONSTANTS.KY ? (TOBACCO_STATE_CODES.includes(stores.globalStore.agentState) && stores.globalStore.agentYear === DEFAULT_YEAR) : TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) && (

                                                    <div className='fwc-col-5 md-6 sm-12'>
                                                        <div className='form-group'>
                                                            <fieldset className='tobacco-fieldset mt20'>
                                                                <legend className="fwc-label tobacco">
                                                                    {pageContent?.FORM_CONTENT.LABELS.TOBACCO}<span><Tooltip icon message={pageContent?.HELP_TOBACCO} /></span>
                                                                </legend>
                                                                <div className="fwc-radio">
                                                                    <input
                                                                        {...register(`spouse.${index}.isTabaccoUser`, {
                                                                            validate: (value) => validateTobacco(value)
                                                                        })}
                                                                        type="radio"
                                                                        value="YES"
                                                                        id={`spouse-${index}-tobacco-yes`}
                                                                    />
                                                                    <label htmlFor={`spouse-${index}-tobacco-yes`}>Yes</label>
                                                                </div>
                                                                <div className="fwc-radio">
                                                                    <input
                                                                        {...register(`spouse.${index}.isTabaccoUser`, {
                                                                            validate: (value) => validateTobacco(value)
                                                                        })}
                                                                        type="radio"
                                                                        value="NO"
                                                                        id={`spouse-${index}-tobacco-no`}
                                                                    />
                                                                    <label htmlFor={`spouse-${index}]-tobacco-no`}>No</label>
                                                                </div>
                                                            </fieldset>
                                                            {errors.spouse?.[index]?.isTabaccoUser?.type === "validate" && (
                                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>

                                                )}
                                            </div>
                                            <div className='fwc-row'>

                                                <div className="fwc-col-4 md-6 sm-12 mobile">
                                                    <Button
                                                        btnType="secondary"
                                                        id={`remove-spouse${index}`}
                                                        onClick={() => { spouseRemove(index); }}
                                                    >Remove Spouse</Button>
                                                </div>
                                            </div>
                                            <div className='fwc-row divider'>
                                                <div className='fwc-col-12 '>
                                                    <hr />
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}

                                {dependentFields.map((element, index) => {
                                    return (
                                        <>
                                            <div className={"fwc-row"} key={element.id}>
                                                <div className="fwc-col-5 md-6 sm-12">
                                                    <div className='form-group'>
                                                        <div className="fwc-input">
                                                            <label className="fwc-label" htmlFor={`dependents.${index}.firstName`}>
                                                                {pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.FIRST_NAME}
                                                            </label>
                                                            <input
                                                                {...register(`dependents.${index}.firstName`, {
                                                                    required: true,
                                                                    validate: validateFirstName,
                                                                })}
                                                                id={`dependents.${index}.firstName`}
                                                                maxLength={30}
                                                                placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.FIRST_NAME}
                                                                type="text"
                                                            />
                                                            {errors.dependents?.[index]?.firstName?.type === "required" && (
                                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                                </span>
                                                            )}
                                                            {errors.dependents?.[index]?.firstName?.type === "validate" && (
                                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_CHARACTER}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="fwc-col-5 md-6 sm-12">
                                                    <div className='form-group'>
                                                        <div className="fwc-input">
                                                            <label className="fwc-label" htmlFor={`dependents.${index}.lastName`}>
                                                                {pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.LAST_NAME}
                                                            </label>
                                                            <input
                                                                {...register(`dependents.${index}.lastName`, {
                                                                    required: true,
                                                                    validate: validateFirstName,
                                                                })}
                                                                id={`dependents.${index}.lastName`}
                                                                maxLength={30}
                                                                placeholder={pageContent?.FORM_CONTENT.PLACE_HOLDERS.LAST_NAME}
                                                                type="text"
                                                            />
                                                            {errors.dependents?.[index]?.lastName?.type === "required" && (
                                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                                </span>
                                                            )}
                                                            {errors.dependents?.[index]?.lastName?.type === "validate" && (
                                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.INVALID_CHARACTER}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="fwc-col-2 md-12 sm-12 desktop">
                                                    <i
                                                        className="sae-icon sae-icon-trash trash-icon"
                                                        id={`remove-dependent${index}`}
                                                        onClick={() => { dependentRemove(index) }}
                                                    ></i>
                                                </div>
                                            </div>
                                            <div className="fwc-row">
                                                <div className="fwc-col-5 md-6 sm-12">
                                                    <div className="fwc-row dateofbirth">
                                                        <div className="fwc-col-6">
                                                            <div className='form-group'>
                                                                <div className="fwc-input dob">
                                                                    <label
                                                                        className="fwc-label"
                                                                        htmlFor={`dependents.${index}.dateOfBirth`}
                                                                    >
                                                                        {pageContent?.FORM_CONTENT.LABELS.DATE_OF_BIRTH}{" "}
                                                                    </label> <span>
                                                                        <Tooltip icon message={pageContent?.FORM_CONTENT.LABELS.HELP_DATE_OF_BIRTH} /></span>
                                                                    <input type="date" min={dayjs('1900-01-01').format('YYYY-MM-DD')} max={dayjs().format('YYYY-MM-DD')}  {...register(`dependents.${index}.dateOfBirth`, {
                                                                        validate: (value) => validateAge('dependent', value, `dependents.${index}`, getValues(`dependents.${index}.applicantType`)),
                                                                    })} />
                                                                    {errors.dependents && errors.dependents?.[index]?.dateOfBirth?.type === "required" && (
                                                                        <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                            {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                                        </span>
                                                                    )}
                                                                    {errors.dependents && errors.dependents?.[index]?.dateOfBirth?.type === "validate" && errors.dependents?.[index]?.dateOfBirth?.message && (
                                                                        <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                            {errors.dependents?.[index]?.dateOfBirth?.message}
                                                                        </span>
                                                                    )}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {((stores.globalStore.agentState && stores.globalStore.agentState === SET_APPLICATION_CONSTANTS.KY ? (TOBACCO_STATE_CODES.includes(stores.globalStore.agentState) && stores.globalStore.agentYear === DEFAULT_YEAR) : TOBACCO_STATE_CODES.includes(stores.globalStore.agentState))) && (
                                                    <div className='fwc-col-5 md-6 sm-12'>
                                                        <div className='form-group'>
                                                            <fieldset className='tobacco-fieldset mt20'>
                                                                <legend className="fwc-label tobacco">
                                                                    {pageContent?.FORM_CONTENT.LABELS.TOBACCO}<span><Tooltip icon message={pageContent?.HELP_TOBACCO} /></span>
                                                                </legend>
                                                                <div className="fwc-radio">
                                                                    <input
                                                                        {...register(`dependents.${index}.isTabaccoUser`, {
                                                                            validate: (value) => validateTobacco(value)
                                                                        })}
                                                                        type="radio"
                                                                        value="YES"
                                                                        id={`dependents-${index}-tobacco-yes`}
                                                                    />
                                                                    <label htmlFor={`dependents-${index}-tobacco-yes`}>Yes</label>
                                                                </div>
                                                                <div className="fwc-radio">
                                                                    <input
                                                                        {...register(`dependents.${index}.isTabaccoUser`, {
                                                                            validate: (value) => validateTobacco(value)
                                                                        })}
                                                                        type="radio"
                                                                        value="NO"
                                                                        id={`dependents-${index}-tobacco-no`}
                                                                    />
                                                                    <label htmlFor={`dependents-${index}-tobacco-no`}>No</label>
                                                                </div>
                                                            </fieldset>
                                                            {errors.dependents && errors.dependents?.[index]?.isTabaccoUser?.type === "validate" && (
                                                                <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                    {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                                </span>
                                                            )}
                                                        </div>
                                                    </div>
                                                )}
                                                {(stores.globalStore.agentState === CALIFORNIA_CODE) && (stores.globalStore.agentCoverageType && MEDICAL_PLAN_TYPES.includes(stores.globalStore.agentCoverageType)) && (
                                                    <div className="fwc-col-3  md-6 sm-12">
                                                        <div className='form-group'>
                                                            <div className="fwc-select-list">
                                                                <label className="fwc-label" htmlFor={`dependents.${index}.relationship`}>
                                                                    {pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.RELATIONSHIP_APPLICANT}
                                                                </label>
                                                                <select
                                                                    className="text-trim"
                                                                    id={`dependents.${index}.relationship`}
                                                                    {...register(`dependents.${index}.applicantType`, {
                                                                        required: true,
                                                                        validate: (value) => value === '' || validateRealationShipType(`dependents.${index}`, value)
                                                                    })}
                                                                >
                                                                    <option value="">{pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.SELECT}</option>
                                                                    <option value={RELATIONSHIP.CHILD}>{pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.CHILD}</option>
                                                                    <option value={RELATIONSHIP.PARENT}>{pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.PARENT}</option>
                                                                    <option value={RELATIONSHIP.STEPPARENT}>{pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.STEPPARENT}</option>
                                                                    <option value={RELATIONSHIP.PARENTINLAW}>{pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.PARENT_IN_LAW}</option>
                                                                    <option value={RELATIONSHIP.OTHER}>{pageContent?.FORM_CONTENT.LABELS.DEPENDENTS.OTHER}</option>
                                                                </select>
                                                                {errors.dependents && errors.dependents?.[index]?.applicantType?.type === "required" && (
                                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.REQUIRED_FIELD}
                                                                    </span>
                                                                )}
                                                                {errors.dependents && errors.dependents?.[index]?.applicantType?.type === "validate" && (
                                                                    <span role="alert" className="fwc-inline-icon fwc-icon-delete">
                                                                        {pageContent?.FORM_CONTENT.ERROR_MESSAGES.PARENT_IN_LAW_ERROR}
                                                                    </span>
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                )}

                                            </div>
                                            <div className='fwc-row'>
                                                <div className="fwc-col-4 md-6 sm-12 mobile">
                                                    <Button
                                                        btnType="secondary"
                                                        className=""
                                                        id={`remove-dependent${index}`}
                                                        onClick={() => { dependentRemove(index) }}
                                                    >Remove Dependent</Button>
                                                </div>
                                            </div>
                                            <div className='fwc-row divider'>
                                                <div className='fwc-col-12 '>
                                                    <hr />
                                                </div>
                                            </div>
                                        </>
                                    );
                                })}
                                {!showSubsidyBlock && <div className='fwc-row'>
                                    <div className='fwc-col-12 addApplicantandOthers'>
                                        <Button
                                            btnType="secondary"
                                            name="add-dependent"
                                            id="add-dependent"
                                            disabled={(spouseFields.length === 1 ? dependentFields.length === 18 : dependentFields.length === 19)}
                                            onClick={(e) => { addDepSpouseFormFields(e, 'dependent'); }}
                                        >
                                            + {pageContent?.FORM_CONTENT.LABELS.ADD_DEPENDENT}
                                        </Button>
                                        <Button btnType="secondary" name="add-spouse" id="add-spouse" disabled={(spouseFields.length === 1 || dependentFields.length === 19)}
                                            onClick={(e) => addDepSpouseFormFields(e, 'spouse')}>
                                            + {pageContent?.FORM_CONTENT.LABELS.ADD_SPOUSE}
                                        </Button>
                                    </div>
                                </div>}
                            </div>
                        }
                        {stores.globalStore.agentState && !zipError && !agentStateError && !showSubsidyBlock && <div className="fwc-row">
                            <div className="fwc-col-12 submit-button">
                                <Button data-analytics="continueBtnBasicsIos" id="btContinue" type="submit">
                                    {pageContent?.FORM_CONTENT.LABELS.CONTINUE}
                                </Button>
                            </div>
                        </div>}
                    </form >
                    {showSubsidyBlock &&
                        <div className='subsidyBlock'>
                            <SubsidyBlockForm></SubsidyBlockForm>
                            <div className='fwc-row'>
                                <div className='fwc-col-12 submit-button'>
                                    <button data-analytics="continueBtnBasicsIos" className="fwc-btn fwc-btn-secondary" id="btContinue" onClick={() => editBasicsForm()}>
                                        Edit Basics
                                    </button>
                                    <Button data-analytics="continueBtnBasicsIos" id="btContinue" onClick={() => formSubmit(getValues())}>
                                        {pageContent?.FORM_CONTENT.LABELS.CONTINUE}
                                    </Button>
                                </div>
                            </div>
                        </div>
                    }
                    <div className='fwc-row disclaimer'>
                        <p dangerouslySetInnerHTML={{ __html: pageContent?.FOOTER_CONTENT.DESCRIPTION }}></p>
                    </div>
                    {/* <DevTool control={control} /> */}
                </div >
                <div className='alertModals'>
                    {openAlertModal && <Modal
                        id="alertModal"
                        onClose={closeAlertPopup}
                        className={'alertModal'}
                        title={'You May Need To Re-Add Dependents'}
                        open={openAlertModal}
                    >
                        <Modal.Body>
                            <p>This change may have required us to remove dependents you have added. If you have previously added dependents to your quote, please re-add them before continuing.</p>
                        </Modal.Body>
                    </Modal>}
                </div>
            </>
        );
    }
};

export default AgentBasicsLanding;
