import { Paragraph, Popover } from '@sydney-broker-ui/ios';
import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import colaradoPlan from '../../../images/colaradoPlan.svg';
import { GRADE_LEVELS } from '../../../pages/public/medical-plans/constants';
import { getFormattedCurrency } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from "../../../store/globalStore";
import MatchingListCard from '../matching-list-card/MatchingListCard';
import './PlanCard.scss';
import {
  getApplicableGrade,
  getBenefitOneDescription,
  getBenefitThreeDescription,
  getBenefitTwoDescription,
  getGradeLabel,
  getMedicationNames,
  isMappedContract
} from './planCardUtils';

const PlanCard: React.FC<PlanCardProps> = ({
  labels,
  contract,
  selectedPlan,
  drugList,
  matchedProviders,
  matchedMedications = [],
  matchedMedicationsFlag,
  matchedProvidersFlag,
  isFavouritePlan,
  isCurrentCoveragePlan,
  planType,
  onPlanNameClick,
  onSelectClick,
  onFavouriteClick,
  scrolltoImprtantNotice
}) => {
  const [anchor, setAnchor] = useState<React.SetStateAction<null> | (EventTarget & HTMLSpanElement)>(null);
  const [popoverOpen, setPopoverOpen] = useState(false);
  const [transitionPopoverOpen, setTransitionPopoverOpen] = useState(false);
  const [showMore, setShowMore] = useState(false);
  const [hideShowMore, setHideShowMore] = useState(true);
  const [isCompared, setIsCompared] = useState(false);

  const [plan] = contract.plan;
  const isSelected = selectedPlan.plan?.contractCode === contract.contractCode;
  const isColaradoPlan = contract.poPlan;
  const globalStore = useGlobalStore((store) => store);
  const navigate = useNavigate();
  const {
    comparedMedicalPlans,
    comparedDentalPlans,
    comparedVisionPlans,
    updateComparedMedicalPlans,
    updateComparedDentalPlans,
    updateComparedVisionPlans
  } = globalStore;
  const isChecked = () => {
    if (planType === 'medical') {
      return !!comparedMedicalPlans?.find((comparedPlan) => comparedPlan?.contract.contractCode === contract?.contractCode);
    }
    if (planType === 'dental') {
      return !!comparedDentalPlans?.find((comparedPlan) => comparedPlan?.contract.contractCode === contract?.contractCode);
    }
    if (planType === 'vision') {
      return !!comparedVisionPlans?.find((comparedPlan) => comparedPlan?.contract.contractCode === contract?.contractCode);
    }
  }
  const compareClick = (plan: Contract) => {
    const newPlan = {
      contract: plan,
      matchedProviders: matchedProviders,
      matchedMedications: matchedMedications
    };
    if (planType === 'medical') {
      if (comparedMedicalPlans.length < 3) {
        if (comparedMedicalPlans.find((comparedPlan) => comparedPlan.contract.contractCode === newPlan.contract.contractCode)) {
          const newArray = comparedMedicalPlans.filter((comparedPlan) => comparedPlan.contract.contractCode !== newPlan.contract.contractCode);
          updateComparedMedicalPlans([...newArray]);
        } else {
          updateComparedMedicalPlans([...comparedMedicalPlans, newPlan]);
        }
      } else {
        if (comparedMedicalPlans.find((comparedPlan) => comparedPlan.contract.contractCode === newPlan.contract.contractCode)) {
          const newArray = comparedMedicalPlans.filter((comparedPlan) => comparedPlan.contract.contractCode !== newPlan.contract.contractCode);
          updateComparedMedicalPlans(newArray);
        }
      }
    }
    if (planType === 'dental') {
      if (comparedDentalPlans.length < 3) {
        if (comparedDentalPlans.find((comparedPlan) => comparedPlan.contract.contractCode === newPlan.contract.contractCode)) {
          const newArray = comparedDentalPlans.filter((comparedPlan) => comparedPlan.contract.contractCode !== plan.contractCode);
          updateComparedDentalPlans([...newArray]);
        } else {
          updateComparedDentalPlans([...comparedDentalPlans, newPlan]);
        }
      } else {
        if (comparedDentalPlans.find((comparedPlan) => comparedPlan.contract.contractCode === newPlan.contract.contractCode)) {
          const newArray = comparedDentalPlans.filter((comparedPlan) => comparedPlan.contract.contractCode !== plan.contractCode);
          updateComparedDentalPlans([...newArray]);
        }
      }
    }
    if (planType === 'vision') {
      if (comparedVisionPlans.length < 3) {
        if (comparedVisionPlans.find((comparedPlan) => comparedPlan.contract.contractCode === newPlan.contract.contractCode)) {
          const newArray = comparedVisionPlans.filter((comparedPlan) => comparedPlan.contract.contractCode !== newPlan.contract.contractCode);
          updateComparedVisionPlans([...newArray]);
        } else {
          updateComparedVisionPlans([...comparedVisionPlans, newPlan]);
        }
      } else {
        if (comparedVisionPlans.find((comparedPlan) => comparedPlan.contract.contractCode === newPlan.contract.contractCode)) {
          const newArray = comparedVisionPlans.filter((comparedPlan) => comparedPlan.contract.contractCode !== newPlan.contract.contractCode);
          updateComparedVisionPlans([...newArray]);
        }
      }
    }
  }

  const handleHelpClick = (currentTarget: React.SetStateAction<null> | (EventTarget & HTMLSpanElement)) => {
    setAnchor(currentTarget);
    setPopoverOpen(!popoverOpen);
  };
  const handleTransitionHelpClick = (currentTarget: React.SetStateAction<null> | (EventTarget & HTMLSpanElement)) => {
    setAnchor(currentTarget);
    setTransitionPopoverOpen(!transitionPopoverOpen);
  };

  useEffect(() => {
    const label = document.getElementById('plan-card-benefit-primary-care-visit-value-' + contract.contractCode);
    const innerText = label?.innerText;
    if (innerText?.includes('...')) {
      setHideShowMore(false);
    } else {
      setHideShowMore(true);
    }
  }, []);

  const renderGradetag = (gradeLevels: GradeLevel[]) => {
    const applicableGrade = getApplicableGrade(gradeLevels);

    if (isCurrentCoveragePlan) {
      return (
        <div className={'grade-wrapper current-coverage-grade-container'}>
          <span className="grade-label">{labels.currentCoverage}</span>
        </div>
      );
    } else if (isMappedContract(contract)) {
      return (
        <div className="transition-coverage-info-wrapper">
          <div className={'grade-wrapper transition-coverage-grade-container'}>
            <span className="grade-label">{labels?.transitionCoverage}</span>
          </div>
          <div>
            {transitionPopoverOpen && anchor && (
              <Popover id="transition-popover" placement={'top'} anchorEl={anchor} onClickOutside={() => setTransitionPopoverOpen(!transitionPopoverOpen)}>
                <span className="transition-popover-content">{labels?.transitionCoverageTooltip}</span>
              </Popover>
            )}

            <span id="plan-card-transition-tooltip" className="sae-icon sae-icon-help help-icon" onClick={(e) => handleTransitionHelpClick(e.currentTarget)} />
          </div>
        </div>
      );

    } else {
      switch (applicableGrade) {
        case GRADE_LEVELS.RECOMMENDED:
        case GRADE_LEVELS.POPULAR:
          return (
            <div className={applicableGrade === GRADE_LEVELS.RECOMMENDED ? 'grade-wrapper recommended-grade-container' : 'grade-wrapper popular-grade-level'}>
              <span className="grade-label">{getGradeLabel(applicableGrade, labels)}</span>
            </div>
          );
        default:
          return null;
      }
    }
  };

  const renderMonthlyPremium = () => {
    if (contract?.rateData?.rate?.totSubsidizedPrem || contract?.rateData?.rate?.subsidyAmtApplied || contract?.rateData?.rate?.totSubsidizedPrem === 0) {
      return getFormattedCurrency(contract?.rateData?.rate?.totSubsidizedPrem);
    } else {
      return labels.rateNotAvailable;
    }
  };

  const renderPopoverContent = (rateObj: { totalPremium: number; totSubsidizedPrem: number; subsidyAmtApplied: number }) => {
    return (
      <Paragraph>
        <div className="popover-container">
          <span className="label-amount-container-margin-btm">
            <b>{labels.monthlyCostBreakdown}</b>
          </span>
          <div className="amount-label-container">
            <p>{labels.totalPlanCost}</p>
            <span>
              <b>{getFormattedCurrency(rateObj.totalPremium)}</b>
            </span>
          </div>
          <div className="amount-label-container label-amount-container-margin-btm">
            <p>{labels.subsidyApplied}</p>
            <span>
              <b>{getFormattedCurrency(rateObj.subsidyAmtApplied)}</b>
            </span>
          </div>
          <div className="amount-label-container">
            <span className="label-margin-right">
              <b>{labels.totalMemberResponsibility}</b>
            </span>
            <span className="total-subsidised-amount">
              <b>{getFormattedCurrency(rateObj.totSubsidizedPrem)}</b>
            </span>
          </div>
        </div>
      </Paragraph>
    );
  };
  const getAnalyticsSelectTag = () => {
    if (planType === 'medical') {
      return 'selectPlanMedicalPlansIos';
    } else if (planType === 'dental') {
      return 'selectPlanDentalPlansIos';
    } else if (planType === 'vision') {
      return 'selectPlanVisionPlansIos';
    }
  }
  const getAnalyticsFavoriteTag = () => {
    if (planType === 'medical') {
      return 'addFavoritesMedicalPlansIos';
    } else if (planType === 'dental') {
      return 'addFavoritesDentalPlansIos';
    } else if (planType === 'vision') {
      return 'addFavoritesVisionPlansIos';
    }
  }
  const getAnalyticsCompareTag = () => {
    if (planType === 'medical') {
      return 'compEmailPrintMedicalPlansIos';
    } else if (planType === 'dental') {
      return 'compEmailPrintDentalPlansIos';
    } else if (planType === 'vision') {
      return 'compEmailPrintVisionPlansIos';
    }
  }
  return (
    <div id="plan-card" key={contract.contractCode} className="plan-card-container">
      <div id="plan-card-grade" className="grade-container">
        {renderGradetag(contract.gradeLevels)}
      </div>
      <div className="plane-name-premium-container">
        <h4
          id="plan-card-plan-name"
          className="plan-name"
          onClick={() => {
            onPlanNameClick(contract);
          }}
        >
          {contract.planMarketingName + ' ' + contract.contractCode}
        </h4>
        <div className="monthly-premium-container">
          <p id="plan-card-plan-premium" className="label-margin label-margin ">
            {labels.monthlyPremium}
          </p>
          <h4 className="label-margin">{renderMonthlyPremium()}</h4>
          {popoverOpen && anchor && contract?.rateData?.rate && (
            <Popover id="plan-card-plan-amount-popover" placement={'top'} anchorEl={anchor} onClickOutside={() => setPopoverOpen(!popoverOpen)}>
              {renderPopoverContent(contract.rateData.rate)}
            </Popover>
          )}

          {!isCurrentCoveragePlan && <span id="plan-card-amount-tooltip" className="sae-icon sae-icon-help help-icon" onClick={(e) => handleHelpClick(e.currentTarget)} />}
        </div>
      </div>
      {!isCurrentCoveragePlan && <div className="drug-list-container">
        <div>
          {labels?.drugList && (
            <a data-analytics="drugListMedicalPlansIos" href={drugList} id="plan-card-drug-list" className="drug-list-hyper-link" target="_blank">
              <span>
                <span className="link-text"> {labels.drugList + ' '}</span>
                <span id="plan-card-drug-list-icon" className="sae-icon sae-icon-external-link link-icon" />
              </span>
            </a>
          )}
        </div>

        <div className="select-button-container">
          <button
            className={`select-button fwc-btn ${isSelected ? 'fwc-btn-secondary' : 'fwc-btn-primary'}`}
            data-analytics={getAnalyticsSelectTag()}
            onClick={() => {
              onSelectClick(contract, matchedProviders, matchedMedications, isSelected);
            }}
          >
            {isSelected ? labels.deselect : labels.select}
          </button>
          {isColaradoPlan && <img src={colaradoPlan} className="colarado-plan-logo" id={'colarado-plan-logo'} alt="" />}
        </div>
      </div>
      }
      {!isCurrentCoveragePlan && <div className="matching-list-outer-wrapper">
        {matchedProvidersFlag && (
          <MatchingListCard
            icon={'sae-icon-doctors-bag'}
            label={labels.doctorsListLabel}
            showMoreText={`${labels.showMore} ${labels.doctors}`}
            showLessText={`${labels.showLess} ${labels.doctors}`}
            providers={matchedProviders}
            tooltipMessage={labels.matchedDoctorsTooltipText}
          />
        )}
        {matchedMedicationsFlag && (
          <MatchingListCard
            icon={'sae-icon-prescription-pill-bottle'}
            label={labels.medicationsListLabel}
            showMoreText={`${labels.showMore} ${labels.medications}`}
            showLessText={`${labels.showLess} ${labels.medications}`}
            data={getMedicationNames(matchedMedications)}
            impNoticeText={labels.importantDrugsNotice}
            scrolltoImprtantNotice={scrolltoImprtantNotice}
          />
        )}
      </div>
      }
      <div className="benefits-container">
        <div className="benefit-column deductible">
          <span id="plan-card-benefit-deductible" className="benefit-bold-label">
            {labels.benefitLabelOne}
          </span>
          <div
            id={'plan-card-benefit-deductible-value-' + contract.contractCode}
            className="benefit-label"
            dangerouslySetInnerHTML={{ __html: getBenefitOneDescription(plan, showMore) }}
          />
        </div>
        <div className="benefit-column primary">
          <span id="plan-card-benefit-primary-care-visit" className="benefit-bold-label">
            {labels.benefitLabelTwo}
          </span>
          <div
            id={"plan-card-benefit-primary-care-visit-value-" + contract.contractCode}
            className="benefit-label"
            dangerouslySetInnerHTML={{ __html: getBenefitTwoDescription(plan, showMore) }}
          />
        </div>
        <div className="benefit-column preventative">
          <span id="plan-card-benefit-preventive-care" className="benefit-bold-label">
            {labels.benefitLabelThree}
          </span>
          <div
            id={"plan-card-benefit-preventive-care-value-" + contract.contractCode}
            className="benefit-label"
            dangerouslySetInnerHTML={{ __html: getBenefitThreeDescription(plan, showMore) }}
          />
        </div>
      </div>
      <div className="bottom-container">
        {!hideShowMore && <span id="plan-card-show-more-toggle" className="show-more-text" onClick={() => setShowMore(!showMore)}>
          <span>{showMore ? labels.showLess : labels.showMore}</span>
          <span id="plan-card-show-more" className={showMore ? 'sae-icon sae-icon-angle-up toggle-icon' : 'sae-icon sae-icon-angle-down toggle-icon'} />
        </span>}
        <div className="right-buttons-container flex-align-right">
          <a data-analytics={getAnalyticsFavoriteTag()} href={'javascript:void(0);'} id="plan-card-fav-button" className="right-button" onClick={() => onFavouriteClick(contract.contractCode)}>
            <span id="plan-card-fav" className={`sae-icon ${isFavouritePlan ? 'sae-icon-filled-star' : 'sae-icon-star'} fav-icon`} />
            <span className="right-button-label">{isFavouritePlan ? labels.removeFromFavourites : labels.addToFavourites}</span>
          </a>
          <div className="right-button">
            <div className="fwc-checkbox">
              <input
                id={"plan-card-mail-print-button-" + contract.contractCode}
                type="checkbox"
                data-analytics={getAnalyticsCompareTag()}
                checked={isChecked()}
                onClick={() => {
                  compareClick(contract);
                }}
                onChange={() => { }} />
              <label className="right-button-label" htmlFor={"plan-card-mail-print-button-" + contract.contractCode}>{labels.compareEmailPrint}</label>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PlanCard;
