import { Button } from '@sydney-broker-ui/ios';
import React from 'react';
import { useNavigate } from 'react-router-dom';
import colaradoPlan from '../../../../../src/images/colaradoPlan.svg';
import { PLAN_MARKET_TYPES } from '../../../../components/common/compare-tray/constant';
import { NAV_PATHS, PLAN_TYPES, PLAN_TYPES_NAMES, USER_ROLES } from '../../../../shared/globalConstants';
import { filterDependentsOnRelationType, getNotMedicaidEligibleDependents } from '../../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../../store/globalStore';
import { useUserStore } from '../../../../store/userStore';
import { getFormattedApplicantDetails } from '../../medical-plans/medicalPlansUtils';
import { DENTAL, MEDICAL, VISION } from '../PlanSelectionConstants';
import './PlanSummaryCard.scss';

const PlanSummaryCard: React.FC<PlanSummaryCardProps> = ({ labels, editable, selectedSummaryPlan, planTypeIcon, planType, isPrimary, applicants, deleteSelectedPlan, selectedPlanMarketType, onPlanNameClick }) => {
  const navigate = useNavigate();
  const { selectedPlan, selectedDentalPlan, updateCoverageType, updateEditCoverageType, applicantFormDetails, coverageType, resetEyeDoctorStates, resetVisionPlanStates, resetDentistsStates, resetDentalPlanStates, memberInfo, updateShowRecommendationModal, updateSelectedVisionPlan, updateSelectedDentalPlan, updateProgressSteps, medicalSteps, dentalSteps, visionSteps, medicalDentalVisionSteps } = useGlobalStore((store) => store);
  const { loggedIn, role } = useUserStore((state) => state);
  const { currentCoverages } = memberInfo;

  const getAnalyticsTags = () => {
    switch (planType) {
      case MEDICAL:
        return "editMedCoveragePlanSummaryIos"
      case DENTAL:
        return "editDentalCoveragePlanSummaryIos"
      case VISION:
        return "editVisionPlanSummaryIos"
      default:
        return "";
    }
  }

  const onChangePlanSelect = () => {
    switch (planType) {
      case MEDICAL:
        navigate(NAV_PATHS.MEDICAL_LISTING);
        break;
      case DENTAL:
        navigate(NAV_PATHS.DENTAL_LISTING);
        break;
      case VISION:
        return updateShowRecommendationModal(true);
      default:
        // Default case logic here
        break;
    }
  }

  const onAddPlanSelect = () => {
    switch (planType) {
      case MEDICAL:
        updateCoverageType(PLAN_TYPES.MDV);
        updateEditCoverageType(PLAN_TYPES.MDV);
        updateProgressSteps(medicalDentalVisionSteps);
        navigate(NAV_PATHS.BASICS_LANDING);
        break;
      case DENTAL:
        if (coverageType === PLAN_TYPES.DENTAL) {
          navigate(NAV_PATHS.BASICS_LANDING);
        } else {
          resetEyeDoctorStates();
          resetVisionPlanStates();
          resetDentalPlanStates();
          resetDentistsStates();
          updateSelectedDentalPlan({
            plan: null,
            applicants: getFormattedApplicantDetails(filterDependentsOnRelationType(getNotMedicaidEligibleDependents(applicantFormDetails))),
            matchedProviders: []
          });
          if (coverageType === PLAN_TYPES.MEDICAL) {
            updateCoverageType(PLAN_TYPES.MDV);
            updateEditCoverageType(PLAN_TYPES.MDV);
            updateProgressSteps(medicalDentalVisionSteps);
          } else if (coverageType === PLAN_TYPES.VISION) {
            updateCoverageType(PLAN_TYPES.DENTAL);
            updateEditCoverageType(PLAN_TYPES.DENTAL);
            updateProgressSteps(dentalSteps);
          } else if (coverageType === PLAN_TYPES.MDV) {
            updateProgressSteps(medicalDentalVisionSteps);
          } else {
            updateProgressSteps(dentalSteps);
          }
          navigate(NAV_PATHS.DENTAL_LISTING);
        }
        break;
      case VISION:
        if (coverageType === PLAN_TYPES.MEDICAL) {
          updateCoverageType(PLAN_TYPES.MDV);
          updateEditCoverageType(PLAN_TYPES.MDV);
          updateProgressSteps(medicalDentalVisionSteps);
        }
        resetVisionPlanStates();
        resetEyeDoctorStates();
        updateSelectedVisionPlan({
          plan: null,
          applicants: getFormattedApplicantDetails(filterDependentsOnRelationType(applicantFormDetails)),
          matchedProviders: []
        });
        if (selectedPlan?.plan && selectedPlan.plan.onExchange) {
          updateShowRecommendationModal(false);
          return navigate(NAV_PATHS.VISION_LISTING);
        } else if (selectedDentalPlan?.plan && selectedDentalPlan.plan.onExchange) {
          updateShowRecommendationModal(false);
          return navigate(NAV_PATHS.VISION_LISTING);
        } else {
          return updateShowRecommendationModal(true);
        }
      default:
        // Default case logic here
        break;
    }
  }

  let addButton = '';
  let changeButton = '';
  if (loggedIn && role === USER_ROLES.MEMBER) {
    const isHavingMedicalPlan = currentCoverages.filter((item) => item.planType === PLAN_TYPES_NAMES.MEDICAL);
    const isHavingDentalPlan = currentCoverages.filter((item) => item.planType === PLAN_TYPES_NAMES.DENTAL);
    const isHavingVisionPlan = currentCoverages.filter((item) => item.planType === PLAN_TYPES_NAMES.VISION);

    switch (planType) {
      case MEDICAL:
        if (isHavingMedicalPlan?.length > 0 && !selectedSummaryPlan) {
          addButton = labels.MACFLOW_BUTTON_LABELS.SWITCH_MEDICAL_PLAN
        } else if (isHavingMedicalPlan?.length <= 0 && !selectedSummaryPlan) {
          addButton = labels.MACFLOW_BUTTON_LABELS.ADD_MEDICAL_PLAN
        } else {
          changeButton = labels.CHANGE
        }
        break;
      case DENTAL:
        if (isHavingDentalPlan?.length > 0 && !selectedSummaryPlan) {
          addButton = labels.MACFLOW_BUTTON_LABELS.SWITCH_DENTAL_PLAN
        } else if (isHavingDentalPlan?.length <= 0 && !selectedSummaryPlan) {
          addButton = labels.MACFLOW_BUTTON_LABELS.ADD_DENTAL_PLAN
        } else {
          changeButton = labels.CHANGE
        }
        break;
      case VISION:
        if (isHavingVisionPlan?.length > 0 && !selectedSummaryPlan) {
          addButton = labels.MACFLOW_BUTTON_LABELS.SWITCH_VISION_PLAN
        } else if (isHavingVisionPlan?.length <= 0 && !selectedSummaryPlan) {
          addButton = labels.MACFLOW_BUTTON_LABELS.ADD_VISION_PLAN
        } else {
          changeButton = labels.CHANGE
        }
        break;
      default:
        // Default case logic here
        break;
    }
  } else {
    changeButton = labels.CHANGE;
    addButton = labels.ADD_PLAN
  }
  const setAnalyticsString = () => {
    let str = '';
    switch (planType) {
      case 'Medical':
        str = 'MedPlan';
        break;
      case 'Dental':
        str = 'DentalPlan';
        break;
      case 'Vision':
        str = 'VisionPlan';
        break;
    }
    return str;
  }

  return (
    <div className="plan-summary-card">
      <div className="plan-type-container">
        <div className="plan-type-info-wrapper">
          <span className={'sae-icon ' + planTypeIcon}></span>
          <div className="plan-type-name">{planType}</div>
        </div>

        {!isPrimary && selectedSummaryPlan && editable && (
          <a data-analytics={'delete' + setAnalyticsString() + 'SummaryIos'} href={'javascript:void(0)'} className="delete-icon-container" onClick={() => deleteSelectedPlan(planType)}>
            <span className="sae-icon sae-icon-trash info-icon-size"></span>
          </a>
        )}
      </div>
      <div className="plan-details-container">
        {selectedSummaryPlan && (
          <div className="plan-info-container">
            <div>
              <h3 onClick={() => onPlanNameClick(selectedSummaryPlan)}>{selectedSummaryPlan.planMarketingName}</h3>
            </div>
            {
              (selectedSummaryPlan.planMarketingName && selectedPlanMarketType === PLAN_MARKET_TYPES.COLARADO_CONNECT) && <div className='fwc-row fwc-row-bm'>
                <div className='fwc-col-4 sm-3 md-3'>
                  <img id="co-logo" alt="" src={colaradoPlan} />
                </div>
              </div>
            }
            <div>
              <h4>Who's Covered {editable && <a data-analytics={getAnalyticsTags()} onClick={() => navigate('/individuals/shop/basics')} href={'javascript:void(0)'} className={'sae-icon sae-icon-edit'}></a>}</h4>
              <div className="user-details-container">
                {applicants?.map((dependent, index) => {
                  return (
                    <p key={index}>
                      <i className="sae-icon sae-icon-user-checked"></i>
                      {dependent.firstName} <span>{`(${dependent.applicantType})`}</span>
                    </p>
                  );
                })}
              </div>
            </div>
          </div>
        )}
        {!selectedSummaryPlan && (
          <div className="plan-info-container">
            {/* <div>Add plan content goes here</div> */}
            {planType === MEDICAL && <div dangerouslySetInnerHTML={{ __html: labels.planContent.MEDICAL }}></div>}
            {planType === DENTAL && <div>{labels.planContent.DENTAL}</div>}
            {planType === VISION && <div>{labels.planContent.VISION}</div>}
          </div>
        )}

        <div className="plan-change-container">
          {selectedSummaryPlan && (
            <div className="plan-price-container">
              <p>
                {labels.MONTHLY_PREMIUM}<span className="plan-price">${selectedSummaryPlan?.rateData?.rate?.totSubsidizedPrem}</span>
              </p>
            </div>
          )}
          {editable && <div className="action-wrapper">
            {!selectedSummaryPlan && <Button data-analytics={'add' + setAnalyticsString() + 'SummaryIos'} className="action-button" btnType="secondary" onClick={() => { onAddPlanSelect(); }}> {addButton}</Button>}
            {selectedSummaryPlan && <Button data-analytics={'change' + setAnalyticsString() + 'SummaryIos'} className="action-button" btnType="secondary" onClick={() => { onChangePlanSelect(); }}>{changeButton}</Button>}
          </div>
          }
        </div>
      </div>
    </div>
  );
};

export default PlanSummaryCard;
