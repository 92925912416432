import { APPLICATION_TYPE, PLAN_TYPES_NAMES, USER_DOMAINS } from '../../../shared/globalConstants';
import { formatDateToYYYYMMDD } from '../../../shared/utils/globalUtils';
import { getApplicableMetalTiers, getCSR, getFormattedApplicantDetails, getSubsidyAmount } from '../medical-plans/medicalPlansUtils';

const getMedicalPlanDetailsPayload = (globalStore: QuoteStore & EstimateSavingsStore, lcontarctCode: string): PlanSummaryPayload => {
  let payload: PlanSummaryPayload = {
    brand: globalStore.brand,
    planTypes: [PLAN_TYPES_NAMES.MEDICAL],
    metalTierTypes: getApplicableMetalTiers(getFormattedApplicantDetails(globalStore.applicantFormDetails)),
    marketSegment: USER_DOMAINS.MARKET_SEGMENT_FOR_PLAN_SUMMARY,
    zipCode: globalStore.zipcode,
    county: globalStore.county,
    state: globalStore.state,
    coverageEffectiveDate: formatDateToYYYYMMDD(globalStore.coverageDate), //Update the format
    planYear: globalStore.year,
    applicants: getFormattedApplicantDetails(globalStore.applicantFormDetails, false),
    subsidy: {
      subsidyAmt: getSubsidyAmount(globalStore.subsidyresponse),
      costShareReduction: getCSR(globalStore.subsidyresponse)
    },
    language: 'en', //For now hardcoding to english, Yet to be made dynamic
    countyCode: globalStore.countyCode,
    contractList: {
      contractBundle: [
        {
          contractCode: lcontarctCode
        }
      ]
    }
  };

  return payload;
};

const displayStars = (starNum: number) => {
  let resultantDiv = ``;
  let filledStarDiv = `<span
  class='sae-icon sae-icon-filled-star' style='color:#F2BC35;font-size:14px;padding-right:2px;'
   ></span>`;
  let emtpyStarDiv = `<span
  class='sae-icon sae-icon-star' style='color:#EBEBEB;font-size:14px;padding-right:2px;'
  ></span>`;
  let count = 0;
  for (let i = 0; i < starNum; i++) {
    resultantDiv = resultantDiv + filledStarDiv;
    count++;
  }
  if (count <= 5) {
    for (let i = 0; i < 5 - count; i++) {
      resultantDiv = resultantDiv + emtpyStarDiv;
    }
  }
  return resultantDiv;
};

const getApplicationTypeForAEM = (onExchange: boolean, coloradoOptions?: boolean) => {
  if (coloradoOptions) {
    return APPLICATION_TYPE.COLORADO_CONNECT.toLowerCase();
  } else if (onExchange) {
    return APPLICATION_TYPE.ON_EXCHANGE.toLowerCase();
  } else {
    return APPLICATION_TYPE.OFF_EXCHANGE.toLowerCase();
  }
};

export { displayStars, getApplicationTypeForAEM, getMedicalPlanDetailsPayload };
