import { Col, Modal, PageHeader, Row, Title } from "@sydney-broker-ui/ios";
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import { getCMSStaticContent, getCMSdynamicContent } from '../../../api/cms/cmsUtil';
import { getZipcodeValidation } from "../../../api/services/utilService";
import DeleteApplicationPopup from "../../../components/common/delete-application-popup/DeleteApplicationPopup";
import EmailPrint from "../../../components/common/email-print/EmailPrint";
import EmptyPlans from "../../../components/common/empty-plans/EmptyPlans";
import { EMPTY_PLANS_USE_CASES } from "../../../components/common/empty-plans/constants";
import FullPageLoader from "../../../components/common/full-page-loader/FullPageLoader";
import GlobalHeader from '../../../components/common/global-header/GlobalHeader';
import { getApplicableGrade, getGradeLabel } from "../../../components/common/plan-card/planCardUtils";
import PlanCoverageAccordion from "../../../components/common/plan-coverage-accordion/PlanCoverageAccordion";
import SuccessModalBody from "../../../components/common/success-modal-body/SuccessModalBody";
import { LANGUAGE_CODES, NAV_PATHS, PAGE_BLOCKS, PAGE_IDS, PLAN_TYPES, PLAN_TYPES_NAMES, PROPOSAL_STATUSES, SCREEN_NAMES, USER_DOMAINS, ZIP_CODE_VALIDATION_SUCCESS_CODE } from '../../../shared/globalConstants';
import { formatDateToMMDDYYY, getFormattedCurrency, isFutureDate, marketTagClass } from "../../../shared/utils/globalUtils";
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import DentalPlanDetails from "../../public/dental-plan-details/DentalPlanDetails";
import MedicalPlanDetails from "../../public/medical-plan-details/MedicalPlanDetails";
import { getApplicationTypeForAEM } from "../../public/medical-plan-details/medicalPlanDetailsUtils";
import { GRADE_LEVELS } from "../../public/medical-plans/constants";
import { fetchPlanList } from '../../public/medical-plans/medicalPlansServices';
import { getFormattedApplicantDetails } from "../../public/medical-plans/medicalPlansUtils";
import { DENTAL_PLAN_BENEFITS_ARR, MEDICAL_PLAN_BENEFITS_ARR, VISION_PLAN_BENEFITS_ARR } from "../../public/plan-recommendations/constants";
import { getProposalID } from "../../public/plan-recommendations/planRecommendationsServices";
import { getCoverageTypeForDynamicAEM, getRecommededPlanCardLabels, getRecommendedPlansPayload, getTotalPremiums } from '../../public/plan-recommendations/planRecommendationsUtils';
import VisionPlanDetails from "../../public/vision-plan-details/VisionPlanDetails";
import { getPrimaryProductTypeByCoverageType } from "../product-selection/productSelectionUtils";
import './ProposalQuoteOverview.scss';
import { default as proposalQuoteOverviewContent } from './proposalQuoteOverview.json';
import { deleteAtkProposal } from "./proposalQuoteOverviewServices";
import { getComparedProducts, getContractBundle, getQuoteOrderingsRecContracts, getQuoteRecContracts, getUniqueContractBundle, getUpdatedApplicantDetails } from './proposalQuoteOverviewUtils';

export default function ProposalQuoteOverview() {
    const [getProposalRes, setGetProposalRes] = useState<GetPropsalResponse>({} as GetPropsalResponse);
    const [quoteRecContracts, setQuoteRecContracts] = useState<QuoteRecContracts>([]);
    const [quoteOrderings, setQuoteOrderings] = useState<QuoteOrderings>([]);
    const [medicalPdfUrl, setMedicalPdfUrl] = useState('');
    const [dentalPdfUrl, setDentalPdfUrl] = useState('');
    const [visionPdfUrl, setVisionPdfUrl] = useState('');
    const [medicalRecPlansContracts, setMedicalRecPlansContracts] = useState<Contracts>([]);
    const [dentalRecPlansContracts, setDentalRecPlansContracts] = useState<Contracts>([]);
    const [visionRecPlansContracts, setVisionRecPlansContracts] = useState<Contracts>([]);
    const [medicalRecommendedPlans, setMedicalRecommendedPlans] = useState<RecComparedPlans>([]);
    const [dentalRecommendedPlans, setDentalRecommendedPlans] = useState<RecComparedPlans>([]);
    const [visionRecommendedPlans, setVisionRecommendedPlans] = useState<RecComparedPlans>([]);
    const [totalPremiumArray, setTotalPremiumArray] = useState<TotalPremiums>([]);
    const [serverError, setServerError] = useState(false);
    const [proposalLoading, setProposalLoading] = useState<boolean>(false);
    const [pageContent, setContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [medicalContract, setMedicalContract] = useState<Contract | null>(null);
    const [dentalContract, setDentalContract] = useState<Contract | null>(null);
    const [visionContract, setVisionContract] = useState<Contract | null>(null);
    const [primaryProductType, setPrimaryProductType] = useState<string>('');
    const [deletePopUp, setDeletePopUp] = useState(false);
    const [openEmailModal, setOpenEmailModal] = useState(false);
    const [printQuote, setPrintQuote] = useState(false);
    const [showSuccessModal, setShowSuccessModal] = useState(false);
    const [currentDateTimeInZone, setCurrentDateTimeInZone] = useState<string>('');

    const { agentSSOInfo, updateAgentBasicsDemographic, agentupdatePlanTypes, updateHouseholdMemberValues, updateComparedProducts, agentUpdateApplicantFormDetails, updateATKsaveProposalResponse } = useGlobalStore();
    const { role, isWellpoint } = useUserStore((state) => state);

    const navigate = useNavigate()


    const getContent = useCallback(async (): Promise<boolean> => {
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.PROPOSAL_QUOTE_OVERVIEW);
            let cmsResponse = response.data;

            setContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.ProposalQuoteOverview);
            //setContent(proposalQuoteOverviewContent.data.iospagecontentList.items[0].pageContent?.ProposalQuoteOverview);
        } catch (error) {
            setContent(proposalQuoteOverviewContent.data.iospagecontentList.items[0].pageContent?.ProposalQuoteOverview);

            return false;
        } finally {
            setContentLoaded(true);
        }
        return true;
    }, []);

    const initiate = async () => {
        await getContent();
    };



    useEffect(() => {
        initiate();
        callGetProposalRes();
        setProposalLoading(true);
    }, [agentSSOInfo]);

    const callGetProposalRes = () => {
        // setGetProposalRes(GET_PROPOSAL_ID_RESP);
        setServerError(false);
        getProposalID(agentSSOInfo.eventHeader.brokerUserID, agentSSOInfo.eventHeader.proposalID ?? '').then((response) => {
            const data: GetPropsalResponse = response.data;
            setGetProposalRes(data);
            updateATKsaveProposalResponse(data)
            setProposalLoading(false);
        })
            .catch((error) => {
                setServerError(true)
                setProposalLoading(false);
            });
    }

    const fomatContracts = async () => {
        try {
            await validateZipCode(getProposalRes?.demographicInfo?.zipCode ?? '');
            formatQuoteRecContracts(getProposalRes.quotes.quote, getProposalRes?.demographicInfo ?? {} as DemographicInfo);
        } catch (error) {
            console.error('ProposalQuoteOverview.tsx fomatContracts error :', error)
        }
    };

    useEffect(() => {
        if (getProposalRes && Object.keys(getProposalRes)?.length > 0) {
            fomatContracts();
        }
    }, [getProposalRes]);

    const validateZipCode = useCallback(async (zipCode: string): Promise<boolean> => {

        if (getProposalRes.demographicInfo?.zipCode !== '') {
            try {
                setProposalLoading(true);
                const payload: ZipCodeValidationPayload = {
                    zipCode: zipCode,
                    marketSegment: USER_DOMAINS.MARKET_SEGMENT
                };

                const response = await getZipcodeValidation(payload);
                const data: ZipCodeValidationResponse = response.data;

                if (data.zipCodeResponse.responseMessage.responseCode === ZIP_CODE_VALIDATION_SUCCESS_CODE) {
                    const { zipCodeResponse } = data;
                    setCurrentDateTimeInZone(zipCodeResponse.zipCode.currentDateTimeInZone)
                    setProposalLoading(false);
                    return true;
                } else {
                    setProposalLoading(false);
                    return false;
                }
            } catch (error) {
                setProposalLoading(false);
                return false;
            }
        }
        return true
    }, []);

    const formatQuoteRecContracts = (quotes: Quotes[], demographicInfo: DemographicInfo) => {
        const { quoteRecContracts, quoteOrderings } = getQuoteOrderingsRecContracts(quotes, demographicInfo)
        setQuoteRecContracts(quoteRecContracts);
        setQuoteOrderings(quoteOrderings.sort(function (a, b) {
            return b.quoteLineItem - a.quoteLineItem;
        }))
        const medicalQuoteRecContracts = getQuoteRecContracts(quoteRecContracts, PLAN_TYPES_NAMES.MEDICAL);
        const dentalQuoteRecContracts = getQuoteRecContracts(quoteRecContracts, PLAN_TYPES_NAMES.DENTAL);
        const visionQuoteRecContracts = getQuoteRecContracts(quoteRecContracts, PLAN_TYPES_NAMES.VISION);
        if (medicalQuoteRecContracts && medicalQuoteRecContracts?.length) {
            fromatRecommendedPlansPayload(medicalQuoteRecContracts, PLAN_TYPES_NAMES.MEDICAL);
        }
        if (dentalQuoteRecContracts && dentalQuoteRecContracts?.length) {
            fromatRecommendedPlansPayload(dentalQuoteRecContracts, PLAN_TYPES_NAMES.DENTAL);
        }
        if (visionQuoteRecContracts && visionQuoteRecContracts?.length) {
            fromatRecommendedPlansPayload(visionQuoteRecContracts, PLAN_TYPES_NAMES.VISION);
        }
    }

    const fromatRecommendedPlansPayload = (quoteRecContracts: QuoteRecContracts, type: string) => {
        const contractBundle: contractBundle[] = getContractBundle(quoteRecContracts);
        const uniqueContractBundle: contractBundle[] = getUniqueContractBundle(contractBundle)
        const payload = getRecommendedPlansPayload(quoteRecContracts[0].planType, quoteRecContracts[0].applicant,
            getProposalRes?.demographicInfo ?
                getProposalRes.demographicInfo : {}, uniqueContractBundle);

        loadRecommendedPlans(payload, type)
    }

    const loadRecommendedPlans = (payload: PlanSummaryPayload, type: string) => {
        setProposalLoading(true)
        setServerError(false)
        fetchPlanList(payload)
            .then((response) => {
                setProposalLoading(false)
                const data: PlanSummaryResponse = response.data;
                const contractPlan: Contracts = data.planSummaryResponse.plans.contract;

                switch (type) {
                    case PLAN_TYPES_NAMES.MEDICAL:
                        setMedicalRecPlansContracts(contractPlan);
                        break;
                    case PLAN_TYPES_NAMES.DENTAL:
                        setDentalRecPlansContracts(contractPlan);
                        break;
                    case PLAN_TYPES_NAMES.VISION:
                        setVisionRecPlansContracts(contractPlan);
                        break;
                    default:
                        setMedicalRecPlansContracts([]);
                        setDentalRecPlansContracts([]);
                        setVisionRecPlansContracts([]);
                }
            })
            .catch((error) => {
                setServerError(true);
                setProposalLoading(false)
            });
    }


    const getPdfURL = useCallback(async (screenName: string, state: string, brand: string, year: string, aemPlanType: string): Promise<boolean> => {
        if (screenName !== '') {
            try {

                const queryParam = ';state=' + state + ';brand=' + brand + ';role=' + role.toLowerCase() + ';plantype=' + aemPlanType + ';coveragetype=' + getCoverageTypeForDynamicAEM(screenName) + ';year=' + year;
                const response = await getCMSdynamicContent(PAGE_IDS.PRODUCT_BROUCHER_LINK_ALL, PAGE_BLOCKS.PRODUCT_BROUCHER_LINK, queryParam, currentDateTimeInZone)
                const cmsResponse = response?.data;

                switch (screenName) {
                    case SCREEN_NAMES.MEDICAL_PLAN_DETAILS:
                        setMedicalPdfUrl(cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary);
                        break;
                    case SCREEN_NAMES.DENTAL_DETAILS_SCREEN_NAME:
                        setDentalPdfUrl(cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary);
                        break;
                    case SCREEN_NAMES.VISION_PLAN_DETAILS:
                        setVisionPdfUrl(cmsResponse.data?.iosdynamiccontentList?.items[0]?.textsummary);
                        break;
                    default:
                        break;
                }

            } catch (error) {
                return false;
            }
        }
        return true;

    }, [quoteRecContracts]);

    const calculateTotalPremium = () => {
        const recommendedPlans: RecComparedPlans = [...medicalRecommendedPlans, ...dentalRecommendedPlans, ...visionRecommendedPlans]
        recommendedPlans?.length > 0 && quoteOrderings?.length > 0 ? setTotalPremiumArray(getTotalPremiums(recommendedPlans, quoteOrderings)) : setTotalPremiumArray([])
    }

    useEffect(() => {
        const state = getProposalRes?.demographicInfo?.state ?? '';
        const brand = getProposalRes?.demographicInfo?.brand ?? '';
        const county = getProposalRes?.demographicInfo?.county ?? '';
        const countyCode = getProposalRes?.demographicInfo?.countyCode ?? '';
        const coverageType = isWellpoint ? PLAN_TYPES.MEDICAL : getProposalRes?.demographicInfo?.coverageType ?? ''; //To do -check the logic for anthem
        const zipCode = getProposalRes?.demographicInfo?.zipCode ?? '';
        const coverageEffectiveDate = getProposalRes?.demographicInfo?.coverageEffectiveDate ?? '';
        const year = getProposalRes?.demographicInfo?.coverageEffectiveDate ? getProposalRes?.demographicInfo.coverageEffectiveDate.split('-')[0] : '';
        const subsidyFormData = {
            dependents: Number(getProposalRes?.demographicInfo?.subsidy?.houseHoldSize) > 0
                ? Number(getProposalRes?.demographicInfo?.subsidy?.houseHoldSize) - (Array.isArray(getProposalRes?.demographicInfo?.applicant) ? getProposalRes?.demographicInfo?.applicant?.length : 0)
                : 0,
            houseHoldIncome: getProposalRes?.demographicInfo?.subsidy?.houseHoldIncome || 0
        };
        setPrimaryProductType(getPrimaryProductTypeByCoverageType(coverageType))
        updateHouseholdMemberValues(subsidyFormData);
        updateAgentBasicsDemographic(brand, county, countyCode, coverageEffectiveDate, coverageType, state, zipCode, year)
        agentupdatePlanTypes(county, year, agentSSOInfo.agentType)
        setMedicalPdfUrl('');
        setDentalPdfUrl('');
        setVisionPdfUrl('');
        if (medicalRecPlansContracts?.length > 0) {
            const contract: Contract = medicalRecPlansContracts[0]
            getPdfURL(SCREEN_NAMES.MEDICAL_PLAN_DETAILS, state, brand, year, getApplicationTypeForAEM(contract.onExchange, contract.poPlan));
        }
        if (dentalRecPlansContracts?.length > 0) {
            const contract: Contract = dentalRecPlansContracts[0]
            getPdfURL(SCREEN_NAMES.DENTAL_DETAILS_SCREEN_NAME, state, brand, year, getApplicationTypeForAEM(contract.onExchange, contract.poPlan));
        }
        if (visionRecPlansContracts?.length > 0) {
            const contract: Contract = visionRecPlansContracts[0]
            getPdfURL(SCREEN_NAMES.VISION_PLAN_DETAILS, state, brand, year, getApplicationTypeForAEM(contract.onExchange, contract.poPlan));
        }
    }, [medicalRecPlansContracts, dentalRecPlansContracts, visionRecPlansContracts])


    useEffect(() => {
        let count: number = 0;
        let medicalRecommendedPlans: RecComparedPlans = [];
        let dentalRecommendedPlans: RecComparedPlans = [];
        let visionRecommendedPlans: RecComparedPlans = [];

        quoteOrderings?.map((quote) => {
            count++;
            quoteRecContracts?.map((quoteRecContract) => {
                setProposalLoading(true);
                if (quote.quoteId === quoteRecContract.quoteId && quoteRecContract.planType === PLAN_TYPES_NAMES.MEDICAL && medicalRecPlansContracts?.length > 0) {
                    const medicalContract: RecComparedContract = {
                        quoteId: quoteRecContract.quoteId,
                        contract: medicalRecPlansContracts?.find((contract: Contract) => contract.contractCode === quoteRecContract.contractCode) as Contract,
                        matchedProviders: [],
                        matchedMedications: [],
                        pdfBrochureUrl: medicalPdfUrl,
                    }
                    medicalRecommendedPlans.push(medicalContract);
                } else if (quote.quoteId === quoteRecContract.quoteId && quoteRecContract.planType === PLAN_TYPES_NAMES.DENTAL && dentalRecPlansContracts?.length > 0) {

                    const dentalContract: RecComparedContract = {
                        quoteId: quoteRecContract.quoteId,
                        contract: dentalRecPlansContracts?.find((contract: Contract) => contract.contractCode === quoteRecContract.contractCode) as Contract,
                        matchedProviders: [],
                        matchedMedications: [],
                        pdfBrochureUrl: dentalPdfUrl,
                    }
                    dentalRecommendedPlans.push(dentalContract);
                } else if (quote.quoteId === quoteRecContract.quoteId && quoteRecContract.planType === PLAN_TYPES_NAMES.VISION && visionRecPlansContracts?.length > 0) {
                    const visionContract: RecComparedContract = {
                        quoteId: quoteRecContract.quoteId,
                        contract: visionRecPlansContracts?.find((contract: Contract) => contract.contractCode === quoteRecContract.contractCode) as Contract,
                        matchedProviders: [],
                        matchedMedications: [],
                        pdfBrochureUrl: visionPdfUrl,
                    }
                    visionRecommendedPlans.push(visionContract);
                }
            })
        })

        if (medicalRecommendedPlans?.length > 0) {
            setMedicalRecommendedPlans(medicalRecommendedPlans);
        }
        if (dentalRecommendedPlans?.length > 0) {
            setDentalRecommendedPlans(dentalRecommendedPlans);
        }
        if (visionRecommendedPlans?.length > 0) {
            setVisionRecommendedPlans(visionRecommendedPlans);
        }

        if (count === quoteOrderings?.length) {
            calculateTotalPremium();
            setProposalLoading(false)
        }
        const comparedProducts = getComparedProducts(quoteRecContracts, medicalRecommendedPlans, dentalRecommendedPlans, visionRecommendedPlans);
        updateComparedProducts(comparedProducts);
        const updatedApplicantDetails = getUpdatedApplicantDetails(getProposalRes?.demographicInfo?.applicant ?? [], getProposalRes?.user);
        agentUpdateApplicantFormDetails(updatedApplicantDetails);
    }, [medicalRecPlansContracts, dentalRecPlansContracts, visionRecPlansContracts, medicalPdfUrl, dentalPdfUrl, visionPdfUrl])


    const applyNow = (quoteId: string) => {
        // console.log("quoteId", quoteId)
    }

    const renderGradetag = (gradeLevels: GradeLevel[], isCurrentCoveragePlan: boolean = false) => {
        if (isCurrentCoveragePlan) {
            return (
                <div className={'grade-wrapper current-coverage-grade-container'}>
                    <span className="grade-label">{pageContent?.CURRENT_COVERAGE}</span>
                </div>
            );
        } else {
            const applicableGrade = getApplicableGrade(gradeLevels);

            switch (applicableGrade) {
                case GRADE_LEVELS.RECOMMENDED:
                case GRADE_LEVELS.POPULAR:
                    return (
                        <div className={applicableGrade === GRADE_LEVELS.RECOMMENDED ? 'grade-wrapper recommended-grade-container' : 'grade-wrapper popular-grade-level'}>
                            <span className="grade-label">{getGradeLabel(applicableGrade, getRecommededPlanCardLabels(pageContent?.PLAN_CARD_LABELS))}</span>
                        </div>
                    );
                default:
                    return null;
            }
        }
    };

    const fetchColumn = () => {
        if (quoteOrderings?.length > 3) {
            return 'fwc-col-3';
        } else {
            return 'fwc-col-4';
        }
    }

    const placeholders = () => {
        return (
            quoteOrderings?.length > 3 ? <>
                {(quoteOrderings?.length > 0 && quoteOrderings?.length <= 3) &&
                    <div className="planRec-tray-plan placeholder-plan fwc-col-3 fwc-card-generic"></div>}
                {quoteOrderings.length === 1 &&
                    <div className="planRec-tray-plan placeholder-plan fwc-col-3 fwc-card-generic"></div>}
                {quoteOrderings.length === 1 &&
                    <div className="planRec-tray-plan placeholder-plan fwc-col-3 fwc-card-generic"></div>}
            </> :
                <>
                    {(quoteOrderings?.length > 0 && quoteOrderings?.length <= 2) &&
                        <div className="planRec-tray-plan placeholder-plan fwc-col-4 fwc-card-generic"></div>}
                    {quoteOrderings.length === 1 &&
                        <div className="planRec-tray-plan placeholder-plan fwc-col-4 fwc-card-generic"></div>}

                </>
        )
    }

    const displayEmptyCard = () => {
        return (
            <div className={'cs-card fwc-card-generic flat ' + fetchColumn()}> </div>
        )
    }

    const onClickDetails = (contract: Contract, type: string) => {
        switch (type) {
            case PLAN_TYPES_NAMES.MEDICAL:
                setMedicalContract(contract);
                break;
            case PLAN_TYPES_NAMES.DENTAL:
                setDentalContract(contract);
                break;
            case PLAN_TYPES_NAMES.VISION:
                setVisionContract(contract);
                break;
            default:
                setMedicalContract(null);
                setDentalContract(null);
                setVisionContract(null);
        }
    }

    const findPlanBenefit = (code, plan) => {
        const benefit = plan.contract.plan[0].planBenefits.find(benefit => benefit.planBenefitCode === code);
        return benefit?.planBenefitValue;
    };

    const handleCloseSuccessModal = () => {
        setShowSuccessModal(false);

        try {
            navigate(NAV_PATHS.ATK_SESSION_END);
        }
        catch (err) {
            console.error("ProposalQuoteOverview.tsx deleteProposal error", err);
        }
    }

    const deleteProposal = () => {
        setProposalLoading(true)

        if (agentSSOInfo?.eventHeader?.proposalID) {
            deleteAtkProposal(agentSSOInfo?.eventHeader?.proposalID).then((response) => {
                setProposalLoading(false)
                setDeletePopUp(false)
                setShowSuccessModal(true);
            })
                .catch((error) => {
                    console.error('ProposalQuoteOverview.tsx deleteProposal error :', error)
                    setServerError(true);
                    setProposalLoading(false)
                });
        } else {
            setProposalLoading(false)
        }
    }

    const getAtkPdfBrochureUrl = () => {
        switch (primaryProductType) {
            case PLAN_TYPES_NAMES.MEDICAL_PRODUCT:
                return medicalRecommendedPlans[0]?.pdfBrochureUrl;
            case PLAN_TYPES_NAMES.DENTAL_PRODUCT:
                return dentalRecommendedPlans[0]?.pdfBrochureUrl;
            case PLAN_TYPES_NAMES.VISION_PRODUCT:
                return visionRecommendedPlans[0]?.pdfBrochureUrl;
            default:
                return '';
        }
    }

    const editOrCopyProposal = (edit = true) => {
        updateComparedProducts([])
        if (edit) {
            //Edit same proposal so reset proposal id
            navigate(NAV_PATHS.AGENTS_BASICS, { state: { editFlow: true } });
        } else {
            //Copy to new Quote so reset proposal id
            updateATKsaveProposalResponse({} as ATKsaveProposalResponse);
            navigate(NAV_PATHS.AGENTS_BASICS, { state: { editFlow: true } });
        }
    }

    return (
        <div>
            <GlobalHeader />
            <EmailPrint pdfBrochureUrl={getAtkPdfBrochureUrl()} hideSendPrintButtons={true} openEmailModal={openEmailModal}
                closeEmailModal={() => setOpenEmailModal(false)} printQuote={printQuote} onPrintQuoteSuccess={() => setPrintQuote(false)} />
            <div className={'header-container'}>
                <PageHeader>
                    <Row alignItems="center">
                        <Col
                            bottomMargin
                            lg="5"
                            sm="12"
                        >
                            <Title>
                                {pageContent?.QUOTE_DETAILS}
                            </Title>
                        </Col>
                    </Row>
                </PageHeader>
            </div>
            {(!contentLoaded || proposalLoading) && <FullPageLoader />}
            {
                contentLoaded && !proposalLoading && serverError && <EmptyPlans useCase={EMPTY_PLANS_USE_CASES.SERVER_ERROR} labels={pageContent?.EMPTY_PLANS} />
            }
            {contentLoaded && !proposalLoading && !serverError && <div className='propsal-quote-container '>
                {
                    getProposalRes && Object.keys(getProposalRes)?.length > 0 && <>
                        <div className='fwc-row fwc-row-bm'>
                            <div className='fwc-col-4'>
                                <h3>{getProposalRes?.user?.firstName}</h3>
                            </div>
                            <div className='fwc-col-8'>
                                <div className='fwc-row buttons-align'>
                                    <div className='buttons-padding'>
                                        <button disabled={getProposalRes.proposalCreator === 'false' && !isFutureDate(getProposalRes.demographicInfo?.coverageEffectiveDate ?? '')} className={'fwc-btn fwc-btn-primary'} id='print-plan' onClick={() => { setPrintQuote(true) }}>{pageContent?.PRINT_PLAN}</button>
                                    </div>
                                    <div className='buttons-padding'>
                                        <button disabled={getProposalRes.proposalCreator === 'false'} className={'fwc-btn fwc-btn-primary'} id='edit' onClick={() => { editOrCopyProposal(getProposalRes.status === PROPOSAL_STATUSES.CREATED) }}>{getProposalRes.status === PROPOSAL_STATUSES.CREATED ? pageContent?.EDIT : pageContent?.COPY_NEW_QUOTE}</button>
                                    </div>
                                    <div className='buttons-padding'>
                                        <button disabled={getProposalRes.proposalCreator === 'false' && !isFutureDate(getProposalRes.demographicInfo?.coverageEffectiveDate ?? '')} className={'fwc-btn fwc-btn-primary'} id='shop' onClick={() => { setOpenEmailModal(true) }}>{getProposalRes.status === PROPOSAL_STATUSES.CREATED ? pageContent?.SEND_PROPOSAL : pageContent?.RESEND_PROPOSAL}</button>
                                    </div>
                                    <div className='buttons-padding'>
                                        <button disabled={getProposalRes.proposalCreator === 'false'} className={'fwc-btn fwc-btn-primary'} id='shop' onClick={() => { setDeletePopUp(true) }}>{pageContent?.DELET}</button>
                                    </div>
                                </div>

                            </div>
                        </div>
                        <div className="fwc-row coverage-accordion-container">
                            <PlanCoverageAccordion planType={''} labels={pageContent?.ACCORDION_LABELS} colaradoPlans={false} applicants={getFormattedApplicantDetails(getProposalRes?.demographicInfo?.applicant ?? [])} hideForQuoteDetails={true} />
                        </div>
                        <div className='fwc-row fwc-row-bm'>
                            <div className="coverages-container">
                                <div className="coverage-column date-created">
                                    <span id="state-label" className="coverage-bold-label">
                                        {pageContent?.STATE}
                                    </span>
                                    <div
                                        id={"state-value"}
                                        className="coverage-label"
                                    > {getProposalRes?.demographicInfo?.state}</div>
                                </div>
                                <div className="coverage-column date-created">
                                    <span id="zipcode-label" className="coverage-bold-label">
                                        {pageContent?.ZIP_CODE}
                                    </span>
                                    <div
                                        id={"zipcode-value"}
                                        className="coverage-label"
                                    > {getProposalRes?.demographicInfo?.zipCode}</div>
                                </div>
                                <div className="coverage-column date-created">
                                    <span id="date-created-label" className="coverage-bold-label">
                                        {pageContent?.DATE_CREATED}
                                    </span>
                                    <div
                                        id='date-created-value'
                                        className="coverage-label"
                                    > {formatDateToMMDDYYY(getProposalRes.createdTS ?? '')}</div>
                                </div>
                                <div className="coverage-column date-created">
                                    <span id="eff-date-label" className="coverage-bold-label">
                                        {pageContent?.EFFECTIVE_DATE}
                                    </span>
                                    <div
                                        id={"eff-date-value"}
                                        className="coverage-label"
                                    >{formatDateToMMDDYYY(getProposalRes?.demographicInfo?.coverageEffectiveDate ?? '')}</div>
                                </div>

                                <div className="coverage-column date-created">
                                    <span id="agent-writing-label" className="coverage-bold-label">
                                        {pageContent?.AGENT_WRITING_TIN}
                                    </span>
                                    <div
                                        id={"agent-writing-value"}
                                        className="coverage-label"
                                    > {getProposalRes?.agent?.writingTIN || '-'}</div>
                                </div>
                                <div className="coverage-column date-created">
                                    <span id="agency-writing-label" className="coverage-bold-label">
                                        {pageContent?.AGENCY_WRITING_TIN}
                                    </span>
                                    <div
                                        id={"agency-writing-value"}
                                        className="coverage-label"
                                    > {getProposalRes?.agent?.parentTin || '-'}</div>
                                </div>
                            </div>
                        </div>
                    </>
                }
                {medicalRecommendedPlans?.length > 0 && <div id="medical-plans">
                    <div className='fwc-row fwc-row-bm plan-type-info-wrapper'>
                        <span className={'sae-icon sae-icon-heart-health right-xxs'}></span>
                        <h2>{pageContent?.MEDICAL_PLANS}</h2>
                    </div>
                    <div className='fwc-row fwc-row-bm'>
                        <div className={'planRec-tray-content'}>
                            {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                return (
                                    filterArr?.length > 0 ?
                                        filterArr?.map((plan: RecComparedContract) => (
                                            plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={"planRec-tray-plan fwc-card-generic " + fetchColumn()}>
                                                <div className="planRec-tray-plan-header">
                                                    <div id="plan-card-grade" className="grade-container">
                                                        {renderGradetag(plan?.contract.gradeLevels)}
                                                    </div>
                                                    <div className={'market-tag ' + marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}>{marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}</div>
                                                </div>
                                                <div className={"planRec-tray-plan-title slider"}>
                                                    {plan?.contract.planMarketingName}
                                                </div>

                                                <button className={'fwc-btn fwc-btn-primary fwc-col-12'} onClick={() => applyNow(plan.quoteId ?? '')}>{pageContent?.APPLY_NOW}</button>
                                            </div>
                                        ))
                                        : <div className={"planRec-tray-plan fwc-card-generic " + fetchColumn()}>

                                        </div>
                                )
                            })}
                            {placeholders()}
                        </div>
                    </div>
                    <div className='fwc-row fwc-row-bm'>
                        <div className="denta-plans-width">
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                    <div className='pdf-wrapper'>
                                                        <p className={'pdf-card-paragraph'}>
                                                            <span className='sae-icon sae-icon-pdf icon-style' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}
                                                            ></span>
                                                            <p className='highlight-text' id='pdf-brocher' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}>{pageContent?.PDF_BROCHURE}</p>
                                                        </p>
                                                        <p>
                                                            <p className='highlight-text' id='pdf-brocher' onClick={() => onClickDetails(plan.contract, PLAN_TYPES_NAMES.MEDICAL)}>{pageContent?.DETAILS}</p>
                                                        </p>
                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}

                                {placeholders()}
                            </div>

                            {
                                (medicalRecommendedPlans?.length) && medicalRecommendedPlans[0].contract && (medicalRecommendedPlans[0].contract as Contract).plan[0].planBenefits?.map((benefit: PlanBenefit) => (
                                    <>
                                        {
                                            MEDICAL_PLAN_BENEFITS_ARR.includes(benefit.planBenefitCode) && (
                                                <>
                                                    <div className={'cs-row-header'}>{pageContent?.MEDICAL_PLAN_BENEFIT_NAMES[benefit.planBenefitCode]}</div>
                                                    <div className={'cs-row'}>

                                                        {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                                            const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                                            return (
                                                                filterArr?.length > 0 ?
                                                                    filterArr?.map((plan: RecComparedContract) => (
                                                                        plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                                            <p className={'cs-card-paragraph'}>
                                                                                <span dangerouslySetInnerHTML={{ __html: findPlanBenefit(benefit.planBenefitCode, plan) }}></span>
                                                                            </p>
                                                                        </div>
                                                                    ))
                                                                    : displayEmptyCard()
                                                            )
                                                        })}
                                                        {placeholders()}
                                                    </div>
                                                </>
                                            )
                                        }
                                    </>
                                ))}

                            <div className={'cs-row-header'}>{pageContent?.TOTAL_MONTHLY_PREMIUM}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                    <div className={'cs-card-header'}>
                                                        {getFormattedCurrency(plan?.contract?.rateData?.rate?.totalPremium)}
                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}
                                {placeholders()}
                            </div>

                            <div className={'cs-row-header'}>{pageContent?.TAX_CREDIT_APPLIED}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                    <div className={'cs-card-header'}>
                                                        {getFormattedCurrency(plan?.contract?.rateData?.rate?.subsidyAmtApplied)}

                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}
                                {placeholders()}
                            </div>


                            <div className={'cs-row-header'}>{pageContent?.TOTAL_MEM_RESPONSIBILITY}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = medicalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                    <div className={'cs-card-header'}>
                                                        {getFormattedCurrency(plan?.contract?.rateData?.rate?.totSubsidizedPrem)}

                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}
                                {placeholders()}
                            </div>
                        </div>
                    </div>

                </div>}

                {
                    dentalRecommendedPlans?.length > 0 && <div id="dental-plans">
                        <div className='fwc-row fwc-row-bm plan-type-info-wrapper'>
                            <span className={'sae-icon sae-icon-tooth right-xxs'}></span>
                            <h2>{pageContent?.DENTAL_PLANS}</h2>
                        </div>
                        <div className='fwc-row fwc-row-bm'>
                            <div className={'planRec-tray-content'}>

                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={"planRec-tray-plan fwc-card-generic " + fetchColumn()}>
                                                    <div className="planRec-tray-plan-header">
                                                        <div id="plan-card-grade" className="grade-container">
                                                            {renderGradetag(plan?.contract.gradeLevels)}
                                                        </div>
                                                        <div className={'market-tag ' + marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}>{marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}</div>
                                                    </div>
                                                    <div className={"planRec-tray-plan-title slider"}>
                                                        {plan?.contract.planMarketingName}
                                                    </div>
                                                    {
                                                        !medicalRecommendedPlans?.length && <button className={'fwc-btn fwc-btn-primary fwc-col-12'} onClick={() => applyNow(plan.quoteId ?? '')}>{pageContent?.APPLY_NOW}</button>
                                                    }
                                                </div>
                                            ))
                                            : <div className={"planRec-tray-plan fwc-card-generic " + fetchColumn()}>

                                            </div>
                                    )
                                })}
                                {placeholders()}
                            </div>
                        </div>

                        <div className='fwc-row fwc-row-bm'>
                            <div className='denta-plans-width'>
                                <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                        <div className='pdf-wrapper'>
                                                            <p className={'pdf-card-paragraph'}>
                                                                <span className='sae-icon sae-icon-pdf icon-style' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}
                                                                ></span>
                                                                <p className='highlight-text' id='pdf-brocher' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}>{pageContent?.PDF_BROCHURE}</p>
                                                            </p>
                                                            <p>
                                                                <p className='highlight-text' id='pdf-brocher' onClick={() => onClickDetails(plan.contract, PLAN_TYPES_NAMES.DENTAL)}>{pageContent?.DETAILS}</p>
                                                            </p>
                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}
                                    {placeholders()}
                                </div>
                                <div>

                                    {
                                        (!medicalRecommendedPlans?.length) && (dentalRecommendedPlans?.length) && dentalRecommendedPlans[0].contract && (dentalRecommendedPlans[0].contract as Contract).plan[0].planBenefits?.map((benefit: PlanBenefit) => (
                                            <>
                                                {
                                                    DENTAL_PLAN_BENEFITS_ARR.includes(benefit.planBenefitCode) && (
                                                        <>
                                                            <div className={'cs-row-header'}>{pageContent?.DENTAL_PLAN_BENEFIT_NAMES[benefit.planBenefitCode]}</div>
                                                            <div className={'cs-row'}>

                                                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                                                    const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                                                    return (
                                                                        filterArr?.length > 0 ?
                                                                            filterArr?.map((plan: RecComparedContract) => (
                                                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                                                    <p className={'cs-card-paragraph'}>
                                                                                        <span dangerouslySetInnerHTML={{ __html: findPlanBenefit(benefit.planBenefitCode, plan) }}></span>
                                                                                    </p>
                                                                                </div>
                                                                            ))
                                                                            : displayEmptyCard()
                                                                    )
                                                                })}
                                                                {placeholders()}
                                                            </div>
                                                        </>
                                                    )
                                                }
                                            </>
                                        ))}
                                </div>
                                <div className={'cs-row-header'}>{pageContent?.TOTAL_MONTHLY_PREMIUM}</div>
                                <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.totalPremium)}
                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>
                                {
                                    !medicalRecommendedPlans?.length && <div className={'cs-row-header'}>{pageContent?.TAX_CREDIT_APPLIED}</div>
                                }

                                {!medicalRecommendedPlans?.length && <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.subsidyAmtApplied)}

                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>
                                }


                                {!medicalRecommendedPlans?.length && <div className={'cs-row-header'}>{pageContent?.TOTAL_MEM_RESPONSIBILITY}</div>}
                                {!medicalRecommendedPlans?.length && <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = dentalRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.totSubsidizedPrem)}

                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}
                                    {placeholders()}
                                </div>
                                }

                            </div>
                        </div>

                    </div>
                }

                {
                    visionRecommendedPlans?.length > 0 && <div id="vision-plans">
                        <div className='fwc-row fwc-row-bm plan-type-info-wrapper'>
                            <span className={'sae-icon sae-icon-eye right-xxs'}></span>
                            <h2>{pageContent?.VISION_PLANS}</h2>
                        </div>
                        <div className='fwc-row fwc-row-bm'>
                            <div className={'planRec-tray-content'}>

                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: RecComparedContract) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={"planRec-tray-plan fwc-card-generic " + fetchColumn()}>
                                                    <div className="planRec-tray-plan-header">
                                                        <div id="plan-card-grade" className="grade-container">
                                                            {renderGradetag(plan?.contract.gradeLevels)}
                                                        </div>
                                                        <div className={'market-tag ' + marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}>{marketTagClass(plan.contract.onExchange, plan.contract.poPlan)}</div>
                                                    </div>
                                                    <div className={"planRec-tray-plan-title slider"}>
                                                        {plan?.contract.planMarketingName}
                                                    </div>
                                                    {
                                                        !medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <button className={'fwc-btn fwc-btn-primary fwc-col-12'} onClick={() => applyNow(plan.quoteId ?? '')}>{pageContent?.APPLY_NOW}</button>
                                                    }
                                                </div>
                                            ))
                                            : <div className={"planRec-tray-plan fwc-card-generic " + fetchColumn()}>

                                            </div>
                                    )
                                })}

                                {placeholders()}
                            </div>
                        </div>
                        <div className='fwc-row fwc-row-bm'>
                            <div className='denta-plans-width'>
                                <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                        <div className='pdf-wrapper'>
                                                            <p className={'pdf-card-paragraph'}>
                                                                <span className='sae-icon sae-icon-pdf icon-style' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}
                                                                ></span>
                                                                <p className='highlight-text' id='pdf-brocher' onClick={() => window.open(plan.pdfBrochureUrl, "_blank")}>{pageContent?.PDF_BROCHURE}</p>
                                                            </p>
                                                            <p>
                                                                <p className='highlight-text' id='pdf-brocher' onClick={() => onClickDetails(plan.contract, PLAN_TYPES_NAMES.VISION)}>{pageContent?.DETAILS}</p>
                                                            </p>
                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>


                                {
                                    (!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length) && (visionRecommendedPlans?.length) && visionRecommendedPlans[0].contract && (visionRecommendedPlans[0].contract as Contract).plan[0].planBenefits?.map((benefit: PlanBenefit) => (
                                        <>
                                            {
                                                VISION_PLAN_BENEFITS_ARR.includes(benefit.planBenefitCode) && (
                                                    <>
                                                        <div className={'cs-row-header'}>{pageContent?.VISION_PLAN_BENEFIT_NAMES[benefit.planBenefitCode]}</div>
                                                        <div className={'cs-row'}>

                                                            {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                                                const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                                                return (
                                                                    filterArr?.length > 0 ?
                                                                        filterArr?.map((plan: RecComparedContract) => (
                                                                            plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                                                <p className={'cs-card-paragraph'}>
                                                                                    <span dangerouslySetInnerHTML={{ __html: findPlanBenefit(benefit.planBenefitCode, plan) }}></span>
                                                                                </p>
                                                                            </div>
                                                                        ))
                                                                        : displayEmptyCard()
                                                                )
                                                            })}
                                                            {placeholders()}
                                                        </div>
                                                    </>
                                                )
                                            }
                                        </>
                                    ))}




                                <div className={'cs-row-header'}>{pageContent?.TOTAL_MONTHLY_PREMIUM}</div>
                                <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.totalPremium)}
                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>

                                {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <div className={'cs-row-header'}>{pageContent?.TAX_CREDIT_APPLIED}</div>}
                                {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.subsidyAmtApplied)}

                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>}


                                {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <div className={'cs-row-header'}>{pageContent?.TOTAL_MEM_RESPONSIBILITY}</div>}
                                {!medicalRecommendedPlans?.length && !dentalRecommendedPlans?.length && <div className={'cs-row'}>
                                    {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                        const filterArr = visionRecommendedPlans?.filter((plan: RecComparedContract) => plan.quoteId === ordering.quoteId);
                                        return (
                                            filterArr?.length > 0 ?
                                                filterArr?.map((plan: RecComparedContract) => (
                                                    plan.quoteId === ordering.quoteId && <div key={plan.contract.contractCode} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                        <div className={'cs-card-header'}>
                                                            {getFormattedCurrency(plan?.contract?.rateData?.rate?.totSubsidizedPrem)}

                                                        </div>

                                                    </div>
                                                ))
                                                : displayEmptyCard()
                                        )
                                    })}

                                    {placeholders()}
                                </div>
                                }

                            </div>
                        </div>

                    </div>
                }
                {
                    totalPremiumArray?.length > 0 && <div className='fwc-row fwc-row-bm'>
                        <div className='denta-plans-width'>
                            <div className={'cs-row-header'}>{pageContent?.TOTAL}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {
                                    const filterArr = totalPremiumArray?.filter((plan: any) => plan.quoteId === ordering.quoteId);
                                    return (
                                        filterArr?.length > 0 ?
                                            filterArr?.map((plan: any) => (
                                                plan.quoteId === ordering.quoteId && <div key={plan.quoteId} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                                    <div className={'cs-card-header'}>
                                                        {getFormattedCurrency(plan.totalPremium)}
                                                    </div>

                                                </div>
                                            ))
                                            : displayEmptyCard()
                                    )
                                })}

                                {placeholders()}
                            </div>

                        </div>
                    </div>
                }
                {
                    quoteOrderings?.length > 0 && <div className='fwc-row fwc-row-bm'>
                        <div className='denta-plans-width'>
                            <div className={'cs-row-header'}>{pageContent?.DISCLAIMERS}</div>
                            <div className={'cs-row'}>
                                {quoteOrderings?.map((ordering: QuoteOrdering) => {

                                    return (
                                        <div key={ordering.quoteId} className={'cs-card fwc-card-generic flat ' + fetchColumn()}>
                                            <p className={'cs-card-paragraph'}>
                                                {pageContent?.DISCLAIMER_REC}
                                            </p>
                                        </div>
                                    )
                                })}

                                {placeholders()}
                            </div>

                        </div>
                    </div>
                }
                {
                    quoteOrderings?.length > 0 && <div className='fwc-row fwc-row-bm' id='disclaimer'>
                        <div className='denta-plans-width'>
                            <div className="fwc-row-bm">
                                <p>*{pageContent?.DISCLAIMER_SEC.DISCLAIMER}:</p>
                                <p>{pageContent?.DISCLAIMER_SEC.DISCLAIMER_1}</p>
                                <p>{pageContent?.DISCLAIMER_SEC.DISCLAIMER_2}</p>
                                <p>{pageContent?.DISCLAIMER_SEC.DISCLAIMER_3}</p>
                            </div>
                        </div>
                    </div>
                }

                {medicalContract && (
                    <MedicalPlanDetails
                        isCurrentCoveragePlan={false}
                        closeMedicalPlanDetails={() => setMedicalContract(null)}
                        contract={medicalContract}
                        showCloseButton={true}
                    />
                )}
                {dentalContract && (
                    <DentalPlanDetails
                        isCurrentCoveragePlan={false}
                        closeDentalPlanDetails={() => setDentalContract(null)}
                        contract={dentalContract}
                        showCloseButton={true}
                    />
                )}
                {visionContract && (
                    <VisionPlanDetails
                        isCurrentCoveragePlan={false}
                        closeVisionPlanDetails={() => setVisionContract(null)}
                        contract={visionContract}
                        showCloseButton={true}
                    />
                )}
                <DeleteApplicationPopup showPopup={deletePopUp} deleteProposal={true} popupClose={() => setDeletePopUp(false)} confirmDelete={() => deleteProposal()} />
                <Modal open={showSuccessModal} onClose={handleCloseSuccessModal}>
                    <Modal.Body>
                        {<SuccessModalBody
                            heading={pageContent?.SUCCESS_POPUP_LABLES?.HEADING}
                            description={pageContent?.SUCCESS_POPUP_LABLES?.SUCCESS_MESSAGE}
                            closeButtonText={pageContent?.SUCCESS_POPUP_LABLES?.OK}
                            closeModal={() => {
                                handleCloseSuccessModal();
                            }}
                        />
                        }
                    </Modal.Body>
                </Modal>
            </div>}
        </div>
    )
}
