import { PreLoader, Tooltip } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { Controller, useForm } from 'react-hook-form';
import { useLocation, useNavigate } from 'react-router-dom';
import { getCMSStaticContent } from '../../../api/cms/cmsUtil';
import { getZipcodeValidation } from '../../../api/services/utilService';
import '../../../components/common/zip-code/ZipCode';
import { BCBS_URL, BRAND_NAMES, BSCS_LINK_TEXT, LANGUAGE_CODES, PLAN_TYPES_NAMES, ROLE_ALL, SCREEN_NAMES } from '../../../shared/globalConstants';
import { getPlanTypes } from '../../../shared/utils/globalUtils';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import './ZipCode.scss';
import { default as content } from './zipCodeMessages.json';

function ZipCode(props) {
  const { onUpdateZipCode, isEdit, noNavigate } = props;

  let navigate = useNavigate();
  let location = useLocation();

  const { brand, updateZipCodeResponse, updateEditZipCodeRes, updateApplicantFormDetails, updateZipCodeFormDetails, updatePlanTypes, updateEditPlanTypes, updateEditZipCodeFormDetails, county, year, zipcode, countyList, state, editZipCodeFormDetails, resetEditZipCodeRes, resetEditZipCodeFormDetails, updateZipCode, updateSelectedBrand, zipCodeRes, updateShowCountyError, showCountyError, updateBrand } = useGlobalStore(
    (state) => state
  );
  const { resetMedicalPlanStates } = useGlobalStore((state) => state);

  const { register, control, handleSubmit, formState, setValue, watch, reset, getValues } = useForm<ZipCodeForm>({
    mode: 'onChange',
    defaultValues: {
      zipCode: '',
      countyField: '',
      planYear: ''
    },
    values: {
      zipCode: zipcode,
      countyField: county,
      planYear: year
    }
  });
  const { isValid, errors } = formState;
  const [isLoading, setIsLoading] = useState(false);
  const [showCountyInfo, setShowCountyInfo] = useState(false);
  const [hasVAZipcode, setHasVAZipcode] = useState(false);
  const [pageContent, setContent] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const zipCode = watch('zipCode');
  const [zipcodeDynamicData, setZipcodeDynamicData] = useState<any>(null);
  const { isWellpoint } = useUserStore((state) => state);
  const [invalidZipCode, setInvalidZipCode] = useState(false);
  const [jsonArea, setJsonArea] = useState(props.jsonArea ? JSON.parse(props.jsonArea) : null);


  const getContent = useCallback(async (): Promise<boolean> => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.ZIPCODE);
      let cmsResponse = response.data;
      setContent(cmsResponse.data.iospagecontentList.items[0].pageContent.ZIPCODE);
      setJsonArea(cmsResponse.data.iospagecontentList.items[0].pageContent.ZIPCODE);
      if (!year) {
        setValue('planYear', cmsResponse?.data.iospagecontentList.items[0].pageContent.ZIPCODE.YEARS[0]);
      }
    } catch (error) {
      setContent(content?.data.iospagecontentList.items[0].pageContent.ZIPCODE);
      setJsonArea(content?.data.iospagecontentList.items[0].pageContent.ZIPCODE)
      if (!year) {
        setValue('planYear', content?.data.iospagecontentList.items[0].pageContent.ZIPCODE.YEARS[0]);
      }
      return false;
    }
    finally {
      setContentLoaded(true);
    }
    return true;
  }, []);

  useEffect(() => {
    initiate();
    if (zipCode && zipCode.length === 5) {
      fetchDataForCounty(zipCode);
    }
  }, [zipCode]);

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    if (showCountyError) {
      setShowCountyInfo(false);
      setHasVAZipcode(false);
    } else {
      if (isValid) {
        let coverageTypes = getPlanTypes(zipCodeRes, getValues().countyField, getValues().planYear, ROLE_ALL)
        if (coverageTypes?.length && !coverageTypes.includes(PLAN_TYPES_NAMES.MEDICAL)) {
          setShowCountyInfo(true);
        } else {
          setShowCountyInfo(false);
        }
      }
    }
  }, [getValues, isValid, showCountyError, zipCode, zipCodeRes])

  const zipCodeForm = handleSubmit((data) => {
    if (props.disablePlanYear) {
      data.planYear = isWellpoint ? jsonArea.YEARS[1] : jsonArea.YEARS[0];
    }
    updateZipCodeFormDetails(data.zipCode, data.countyField, data.planYear);
    updatePlanTypes(data.countyField, data.planYear);
    if (isEdit) {
      onUpdateZipCode(isEdit);
    }
    if (!noNavigate && isValid) {
      navigate('/individuals/shop/basics');
    }
  });

  const handleZipCodeChange = (event) => {
    updateShowCountyError(false);
    const name = event.target.name;
    const value = event.target.value.replace(/[^0-9]/g, '');
    event.target.value = value;
  };

  const fetchDataForCounty = async (zipcode) => {
    try {
      setIsLoading(true);
      setShowCountyInfo(false);
      setHasVAZipcode(false);
      setInvalidZipCode(false);
      const response = await getZipcodeValidation({ zipCode: zipcode, marketSegment: 'UNDER_65' });
      if (response.data.zipCodeResponse.responseMessage.message === 'SUCCESSFULLY FETCHED') {
        updateShowCountyError(false);
        setCountyValue(response?.data.zipCodeResponse.zipCode.countyList.county);
        updateBrand(response.data.zipCodeResponse.zipCode.countyList.county[0].brand);
        updateZipCodeResponse(response.data.zipCodeResponse.zipCode);

        await getContent();
        // resetMedicalPlanStates(); //Should be moved to final popup
      }
      else {
        if (response.data.zipCodeResponse.responseMessage.message === 'INVALID ZIPCODE') {
          setInvalidZipCode(true);
        }
        updateShowCountyError(true);
      }
    } catch (error) {
      console.error('Error fetching county data:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const isNotSelect = (value) => {
    if (value === 'select') {
      return false;
    } else {
      return true;
    }
  };

  const handleAnthemWellpointZipcode = (countyData, isCheckInService) => {
    if (isWellpoint && countyData.brand === BRAND_NAMES.WELLPOINT_BRAND) {
      isCheckInService ? updateShowCountyError(false) : setValue('countyField', countyData.countyName)
    } else if (!isWellpoint && countyData.brand !== BRAND_NAMES.WELLPOINT_BRAND) {
      isCheckInService ? updateShowCountyError(false) : setValue('countyField', countyData.countyName)
    } else {
      updateShowCountyError(true);
      setValue('countyField', countyData.countyName)
    }
  }

  const checkInService = (value) => {
    let data = countyList.find((county) => {
      return county.countyName === value;
    });
    if (data?.inService.value === 'Y') {
      handleAnthemWellpointZipcode(data, true)
    } else {
      updateShowCountyError(true);
    }
  }

  const setCountyValue = (countyData) => {
    if (countyData.length === 1) {
      countyData[0].inService.value === 'Y' ? handleAnthemWellpointZipcode(countyData[0], false) : updateShowCountyError(true); setValue('countyField', countyData[0].countyName);
    } else {
      if (county) {
        const countyValue = countyData.find((val) => val.countyName === county);
        if (countyValue && countyValue.inService.value === 'Y') {
          handleAnthemWellpointZipcode(countyValue, false)
          // setValue('countyField', countyValue.countyName)
        } else {
          setValue('countyField', 'select');
        }
      } else {
        setValue('countyField', 'select');
      }
    }
  }
  return (
    <>
      {contentLoaded && props && (
        <form className="zipcode-fom" onSubmit={zipCodeForm} noValidate>
          <div className="fwc-row">
            <div className="fwc-col-6 md-12 sm-12">
              <div className="fwc-input">
                <div className={'fwc-flex'}>
                  <label className="fwc-label right-xxs" htmlFor="zipCode">
                    {props.zipCodeText || pageContent?.ZIP}
                  </label>
                  <Tooltip icon message={props.zipCodeToolTip || pageContent?.ZIP_TOOLTIP}></Tooltip>
                </div>
                <input
                  id="zipCode"
                  type="text"
                  maxLength={5}
                  pattern="[^0-9]"
                  defaultValue={zipcode || ''}
                  {...register('zipCode', {
                    required: {
                      value: true,
                      message: pageContent.REQUIRED_FIELD
                    },
                    minLength: {
                      value: 5,
                      message: props.invalidZipCodeText || pageContent?.INVALID_ZIP
                    },
                    onChange: (e) => {
                      handleZipCodeChange(e);
                    }
                  })}
                />
                {errors.zipCode?.message && (
                  <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                    {errors.zipCode?.message}
                  </span>
                )}
              </div>
            </div>
            {!props.disablePlanYear &&
              (<div className="fwc-col-6 md-12 sm-12">
                <div className={'fwc-flex'}>
                  <label className="fwc-label right-xxs" htmlFor="year-select">
                    {props.planYearText || pageContent?.YEAR}{' '}
                  </label>
                  <Tooltip icon message={props.planYearToolTip || pageContent?.YEAR_TOOLTIP}></Tooltip>
                </div>
                <div className="fwc-select-list">
                  <select
                    id="year-select"
                    defaultValue={jsonArea?.YEARS[0]}
                    data-analytics={'planYearLpIos'}
                    {...register('planYear', {
                      required: true
                    })}
                  >
                    {jsonArea.YEARS.map((yearvalue, index) => {
                      return (
                        <option key={index} value={yearvalue} data-analytics={yearvalue.toString() === new Date().getFullYear().toString() ? 'currentYearLpIos' : 'futureYearLpIos'}>
                          {yearvalue}
                        </option>
                      );
                    })}
                  </select>
                </div>
              </div>
              )}
          </div>
          {showCountyError && (
            <div className="fwc-row fwc-col-12" id={'zip_error'}>
              <div className="error-callout">
                <div className={'fwc-flex a-start'}>
                  <div className={'fwc-inline-icon fwc-icon-info error-icon'}></div>
                  {invalidZipCode && (
                    <div>
                      <div id="zip_error" dangerouslySetInnerHTML={{ __html: props.invalidZipCode || pageContent?.INVALID_ZIP_TEXT }}></div>
                    </div>
                  )}
                  {!invalidZipCode && (
                    <div>
                      <div id="zip_error" dangerouslySetInnerHTML={{ __html: isWellpoint ? props.invalidCountryLink || pageContent?.INVALID_COUNTY_LINK_FOR_WELLPOINT : props.invalidCountryLink || pageContent?.INVALID_COUNTY_LINK_FOR_ANTHEM }}></div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          )}

          {showCountyInfo && (
            <div className="fwc-row fwc-col-12" id={'zip_error'}>
              <div className="error-callout">
                <div className={'fwc-flex a-start'}>
                  <div className={'fwc-inline-icon fwc-icon-info error-icon'}></div>
                  <div>
                    {hasVAZipcode ? <div>
                      {jsonArea?.INFO_MESSAGE_2 || pageContent?.INFO_MESSAGE_2} <span> <a href={'javascript:void(0)'} id={'bsbc-link'} onClick={() => window.open(BCBS_URL, "_blank")}>{BSCS_LINK_TEXT}</a> </span>
                    </div> : <div>
                      {jsonArea?.INFO_MESSAGE_1 || pageContent?.INFO_MESSAGE_1}
                    </div>}
                  </div>
                </div>
              </div>
            </div>
          )}

          {countyList && countyList.length === 1 && (
            <div className="fwc-input top-xs">
              <div className={'fwc-flex'}>
                <label className="fwc-label right-xxs" htmlFor="county-select">
                  {jsonArea?.COUNTY || pageContent?.COUNTY}
                </label>
              </div>
              <input id="county-select" type="text" maxLength={5} pattern="[^0-9]" disabled {...register('countyField')} />
            </div>
          )}
          {countyList && countyList.length > 1 && (
            <div className="fwc-row">
              <div className="fwc-select-list fwc-col-12 top-xs">
                <label className="fwc-label" htmlFor="county-select">
                  {jsonArea?.COUNTY || pageContent?.COUNTY}
                </label>
                <Controller
                  name="countyField"
                  control={control}
                  defaultValue={county || (countyList && countyList[0]?.countyName) || ''}
                  rules={{ validate: { isNotSelect } }}
                  render={({ field }) => (
                    <select id="county-select" {...field} onChange={(e) => {
                      field.onChange(e); // Call the default onChange handler
                      checkInService(e.target.value); // Your custom logic here
                      updateSelectedBrand(e.target.value)
                    }}>
                      <option value="select">{pageContent?.SELECT}</option>
                      {countyList.map((county, index) => {
                        return (
                          <option key={index} value={county['countyName']}>
                            {county['countyName']}
                          </option>
                        );
                      })}
                    </select>
                  )}
                />
                {errors.countyField && errors.countyField.type === 'isNotSelect' && (
                  <span role="alert" className="fwc-inline-icon fwc-icon-delete" id="zip_error">
                    {jsonArea?.PLEASE_SELECT_COUNTY || pageContent?.PLEASE_SELECT_COUNTY}
                  </span>
                )}
              </div>
            </div>
          )}
          {isLoading && <PreLoader id={'login-modal-loader'} />}
          {!isLoading && !showCountyError && (
            <button data-analytics={`${isEdit ? "saveChangesBasicsModalIos" : "getQuoteBtnLpIos"}`} className="fwc-btn fwc-btn-callout fwc-col-12 top-sm" id={'get-quote'}>
              {isEdit ? jsonArea?.SAVE_CHANGES || pageContent?.SAVE_CHANGES : props.getQuoteText || pageContent?.GET_QUOTE}
            </button>
          )}
        </form>
      )}
    </>
  );
}
export default ZipCode;
