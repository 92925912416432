import { Modal } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import GlobalFooter from '../../../../components/common/global-footer/GlobalFooter';
import GlobalHeader from '../../../../components/common/global-header/GlobalHeader';
import ShopperTabs from '../../../../components/common/shopperTabs/ShopperTabs';
import TwoFAModalBody from '../../two-fa-modal/TwoFAModalBody';
import MacLoginWithAnthemCard from '../mac-login-with-anthem-card/MacLoginWithAnthemCard';
import MacLoginWithCreds from '../mac-login-with-creds/MacLoginWithCreds';

import { getCMSStaticContent } from '../../../../api/cms/cmsUtil';
import { getUpdatedMacLoginTabs } from './macLoginUtils';

import { LANGUAGE_CODES, NAV_PATHS, SCREEN_NAMES } from '../../../../shared/globalConstants';
import { useGlobalStore } from '../../../../store/globalStore';
import { TWO_FA_USE_CASES } from '../../two-fa-modal/constants';
import './MacLogin.scss';
import { BROWSER_TAB_TITLE, MAC_LOGIN_TABS, MAC_LOGIN_TABS_VALUES } from './constants';
import { default as Content } from './macLogin.json';



const MacLogin = () => {
    const [pageContent, setPageContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const [macLoginTabs, setMacLoginTabs] = useState(MAC_LOGIN_TABS)
    const [selectedMacTab, setSelectedMacTab] = useState(MAC_LOGIN_TABS_VALUES.LOGIN)
    const [showTwoFAModal, setShowTwoFAModal] = useState(false);
    const [modalHeading, setModalHeading] = useState('');
    const { deepLinkInfo } = useGlobalStore((state) => state);
    const { proposalID } = deepLinkInfo

    const navigate = useNavigate()

    useEffect(() => {
        const originalTitle = document.title;
        document.title = BROWSER_TAB_TITLE;
        return () => {
            document.title = originalTitle;
        };
    }, []);

    useEffect(() => {
        if (proposalID) {
            setMacLoginTabs([macLoginTabs[0]])
        }
    }, [proposalID, pageContent])

    const getContent = useCallback(async (): Promise<boolean> => {
        let content: any;

        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.MAC_LOGIN);
            let cmsResponse = response.data;
            content = cmsResponse.data.iospagecontentList.items[0].pageContent?.MacLogin;
        } catch (error) {
            content = Content.data.iospagecontentList.items[0].pageContent.MacLogin;

            return false;
        } finally {
            setPageContent(content);
            setMacLoginTabs(getUpdatedMacLoginTabs(macLoginTabs, content))
            setContentLoaded(true);
        }

        return true;
    }, []);

    const initiate = async () => {
        await getContent();
    };

    useEffect(() => {
        initiate()
    }, [])

    const handleTabChange = (tab: string) => {
        setSelectedMacTab(tab);
    };

    const initiateTwoFa = () => {
        setModalHeading(pageContent?.HEADING);
        setShowTwoFAModal(true);
    }

    const getTabComponent = () => {
        switch (selectedMacTab) {
            case MAC_LOGIN_TABS_VALUES.LOGIN:
                return <MacLoginWithCreds labels={pageContent} initiateTwoFa={() => initiateTwoFa()} />
            default:
                return <MacLoginWithAnthemCard labels={pageContent} />
        }
    }

    const handleTwoFAModalClose = () => {
        setShowTwoFAModal(false);
    };


    if (!contentLoaded) {
        return <FullPageLoader />;
    } else {
        return (
            <div className='mac-login-container'>
                <GlobalHeader showOnlyLogo={true} />
                <div className='login-form-outer-wrapper'>
                    <div className='fwc-row'>
                        <div className='static-container '>
                            <p className='home-text' onClick={() => navigate(NAV_PATHS.HOME)}>
                                {pageContent?.HOME}
                            </p>
                            <h1 className='mac-login-title'>
                                {pageContent?.MY_ANTHEM_CHOICES}
                            </h1>
                            <h4 className='mac-login-title'>
                                {pageContent?.IT_IS_EASY_TO_COMPARE}
                            </h4>
                            <img src={pageContent?.MAC_LOGO} className='mac-image' id={'mac-image'} alt="" />
                            <p className='mac-login-desc'>
                                {pageContent?.MAC_LOGINDESC_1}
                            </p>
                            <p className='mac-login-desc'>
                                {pageContent?.MAC_LOGINDESC_2}
                            </p>
                        </div>
                        <div className='login-container'>
                            <h4 className='mac-login-title'>
                                {pageContent?.LOGIN_WITH_YOUR}
                            </h4>
                            <div className='mac-login-tabs'>
                                <ShopperTabs tabs={macLoginTabs} activeTab={selectedMacTab} onChange={handleTabChange} />
                            </div>
                            <div className="form-container">
                                {getTabComponent()}
                            </div>
                        </div>
                    </div>
                </div>
                <Modal open={showTwoFAModal} onClose={handleTwoFAModalClose} title={modalHeading}>
                    <Modal.Body>
                        <TwoFAModalBody
                            useCase={TWO_FA_USE_CASES.LOGIN}
                            showModal={showTwoFAModal}
                            showFooter={true}
                            closeModal={handleTwoFAModalClose}
                            hideModalHeading={() => setModalHeading('')}
                            updateModalHeading={(heading) => setModalHeading(heading)}
                        />
                    </Modal.Body>
                </Modal>
                <GlobalFooter />
            </div>
        )
    }
}

export default MacLogin