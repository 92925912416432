import { Tooltip } from '@sydney-broker-ui/ios';
import { AxiosError, isAxiosError } from 'axios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useState } from 'react';
import { KY_EXCHANGE_BASE_URLS, OFF_EXCHANGE_BASE_URLS, WELLPOINT_OFF_EXCHANGE_BASE_URLS } from '../../../../api/api-name-list';
import { getCMSStaticContent } from '../../../../api/cms/cmsUtil';
import { HTTP_STATUS_CODES } from '../../../../api/constants';
import FullPageLoader from '../../../../components/common/full-page-loader/FullPageLoader';
import SubmitApplicationPopup from '../../../../components/common/submit-application-popup/SubmitApplicationPopup';
import { AGENT_ROLES, APPLICATION_STATUSES, DATE_FORMATS, IN_PROGRESS_APPLICATION_STATUSES, LANGUAGE_CODES, SCREEN_NAMES, SET_APPLICATION_CONSTANTS, STATE_CODES } from '../../../../shared/globalConstants';
import { useGlobalStore } from '../../../../store/globalStore';
import { useUserStore } from '../../../../store/userStore';
import { getEncryptacnPayload } from '../../../public/plan-selection-summary/planSelectionSummaryUtils';
import { getEncryptAcn, setApplicationApi } from '../../../public/plan-selection-summary/planSummaryServices';
import { getPDF } from '../pdf/pdfServices';
import './DashboardCard.scss';
import { CONTINUE_APPLICATION, DELETE_PAYLOAD_INPUT } from './constants';
import { cancelInProgressApplication, generateVoiceVaultLetter, getDashboardApplications, submitApplication } from './dashboardCardServices';
import { deleteInProgressDashboardCardPayload, getApplicationDashboardCardPayload, setApplicationDashboardCardPayload, submitApplicationDashboardCardPayload } from './dashboardCardUtils';
import { default as Content } from './dashboardcard.json';


const { REACT_APP_ENV } = process.env;
function DashboardCard(props) {
    const [pageContent, setPageContent] = useState<any>(null);
    const [contentLoaded, setContentLoaded] = useState(false);
    const { brand, zipCodeRes, agentSSOInfo } = useGlobalStore((store) => store);
    const { isWellpoint } = useUserStore((state) => state);
    const getContent = useCallback(async (): Promise<boolean> => {
        let content: any;
        try {
            const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.DASHBOARD_CARD);
            content = response.data.data.iospagecontentList.items[0].pageContent?.DashboardCard;
        } catch (error) {
            content = Content.data.iospagecontentList.items[0].pageContent.DashboardCard;
            return false;
        } finally {
            setPageContent(content);
            setContentLoaded(true);
        }
        return true;
    }, []);

    const initiate = async () => {
        await getContent();
    };

    useEffect(() => {
        initiate();
    }, []);

    const getMessage = (status) => {
        switch (status) {
            case APPLICATION_STATUSES.ADDINFOREQD:
                return pageContent?.OFF_SUBMITTED_ADDINFOREQD_TOOLTIP.replace('{addinfo_date}', dayjs(props.cardData.updateDt).add(14, 'day').format(DATE_FORMATS.MMDDYYYY));
            case APPLICATION_STATUSES.SEPAPPROVED:
                return pageContent?.OFF_SUBMITTED_SEPAPPROVED_TOOLTIP.replace('{coverage_date}', dayjs(props.cardData.reqEffDate).format(DATE_FORMATS.MMDDYYYY)).replace('{initial_payment}', props.cardData.totalPremiumAmt);
            case APPLICATION_STATUSES.SUBMITTED:
                return pageContent?.OFF_SUBMITTED_TOOLTIP.replace('{coverage_date}', dayjs(props.cardData.reqEffDate).format(DATE_FORMATS.MMDDYYYY)).replace('{initial_payment}', props.cardData.totalPremiumAmt);
            case APPLICATION_STATUSES.APPROVED:
                return pageContent?.OFF_ENROLLED_TOOLTIP.replace('{coverage_date}', dayjs(props.cardData.reqEffDate).format(DATE_FORMATS.MMDDYYYY));
            case APPLICATION_STATUSES.INPROGRESS:
                if (props.cardData.applicationType === CONTINUE_APPLICATION.ONEXCHANGE && props.cardData.state === CONTINUE_APPLICATION.KY) {
                    return pageContent?.ON_INPROGRESS_KYNEC;
                } else { return pageContent?.OFF_INPROGRESS_TOOLTIP; }
            case APPLICATION_STATUSES.PENDINGVALIDATION:
                return pageContent?.OFF_ELIGIBILITYREVIEW_TOOLTIP;
            case APPLICATION_STATUSES.QENOTVALID:
                return pageContent?.OFF_SUBMITTED_QENOTVALID_TOOLTIP;
            case APPLICATION_STATUSES.WEMCANCELLED:
                return pageContent?.OFF_SUBMITTED_WEMCANCELLED_TOOLTIP;
            case APPLICATION_STATUSES.PENDATTESTATION:
                return pageContent?.IN_PENDATTESTATION_TOOLTIP;
            case APPLICATION_STATUSES.PENDING:
                return pageContent?.IN_PENDING_TOOLTIP;
            default:
                return '';
        }
    }

    const [inProgress, setInProgress] = useState(false);
    const [inReview, setInReview] = useState(false);
    const [submitted, setSubmitted] = useState(false);
    const [pendAttestation, setPendAttestation] = useState(false);
    const [pendingStatus, setPendingStatus] = useState(false);
    const [submitApplicationPopUp, setSubmitApplicationPopUp] = useState(false);
    const [inprogressACN, setInprogressACN] = useState('');
    const [isAgentSubmitted, setIsAgentSubmitted] = useState(false);
    const [deleteApplicationError, setDeleteApplicationError] = useState({
        hasError: false,
        errorStatus: '',
        message: ''
    });

    useEffect(() => {
        setStatus();
    }, [])

    useEffect(() => {
        setInprogressACN(props.cardData.acn);
    }, [inProgress])

    useEffect(() => {
        if (props.continueDelete) {
            deleteApplication();

        }
    }, [props.continueDelete])

    useEffect(() => {
        if (props.cardData.isAgentSubmitted === SET_APPLICATION_CONSTANTS.YES && !IN_PROGRESS_APPLICATION_STATUSES.includes(props.cardData.applicationStatus)) {
            setIsAgentSubmitted(true);
        } else {
            setIsAgentSubmitted(false);
        }
    }, [inReview])

    useEffect(() => {
        if (props.cardData.isAgentSubmitted === SET_APPLICATION_CONSTANTS.YES && !IN_PROGRESS_APPLICATION_STATUSES.includes(props.cardData.applicationStatus)) {
            setIsAgentSubmitted(true);
        } else {
            setIsAgentSubmitted(false);
        }
    }, [submitted])



    function setStatus() {
        if (IN_PROGRESS_APPLICATION_STATUSES.includes(props.cardData.applicationStatus)) {
            setInProgress(true);
            if (props.cardData.applicationStatus === APPLICATION_STATUSES.PENDATTESTATION) {
                setPendAttestation(true);
            } else if (props.cardData.applicationStatus === APPLICATION_STATUSES.PENDING) {
                setPendingStatus(true);
            }
        } else if (props.cardData.applicationStatus === APPLICATION_STATUSES.PENDINGVALIDATION) {
            setInReview(true);
        } else {
            setSubmitted(true);
        }
    }

    const handleVoiceVaultLetter = () => {
        const payload: getApplicationPayload = getApplicationDashboardCardPayload(props.cardData.acn, DELETE_PAYLOAD_INPUT.PARTNETID);
        generateVoiceVaultLetter(payload).then((response) => {
            const data: voiceVaultLetterResponse = response.data;
            if (response.status === HTTP_STATUS_CODES.SUCCESS && data.status === "SUCCESS") {
                const htmlContent = data.response.voiceVaultLetter;
                const blob = new Blob([htmlContent], { type: 'text/html' });
                const url = URL.createObjectURL(blob);
                window.open(url, '_blank');

            } else {
                setDeleteApplicationError({
                    hasError: true,
                    errorStatus: data.status,
                    message: response.statusText
                });
            }
        }).catch((error) => {
            console.error('Error fetching voice vault letter:', error);
        })
    }


    const fetchPDF = async () => {
        try {

            const response = await getPDF({
                acn: inprogressACN,
                partnerId: SET_APPLICATION_CONSTANTS.OLS,

            });

            const PDFData: getPDFResponse = response.data;
            const linkSource = `data:application/pdf;base64,${PDFData.response.pdfString}`;

            // Create a new window and open the PDF in it
            const pdfWindow = window.open("");
            if (pdfWindow) {
                pdfWindow.document.write(
                    "<iframe width='100%' height='100%' src='" + linkSource + "'></iframe>"
                );
            }


        } catch (error) {
            console.error('Error fetching pdf data:', error);
        }
    };
    const deleteApplication = () => {
        const payload: deleteApplicationPayload = deleteInProgressDashboardCardPayload(inprogressACN, DELETE_PAYLOAD_INPUT.CANCELREASON, DELETE_PAYLOAD_INPUT.PARTNETID, props.cardData.accessControlList[0].user.userId);
        cancelInProgressApplication(payload).then((response) => {
            const data: deleteApplicationResponse = response.data;

            if (response.status === HTTP_STATUS_CODES.SUCCESS && data.status === "SUCCESS") {
                props.setDeletedInProgress(true);
            } else {
                setDeleteApplicationError({
                    hasError: true,
                    errorStatus: data.status,
                    message: response.statusText
                });
            }

        }).catch((error: AxiosError<RegisterShopperErrorResponse>) => {
            console.warn('Register Shopper Error response :', error);
            if (isAxiosError(error)) {
                const errorObj = error.response?.data?.error;

                setDeleteApplicationError({
                    hasError: true,
                    errorStatus: errorObj?.errorKey ? errorObj?.errorKey : '',
                    message: 'Profile Information Save Failed'
                });
            } else {
                setDeleteApplicationError({
                    hasError: true,
                    errorStatus: HTTP_STATUS_CODES.SERVER_ERROR.toString(),
                    message: 'Sorry, Something went wrong!'
                });
            }

        })

    }

    const handleDeleteApplication = () => {
        props.setDeletePopUp(true);

        if (props.continueDelete) {
            deleteApplication();

        }
    }

    const handleSubmitApplication = () => {
        setSubmitApplicationPopUp(true);
    }


    const handleRemoveAgentSubmit = () => {
        getDashbaordApplication();
    }

    const getDashbaordApplication = () => {
        setContentLoaded(false);
        const payload: getApplicationPayload = getApplicationDashboardCardPayload(inprogressACN, DELETE_PAYLOAD_INPUT.PARTNETID);
        getDashboardApplications(payload).then((response) => {
            const data: getApplicationResponse = response.data;
            data.response.application.agent.action = SET_APPLICATION_CONSTANTS.DELETE;
            data.response.application.action = SET_APPLICATION_CONSTANTS.UPDATE;
            data.response.application.isAgentAssisted = SET_APPLICATION_CONSTANTS.NO;

            if (response.status === HTTP_STATUS_CODES.SUCCESS && data.status === "SUCCESS") {
                const setApplicationPayload: SetApplicationPayload = setApplicationDashboardCardPayload(SET_APPLICATION_CONSTANTS.OLS, props.cardData.accessControlList[0].user.userId, AGENT_ROLES.CONSUMER, data);
                setApplicationApi(setApplicationPayload).then((response) => {
                    if (response?.data?.status === SET_APPLICATION_CONSTANTS.SUCCESS) {
                        const data = response.data;
                        const submitAppPayload: submitApplicationPayload = submitApplicationDashboardCardPayload(SET_APPLICATION_CONSTANTS.OLS, props.cardData.accessControlList[0].user.userId, inprogressACN);
                        submitApplication(submitAppPayload).then((response) => {
                            const data = response.data;
                            if (response.status === HTTP_STATUS_CODES.SUCCESS) {
                                props.setLoadGetApp(true);
                                props.setLoading(true);
                                setContentLoaded(true);
                            }
                        }).catch((error) => { console.error(error); setContentLoaded(true); });
                    }
                }).catch((error) => { console.error(error); setContentLoaded(true); });
            } else {
                setContentLoaded(true);
                setDeleteApplicationError({
                    hasError: true,
                    errorStatus: data.status,
                    message: response.statusText
                });
            }
        })
    }

    const handleContinueApplication = async () => {
        const encryptedACNPayload: EncryptacnPayload = getEncryptacnPayload(inprogressACN)
        const encryptedacnResponse = await getEncryptAcn(encryptedACNPayload);
        if (props.cardData.applicationType === CONTINUE_APPLICATION.OFFEXCHANGE) {
            window.open(
                `${isWellpoint ? WELLPOINT_OFF_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? ''] : OFF_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? '']}/sales/eox/secure/apply?acn=${encryptedacnResponse?.data?.encryptedACN}&source=${agentSSOInfo.isAtk ? 'acaRefreshPTB' : 'acaRefresh'}`,
                '_self'
            );
        } else if (props.cardData.applicationType === CONTINUE_APPLICATION.ONEXCHANGE && props.cardData.state === CONTINUE_APPLICATION.KY) {
            window.open(`${KY_EXCHANGE_BASE_URLS[REACT_APP_ENV ?? '']}/individuals/secure/api/ede/mvc/redirectToKyNect?acn=${encryptedacnResponse?.data?.encryptedACN}`, '_self');

        }

    }

    return (!contentLoaded ? <FullPageLoader /> :
        <div>
            <div className={'progress-layout'}>
                <div className={'fwc-col-12'}>
                    <div className={'fwc-card-generic'}>
                        <div className={'fwc-row j-between fwc-col-12 progress-app-dashboard-status'}>
                            <div className={'fwc-flex'}>
                                {pageContent?.ACN}: <span className={'bold left-xxs'}>{props.cardData.acn}</span>
                            </div>
                            <div className={'fwc-flex'}>
                                {pageContent?.COVERAGE_START_DATE}: <span className={'bold left-xxs'}>{dayjs(props.cardData.reqEffDate).format(DATE_FORMATS.MMDDYYYY)}</span>
                            </div>
                            <div className={'fwc-flex'}>
                                {pageContent?.STATUS}:
                                {inProgress && <div className={'fwc-badge notice left-xxs'}>{props.cardData.dashBoardStatus}</div>}
                                {submitted && <div className={'fwc-badge submitted left-xxs'}>{props.cardData.dashBoardStatus}</div>}
                                {inReview && <div className={'fwc-badge review left-xxs'}>{props.cardData.dashBoardStatus}</div>}
                                <div className={'left-xxs'}>
                                    {Object.keys(APPLICATION_STATUSES).includes(props.cardData.applicationStatus) && <Tooltip icon message={getMessage(props.cardData.applicationStatus)}></Tooltip>}
                                </div>
                            </div>

                        </div>
                        <div className={'card-padding'}>
                            <div className={'fwc-row j-between fwc-row-bm'}>

                                <div className={'fwc-col-6'}>
                                    <div>{pageContent?.PLANS}({props.cardData?.plans.length})</div>
                                    <div className={'card-data'}>
                                        {props.cardData.plans?.map((eachPlan) => <p key={eachPlan.planName + '-key'}>{eachPlan.planName}</p>)}
                                    </div>
                                </div>
                                <div className={'fwc-col-3'}>
                                    <div>{pageContent?.APPLICANT}</div>
                                    <div className={'card-data'}>
                                        <span className="sae-icon sae-icon-contacts right-xxs"></span>
                                        {props.cardData.firstName} {props.cardData.mi} {props.cardData.lastName}
                                    </div>
                                </div>
                                <div className={'fwc-col-3'}>
                                    <div> {pageContent?.PREMIUM}</div>
                                    <div className={'card-data'}>
                                        ${props.cardData.totalPremiumAmt?.toFixed(2)}
                                    </div>
                                </div>
                            </div>
                            <hr className={'fwc-col-12'} />
                            <div className={'fwc-row a-center'}>
                                <div className={'created-date'}>{pageContent?.CREATED_DATE} {dayjs(props.cardData.createdTS).format(DATE_FORMATS.MMDDYYYY)}</div>
                                <div className={'fwc-flex flex-align-right'}>
                                    {!pendingStatus && <div className={'card-actions'}>
                                        <a id={'print-save' + props.index} href={'javascript:void(0)'} onClick={(e) => fetchPDF()}>
                                            <span className={'fwc-icon fwc-icon-pdf'}></span>
                                            {pageContent?.PRINT}
                                        </a>
                                    </div>}
                                    {inProgress && !pendAttestation && !pendingStatus && <div className={'card-actions left-xs'}>
                                        <a id={'delete-' + props.index} href={'javascript:void(0)'} onClick={(e) => handleDeleteApplication()}>
                                            <span className={'fwc-icon fwc-icon-trash'}></span>
                                            {pageContent?.DELETE}
                                        </a>
                                    </div>}
                                    {pendingStatus && <div className={'card-actions'}>
                                        <a id={'submit-application' + props.index} href={'javascript:void(0)'} onClick={(e) => handleSubmitApplication()}>
                                            {pageContent?.SUBMIT_APPLICATION}
                                        </a>
                                    </div>}

                                    {isAgentSubmitted && <div className={'card-actions'}>
                                        <a id={'voice-vault-letter' + props.index} href={'javascript:void(0)'} onClick={(e) => handleVoiceVaultLetter()}>
                                            <span className={'fwc-icon fwc-icon-pdf'}></span>
                                            {pageContent?.VOICE_VAULT_LETTER}
                                        </a>
                                    </div>}
                                </div>
                            </div>
                        </div>

                        {props.cardData.applicationStatus === APPLICATION_STATUSES.INPROGRESS && props.cardData.state === STATE_CODES.NY && <div className={'motif-card-content'} dangerouslySetInnerHTML={{ __html: pageContent?.IN_PROGRESS_NY_PAPER_APP_CONTENT }} />}
                    </div>
                    <div className={'fwc-row'}>
                        {inProgress && !pendAttestation && !pendingStatus && <button id={'continue-button' + props.index} className={'fwc-btn fwc-btn-primary flex-align-right top-sm btm-md'} onClick={() => handleContinueApplication()}>{pageContent?.CONTINUE_BUTTON}</button>}
                    </div>
                </div>
            </div>
            <div>
                <SubmitApplicationPopup showPopup={submitApplicationPopUp} popupClose={() => setSubmitApplicationPopUp(false)} cancelStartOver={handleDeleteApplication} removeAgentSubmit={handleRemoveAgentSubmit} />
            </div>
        </div>
    )
}

export default DashboardCard;
