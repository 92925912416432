import { Modal, Paragraph, Popover } from '@sydney-broker-ui/ios';
import dayjs from 'dayjs';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { useLocation, useNavigate } from "react-router-dom";
import { HEALTHSHERPA_BASE_URLS, HEALTHSHERPA_WELLPOINT_BASE_URLS } from '../../../api/api-name-list';
import { getCMSStaticContent, getCMSdynamicContent } from '../../../api/cms/cmsUtil';
import { PLAN_MARKET_TYPES } from '../../../components/common/compare-tray/constant';
import EmailPrint from '../../../components/common/email-print/EmailPrint';
import FullPageLoader from '../../../components/common/full-page-loader/FullPageLoader';
import { SaveDemographicsAndQuoteInfo } from '../../../components/common/public-nav-bar/SaveAndResumeLater.utils';
import { default as TwoFAContent } from '../../../pages/public/two-fa-modal/twoFAModal.json';
import { CATASTROPHIC_PLAN, LANGUAGE_CODES, MEDICAL_PLAN_TYPES, ME_REDIRECTION_URL, NON_FFM_STATES, PAGE_BLOCKS, PAGE_IDS, PLAN_SUMMARY_STR, PLAN_TYPES, PLAN_TYPES_NAMES, PROPOSAL_REDIRECTION_MARKET_PLACE_STATES, SCREEN_NAMES, SET_APPLICATION_CONSTANTS, STATE_CODES, USER_ROLES, VA_REDIRECTION_URL, WELLPOINT_MD_STATE } from '../../../shared/globalConstants';
import { getFormattedCurrency, handleSetApplicationSuccessRedirection } from "../../../shared/utils/globalUtils";
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import DentalPlanDetails from '../dental-plan-details/DentalPlanDetails';
import LoginModal from '../login/login-modal/LoginModal';
import MedicalPlanDetails from '../medical-plan-details/MedicalPlanDetails';
import { getSubsidyAmount } from "../medical-plans/medicalPlansUtils";
import TwoFAModalBody from '../two-fa-modal/TwoFAModalBody';
import { TWO_FA_USE_CASES } from '../two-fa-modal/constants';
import VisionPlanDetails from '../vision-plan-details/VisionPlanDetails';
import { DENTAL, MEDICAL, VISION } from './PlanSelectionConstants';
import { default as Content } from './PlanSelectionSummary.json';
import './PlanSelectionSummary.scss';
import { getApplicableMarketType, getEncryptacnPayload, getSelectedPlanId, getSetApplicationData, getUpdateQuoteStatusPayload } from './planSelectionSummaryUtils';
import PlanSummaryCard from './planSummaryCard/PlanSummaryCard';
import { getEncryptAcn, setApplicationApi, updateQuoteStatus } from './planSummaryServices';
const { REACT_APP_ENV } = process.env;

const PlanSelectionSummary: React.FC = () => {
  const [anchor, setAnchor] = useState(null);
  const [showDeleteConfirmPopup, setShowDeleteConfirmPopup] = useState<boolean>(false);
  const [emailContracts, setEmailContracts] = useState<Contracts>([]);
  const [deletePlanType, setDeletePlanType] = useState<string>('');
  const [applyModalTitle, setApplyModalTitle] = useState<string>('');
  const [applyNowLabel, setApplyNowLabel] = useState<string>('');
  const [open, setOpen] = useState<boolean>(false);
  const [placement, setPlacement] = React.useState();
  const [showApplyModal, setShowApplyModal] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showTwoFAModal, setShowTwoFAModal] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [selectedPlanMarketType, setSelectedPlanMarketType] = useState(PLAN_MARKET_TYPES.OFF_MARKET);
  const [loader, setLoader] = useState(false);
  let { coverageType, isPlanSummaryEditable, planTypes, selectedPlan, selectedDentalPlan, selectedVisionPlan, deepLinkInfo, updateSelectedPlan, updateSelectedDentalPlan, updateSelectedVisionPlan, subsidyresponse, state, memberInfo, applicantFormDetails, brand, agentInfo, zipcode, updateIsApplyNowClicked, isApplyNowClicked, previousStep, memberPlanTypes, updateIsPlanSummaryEditable, zipCodeRes, isSaveQuoteApiCalled, year, coverageRule } = useGlobalStore((store) => store);
  const { loggedIn, isWellpoint, role } = useUserStore((state) => state);
  const importantNotice = useRef<HTMLDivElement>(null);
  const estimatedTaxCredit = getSubsidyAmount(subsidyresponse) ?? '0';
  const [pageContent, setPageContent] = useState<any>(null);
  const [pageContent2FA, setPageContent2FA] = useState<any>(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [planSummaryHeaderContent, setPlanSummaryHeaderContent] = useState<string>('');
  const [planForDetails, setPlanForDetails] = useState<Contract>({} as Contract);
  const divRef = useRef(null);
  const location = useLocation()

  const getContent = useCallback(async (): Promise<boolean> => {
    let content: any;
    let content2: any;
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.PLAN_SELECTION_SUMMERY);
      content = response.data.data.iospagecontentList.items[0].pageContent?.PlanSelectionSummary;
      const response2 = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.TWO_FA);
      content2 = response2.data.data.iospagecontentList.items[0].pageContent?.TWO_FA.HEADING;
    } catch (error) {
      content = Content.data.iospagecontentList.items[0].pageContent?.PlanSelectionSummary;
      content2 = TwoFAContent.data.iospagecontentList.items[0].pageContent.TWO_FA.HEADING;
      return false;
    } finally {
      setPageContent(content);
      setPageContent2FA(content2);
      setContentLoaded(true);

    }
    return true;
  }, [zipCodeRes.stateCode]);

  useEffect(() => {
    if (pageContent) {
      const applicableMarketType = getApplicableMarketType(selectedPlan.plan, selectedDentalPlan.plan, selectedVisionPlan.plan)

      setSelectedPlanMarketType(applicableMarketType);
      selectApplyNowLabel(applicableMarketType);
    }
  }, [pageContent])

  const initiate = async () => {
    await getContent();
  };

  useEffect(() => {
    initiate();


    return () => {
      // cleanup
      updateIsPlanSummaryEditable(true)
      setLoader(false);
    };
  }, [])

  useEffect(() => {
    if (loggedIn && isApplyNowClicked && isSaveQuoteApiCalled) {
      loginUser()
    }
  }, [isApplyNowClicked, loggedIn, isSaveQuoteApiCalled]);

  useEffect(() => {
    getdynamicContent();

  }, [])

  document.body.classList.remove("no-scroll");

  const getdynamicContent = async () => {
    let coverageSelected = findCoverageSelected();
    const isCOPlan = selectedPlan?.plan?.poPlan;
    let planType;
    if (isCOPlan) {
      planType = 'coloradoconnect';
    } else if (selectedPlan?.plan?.onExchange) {
      planType = 'onexchange'
    } else {
      planType = 'offexchange'
    }

    let queryParam = ';state=' + zipCodeRes.stateCode + ';brand=' + brand + ';role=' + role?.toLowerCase() + ';coveragetype=' + coverageSelected + ';plantype=' + planType + ';year=' + year;
    await getCMSdynamicContent(PAGE_IDS.PLAN_SELECTION_SUMMARY, PAGE_BLOCKS.COVERAGE_TYPE, queryParam)
      .then((response) => {
        setPlanSummaryHeaderContent(response?.data.data?.iosdynamiccontentList?.items[0]?.textsummary);
      })
      .catch((error) => {
        setPlanSummaryHeaderContent('');
      });
  };

  // hide apply now
  const handleApplyNow = () => {
    try {
      if (zipCodeRes?.currentDateTimeInZone) {
        const today = dayjs(zipCodeRes.currentDateTimeInZone).toISOString();
        let selectedCoveragePeriod: boolean = true;
        coverageRule.find((period) => {
          const startDate = dayjs(period.startDate).toISOString();
          const endDate = dayjs(period.endDate).toISOString();
          if (today >= startDate && today <= endDate && period.planYear === year && (period.planType === 'ALL' || (period.planType.indexOf(coverageType) != -1)) && (period.role === 'ALL' || (period.role.indexOf(role) != -1)) && (period.state === 'ALL' || (period.state.indexOf(state) != -1))) {
            selectedCoveragePeriod = false;
          }
        });

        return selectedCoveragePeriod;
      } else {
        return false;
      }
    } catch (error) {
      console.error('PlanSelectionSummary.tsx handleApplyNow error :', error)
      return false;
    }
  };

  const findCoverageSelected = (): String => {
    let coverageSelected = '';
    switch (coverageType) {
      case PLAN_TYPES.MDV:
        coverageSelected = PLAN_TYPES_NAMES.MEDICAL.toLowerCase();
        break;
      case PLAN_TYPES.MEDICAL:
        coverageSelected = PLAN_TYPES_NAMES.MEDICAL.toLowerCase();
        break;
      case PLAN_TYPES.DENTAL:
        coverageSelected = PLAN_TYPES_NAMES.DENTAL.toLowerCase();
        break;
      case PLAN_TYPES.VISION:
        coverageSelected = PLAN_TYPES_NAMES.VISION.toLowerCase();
        break;
      default:
        coverageSelected = PLAN_TYPES_NAMES.MEDICAL.toLowerCase();
        break;
    }
    return coverageSelected;
  }

  const getSetApplication = async (isOffExchange: boolean) => {
    setLoader(true);
    let payload = getSetApplicationData(!isOffExchange);
    updateIsApplyNowClicked(false);
    try {
      let response = await setApplicationApi(payload);
      if (response?.data?.status === SET_APPLICATION_CONSTANTS.SUCCESS) {

        const updateQuoteStatusPayload = getUpdateQuoteStatusPayload(response?.data?.response?.acn, useUserStore.getState().quotes?.[0]?.quoteId)
        await updateQuoteStatus(updateQuoteStatusPayload);

        const encryptedACNPayload: EncryptacnPayload = getEncryptacnPayload(response?.data?.response?.acn)
        const encryptedacnResponse = await getEncryptAcn(encryptedACNPayload);
        // setLoader(false);
        handleSetApplicationSuccessRedirection(isOffExchange, encryptedacnResponse?.data?.encryptedACN, REACT_APP_ENV, isWellpoint)
      } else {
        setLoader(false);
      }
    } catch (error) {
      setLoader(false);
      console.error(error)
    }
  }

  const redirectToHealthSherpa = () => {
    if (agentInfo && agentInfo?.npn) {
      window.open(`${isWellpoint ? HEALTHSHERPA_WELLPOINT_BASE_URLS[REACT_APP_ENV ?? ''] : HEALTHSHERPA_BASE_URLS[REACT_APP_ENV ?? '']}/apply/?_carrier_id=${isWellpoint ? "wellpoint" : "anthem"}&agent[npn]=${agentInfo?.npn}${getSelectedPlanId()}&state=${state}&zip_code=${zipcode}`, '_self')
    } else {
      window.open(`${isWellpoint ? HEALTHSHERPA_WELLPOINT_BASE_URLS[REACT_APP_ENV ?? ''] : HEALTHSHERPA_BASE_URLS[REACT_APP_ENV ?? '']}/apply/?_agent_id=${isWellpoint ? "wellpoint" : "anthem"}${getSelectedPlanId()}${deepLinkInfo.refSource && "&utm_campaign=" + deepLinkInfo.refID + "&call=" + deepLinkInfo.call.replace(/-/g, '')}&state=${state}&zip_code=${zipcode}`, '_self')
    }
  }

  useEffect(() => {
    let contractArr: Contracts = []
    if (selectedPlan.plan && Object.keys(selectedPlan?.plan).length) {
      contractArr.push(selectedPlan.plan)
    }
    if (selectedDentalPlan.plan && Object.keys(selectedDentalPlan?.plan).length) {
      contractArr.push(selectedDentalPlan.plan)
    }
    if (selectedVisionPlan.plan && Object.keys(selectedVisionPlan?.plan).length) {
      contractArr.push(selectedVisionPlan.plan)
    }
    setEmailContracts(contractArr);
  }, [selectedDentalPlan, selectedPlan, selectedVisionPlan])

  const isCatastriphicPlanSelected = () => {
    return selectedPlan?.plan?.tiersType === CATASTROPHIC_PLAN;
  }

  const isDentalOnlyAddedForOnExchange = () => {
    return ((!selectedPlan?.plan && selectedDentalPlan?.plan) || (NON_FFM_STATES.includes(state)))
  }

  const selectApplyNowLabel = (applicableMarketType: string) => {
    switch (applicableMarketType) {
      case PLAN_MARKET_TYPES.COLARADO_CONNECT:
        setApplyNowLabel(pageContent.costListing.APPLY_ON_COLORADO);
        break;
      case PLAN_MARKET_TYPES.MARKET_PLACE:
        if (isWellpoint) {
          setApplyNowLabel(state === WELLPOINT_MD_STATE ? pageContent.costListing.APPLY_MARKET_PLACE : pageContent.costListing.APPLY_NOW_BUTTON)
        } else if (isCatastriphicPlanSelected()) {
          setApplyNowLabel(NON_FFM_STATES.includes(state) ? pageContent.costListing.APPLY_MARKET_PLACE : pageContent.costListing.APPLY_NOW_BUTTON)
        } else {
          setApplyNowLabel(isDentalOnlyAddedForOnExchange() ? pageContent.costListing.APPLY_MARKET_PLACE : pageContent.costListing.APPLY_NOW_BUTTON)
        }
        break;
      default:
        setApplyNowLabel(pageContent.costListing.APPLY_NOW_BUTTON)
        break;
    }
  }

  const handleModalClose = (e) => {
    setShowLoginModal(false);
  };

  const handleTwoFAModalClose = () => {
    setShowTwoFAModal(false);
  };

  const deleteSelectedPlan = (planType) => {
    setDeletePlanType(planType);
    setShowDeleteConfirmPopup(true);
  };

  const handleContinueDeleteConfirmPopup = async () => {
    switch (deletePlanType) {
      case MEDICAL:
        const updatedSelectedPlan: SelectedPlan = {
          plan: null,
          applicants: selectedPlan.applicants,
          matchedProviders: [],
          matchedMedications: []
        };
        updateSelectedPlan(updatedSelectedPlan);
        break;
      case DENTAL:
        const updateDentalSelectedPlan: SelectedPlan = {
          plan: null,
          applicants: selectedDentalPlan.applicants,
          matchedProviders: []
        };
        updateSelectedDentalPlan(updateDentalSelectedPlan);
        break;
      case VISION:
        const updateVisionSelectedPlan: SelectedPlan = {
          plan: null,
          applicants: selectedVisionPlan.applicants,
          matchedProviders: []
        };
        updateSelectedVisionPlan(updateVisionSelectedPlan);
        break;
      default:
        break;
    }
    setDeletePlanType('');
    setShowDeleteConfirmPopup(false);
    if (loggedIn) {
      setLoader(true)
      try {
        await SaveDemographicsAndQuoteInfo({ globalStore: useGlobalStore.getState(), userStore: useUserStore.getState(), location: location.pathname });
      } catch (error) {
        setLoader(false)
        console.error('PlanSelectionSummary.tsx handleContinueDeleteConfirmPopup error :', error)
      }
      setLoader(false)
    }

  }

  const handleDeleteConfirmPopup = () => {
    setDeletePlanType('');
    setShowDeleteConfirmPopup(false);
  }


  const togglePopover = () => {
    setOpen((old) => !old);
  };

  const handleClick = (newPlacement) => (event) => {
    setAnchor(event.currentTarget);
    setOpen((prev) => placement !== newPlacement || !prev);
    setPlacement(newPlacement);
  };

  /**
 * Handles redirection based on the current state code during proposal flow.
 * 
 * This function checks the current state code and redirects the user to a specific URL.
 * If the state code is 'ME', the user is redirected to the Maine redirection URL.
 * If the state code is 'VA', the user is redirected to the Virginia redirection URL.
 * If the state code is neither 'ME' nor 'VA', no action is taken.
 */
  const handleProposalRedirection = () => {
    switch (state) {
      case STATE_CODES.ME:
        window.open(`${ME_REDIRECTION_URL}`, '_self');
        break;
      case STATE_CODES.VA:
        window.open(VA_REDIRECTION_URL, '_self');
        break;
      default:
        break
    }
  }

  const loginUser = () => {
    switch (selectedPlanMarketType) {
      case PLAN_MARKET_TYPES.MARKET_PLACE:
        if (!isPlanSummaryEditable && PROPOSAL_REDIRECTION_MARKET_PLACE_STATES.includes(state)) {
          handleProposalRedirection()
        } else if (isDentalOnlyAddedForOnExchange() || (isWellpoint && state === WELLPOINT_MD_STATE) || (isCatastriphicPlanSelected())) {
          setApplyModalTitle(pageContent.MARKET_PLACE_MODAL_TITLE)
          setShowApplyModal(true);
        } else if (state === SET_APPLICATION_CONSTANTS.KY) {
          getSetApplication(false);
        } else {
          redirectToHealthSherpa();
        }
        break;
      case PLAN_MARKET_TYPES.OFF_MARKET:
        getSetApplication(true);
        break;
      case PLAN_MARKET_TYPES.COLARADO_CONNECT:
        setApplyModalTitle(pageContent.COLORADO_MODAL_TITLE)
        setShowApplyModal(true);
        break;
    }
  }

  const unnonymousUser = () => {
    switch (selectedPlanMarketType) {
      case PLAN_MARKET_TYPES.MARKET_PLACE:
        if (isDentalOnlyAddedForOnExchange() || (isWellpoint && state === WELLPOINT_MD_STATE) || (isCatastriphicPlanSelected())) {
          setApplyModalTitle(pageContent.MARKET_PLACE_MODAL_TITLE)
          setShowApplyModal(true);
        } else if (state === SET_APPLICATION_CONSTANTS.KY) {
          setShowLoginModal(true);
        } else {
          redirectToHealthSherpa();
        }
        break;
      case PLAN_MARKET_TYPES.OFF_MARKET:
        setShowLoginModal(true);
        break;
      case PLAN_MARKET_TYPES.COLARADO_CONNECT:
        setApplyModalTitle(pageContent.COLORADO_MODAL_TITLE)
        setShowApplyModal(true);
        break;
    }
  }

  const applyNow = () => {
    setShowLoginModal(false);
    setShowApplyModal(false);
    if (!loggedIn) {
      updateIsApplyNowClicked(true);
    }
    MEDICAL_PLAN_TYPES.includes(coverageType) ? openModalOnTab() : openModalOnTab();
  }

  const openModalOnTab = () => {
    if (loggedIn) {
      loginUser()
    } else {
      unnonymousUser();
    }
  }

  const closeApplyNowModal = () => {
    document.body.classList.remove("no-scroll");
    setShowApplyModal(false);
  }
  const navigate = useNavigate();
  const getTotalPremium = () => {
    let totalPremium: number = 0;
    if (selectedPlan?.plan) {
      totalPremium += selectedPlan?.plan?.rateData?.rate?.totSubsidizedPrem ?? 0;
    }
    if (selectedDentalPlan?.plan) {
      totalPremium += selectedDentalPlan?.plan?.rateData?.rate?.totalPremium ?? 0;
    }
    if (selectedVisionPlan?.plan) {
      totalPremium += selectedVisionPlan?.plan?.rateData?.rate?.totalPremium ?? 0;
    }
    return getFormattedCurrency(totalPremium);
  }
  return (!contentLoaded ? <FullPageLoader /> :
    <div className="plan-selection-summary" ref={divRef}>
      <div className={'fwc-row'}>
        <a className='global-back-link' id={'vision-back'} href={'javascript:void(0)'} onClick={() => navigate(previousStep)}>
          Back
        </a>
      </div>
      {loader && <FullPageLoader />}
      <div className="fwc-row">
        <div className="fwc-col-12">

          <div>
            <h2 className={'top-sm'} id="plan-selection-summary-heading">{pageContent.labels.PAGE_TITLE}</h2>
          </div>
          {isPlanSummaryEditable && <div className="content">
            <p>
              See{' '}
              <a data-analytics="importantNoticePlanSummaryIos" id="important-notice-link" href={'javascript:void(0)'} onClick={() => {
                importantNotice.current && importantNotice.current.scrollIntoView({ behavior: 'smooth' })
              }}>
                {pageContent.pageFooter.IMPORTANT_TEXT}
              </a>{' '}
              {pageContent.pageHeader.NOTICE_TEXT}
            </p>
            {planSummaryHeaderContent &&
              <div dangerouslySetInnerHTML={{ __html: planSummaryHeaderContent }}></div>
            }
          </div>}
          {isPlanSummaryEditable && <div className="action-icons-container">
            <div>
              <EmailPrint type={PLAN_SUMMARY_STR} emailPrintContracts={emailContracts} />
            </div>
          </div>}

          <div>
            {planTypes.indexOf(PLAN_TYPES_NAMES.MEDICAL) !== -1 && role === USER_ROLES.SHOPPER && (
              <PlanSummaryCard
                editable={isPlanSummaryEditable}
                selectedSummaryPlan={selectedPlan.plan}
                applicants={selectedPlan.applicants}
                isPrimary={coverageType === 'MDV' || coverageType === 'M'}
                planType={'Medical'}
                planTypeIcon={'sae-icon-heart-health'}
                deleteSelectedPlan={deleteSelectedPlan}
                selectedPlanMarketType={selectedPlanMarketType}
                labels={pageContent}
                onPlanNameClick={(plan: Contract) => { setPlanForDetails(plan) }}
              />
            )}
            {(role === USER_ROLES.MEMBER && memberPlanTypes.includes(PLAN_TYPES_NAMES.MEDICAL)) && (
              <PlanSummaryCard
                editable={isPlanSummaryEditable}
                selectedSummaryPlan={selectedPlan.plan}
                applicants={selectedPlan.applicants}
                isPrimary={coverageType === 'M'}
                planType={'Medical'}
                planTypeIcon={'sae-icon-heart-health'}
                deleteSelectedPlan={deleteSelectedPlan}
                selectedPlanMarketType={selectedPlanMarketType}
                labels={pageContent}
                onPlanNameClick={(plan: Contract) => { setPlanForDetails(plan) }}
              />
            )}
            {planTypes.indexOf(PLAN_TYPES_NAMES.DENTAL) !== -1 && !isWellpoint && role === USER_ROLES.SHOPPER && (
              <PlanSummaryCard
                editable={isPlanSummaryEditable}
                selectedSummaryPlan={selectedDentalPlan.plan}
                applicants={selectedDentalPlan.applicants}
                isPrimary={coverageType === 'D'}
                planType={'Dental'}
                planTypeIcon={'sae-icon-tooth'}
                deleteSelectedPlan={deleteSelectedPlan}
                labels={pageContent}
                onPlanNameClick={(plan: Contract) => { setPlanForDetails(plan) }}
              />
            )}
            {(role === USER_ROLES.MEMBER && !isWellpoint &&
              (memberPlanTypes.includes(PLAN_TYPES_NAMES.DENTAL) || memberPlanTypes.includes(PLAN_TYPES_NAMES.MEDICAL) ||
                (memberPlanTypes.length === 1 && memberPlanTypes.includes(PLAN_TYPES_NAMES.VISION)
                ))) && (
                <PlanSummaryCard
                  editable={isPlanSummaryEditable}
                  selectedSummaryPlan={selectedDentalPlan.plan}
                  applicants={selectedDentalPlan.applicants}
                  isPrimary={coverageType === 'D'}
                  planType={'Dental'}
                  planTypeIcon={'sae-icon-tooth'}
                  deleteSelectedPlan={deleteSelectedPlan}
                  labels={pageContent}
                  onPlanNameClick={(plan: Contract) => { setPlanForDetails(plan) }}
                />
              )}
            {planTypes.indexOf(PLAN_TYPES_NAMES.DENTAL) !== -1 && !isWellpoint && role === USER_ROLES.SHOPPER && (
              <PlanSummaryCard
                editable={isPlanSummaryEditable}
                selectedSummaryPlan={selectedVisionPlan.plan}
                applicants={selectedVisionPlan.applicants}
                isPrimary={coverageType === 'V'}
                planType={'Vision'}
                planTypeIcon={'sae-icon-eye'}
                deleteSelectedPlan={deleteSelectedPlan}
                labels={pageContent}
                onPlanNameClick={(plan: Contract) => { setPlanForDetails(plan) }}
              />
            )}
            {role === USER_ROLES.MEMBER && !isWellpoint && (
              <PlanSummaryCard
                editable={isPlanSummaryEditable}
                selectedSummaryPlan={selectedVisionPlan.plan}
                applicants={selectedVisionPlan.applicants}
                isPrimary={coverageType === 'V'}
                planType={'Vision'}
                planTypeIcon={'sae-icon-eye'}
                deleteSelectedPlan={deleteSelectedPlan}
                labels={pageContent}
                onPlanNameClick={(plan: Contract) => { setPlanForDetails(plan) }}
              />
            )}
          </div>
          <div className={'fwc-row j-end'}>
            {selectedPlan.plan !== null && <p>
              <span className="cost-text">
                {isPlanSummaryEditable && <a data-analytics="editSavingsIconPlanSummaryIos" onClick={() => navigate('/individuals/shop/basics/estimate-cost-savings')} href={'javascript:void(0)'} className={'sae-icon sae-icon-edit'}></a>} Estimated Savings:
                <span className="cost bold"> {getFormattedCurrency(parseFloat(estimatedTaxCredit))} </span>a month
              </span>
            </p>}
          </div>
          <div className="total-pricing-container">
            <a data-analytics="calculatedLinkPlanSummaryIos" href={'javascript:void(0)'} className="calulated-tool-tip" onClick={handleClick('top-end')}>
              {pageContent.costListing.HOW_IS_CALCULATED_TEXT}
              {open && anchor && (
                <Popover className="css-1niekxx-cssPopover" placement={placement} anchorEl={anchor} onClickOutside={togglePopover}>
                  <Paragraph>
                    <div className="total-breakdown-container">
                      <div className="monthly-costbreakdown">
                        <h6>{pageContent.costListing.MONTHLY_BREAKDOWN_TITLE}</h6>
                      </div>
                      <div className="medical-costbreakdown">
                        <p>{pageContent.costListing.MEDICAL.MEDICAL_TITLE}</p>
                        <div className="costbreakdown-container">
                          <div>
                            <p>{pageContent.costListing.MEDICAL.TOTAL_PLAN_COST}</p>
                            <p>{pageContent.costListing.MEDICAL.SUBSIDY_APPLIED}</p>
                            <p>{pageContent.costListing.MEDICAL.MEDICAL_PAYMENT}</p>
                          </div>
                          <div>
                            <p>{selectedPlan?.plan ? getFormattedCurrency(selectedPlan?.plan?.rateData?.rate.totalPremium) : getFormattedCurrency(0)}</p>
                            <p className="cost-breakdown-line">{getFormattedCurrency(selectedPlan?.plan?.rateData?.rate?.subsidyAmtApplied)}</p>
                            <p>{selectedPlan?.plan ? getFormattedCurrency(selectedPlan?.plan?.rateData?.rate.totSubsidizedPrem) : getFormattedCurrency(0)}</p>
                          </div>
                        </div>
                      </div>
                      {!isWellpoint && <div className="dental-costbreakdown">
                        <p>{pageContent.costListing.DENTAL.DENTAL_TITLE}</p>
                        <div className="costbreakdown-container">
                          <div>
                            <p>{pageContent.costListing.DENTAL.TOTAL_PLAN_COST}</p>
                            <p>{pageContent.costListing.DENTAL.DENTAL_PAYMENT}</p>
                          </div>
                          <div>
                            <p className="cost-breakdown-line">{selectedDentalPlan?.plan?.rateData?.rate?.totSubsidizedPrem ? getFormattedCurrency(selectedDentalPlan.plan.rateData.rate.totSubsidizedPrem) : getFormattedCurrency(0)}</p>
                            <p>{selectedDentalPlan?.plan?.rateData?.rate?.totSubsidizedPrem ? getFormattedCurrency(selectedDentalPlan.plan.rateData.rate.totSubsidizedPrem) : getFormattedCurrency(0)}</p>
                          </div>
                        </div>
                      </div>}
                      {!isWellpoint && <div className="vision-costbreakdown">
                        <p>{pageContent.costListing.VISION.VISION_TITLE}</p>
                        <div className="costbreakdown-container">
                          <div>
                            <p>{pageContent.costListing.VISION.TOTAL_PLAN_COST}</p>
                            <p>{pageContent.costListing.VISION.VISION_PAYMENT}</p>
                          </div>
                          <div>
                            <p className="cost-breakdown-line">{selectedVisionPlan?.plan?.rateData?.rate?.totSubsidizedPrem ? getFormattedCurrency(selectedVisionPlan.plan.rateData.rate.totSubsidizedPrem) : getFormattedCurrency(0)}</p>
                            <p>{selectedVisionPlan?.plan?.rateData?.rate?.totSubsidizedPrem ? getFormattedCurrency(selectedVisionPlan.plan.rateData.rate.totSubsidizedPrem) : getFormattedCurrency(0)}</p>
                          </div>
                        </div>
                      </div>}
                      <div className="total-costbreakdown">
                        <div className="costbreakdown-container">
                          <div>
                            <p>{pageContent.costListing.TOTAL_MEMBER_PAYMENT}</p>
                          </div>
                          <div>
                            <p>{getTotalPremium()}</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </Paragraph>
                </Popover>
              )}
              <span className="cost-text">
                {pageContent.costListing.TOTAL_COST_TEXT}
                <span className="cost">{getTotalPremium()} </span>a month
              </span>
            </a>
          </div>
          <div className="apply-now-container" ref={importantNotice}>
            {handleApplyNow() ?
              <button data-analytics="applyNowBtnPlanSummaryIos" className={'fwc-btn fwc-btn-primary'} id="apply-now-btn" onClick={applyNow}>{applyNowLabel}</button>
              : <div><b>{pageContent.APPLY_NOW_DESCRIPTION}</b></div>}
          </div>
          <div className="footer-container">
            <p dangerouslySetInnerHTML={{ __html: pageContent.pageFooter.IMPORTANT_TEXT }}></p>
            <p dangerouslySetInnerHTML={{ __html: pageContent.pageFooter.IMPORTANT_CONTENT }}></p>
            <p dangerouslySetInnerHTML={{ __html: pageContent.pageFooter.ABOUT_COVERED }}></p>
            <p dangerouslySetInnerHTML={{ __html: pageContent.pageFooter.BENEFIT_INFORMATION }}></p>
          </div>
        </div>
      </div>
      <Modal open={showDeleteConfirmPopup} onClose={handleDeleteConfirmPopup} title={'Confirmation'}>
        <Modal.Body>
          <div className='confirm-delete-popup-body' id='confirm-delete-popup-body'>
            <p>{pageContent.confirmDeletePopup.DELETE_PLAN_TEXT}{' '}{deletePlanType.toLowerCase()} {pageContent.confirmDeletePopup.DELETE_PLAN_TEXT_2 + pageContent.confirmDeletePopup.DELETE_PLAN_TEXT_3}</p>
          </div>
        </Modal.Body>
        <Modal.Footer>
          {' '}
          <button
            data-analytics="cancelBtnDeletePlanModalIos"
            className="confirm-delete-buttons fwc-btn fwc-btn-primary"
            onClick={() => {
              setDeletePlanType('');
              setShowDeleteConfirmPopup(false);
            }}
          >
            {pageContent.confirmDeletePopup.CANCEL_BUTTON}
          </button>
          <button
            data-analytics="continueBtnDeletePlanModalIos"
            className="confirm-delete-buttons fwc-btn fwc-btn-primary"
            onClick={handleContinueDeleteConfirmPopup}
          >
            {pageContent.confirmDeletePopup.CONTINUE_BUTTON}
          </button>
        </Modal.Footer>
      </Modal>

      {showLoginModal && <Modal open={showLoginModal} onClose={handleModalClose} title={pageContent.OFF_MARKET_PLAN_TITLE}>
        <Modal.Body>
          <LoginModal
            showModal={showLoginModal}
            closeLoginModal={() => {
              setShowLoginModal(false);
            }}
            initiateTwoFa={() => {
              setShowLoginModal(false);
              setModalHeading(pageContent2FA);
              setShowTwoFAModal(true);
            }}
            loginDesc={pageContent.OFF_MARKETPLACE_PLAN_LOGIN_DESC}
            profileDesc={pageContent.OFF_MARKETPLACE_PLAN_PROFILE_DESC}
          />
        </Modal.Body>
      </Modal>}
      {showTwoFAModal && <Modal open={showTwoFAModal} onClose={handleTwoFAModalClose} title={modalHeading}>
        <Modal.Body>
          <TwoFAModalBody
            useCase={TWO_FA_USE_CASES.LOGIN}
            showModal={showTwoFAModal}
            showFooter={true}
            closeModal={handleTwoFAModalClose}
            hideModalHeading={() => setModalHeading('')}
            updateModalHeading={(heading) => setModalHeading(heading)}
          />
        </Modal.Body>
      </Modal>}
      {
        showApplyModal && <Modal
          onClose={closeApplyNowModal}
          title={applyModalTitle}
          open={showApplyModal}
        >
          <Modal.Body>
            <div className="apply-modal-padding">{pageContent.MODAL_DESC}</div>
          </Modal.Body>
          <Modal.Footer>
            <button id='modal-slide-select' className={'fwc-btn fwc-btn-primary'} onClick={closeApplyNowModal}>
              {pageContent.CLOSE}
            </button>
          </Modal.Footer>
        </Modal>
      }

      {planForDetails && Array.isArray(planForDetails.plan) && (planForDetails?.plan[0]?.planType === PLAN_TYPES_NAMES.MEDICAL) && (
        <MedicalPlanDetails
          isCurrentCoveragePlan={false}
          closeMedicalPlanDetails={() => setPlanForDetails({} as Contract)}
          contract={planForDetails}
          divRef={divRef}
          showCloseButton={true}
        />
      )}
      {planForDetails && Array.isArray(planForDetails.plan) && (planForDetails?.plan[0]?.planType === PLAN_TYPES_NAMES.DENTAL) && (
        <DentalPlanDetails
          closeDentalPlanDetails={() => setPlanForDetails({} as Contract)}
          contract={planForDetails}
          isCurrentCoveragePlan={false}
          showCloseButton={true}
        />
      )}
      {planForDetails && Array.isArray(planForDetails.plan) && (planForDetails?.plan[0]?.planType === PLAN_TYPES_NAMES.VISION) && (
        <VisionPlanDetails
          isCurrentCoveragePlan={false}
          contract={planForDetails}
          closeVisionPlanDetails={() => setPlanForDetails({} as Contract)}
          showCloseButton={true}
        />
      )}
    </div >
  );
};

export default PlanSelectionSummary;
