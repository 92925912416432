import { CHINESE_LINKS, KOREAN_LINKS, SPANISH_LINKS, TAGALOG_LINKS, VIETNAMESE_LINKS } from './GlobalFooterConstants';

const getlink = (lan: string) => {
  let linkobj;
  switch (lan) {
    case 'chinese':
      linkobj = CHINESE_LINKS;
      break;
    case 'vietnamese':
      linkobj = VIETNAMESE_LINKS;
      break;
    case 'korean':
      linkobj = KOREAN_LINKS;
      break;
    case 'tagalog':
      linkobj = TAGALOG_LINKS;
      break;
    case 'spanish':
      linkobj = SPANISH_LINKS;
      break;
    default:
      linkobj = CHINESE_LINKS;
  }

  return linkobj;
};
export { getlink };
