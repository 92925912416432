import { create } from 'zustand';
import { createJSONStorage, devtools, persist } from 'zustand/middleware';
import { USER_ROLES } from '../shared/globalConstants';

const initialState = {
  loggedIn: false,
  userName: '',
  userFirstName: '',
  userLastName: '',
  userEmailAddress: '',
  userPhoneNumber: '',
  userPhoneNumberType: '',
  userSMSConsent: '',
  userDateOfBirth: '',
  zipCode: '',
  currentDateTimeInZone: '',
  planYear: '',
  county: '',
  state: 'DEFAULT',
  role: USER_ROLES.SHOPPER,
  contacts: [],
  secretQuestionAnswers: [],
  pingRiskId: '',
  resetId: '',
  isWellpoint: false,
  showCreateProfileFlag: false,
  showLoginButtonFlag: false,
  webAccountGUID: '',
  shopperId: '',
  demographicInfo: {},
  jwtToken: '',
  quotes: [],
  favourites: [],
  isPHILogin: false,
  webAccessId: ''
};

export const useUserStore = create(
  persist(
    devtools<UserStore>((set, get) => ({
      ...initialState,
      updateZipCode: (newZipCode: string) => set({}),
      updateLoginResponse: (loginPayload: LoginSuccessPayload) =>
        set({
          userName: loginPayload.userName,
          role: loginPayload.role,
          contacts: loginPayload.contacts,
          secretQuestionAnswers: loginPayload.secretQuestionAnswers,
          pingRiskId: loginPayload.pingRiskId,
          userFirstName: loginPayload.userFirstName,
          userLastName: loginPayload.userLastName,
          userEmailAddress: loginPayload.userEmailAddress,
          userPhoneNumber: loginPayload.userPhoneNumber,
          userPhoneNumberType: loginPayload.userPhoneNumberType,
          userSMSConsent: loginPayload.userSMSConsent,
          userDateOfBirth: loginPayload.userDateOfBirth
        }),
      updateValidateResetIDResponse: (validateResetPwdIdPayload: ValidateResetIDSuccessPayload) =>
        set({
          resetId: validateResetPwdIdPayload.resetId,
          // brand: validateResetPwdIdPayload.brand,
          contacts: validateResetPwdIdPayload.contacts,
          secretQuestionAnswers: validateResetPwdIdPayload.secretQuestionAnswers,
          pingRiskId: validateResetPwdIdPayload.pingRiskId
        }),
      updateLoginStatus: (loggedInStatus: boolean) =>
        set({
          loggedIn: loggedInStatus
        }),
      updateBrandSite: (isWellpointUrl: boolean) =>
        set({
          isWellpoint: isWellpointUrl
        }),

      updateShowCreateProfileFlag: (createProfileFlag: boolean) =>
        set({
          showCreateProfileFlag: createProfileFlag
        }),
      updateShowLoginButtonFlag: (loginbuttonflag: boolean) =>
        set({
          showLoginButtonFlag: loginbuttonflag
        }),

      updateProfileInformation: (updateProfilePayload: UpdateProfileInformation) =>
        set({
          userFirstName: updateProfilePayload.userFirstName,
          userLastName: updateProfilePayload.userLastName,
          userEmailAddress: updateProfilePayload.userEmailAddress,
          userPhoneNumber: updateProfilePayload.userPhoneNumber,
          userPhoneNumberType: updateProfilePayload.userPhoneNumberType,
          userSMSConsent: updateProfilePayload.userSMSConsent,
          userDateOfBirth: updateProfilePayload.userDateOfBirth
        }),

      updateSecretQuestionAnswers: (updateSecretQAndAs: UpdateSecretQAndAs) =>
        set({
          secretQuestionAnswers: updateSecretQAndAs.secretQuestionAnswers
        }),

      updateWebAccount: (updateWebAccount: UpdateWebAccount) => {
        set({
          webAccountGUID: updateWebAccount.webAccountGUID,
          shopperId: updateWebAccount.shopperId,
          webAccessId: updateWebAccount.webAccessId
        });
      },
      updateDemographicInfo: (demographicInfo: DemographicInfo) => {
        set({
          demographicInfo: demographicInfo
        });
      },
      updateMemberPHILoginResponse: (loginPayload: MemberPHILoginSuccessPayload) =>
        set({
          role: loginPayload.role,
          userFirstName: loginPayload.userFirstName,
          userLastName: loginPayload.userLastName,
          userDateOfBirth: loginPayload.userDateOfBirth,
          isPHILogin: true
        }),
      updateJWTToken: (updateJWTToken: updateJWTToken) => {
        set({
          jwtToken: updateJWTToken.jwtToken
        });
      },
      updateQuotes: (quotes: Quotes[]) => {
        set({
          quotes: quotes
        });
      },
      updateFavourites: (favourites: Favourites[]) => {
        set({ favourites: favourites });
      },
      resetUserStore: () => set(() => ({ ...initialState, isWellpoint: get().isWellpoint })),
      updateRole: (userrole: string) => {
        set({ role: userrole });
      }
    })),

    {
      name: 'user-store',
      storage: createJSONStorage(() => sessionStorage)
    }
  )
);
