import { Button, Modal } from '@sydney-broker-ui/ios';
import React, { useCallback, useEffect, useState } from 'react';
import { getCMSStaticContent } from '../../../../src/api/cms/cmsUtil';
import TwoFAModalBody from '../../../pages/public/two-fa-modal/TwoFAModalBody';
import { TWO_FA_USE_CASES } from '../../../pages/public/two-fa-modal/constants';
import { default as ContentTWOFA } from '../../../pages/public/two-fa-modal/twoFAModal.json';
import { useGlobalStore } from '../../../store/globalStore';
import { useUserStore } from '../../../store/userStore';
import ContentBlock from '../../common/content-block/ContentBlock';
import LearnMoreModal from '../learn-more-modal/LearnMoreModal';
import { default as Content } from '../learn-more-modal/learnMoreModal.json';
import LoginModal from './../../../pages/public/login/login-modal/LoginModal';
import { CALIFORNIA_CODE, LANGUAGE_CODES, SCREEN_NAMES, USER_ROLES } from './../../../shared/globalConstants';

const twoFAHeading = ContentTWOFA.data.iospagecontentList.items[0].pageContent.TWO_FA.HEADING;

const Questioncards = (props) => {
  const [openLearnMoreModal, setOpenLearnMoreModal] = useState(false);
  const [stateCode, setStateCode] = useState('');
  const { zipCodeRes, memberInfo } = useGlobalStore((store) => store);
  const { loggedIn, role, isPHILogin, isWellpoint } = useUserStore((store) => store);
  const [pageContent, setPageContent] = useState(null);
  const [contentLoaded, setContentLoaded] = useState(false);
  const [showLoginModal, setShowLoginModal] = useState(false);
  const [showTwoFAModal, setShowTwoFAModal] = useState(false);
  const [modalHeading, setModalHeading] = useState('');
  const [showB1, setShowB1] = useState(props.disableBlock1?.toString()==='false'?true:false);
  const [showB2, setShowB2] = useState(props.disableBlock2?.toString()==='false'?true:false);
  const [showB3, setShowB3] = useState(props.disableBlock3?.toString()==='false'?true:false);
  const [showB4, setShowB4] = useState(props.disableBlock4?.toString()==='false'?true:false);

  const getContent = useCallback(async () => {
    try {
      const response = await getCMSStaticContent(LANGUAGE_CODES.ENGLISH, SCREEN_NAMES.LEARN_MORE_MODAL);
      let cmsResponse = response.data;

      setPageContent(cmsResponse.data.iospagecontentList.items[0].pageContent?.LearnMoreModal);
    } catch (error) {
      setPageContent(Content.data.iospagecontentList.items[0].pageContent?.LearnMoreModal);

      return false;
    } finally {
      setContentLoaded(true);
    }
    return true;
  }, [zipCodeRes.stateCode]);

  const initiate = async () => {
    await getContent();
  };
  useEffect(() => {
    setStateCode(zipCodeRes.stateCode);
  }, [zipCodeRes.stateCode]);

  useEffect(() => {
    initiate();
  }, []);

  const closeLearMoreModal = () => {
    document.body.classList.remove('no-scroll');
    setOpenLearnMoreModal(false);
  };

  const handleModalClose = (e) => {
    setShowLoginModal(false);
  };

  const handleTwoFAModalClose = () => {
    setShowTwoFAModal(false);
  };
  const updateQCardLogin = () => {
    // update the Question card block's hide/show status 
    if(loggedIn){
      setShowB1(props.b1isLogin?.toString()==='true' && (props.b1roles.indexOf(role.toLowerCase()) !== -1));
      setShowB2(props.b2isLogin?.toString()==='true' && (props.b2roles.indexOf(role.toLowerCase()) !== -1));
      setShowB3(props.b3isLogin?.toString()==='true' && (props.b3roles.indexOf(role.toLowerCase()) !== -1));
      setShowB4(props.b4isLogin?.toString()==='true' && (props.b4roles.indexOf(role.toLowerCase()) !== -1));
      if(isPHILogin){
        setShowB1(props.b1isLogin?.toString()==='true' && props.b1isPHILogin?.toString()==='true' && (props.b1roles.indexOf(role.toLowerCase()) !== -1));
        setShowB2(props.b2isLogin?.toString()==='true' && props.b2isPHILogin?.toString()==='true' && (props.b2roles.indexOf(role.toLowerCase()) !== -1));
        setShowB3(props.b3isLogin?.toString()==='true' && props.b3isPHILogin?.toString()==='true' && (props.b3roles.indexOf(role.toLowerCase()) !== -1));
        setShowB4(props.b4isLogin?.toString()==='true' && props.b4isPHILogin?.toString()==='true' && (props.b4roles.indexOf(role.toLowerCase()) !== -1));
      }
      if(role === USER_ROLES.MEMBER && memberInfo.ffmonExgCoverage){
        setShowB1(props.b1isLogin?.toString()==='true' && props.b1ffmonExgCoverage?.toString()==='true' && (props.b1roles.indexOf(role.toLowerCase()) !== -1));
        setShowB2(props.b2isLogin?.toString()==='true' && props.b2ffmonExgCoverage?.toString()==='true' && (props.b2roles.indexOf(role.toLowerCase()) !== -1));
        setShowB3(props.b3isLogin?.toString()==='true' && props.b3ffmonExgCoverage?.toString()==='true' && (props.b3roles.indexOf(role.toLowerCase()) !== -1));
        setShowB4(props.b4isLogin?.toString()==='true' && props.b4ffmonExgCoverage?.toString()==='true' && (props.b4roles.indexOf(role.toLowerCase()) !== -1));
      }
    }
  }
  useEffect(() => {
    updateQCardLogin();
  }, [loggedIn,isPHILogin]);
  console.debug('Questioncards.js props: ',props);
  console.debug('Questioncards show Block status:',showB1, showB2, showB3, showB4);
  return (
    <div className="fwc-container">
      <div className="fwc-row question-cards">
        {showB1 && (
          <div className="qc-cont">
            <ContentBlock title={props.b1title} body={props.b1body} icon={props.b1icon}></ContentBlock>
          </div>
        )}

        {showB2 && (
          <div className="qc-cont">
            <ContentBlock title={props.b2title} body={props.b2body} icon={props.b2icon}></ContentBlock>
          </div>
       )}

        {showB3 && (
          <div className="qc-cont">
            <ContentBlock title={props.b3title} body={props.b3body} icon={props.b3icon}></ContentBlock>
          </div>
        )}
        {showB4 && (
          <div className="qc-cont">
            <ContentBlock
              title={props.b4title}
              body={props.b4body}
              icon={props.b4icon}
              modal={props.b4json.toString()==="true"?true:false}
              linkLabel={(props.b4jsondescription && JSON.parse(props.b4jsondescription)?.LEARN) || pageContent?.LEARN}
              description={(props.b4jsondescription && JSON.parse(props.b4jsondescription)?.CALL_US) || pageContent?.CALL_US}
              clickLearnMoreModal={() => {
                setOpenLearnMoreModal(true);
              }}
            ></ContentBlock>
          </div>
        )}
        {role === USER_ROLES.MEMBER && isPHILogin && (
          <div className="qc-cont">
            <div className="content-block">
              <div className="cb-cont">
                <div className="text-content">
                  <strong>{pageContent?.PHI_LOGIN?.RETURN_LABEL}</strong>
                  <p>{pageContent?.PHI_LOGIN?.LOGIN_TEXT}</p>
                </div>
                <div className="cb-content">
                  <button
                    className="fwc-btn fwc-btn-primary left-xs"
                    id={'log-in'}
                    data-analytics="logInButtonTopNavIos"
                    onClick={(e) => {
                      setShowLoginModal(true);
                    }}
                  >
                    {pageContent?.PHI_LOGIN?.LOGIN_BUTTON}
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {openLearnMoreModal && contentLoaded && (
        <Modal
          content="This content will be overridden with html body content if exist any!"
          id="learn-more-modal"
          onClose={closeLearMoreModal}
          title={stateCode === CALIFORNIA_CODE ? pageContent?.CA.TITLE : pageContent?.NON_CA.TITLE}
          open={openLearnMoreModal}
        >
          <Modal.Body>
            <LearnMoreModal stateCode={stateCode} Strings={pageContent} />
          </Modal.Body>
          <Modal.Footer>
            <Button id="modal-slide-select" onClick={closeLearMoreModal}>
              {pageContent?.CLOSE}
            </Button>
          </Modal.Footer>
        </Modal>
      )}

      <Modal open={showLoginModal} onClose={handleModalClose} title={pageContent?.PHI_LOGIN?.LOGIN_MODAL_TITLE}>
        <Modal.Body>
          <LoginModal
            showModal={showLoginModal}
            closeLoginModal={() => {
              setShowLoginModal(false);
            }}
            initiateTwoFa={() => {
              setShowLoginModal(false);
              setModalHeading(twoFAHeading);
              setShowTwoFAModal(true);
            }}
          />
        </Modal.Body>
      </Modal>
      <Modal open={showTwoFAModal} onClose={handleTwoFAModalClose} title={modalHeading}>
        <Modal.Body>
          <TwoFAModalBody
            useCase={TWO_FA_USE_CASES.LOGIN}
            showModal={showTwoFAModal}
            showFooter={true}
            closeModal={handleTwoFAModalClose}
            hideModalHeading={() => setModalHeading('')}
            updateModalHeading={(heading) => setModalHeading(heading)}
          />
        </Modal.Body>
      </Modal>
    </div>
  );
};

export default Questioncards;

export const QuestioncardsIsEmpty = (props) => !(props.b1title || props.b1body) && !(props.b2title || props.b2body) && !(props.b3title || props.b3body) && !(props.b4title || props.b4body);
