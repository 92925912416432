import { API_NAME_LIST } from '../api-name-list';
import axiosInstance from '../axiosWrapper/axiosWrapper';

//TODO: refactor using axios wrapper and env variables
export const getZipcodeValidation = (payload: ZipCodeValidationPayload) => {
  return axiosInstance.post(API_NAME_LIST.VALIDATE_ZIPCODE, payload);
};

export const getMarketingIdValidated = (marketingId: string) => {
  return axiosInstance.post(API_NAME_LIST.VALIDATE_MARKETINGID, {}, {
    headers: {
      refNum: marketingId
    }
  });
};

export const getContactUs = (request) => {
  return axiosInstance.post(API_NAME_LIST.CONTACT_US, request);
};

export const getProviderDetails = (payload: GetProviderDetails) => {
  return axiosInstance.post(API_NAME_LIST.PROVIDER_DETAILS, payload);
};

export const getVisionRecommendations = (payload: VisionRecommendationPayload) => {
  return axiosInstance.post(API_NAME_LIST.VISION_RECOMMENDATION, payload);
};

export const guestUserLogin = (payload: GuestUserLoginPayload) => {
  return axiosInstance.post(API_NAME_LIST.GUEST_USER_LOGIN, payload);
};

export const validatePinAndDateOfBirthByAcn = (payload: ValidatePinAndDateOfBirthByAcnPayload) => {
  return axiosInstance.post(API_NAME_LIST.VALIDATE_PIN_AND_DOB_BY_ACN, payload);
};

export const transferApplicationBetweenAgentAndConsumer = (payload: transferApplicationBetweenAgentAndConsumerPayload) => {
  return axiosInstance.post(API_NAME_LIST.TRANSFER_APPLICATION_BETWEEN_AGENT_AND_CONSUMER, payload);
};
